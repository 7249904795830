import axios from "axios";
import React, { useCallback, useEffect, useReducer } from "react";
import IconSpinner from "../UI_elements/iconSpinner";
import { useInit } from "../hooks/useInit";
import MultiSelectDropdown from "../inputs/multiSelectDropdown";

const tagReducer = (state, action) => {
  switch (action.type) {
    case "INIT_TAGS":
      return action.tags.map((data) => {
        return {
          type: data.type,
          name: data.name,
          value: data._id,
          data: data,
        };
      });

    case "ADD_TEMP_TAG":
      return [{ name: action.newTag }, ...state];

    case "ADD_TAG":
      const addedTag = {
        type: action.tag.type,
        name: action.tag.name,
        value: action.tag._id,
        data: action.tag,
      };

      // const updatedState = state.map((tag) => {
      //   // if (tag.name === action.tag.name) {
      //     return { ...addedTag };
      //   // } else {
      //   //   return tag;
      //   // }
      // });

      return [addedTag, ...state];

    default:
      return state;
  }
};

const TagSelector = ({
  inputVer,
  inputHandler,
  defaultValue,
  sid,
  disabled,
  id,
  notRequired,
  type,
  label,
  selectionDisplayText,
  toolTip,
  tagsToHighlight,
  tagsActiveOnOtherWorkflows,
  pendingTextOverride,
}) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);

  const [tagState, dispatch] = useReducer(tagReducer, null);

  const sendRequest = () => {
    initStarted(0);

    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/tags/get/${type}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        dispatch({
          type: "INIT_TAGS",
          tags: response.data.tags,
        });

        initCompleted(0);
      })
      .catch((err) => {
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  useEffect(() => {
    sendRequest();
  }, []);

  const CreateTagHandler = (newTag) => {
    initStarted(1);

    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/tags/create/${type}`,
        { tagName: newTag },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        dispatch({
          type: "ADD_TAG",
          tag: response.data.tag,
        });

        initCompleted(1);
      })
      .catch((err) => {
        console.log(err);
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
      });
  };

  return !initState[0].started && tagState && defaultValue ? (
    <MultiSelectDropdown
      notRequired={notRequired}
      label={label}
      sid={sid}
      onSelection={inputHandler}
      defaultValue={defaultValue}
      create={label}
      creating={initState[1].started}
      onCreate={CreateTagHandler}
      options={tagState}
      selectionDisplayText={selectionDisplayText}
      id={id}
      inputVer={inputVer}
      dontAutoSelect
      toolTip={toolTip}
      disabled={disabled}
      tagsToHighlight={tagsToHighlight ?? []}
      tagsActiveOnOtherWorkflows={tagsActiveOnOtherWorkflows ?? []}
      pendingTextOverride={pendingTextOverride}
      dataVaries={defaultValue[0]?.value === "Varies"}
    />
  ) : (
    <div>
      <div className="text-sm h-5">{label}</div>
      <div className="flex text-xs w-full items-center border h-8 rounded pl-2 my-2 border-taskinatorMedGrey">
        <IconSpinner /> <div className="capitalize ml-2">{`Loading ${type} Tags`}</div>
      </div>
    </div>
  );
};

export default TagSelector;
