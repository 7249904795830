import axios from "axios";
import { useContext, useEffect, useReducer, useState } from "react";
import { MdError } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import { AppContext } from "../../../contexts/appContext";
import Button from "../../UI_elements/button";
import TaskActionDisplayForm from "../../forms/taskActionDisplayForm";
import { useInit } from "../../hooks/useInit";
import AddStepDisplayButton from "../addStepDisplayButton";
import { updateJobWorkflow } from "../../../services/job.service";

const inputReducer = (state, action) => {
  switch (action.type) {
    case "ACTION_CHANGE":
      const {
        actionData,
        isActive,
        tracerId,
        stageUUID,
        tracerStatus,
        stageIndex,
        isInitial,
        completed,
        isNew,

        ...aidDetails
      } = action;
      let updatedState;

      if (state[action._id]) {
        updatedState = {
          ...state,
          [action._id]: {
            ...state[action._id],
            actionData: aidDetails,
            tracerId: tracerId,

            stageIndex: stageIndex,
            tracerStageId: stageUUID,
            isActive: action.isActive,
            assignee: actionData.assignee
              ? actionData.assignee.map((assignee) => {
                  return {
                    _id: assignee._id,
                    firstName: assignee.firstName,
                    lastName: assignee.lastName,
                    locked: assignee.locked,
                  };
                })
              : [],
            status: tracerStatus,
            isInitial: isInitial,
            completed: completed,

            isNew: isNew,
          },
        };
      } else {
        updatedState = {
          ...state,
          [action._id]: {
            actionData: aidDetails,

            tracerStageId: stageUUID,
            stageIndex: stageIndex,
            isActive: action.isActive,
            assignee: actionData.assignee
              ? actionData.assignee.map((assignee) => {
                  return {
                    _id: assignee._id,
                    firstName: assignee.firstName,
                    lastName: assignee.lastName,
                    locked: assignee.locked,
                  };
                })
              : [],
            status: tracerStatus,
            firstAssigneeState: actionData.assignee
              ? actionData.assignee.map((assignee) => {
                  return {
                    _id: assignee._id,
                    firstName: assignee.firstName,
                    lastName: assignee.lastName,
                    locked: assignee.locked,
                  };
                })
              : [],
            isInitial: isInitial,
            completed: completed,

            isNew: isNew,
          },
        };
      }
      return updatedState;

    default:
      return state;
  }
};

const EditWorkflow = ({ wfid, selStageIds = [], actionPlan, tracer, taskDetails, releasing, splitFilesByTask, selectedWorkflowData, handleCloseModal = () => {} }) => {
  const appData = useContext(AppContext);
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  const [workflow, setWorkflow] = useState({});
  const [stageUUIDS, setStageUUIDS] = useState();
  const [inputState, dispatchLocal] = useReducer(inputReducer, {});

  const isManager = true;
  const atLeastOneIsActive = inputState ? Object.values(inputState).filter((action) => action.isActive === true).length >= 1 : false;

  const activeActionsHaveAssignees = inputState ? Object.values(inputState).filter((action) => action.isActive && action.assignee.length === 0).length === 0 : true;

  const isSave = inputState ? Object.values(inputState).filter((action) => action.tracerId).length >= 1 : false;

  let currentTaskIds = [];
  (Object.values(splitFilesByTask) ?? []).forEach((task) => {
    (task?.files ?? []).forEach((file) => {
      currentTaskIds = [...currentTaskIds, file.currentJobs.taskId];
    });

    // const workflowIndex = file.workflowStatus.findIndex((wf) => wf._id === selectedWorkflowData._id);

    // if (workflowIndex >= 0) {
    //   (file?.workflowStatus[workflowIndex]?.currentFilePosition ?? []).map((item) => (currentTaskIds = [...currentTaskIds, item?._id]));
    // }
  });

  const dispatchDistributor = (data) => {
    dispatchLocal(data);
  };

  const saveClickHandler = () => {
    const activeStages = Object.values(inputState).filter((stage) => stage?.isActive);
    initStarted(1);

    axios
      .post(
        process.env.REACT_APP_BACKEND_URL + `/api/job/update_workflow/${appData?.viewJob?.jobId}/${selectedWorkflowData?._id}/${selectedWorkflowData?.workflowData?.workflowGroupId}`,
        activeStages,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        initCompleted(1);
        handleCloseModal();
      })
      .catch((err) => {
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
      });

    // updateJobWorkflow({
    //   jobId: appData?.viewJob?.jobId,
    //   workflowId: selectedWorkflowData?._id,
    //   wfgId: selectedWorkflowData?.workflowData?.workflowGroupId,
    //   data: activeStages,
    //   onFinish: (res, err) => {
    //     initCompleted(1);
    //     if (!res || err) initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
    //     else handleCloseModal();
    //   },
    // });
  };

  useEffect(() => {
    if (actionPlan) {
      setStageUUIDS(Array.from({ length: actionPlan.length }, () => uuidv4()));
    }
  }, [actionPlan]);

  return (
    <div>
      {!initState[0].started && initState[0].error && (
        <div className="w-96 h-96 items-center justify-center flex">
          <div className="pr-1">
            <MdError color="red" />
          </div>
          <p className="text-red-500">{initState[0].error && `Failed to load workflow data`}</p>
        </div>
      )}

      {!initState[0].started && !initState[0].error && (
        <div className="flex flex-col overflow-auto scrollbar mt-1 xl:grow h-fit">
          <div className="flex flex-col xl:flex-row px-4 py-2 w-full xl:grow overflow-auto">
            {(actionPlan ?? []).map((stage, stageIndex) => {
              return (
                <div key={stageIndex} className="items-center flex flex-col xl:flex-row">
                  <div className="flex xl:flex-col flex-row items-center justify-center">
                    {(stage?.workflow ?? []).map((task, tIndex) => {
                      return (
                        <div key={tIndex} className="flex items-center p-2">
                          {isManager ? (
                            <TaskActionDisplayForm
                              actionData={task}
                              tracerStatus={currentTaskIds?.find((item) => item === task?._id.toString()) ? "live" : ""}
                              isSelected={task.isActive}
                              assignees={task.assignee}
                              dispatch={dispatchDistributor}
                              stageIndex={stageIndex}
                              dontAutoSelect
                              // stageUUID={tracerStageIdToUse}
                              // tracerStatus={isActionPlan ? isActionPlan.status : null}
                              // tracerId={
                              //   currentlySetActionData
                              //     ? null
                              //     : isActionPlan
                              //     ? isActionPlan.tracerId
                              //     : null
                              // }
                              // stateId={stateId}
                              // isSelected={selStageIds.find((selStageId) => selStageId?._id === stage?._id)}
                              // completed={isActionPlan ? isActionPlan.completed : null}
                              // thirdPartyAccessCode={actionData.thirdPartyAccessCode}
                            />
                          ) : (
                            <div className="uppercase border rounded-lg shadow border-taskinatorGreen p-2 text-xs text-taskinatorGreen font-semibold text-center w-fit">{task?.name}</div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  {actionPlan.length > 0 && stageIndex < actionPlan.length - 1 && (
                    <div className="hidden xl:block">
                      <AddStepDisplayButton notClickable />
                    </div>
                  )}
                  {actionPlan.length > 0 && stageIndex < actionPlan.length - 1 && (
                    <div className="block xl:hidden">
                      <AddStepDisplayButton notClickable rotate />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          {isManager && (
            <div className="flex justify-center">
              <Button
                customClasses={`${atLeastOneIsActive && activeActionsHaveAssignees ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"} text-taskinatorWhite h-10 w-full mt-4`}
                label={`${isSave ? "Save & Update" : "Save & Release"}`}
                disabled={!atLeastOneIsActive || !activeActionsHaveAssignees}
                isLoading={initState[1].started}
                error={initState[1].error}
                onClick={() => saveClickHandler()}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EditWorkflow;
