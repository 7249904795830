import React, { useEffect, useContext } from "react";
import CheckboxV4 from "../../inputs/checkbox_V4";
import FileDetails from "./fileDetails";
import Button from "../../UI_elements/button";
import { FileContext } from "./FileContext";

const FilesileListViewDisplayer = ({
  isVisible,
  index,
  file,
  fileSetId,
  fileId,
  inJob,
  isSelected,
  onSelectHandler,
  localOnly,
  removeFileHandler,
  selectAll,
  setFileSelection,
  setSelectAll,
  initState,
  showAuto,
  hideCheckbox,
}) => {
  const fileContext = useContext(FileContext);

  useEffect(() => {
    if (fileContext?.bulkFileSelection?.[fileId] && !isVisible) {
      onSelectHandler(false, fileId, file);
    }

    if (isSelected) {
      setFileSelection();
    }
  }, [isVisible]);

  return (
    <div
      key={fileId ?? index}
      className={`${isSelected ? "bg-taskinatorHoverBlue " : "bg-taskinatorWhite"} flex  items-center cursor-pointer hover:bg-taskinatorHoverBlue mt-0.5 text-taskinatorDarkGrey ${
        isVisible ? "" : "hidden"
      }`}
    >
      <div
        className="w-8 items-center justify-center flex ml-1.5"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {!localOnly && !hideCheckbox && (
          <CheckboxV4
            id={fileId}
            sid="fileOnSet"
            defaultValue={selectAll ? selectAll : !!fileContext?.bulkFileSelection?.[fileId]}
            onInput={(id, isChecked, isValid, sid) => onSelectHandler(isChecked, id, file, fileSetId)}
            customCheckbox={"h-5 w-5"}
          />
        )}
      </div>
      <div
        className="flex   grow h-full py-1.5"
        onClick={
          setFileSelection
            ? () => {
                console.log("setting selected file");
                setSelectAll(false);
                fileContext.setBulkFileSelection();
                setFileSelection({ file: file, fileSetId: fileSetId });
              }
            : () => {}
        }
      >
        <FileDetails file={file} wide localOnly={localOnly} showAuto={showAuto} />
        {file.status === "pending" && !inJob && !localOnly && !hideCheckbox && (
          <div className="flex grow justify-end">
            <div
              className="w-fit"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <Button
                onClick={() => {
                  removeFileHandler(fileId);
                }}
                label={"Delete"}
                customClasses={"text-taskinatorRed opacity-50 hover:opacity-100 hover:border-taskinatorRed hover:border"}
                isLoading={initState[0].started && initState[0].identifier === fileId}
              />
            </div>
          </div>
        )}{" "}
      </div>
    </div>
  );
};

export default FilesileListViewDisplayer;
