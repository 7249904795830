import React, { useContext, useState } from "react";
import { TaskContext } from "../../../contexts/taskContext";
import Button from "../../UI_elements/button";
import Modal from "../../UI_elements/modal";
import NewFileSetForm from "../../forms/newFileSetForm";
import { useInit } from "../../hooks/useInit";

const CreateFileSet = ({ fileSet, onResponse, onFilesUploaded, customButtonCSS }) => {
  const [createFocus, setCreateFocus] = useState();
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();

  const label = fileSet ? "Edit File Set" : "Create File Set";
  const [modalLabel, setModalLabel] = useState(label);

  return (
    <>
      <Button
        label={label}
        onClick={() => setCreateFocus(true)}
        customClasses={customButtonCSS ? customButtonCSS : "bg-taskinatorBlue border-taskinatorBlue text-taskinatorWhite h-10 text-xs md:text-sm ml-2 py-2"}
      />

      {createFocus && (
        <Modal
          title={modalLabel}
          local={() => {
            setCreateFocus();
          }}
        >
          <NewFileSetForm
            fileSet={fileSet}
            onResponse={onResponse}
            closeFocusModal={() => {
              setCreateFocus();
            }}
            onFilesUploaded={(fileSetId) => {
              onFilesUploaded(fileSetId);
              setCreateFocus();
            }}
            setModalLabel={setModalLabel}
          />
        </Modal>
      )}
    </>
  );
};

export default CreateFileSet;
