import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../contexts/appContext";

import FormatDate from "../../../utils/formattedDate";
import DocumentDisplay from "../../UI_elements/documentDisplay";

import LinkifyText from "../../../utils/linkifyText";

const JobSubmissionBubble = ({ submission }) => {
  const appData = useContext(AppContext);
  const isYou = submission.createdBy._id.toString() === appData.workspaceDataV4.user_id;
  const [showDetails, setShowDetails] = useState();
  // if (index <= loadMoreComments) {

  return (
    <div key={submission._id} className="p-2">
      <div className="flex items-center my-1 justify-between text-xs font-semibold text-taskinatorDarkGrey ">
        <div className="capitalize pr-1 ">
          {" "}
          {submission.createdBy.firstName} {submission.createdBy.lastName}
        </div>
        {FormatDate(submission.createdDate)}{" "}
      </div>
      <div className="flex w-full items-start">
        <div className={`w-full  `}>
          <div
            className={`  ${
              isYou
                ? `${submission.critical ? "bg-taskinatorAlertRed text-taskinatorBlack" : "bg-taskinatorPaper text-taskinatorDarkGrey"} rounded-br-none  `
                : `${
                    submission.critical
                      ? "bg-taskinatorAlertRed text-taskinatorBlack"
                      : `${submission.thirdParty ? "bg-taskinatorHoverOrange text-taskinatorDarkGrey border-taskinatorMedGrey border" : "rounded-bl-none  bg-taskinatorPaper text-taskinatorDarkGrey"}`
                  }    `
            }   px-2 py-1 grow  shadow `}
          >
            <div className=" text-sm    ">
              <div className="flex items-center">
                {/* {actionComment.critical && (
                  <div className="pr-1">
                    <MdNotificationsActive />
                  </div>
                )} */}
                <div className="flex items-center ">
                
                  <div className="  flex items-center">
                  <b className="pr-1 ">{submission?.action ?? "submitted"}</b> at
                    <div className="mx-1 text-xs border border-taskinatorDarkGrey px-1 capitalize  rounded items-center">{submission.taskName}</div> in{" "}
                    <div className="mx-1 text-xs border border-taskinatorDarkGrey px-1 capitalize  rounded">{submission.workflowName}</div> workflow.{" "}
                  </div>
                </div>
              </div>
           

              {/* {(submission?.comments || submission?.attachments?.length > 0) && ( */}
                <div className={`flex flex-col items-start border-t ${submission.critical ? "border-t-taskinatorDarkGrey" : "border-t-taskinatorMedGrey"} pt-2 mt-1 py-1`}>
                  <div className="whitespace-break-space flex justify-start w-full ">{submission.comments ? <LinkifyText text={submission.comments} /> : <div className="text-xs">No Instructions Submitted</div>}</div>
                  {submission.attachments.length > 0 && (
                    <div className="font-medium pt-2">
                      {submission.attachments.map((attachment) => {
                        return <DocumentDisplay attachment={attachment} colorChange={"bg-taskinatorLightGrey text-taskinatorDarkGrey"} />;
                      })}
                    </div>
                  )}
                </div>
              {/* )} */}
            </div>
          </div>
          {/* <div
            onClick={() => {
              setShowDetails(!showDetails);
            }}
            className={`cursor-pointer underline text-center text-taskinatorMedGrey  ${showDetails ? "" : ""} text-xxs `}
          >
            {showDetails ? "Hide" : "Show Full Log"}
          </div> */}

          {/* {actionComment.critical && actionComment.acknowledgedBy && actionComment.acknowledgedBy.length > 0 && (
            <div className="pt-2 flex items-center">
              <div className="text-xxs pr-1">Acknowledged:</div>
              {actionComment.acknowledgedBy.map((user) => {
                if (user._id === appData.workspaceDataV4.user_id && !user.acknowledged) {
                  return (
                    <div className="animate-pulse">
                      <AcknowledgeNotificationButton tracerWorkflowGroupId={tracerWorkflowGroupId} logId={actionComment._id} getNotifications={() => setRefresh(!refresh)} />
                    </div>
                  );
                } else {
                  return (
                    <div className={`${user.acknowledged ? "" : "opacity-30"}`}>
                      <UserDisplayBubble
                        textSize={"text-xxs"}
                        colourOverride={user.acknowledged ? "bg-taskinatorHoverGreen text-taskinatorWhite" : null}
                        firstName={user.firstName}
                        lastName={user.lastName}
                      >
                        {user.acknowledged && (
                          <div className="pr-1">
                            {" "}
                            <MdCheckCircle />{" "}
                          </div>
                        )}
                      </UserDisplayBubble>
                    </div>
                  );
                }
              })}
            </div>
          )}{" "} */}
        </div>
      </div>
    </div>
  );
};

export default JobSubmissionBubble;
