import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import IconSpinner from "../components/UI_elements/iconSpinner";

import Modal from "../components/UI_elements/modal";
import { AppContext } from "../contexts/appContext";
import AdobePDFViewerContainer from "./adobePDFViewerContainer";
import { MdFolderZip, MdImage, MdPictureAsPdf } from "react-icons/md";
import { useInit } from "../components/hooks/useInit";
import StringShortenDisplay from "../components/UI_elements/stringShortenDisplay";
import { previewFileExtensions } from "../constants";

const FilePreview = ({ file, viewMarkupsOnly, setMarkupDocument, viewInMarkup, savedMarkupURL, markup, isPreview, showName, notClickable, wide, colorChange, textLimit, noName, small }) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  const [previewURL, setPreviewURL] = useState();
  const [previewError, setPreviewError] = useState();
  const [fileURL, setFileURL] = useState();
  const [modal, setModal] = useState();
  const appData = useContext(AppContext);
  const [expand, setExpand] = useState();

  const getPreviewURL = async (type) => {
    initStarted(1, type);

    if (type === "preview") {
      setPreviewError();
    }

    try {
      const responseData = await axios.get(process.env.REACT_APP_BACKEND_URL + `/api/attachment/get/${file.key}/${type}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      if (!setMarkupDocument) {
        if (type === "preview") {
          setPreviewURL(responseData.data);
        }

        if (type === "file") {
          if (previewFileExtensions.includes(file.fileExtension)) {
            setFileURL(responseData.data.signedURL);
            setModal(responseData.data.signedURL);
          } else {
            window.open(`${responseData.data.signedURL}`, `_blank`, `noopener, noreferrer`);
          }
        }
      } else {
        setMarkupDocument({ ...file, attachmentKey: file.key, pdfFileName: file.fileName, pdfURL: responseData.data.signedURL });
      }

      initCompleted(1, type);
    } catch (err) {
      console.log(err);
      return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1, type);
    }
  };

  useEffect(() => {
    if (isPreview) {
      getPreviewURL("preview");
    }
  }, [file.key, isPreview]);

  return (
    <>
      {isPreview ? (
        <div className="  flex shadow border border-taskinatorMedGrey rounded-lg overflow-hidden">
          {previewURL?.signedURL && !previewError && (
            <div className="flex flex-col">
              <img
                style={{ width: "auto", height: "auto", maxWidth: "100%", maxHeight: "100%" }}
                src={previewURL?.signedURL}
                alt="No Preview"
                className="cursor-pointer items-center justify-center flex scale-50"
                onClick={() => getPreviewURL("file")}
                onError={() => {
                  setPreviewError(true);
                  initCompleted(1, "preview");
                }}
                onLoad={() => initCompleted(1, "preview")}
              />
              {showName && (
                <div className="font-medium pl-1 overflow-clip text-sm text-center bg-taskinatorWhite">
                  {StringShortenDisplay(textLimit ? textLimit : 20, decodeURIComponent(file.fileName))}
                  {file.fileExtension}{" "}
                </div>
              )}
            </div>
          )}
          {previewError && (
            <div
              onClick={() => getPreviewURL("file")}
              className={` cursor-pointer ${
                small ? "h-fit grow" : "h-[300px] w-[175px]"
              } p-4  flex items-center justify-center text-center bg-taskinatorItemBlue text-taskinatorDarkGrey font-bold text-xs`}
            >
              No Preview - Click to view or download{" "}
            </div>
          )}
          {initState[1].error && <div>{initState[1].error}</div>}
          {initState[1].started && initState[1].identifier === "preview" && (
            <div className="flex items-center justify-center text-center grow">
              <IconSpinner />
            </div>
          )}
        </div>
      ) : (
        <>
          {(file?.liveMarkups || file?.markups?.length > 0) && (
            <div className="flex grow  justify-end h-0 pt-3 z-40">
              <div className="relative -top-2 mr-2 items-center flex h-4 w-fit text-taskinatorWhite text-xxs bg-taskinatorRed  px-2 rounded-full font-semibold">MARKUPS</div>
            </div>
          )}
          <div
            className={`flex flex-col `}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div
              className={`flex ${wide ? "w-60" : "w-fit"} rounded border bg-taskinatorItemBlue px-2 py-1  ${!colorChange ? "border-taskinatorBlue text-taskinatorDarkGrey" : colorChange} ${
                notClickable ? "" : "cursor-pointer  hover:bg-taskinatorBlue hover:border-1 hover:text-taskinatorWhite"
              }`}
              onClick={notClickable ? () => {} : () => getPreviewURL("file")}
            >
              <div className="flex justify-start items-center grow">
                {initState[1].started && initState[1].identifier === "file" && (
                  <div className="flex ">
                    <div>{<IconSpinner color={"text-taskinatorBlue"} />}</div>
                  </div>
                )}
                {/* </div>{downloadPercentage !== 100 && <div  className="text-xs pl-1"> {downloadPercentage}%</div> */}
                {!initState[1].started && (
                  <div className="flex items-center justify-center grow ">
                    <div>{file.fileExtension === ".pdf" ? <MdPictureAsPdf size={"1.25rem"} /> : file.fileExtension === ".zip" ? <MdFolderZip size={"1.25rem"} /> : <MdImage size={"1.25rem"} />}</div>
                    {!noName && (
                      <div className="font-medium pl-1 overflow-clip text-xs  ">
                        {StringShortenDisplay(textLimit ? textLimit : 20, decodeURIComponent(file.fileName))}
                        {file.fileExtension}{" "}
                      </div>
                    )}
                    <div className="flex grow justify-end "></div>{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {modal && (
        <Modal
          expand
          size={expand ? "full" : "large"}
          local={() => {
            setModal();
            appData.socket.emit(
              "pdfAnnotation",
              {
                action: "disconnect",
                attachmentKey: file.key,
              },
              (callback) => {}
            );
          }}
          isWithoutClose={file.fileExtension === ".pdf"}
        >
          {file.fileExtension === ".pdf" ? (
            <div className="flex flex-row h-full text-taskinatorDarkGrey">
              <AdobePDFViewerContainer
                expand={expand}
                setExpand={setExpand}
                attachmentKey={file.key}
                pdfFileName={file.fileName}
                pdfURL={fileURL}
                file={file}
                savedMarkupURL={savedMarkupURL}
                viewMarkupsOnly={viewMarkupsOnly}
                uniqueID={file.key}
                close={() => {
                  setModal();
                  appData.socket.emit(
                    "pdfAnnotation",
                    {
                      action: "disconnect",
                      attachmentKey: file.key,
                    },
                    (callback) => {}
                  );
                }}
              />
            </div>
          ) : (
            <div className="h-full w-full flex items-center justify-center">
              <img style={{ width: "auto", height: "auto", maxWidth: "100%", maxHeight: "100%" }} src={modal} alt="No Preview" className="cursor-pointer items-center justify-center flex " />
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default FilePreview;
