import React, { useContext, useEffect, useState } from "react";
import { MdClose, MdPlayArrow } from "react-icons/md";
import IconSpinner from "../components/UI_elements/iconSpinner";
import Modal from "../components/UI_elements/modal";
import { AppContext } from "../contexts/appContext";
import ToolTip from "./toolTip";

const tagCSS = "bg-taskinatorHighlightBlue text-taskinatorDarkGrey mt-1 rounded text-xs py-1 px-4 capitalize font-medium mr-1 shadow flex justify-center items-center";

const JobListFetcher = () => {
  const appData = useContext(AppContext);

  const [tagFilterModal, setTagFilterModal] = useState();
  const [error, setError] = useState();

  //// this function formats the data for us
  const currentTagList = appData?.jobListFilterOptions;
  const selectedTags = appData?.tagFilter && Object.keys(appData.tagFilter);

  const OnAddHandler = (tag) => {
    if (["all tags", "no tags"].includes(tag)) {
      appData.setTagFilter({ [tag]: currentTagList[tag] });
    } else {
      if (Object.keys(appData.tagFilter).filter((tagName) => ["all tags", "no tags"].includes(tagName)).length > 0) {
        appData.setTagFilter({ [tag]: currentTagList[tag] });
      } else {
        if (Object.keys(appData.tagFilter).length === 4) {
          setError("Limit of 4 tags - Remove one from active and try again");
        } else {
          const currentSetTags = appData.tagFilter;
          appData.setTagFilter({
            ...currentSetTags,
            [tag]: currentTagList[tag],
          });
        }
      }
    }
  };

  const onRemoveHandler = (tag) => {
    setError();
    if (tag === "all tags") {
    } else if (tag === "no tags") {
      appData.setTagFilter({ "all tags": appData.tasks });
    } else {
      if (Object.keys(appData.tagFilter).length > 1) {
        const updatedSetFilters = appData.tagFilter;

        delete updatedSetFilters[tag];

        appData.setTagFilter({ ...updatedSetFilters });
      } else {
        appData.setTagFilter({ "all tags": appData.tasks });
      }
    }
  };

  useEffect(() => {
    if (currentTagList) {
      if (appData.tagFilter && Object.keys(appData.tagFilter).length > 0 && appData.tasks) {
      } else {
        appData.setTagFilter({ "all tags": currentTagList["all tags"] });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="py-4 md:flex items-center w-full flex-col hidden">
        <div className="text-taskinatorLightGrey text-xs my-0.5 flex">
          Job Tag Filter
          <ToolTip content={"Limit this workspace to data with the selected job tag"} />
        </div>
        {currentTagList ? (
          <div
            className="md:w-full w-40 flex justify-center items-center md:h-8 md:my-0 my-2 md:mx-0 mx-2 bg-taskinatorPurple rounded text-taskinatorWhite cursor-pointer text-xs"
            onClick={() => {
              setTagFilterModal(true);
              setError();
            }}
          >
            <div className="w-full flex">
              <div className="w-1/6 justify-center items-center flex"> </div>
              <div className="w-4/6 justify-center items-center flex font-medium uppercase">Select Tags</div>
              <div className="w-1/6 justify-center items-center flex">
                <MdPlayArrow size={".75rem"} />
              </div>
            </div>
          </div>
        ) : (
          <IconSpinner />
        )}

        {currentTagList && selectedTags?.length > 0 && (
          <div className="grow flex mx-1 pl-1 flex-col w-full">
            {selectedTags.map((tagName) => {
              const isAllTags = tagName && tagName === "all tags";
              const isNoTags = tagName && tagName === "no tags";

              return (
                <div
                  key={tagName}
                  className={`${tagCSS} flex items-center text-xxs justify-center cursor-pointer hover:opacity-75 ${
                    isAllTags ? "bg-taskinatorOrange text-taskinatorBlack" : isNoTags ? "bg-taskinatorWhite text-taskiantorDarkGrey border border-taskiantorDarkGrey" : ""
                  }`}
                  onClick={!isAllTags && !isNoTags ? () => onRemoveHandler(tagName) : () => {}}
                >
                  {tagName}
                  <div className={`text-taskinatorRed pl-1 text-base hover:opacity-75 cursor-pointer ${isAllTags ? "hidden " : "flex"}`}>
                    <MdClose />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {tagFilterModal && (
        <Modal
          bgColour={"bg-taskinatorLightGrey"}
          title={"Job Tag Filters"}
          local={() => {
            setError();
            setTagFilterModal();
          }}
        >
          <div className="w-96 flex flex-col text-taskinatorDarkGrey p-4">
            <div className="flex flex-col">
              <div className="font-semibold border-b border-b-taskinatorMedGrey">Active</div>
              <div className="flex flex-wrap">
                {selectedTags.map((tagName) => {
                  const isAllTags = tagName && tagName === "all tags";
                  const isNoTags = tagName && tagName === "no tags";
                  return (
                    <div
                      className={`${tagCSS} flex items-center justify-center ${
                        isAllTags ? "bg-taskinatorOrange text-taskinatorBlack" : isNoTags ? "bg-taskinatorWhite text-taskiantorDarkGrey border border-taskiantorDarkGrey" : ""
                      }`}
                      onClick={() => onRemoveHandler(tagName)}
                    >
                      {tagName}
                      <div className={`text-taskinatorRed pl-1 text-base hover:opacity-75 cursor-pointer ${isAllTags ? "hidden " : "flex"}`}>
                        <MdClose />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex flex-col pt-2">
              <div className="font-semibold pt-2 border-b border-b-taskinatorMedGrey">Available</div>
              <div className="flex flex-wrap">
                {Object.keys(currentTagList)
                  .filter((tag) => !selectedTags.includes(tag))
                  .sort((a, b) => a.localeCompare(b))
                  .map((tagName) => {
                    const isAllTags = tagName && tagName === "all tags";
                    const isNoTags = tagName && tagName === "no tags";

                    return (
                      <div
                        className={`${tagCSS} flex items-center justify-center cursor-pointer hover:opacity-75 ${
                          isAllTags ? "bg-taskinatorOrange text-taskinatorBlack" : isNoTags ? "bg-taskinatorWhite text-taskiantorDarkGrey border border-taskiantorDarkGrey" : ""
                        }`}
                        onClick={() => OnAddHandler(tagName)}
                      >
                        {tagName}
                      </div>
                    );
                  })}
              </div>
              {error && <div className="text-xs text-taskinatorRed">{error}</div>}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default JobListFetcher;
