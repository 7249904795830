import React from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentElement, AddressElement } from "@stripe/react-stripe-js";
import Button from "../../components/UI_elements/button";
import axios from "axios";
import { useInit } from "../../components/hooks/useInit";
import IconSpinner from "../../components/UI_elements/iconSpinner";

const UpdateBillingElement = ({ currentDetails, getData }) => {
  const { initState, initStarted, initCompleted, initErrors } = useInit(2);
  const stripe = useStripe();
  const elements = useElements();

  const submitHandler = async () => {
    initStarted(0);

    try {
      if (!stripe || !elements) {
        throw new Error("Stripe & Element Error");
      }

      const { error: submitError } = await elements.submit();

      if (submitError) {
        console.log(submitError);
        throw new Error("Payment Detail Submit Error");
      }

      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL + `/api/billing/create-setup-intent`,
        // { billingEmail: inputState.inputs.billingEmail.value.toLowerCase() },
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const clientSecret = response.data.clientSecret;

      const confirm = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url: process.env.REACT_APP_FRONTEND_URL,
        },
        redirect: "if_required",
      });

      await axios.post(
        process.env.REACT_APP_BACKEND_URL + `/api/billing/update/payment`,
        {
          paymentMethod: confirm.setupIntent.payment_method,
          customerId: response.data.customerId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      getData();
      initCompleted(0);
    } catch (err) {
      console.log(err);
      const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
      return initErrors(errorMessage, 0);
    }
  };

  return (
    <div className="min-w-96">
      <PaymentElement />

      <Button
        // disabled={!selectedPlan}'
        error={initState[0].error}
        isLoading={initState[0].started}
        label={"Update Details"}
        onClick={() => submitHandler()}
        customClasses={`
              bg-taskinatorBlue text-taskinatorWhite
              h-12  mt-2  grow`}
      />
    </div>
  );
};

export default UpdateBillingElement;
