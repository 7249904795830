import React, { useContext } from "react";
import { AppContext } from "../../contexts/appContext";
import DropDownSelector_V4 from "../inputs/dropDownSelector_V4";

const SignUpSelector = ({ inputVer, inputHandler, defaultValue, sid, disabled }) => {
  let options = [
    { type: "", name: "Microsoft", value: "azure" },
    { type: "", name: "Google", value: "google" },
    { type: "", name: "No Restriction", value: null},
  ];


  console.log(defaultValue)
  return (
    <DropDownSelector_V4
      color={"border-taskinatorMedGrey text-taskinatorDarkGrey font-semibold"}
      selectionColor={"text-taskinatorBlue uppercase"}
      label="User Sign Up Restricted To"
      id={"signInStrategies"}
      notSearchable
      type="SEARCH"
      sid={sid}
      defaultValue={defaultValue}
      options={options}
      onSelection={inputHandler}
      disabled={disabled}
      selectionDisplayText={"Select Auth"}
      inputVer={inputVer}
      alwaysValid
    />
  );
};

export default SignUpSelector;
