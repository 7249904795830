import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../contexts/appContext";
import { TaskContext } from "../../contexts/taskContext";
import { updateJobDetail } from "../../services/job.service";
import { useInputV4 } from "../../stateManagement/useInputV4";
import { GetAWSSignedObj, GetGoogleAccessToken, GetMicrosoftAccessToken, UploadFileIntoAWS, UploadFileIntoGoogleDrive, UploadFileIntoOneDrive } from "../../utils/fileUploaderFunctions";
import Button from "../UI_elements/button";
import MemberSelector from "../dataSelectors/memberSelector";
import PrioritySelector from "../dataSelectors/prioritySelector";
import TagSelector from "../dataSelectors/tagSelector";
import { useInit } from "../hooks/useInit";
import FileUploaderV4 from "../inputs/fileUploader_V4";
import InputV4 from "../inputs/input_V4";
import { JobModalContext } from "../displayElements/jobs/jobModalContext";
import { VALIDATOR_MAXLENGTH, VALIDATOR_MINLENGTH, VALIDATOR_NUMBER } from "./validators/validators";

import BulkFileUpload from "../displayElements/files/bulkFileUpload";

const JobDetailsForm = ({ jobId, details, globalSid, globalInputHandler, disabled, workflowId, closeModal, onFilesUploaded, uploadTrigger, onSuccess = () => {}, project, tracerId, errors }) => {
  const appData = useContext(AppContext);
  const taskContext = useContext(TaskContext);

  const jobData = useContext(JobModalContext);
  const [tempFiles, setTempFiles] = useState([]);
  // const [localUploadTrigger, setLocalUploadTrigger] = useState();
  const { inputState, inputHandler, attachmentHandler } = useInputV4({
    inputs: {},
    attachments: {},
    deleteRef: {},
    isValid: false,
  });
  const { initState, initStarted, initCompleted, initErrors } = useInit();
  const sid = "inputs";

  const onSaveHandler = async () => {
    initStarted(0);

    axios
      .post(
        process.env.REACT_APP_BACKEND_URL + `/api/job/edit/${jobData.jobId}`,
        {
          ref: inputState.inputs.ref.value,
          dueDate: inputState.inputs.dueDate.value,
          priority: inputState.inputs.priority.value,
          scope: inputState.inputs.scope.value,
          attachments: Object.values(inputState?.attachments ?? {}).map((attachment) => {
            return {
              key: attachment.value.key,
              fileName: attachment.value.fileName,
              fileExtension: attachment.value.fileExtension,
            };
          }),
          taskTags: inputState.inputs.taskTag.value,
          managers: inputState.inputs.managers.value,
          watchers: details ? details.watchers : [],
          itemsOnTask: details.itemsOnTask,
          estimatedTime: inputState.inputs.estimatedTime.value,
          project: inputState.inputs.project.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        initCompleted(0);
        onSuccess();
        closeModal();
      })
      .catch((err) => {
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });

    // updateJobDetail({
    //   jobId: jobData.jobId,
    //   data: {
    //     ref: inputState.inputs.ref.value,
    //     dueDate: inputState.inputs.dueDate.value,
    //     priority: inputState.inputs.priority.value,
    //     scope: inputState.inputs.scope.value,
    //     attachments: Object.values(inputState?.attachments ?? {}).map((attachment) => {
    //       return {
    //         key: attachment.value.key,
    //         fileName: attachment.value.fileName,
    //         fileExtension: attachment.value.fileExtension
    //       };
    //     }),
    //     taskTags: inputState.inputs.taskTag.value,
    //     managers: inputState.inputs.managers.value,
    //     watchers: details ? details.watchers : [],
    //     itemsOnTask: details.itemsOnTask,
    //     estimatedTime: inputState.inputs.estimatedTime.value,
    //     project: inputState.inputs.project.value,
    //   },
    //   onFinish: (res, err) => {

    //     initCompleted(0);
    //     if (!res || err) {
    //       console.log(err)
    //       initErrors(err?.response?.data?.message ?? err?.message ?? "Error while updating Job Detail", 0);
    //     } else {
    //       onSuccess();
    //       // taskContext.getActionData();
    //       closeModal();
    //     }
    //   },
    // });
  };

  /// this sends to global details
  useEffect(() => {
    if (globalSid) {
      Object.keys(inputState.inputs).map((inputKey) => globalInputHandler(inputKey, inputState.inputs[inputKey].value, inputState.inputs[inputKey].isValid, globalSid));

      globalInputHandler(
        "attachments",
        Object.keys(inputState.attachments).map((key) => {
          return inputState.attachments[key].value;
        }),
        Object.values(inputState.attachments).filter((attachment) => attachment.isValid === false).length === 0,
        globalSid
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputState]);

  return (
    <div className={!details ? "bg-taskinatorWhite border border-taskinatorMedGrey rounded-lg py-2 px-4 shadow grow" : "flex flex-col gap-2"}>
      <div>
        <InputV4
          label="Job Number/Reference"
          sid={sid}
          id="ref"
          placeholder={`Enter job number or ref`}
          customClasses={"rounded-md md:text-sm"}
          onInput={inputHandler}
          validators={[VALIDATOR_MINLENGTH(3)]}
          disabled={disabled}
          validityMessage="Min 3 characters"
          defaultValue={details ? details.ref : null}
        />
      </div>

      <div className="hidden">
        <InputV4
          sid={sid}
          id="project"
          placeholder={`Enter job number or ref`}
          customClasses={"rounded-md md:text-sm"}
          onInput={inputHandler}
          validators={[]}
          disabled={disabled}
          validityMessage="Min 3 characters"
          defaultValue={details ? details.project : project ? project : null}
        />
      </div>

      <div>
        <InputV4
          label="Due Date"
          id="dueDate"
          type="date"
          customClasses={"rounded-md md:text-sm"}
          disabled={disabled}
          sid={sid}
          onInput={inputHandler}
          defaultValue={details ? details.dueDate : null}
          validators={[VALIDATOR_MINLENGTH(6)]}
          validityMessage="Please Set A Due Date"
        />
      </div>

      <div>
        <MemberSelector
          inputHandler={inputHandler}
          defaultValue={
            details
              ? details.managers.map((manager) => {
                  return { value: manager._id };
                })
              : []
          }
          sid={sid}
          id="managers"
          label={"Manager/s"}
          multi
          roleTypes={[process.env.REACT_APP_ACCESS_M, process.env.REACT_APP_ACCESS_O, process.env.REACT_APP_ACCESS_A]}
          notRequired
          dontAutoSelect
          selectionDisplayText={"Add Member"}
        />
      </div>

      <div className="pt-1">
        <InputV4
          label="Job Instructions"
          id="scope"
          customClasses={"rounded-md md:text-sm"}
          placeholder="Detail scope of works for this task and it's deliverables..."
          textareaRows={8}
          sid={sid}
          onInput={inputHandler}
          validators={[VALIDATOR_MINLENGTH(3)]}
          defaultValue={details ? details.scope : null}
          disabled={disabled}
          // requiredOverride
        />
        {/* <div className="text-[0.75rem]">{inputState.inputs && inputState.inputs.scope && 500 - inputState.inputs.scope.value.length} characters remaining</div> */}
      </div>

      <div className="pt-1">
        <BulkFileUpload
          globalHandler={{
            inputHandler: inputHandler,
            attachmentHandler: attachmentHandler,
          }}
          defaultValue={details ? details.attachments : null}
          label="Job Reference Documents"
          disabled={disabled}
          noButton
          type="job"
        />
        {/* <FileUploaderV4
          label="Reference Documents"
          disabled={disabled}
          id="attachment"
          inputHandler={inputHandler}
          attachmentHandler={attachmentHandler}
          defaultValue={details ? details.attachments : null}
          dontAutoUpload={true}
          tempFileHold={tempFiles}
          setTempFileHold={setTempFiles}
          jobId={jobId}
        /> */}
      </div>

      <div>
        <TagSelector
          notRequired
          id={"taskTag"}
          inputHandler={inputHandler}
          defaultValue={
            details
              ? details.taskTags.map((tag) => {
                  return { value: tag._id };
                })
              : []
          }
          sid="inputs"
          type="task"
          label="Tags"
          selectionDisplayText={"Add/Create Job Tag"}
        />
      </div>

      <PrioritySelector sid="inputs" id="priority" inputHandler={inputHandler} notRequired={true} />

      <div>
        <InputV4
          label="Estimated Time in hours"
          id="estimatedTime"
          customClasses={"rounded-md md:text-sm"}
          disabled={disabled}
          sid={sid}
          onInput={inputHandler}
          defaultValue={details ? details.estimatedTime : null}
          validators={[VALIDATOR_NUMBER()]}
          requiredOverride
          validityMessage="Add Estimated Time"
        />
      </div>

      {details && (
        <Button
          onClick={() => {
            onSaveHandler();
          }}
          label={"Save Job Details"}
          customClasses={`${inputState.isValid ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"} bg-taskinatorBlue text-taskinatorWhite h-10 w-full`}
          disabled={!inputState.isValid}
          isLoading={initState[0].started}
          error={initState[0].error}
        />
      )}
    </div>
  );
};

export default JobDetailsForm;
