import React from "react";

import DropDownSelectorV4 from "../inputs/dropDownSelector_V4";

const PrioritySelector = ({ inputVer, inputHandler, defaultValue, sid, disabled, notRequired }) => {
  const options = [
    { type: "", name: "Standard", value: "Standard" },
    { type: "", name: "Low", value: "Low" },
    { type: "", name: "High", value: "High" },
    { type: "", name: "Critical", value: "Critical" },
  ];

  return (
    <DropDownSelectorV4
      color={"border-taskinatorDarkGrey text-taskinatorDarkGrey font-semibold"}
      selectionColor={"text-taskinatorBlue  uppercase"}
      label="Priority"
      id={"priority"}
      sid={sid}
      defaultValue="Standard"
      options={options}
      onSelection={inputHandler}
      disabled={disabled}
      selectionDisplayText="Standard"
      inputVer={inputVer}
      notRequired={notRequired}
    />
  );
};

export default PrioritySelector;
