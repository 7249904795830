import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../contexts/appContext";
import ColourGenerator from "../../utils/colourGenerator";
const UserDisplayBubble = ({
  firstName,
  lastName,
  colourOverride,
  large,
  short,
  textSize,
  noBackground,
  textColour,
  children,
  user,
}) => {
  const [showLong, setShowLong] = useState(!short ? false : true);
  const appData = useContext(AppContext);
  const bgColour = ColourGenerator(
    firstName ? firstName : "",
    lastName ? lastName : ""
  );

  const [timerId, setTimerId] = useState(null);

  const handleMouseOver = () => {
    // Set a timeout for the action to start after a delay
    const id = setTimeout(() => {
      setShowLong(true);
    }, 250); // 2000ms (2 seconds) delay

    // Save the timer id so that it can be cleared if the mouse leaves before the delay
    setTimerId(id);
  };

  const handleMouseOut = () => {
    // Clear the timeout if the mouse leaves before the delay
    clearTimeout(timerId);
    setShowLong(false);
  };

  useEffect(() => {
    // Clean up the timeout when the component unmounts
    return () => {
      clearTimeout(timerId);
    };
  }, [timerId]);

  if (!user) {
    return (
      <div
        key={`${firstName + lastName}`}
        className={`${
          colourOverride
            ? colourOverride
            : "bg-taskinatorMedGrey text-taskinatorBlack"
        } px-1 ${large ? "h-8 w-8" : "py-0.5"} ${
          textSize ? textSize : "text-xs"
        } rounded flex items-center justify-center font-medium uppercase transition-all mx-1`}
      >
        {children && children}
        {!user && (
          <div
            className={`${
              !showLong ? "block" : "hidden"
            } transition-all ease-in-out duration-1500`}
          >
            {firstName ? firstName[0] : ""}
          </div>
        )}
        {!user && (
          <div
            className={`${
              showLong ? "" : "hidden"
            } transition-all ease-in-out duration-1500 pr-1`}
          >
            {firstName ? firstName : ""}
          </div>
        )}
        {!user && <div>{lastName ? lastName[0] : ""}</div>}
      </div>
    );
  } else {
    if (
      appData.workspaceDataV4 &&
      user._id.toString() === appData.workspaceDataV4.user_id.toString()
    ) {
      return (
        <div
          key={user._id}
          className={`${
            colourOverride
              ? colourOverride
              : "bg-taskinatorGreen text-taskinatorWhite"
          } px-1   ${large ? "h-8 w-8" : "py-0.5"} ${
            textSize ? textSize : "text-xs"
          } rounded flex items-center justify-center font-medium uppercase transition-all mx-1`}
        >
          YOU
        </div>
      );
    } else {
      return (
        <div
          key={user._id}
          className={` ${
            colourOverride
              ? colourOverride
              : "bg-taskinatorMedGrey text-taskinatorBlack"
          } px-2 ${large ? "h-8 w-8" : "py-0.5"} ${
            textSize ? textSize : "text-xs"
          } rounded flex items-center justify-center font-medium uppercase transition-all mx-1 whitespace-nowrap`}
        >
          <div
            className={`${
              !showLong ? "block" : "hidden"
            } transition-all ease-in-out duration-1500`}
          >
            {user.firstName ? user.firstName[0] : ""}
          </div>
          <div
            className={`${
              showLong ? "" : "hidden"
            } transition-all ease-in-out duration-1500 pr-1`}
          >
            {user.firstName ? user.firstName : ""}
          </div>
          <div>{user.lastName ? user.lastName[0] : ""}</div>
        </div>
      );
    }
  }
};

export default UserDisplayBubble;
