import { useEffect, useState } from "react";
import { useInit } from "../components/hooks/useInit";
import JobListFormatter from "../utils/jobListFormatter";

export const useDashboardState = (socket, socketConnected, workspaceData) => {
  const { initState, initStarted, initCompleted, initErrors } = useInit(1);
  const [tasks, setTask] = useState({});
  const [jobs, setJobs] = useState({});
  const [questions, setQuestions] = useState({});
  const [notifications, setNotifications] = useState({});

  useEffect(() => {
    if (socketConnected && socket && workspaceData) {
      initStarted(0);

      socket.emit(
        "dashboard",
        {
          action: "getInitDashboardData",
        },
        (callback) => {
          if (callback.success) {
            setTask(callback.tasks);
            setQuestions(callback.questions);
            setNotifications(callback.notifications);
            setJobs(callback.jobs);
            initCompleted(0);
          } else {
            initErrors(callback.error, 0);
          }
        }
      );

      return () => {
        socket.off("taskUpdate");
        socket.off("questionUpdate");
        socket.off("notificationUpdate");
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected, socket, workspaceData]);

  //////////// this turns on the sockets
  socket.on("taskUpdate", (data) => {
    setTask((currentTasks) => {
      const updatedTasks = { ...currentTasks }; // Create a shallow copy for immutability

      if (data.action === "add") {
        // Add or update the task
        updatedTasks[data.tracer._id] = data.tracer;
      } else if (data.action === "remove") {
        // Remove the task if it exists
        delete updatedTasks[data.idToRemove];
      }

      return updatedTasks;
    });
  });

  socket.on("jobUpdate", (data) => {
    setJobs((currentJobs) => {
      const updatedJobs = { ...currentJobs }; // Create a shallow copy for immutability

      if (data.action === "update") {
        // Add or update the task
        if (data.job.completed) {
          delete updatedJobs[data.job._id];
        } else {
          updatedJobs[data.job._id] = data.job;
        }
      } else if (data.action === "remove") {
        // Remove the task if it exists
        delete updatedJobs[data.idToRemove];
      }

      return updatedJobs;
    });
  });

  socket.on("questionUpdate", (data) => {
    if (data.action === "add") {
      setQuestions((prevQuestions) => ({
        ...prevQuestions,
        [data.newQuestion._id]: data.newQuestion,
      }));
    }

    if (data.action === "remove") {
      setQuestions((prevQuestions) => {
        const updatedQuestionState = { ...prevQuestions };
        delete updatedQuestionState[data.idToRemove];
        return updatedQuestionState;
      });
    }
  });

  socket.on("notificationUpdate", (data) => {
    if (data.action === "add") {
      setNotifications((prevNotifications) => ({
        ...prevNotifications,
        [data.newNotifications._id]: data.newNotifications,
      }));
    }

    if (data.action === "remove") {
      setNotifications((prevNotifications) => {
        const updatedNotificationState = { ...prevNotifications };
        delete updatedNotificationState[data.idToRemove];
        return updatedNotificationState;
      });
    }
  });

  const jobListFilterValues = tasks && JobListFormatter([...Object.values(tasks), ...Object.values(jobs)]); /// we use this to store the task details as a live value

  let jobListFilterOptions = {};

  jobListFilterValues &&
    Object.keys(jobListFilterValues)?.map((v) => {
      jobListFilterOptions = { ...jobListFilterOptions, [v]: v };
      return null;
    });

  return [jobs, tasks, questions, notifications, initState, jobListFilterValues, jobListFilterOptions, setTask];
};
