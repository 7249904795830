import React, { useReducer, useEffect } from "react";
import { validate } from "../forms/validators/validators";
import IconSpinner from "../UI_elements/iconSpinner";
import { MdCheck } from "react-icons/md";
import ToolTip from "../../utils/toolTip";

const inputReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: !action.inputType || ["email", "password", "date"].includes(action.inputType) ? validate(action.val, action.validators) : true,
      };

    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    case "RESET":
      return {
        ...state,
        value: "",
        isValid: "false",
      };

    default:
      return state;
  }
};

const Input_V4 = ({
  defaultValue,
  inputVer,
  placeholder,
  onInput,
  disabled,
  id,
  sid,
  label,
  customClasses,
  isInitialValid,
  validators,
  textareaRows,
  type,
  validityMessage,
  waitingOnPendingData,
  requiredOverride,
  externalValid,
  textSize,
  toolTip,
}) => {
  /// this section formates the default value for differnt input types
  let startingVal;
  if (type === "datetime-local" && defaultValue) {
    var utcDatetime = new Date(defaultValue);
    startingVal = utcDatetime.toLocaleString("sv-SE", { timeZone: "Europe/Stockholm" }).slice(0, -3).replace(" ", "T");
  }
  if (type === "date" && defaultValue) {
    const dateObject = new Date(defaultValue);
    const year = dateObject.getFullYear();
    const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObject.getDate()).slice(-2);

    startingVal = `${year}-${month}-${day}`;
  } else {
    startingVal = defaultValue;
  }

  const [inputState, dispatch] = useReducer(inputReducer, {
    value: startingVal ? startingVal : "",
    isTouched: false,
    isValid: !type || ["email", "password", "date"].includes(type) ? validate(startingVal ? startingVal : "", validators ? validators : []) : true,
  });

  const { value, isValid, isTouched } = inputState;

  const changeHandler = (e) => {
    dispatch({
      type: "CHANGE",
      val: e.target.value,
      validators: validators,
      inputType: type,
    });
  };

  const touchHandler = () => {
    dispatch({
      type: "TOUCH",
    });
  };

  useEffect(() => onInput(id, inputState.value, inputState.isValid, sid), [id, value, isValid, onInput, sid]);

  useEffect(() => {
    dispatch({
      type: "CHANGE",
      val: startingVal ? startingVal : "", // Make sure startingVal is updated accordingly
      validators: validators,
      inputType: type,
    });
  }, [defaultValue]); // Listening for changes in defaultValue

  let inputCSS;

  if (inputVer === "1") {
    inputCSS = `${customClasses} ${disabled ? "cursor-not-allowed" : ""} ${textSize ? textSize : "md:text-xs"} ${
      externalValid ? "text-taskinatorGreen font-medium" : ""
    } relative rounded-lg block w-full appearance-none border border-taskinatorMedGrey px-3 py-2 text-gray-900 placeholder-gray-500 ${
      !isValid && isTouched
        ? "border-taskinatorRed focus:border-taskinatorRed border border-taskionatorRed focus:outline-none"
        : "focus:border-taskinatorBlue focus:outline-none focus:border focus:border-taskinatorBlue "
    } `;
  } else {
    inputCSS = `w-full   ${textSize ? textSize : "md:text-xs"} ${
      disabled ? "cursor-not-allowed" : ""
    }  rounded px-3 py-2 text-gray-900 border  border-taskinatorMedGrey placeholder-gray-300 focus:z-10  bg-taskinatorLightGrey  ${customClasses} ${
      !isValid && isTouched
        ? "border-taskinatorRed focus:border-taskinatorRed border border-taskionatorRed focus:outline-none"
        : "focus:border-taskinatorBlue focus:outline-none focus:border focus:border-taskinatorBlue "
    }`;
  }

  const element = textareaRows ? (
    <textarea spellCheck={true} rows={textareaRows} id={id} disabled={disabled} onChange={changeHandler} placeholder={placeholder} onBlur={touchHandler} value={value} className={inputCSS}></textarea>
  ) : (
    <input type={type} id={id} disabled={disabled} onChange={changeHandler} placeholder={placeholder} onBlur={touchHandler} value={value} className={inputCSS} autoComplete="off"></input>
  );

  return (
    <div className={`  ${customClasses} `}>
      {label && (
        <div className="flex capitalize text-sm text-taskinatorDarkGrey">
          <div className={``}>{label}</div>
          <div className={`${validators.length > 0 && !requiredOverride ? "text-taskinatorRed" : "text-taskinatorMedGrey"} opacity-75 pl-1`}>
            {validators.length > 0 && !requiredOverride ? " (Required)" : " (Optional)"}
          </div>
          {toolTip && <ToolTip content={toolTip} />}
        </div>
      )}
      <div className={`flex   `}>
        {element} {waitingOnPendingData && <IconSpinner />} {!waitingOnPendingData && externalValid && "Valid"}
      </div>
      {!isValid && validityMessage && isTouched && <div className="text-taskinatorRed text-xs">{validityMessage}</div>}
    </div>
  );
};

export default Input_V4;
