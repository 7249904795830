import axios from "axios";
import React, { useEffect, useState } from "react";
import IconSpinner from "../../components/UI_elements/iconSpinner";
import Modal from "../../components/UI_elements/modal";
import AddThirdParty from "../../components/forms/addthirdparty";
import { useInit } from "../../components/hooks/useInit";
import InputV4 from "../../components/inputs/input_V4";
import ContentView from "../../pageTemplates/contentView";
import LowerCaseTrim from "../../utils/lowerCaseAndTrim";

const ThirdParty = () => {
  const { initState, initStarted, initCompleted, initErrors } = useInit();
  const [responseData, setResponseData] = useState();
  const [modal, setModal] = useState();
  const [filter, setFilter] = useState();

  const sendRequest = () => {
    initStarted(0);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/thirdparty/getall`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        setResponseData(response.data.teamData);

        initCompleted(0);
      })
      .catch((err) => {
        return initErrors(
          err?.response?.data?.message ?? err?.message ?? "",
          0
        );
      });
  };

  /// get team data on start up
  useEffect(() => {
    sendRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buttons = [
    {
      label: "Add 3rd Party",
      customClasses:
        "bg-taskinatorBlue text-taskinatorWhite h-10 text-xs md:text-sm ml-2 py-2",
      onClick: () => setModal({ user: null }),
      id: "newTask",
    },
  ];

  const centerContent = (
    <div className="flex items-center sm:flex-row flex-col w-full sm:w-fit sm:justify-center  sm:mt-0 mt-1">
      <InputV4
        inputVer={"1"}
        customClasses={"sm:w-96 w-[100%] items-center"}
        placeholder="Search Jobs"
        onInput={(id, value, isValid, sid) => {
          setFilter(value);
        }}
        validators={[]}
      />
    </div>
  );

  return (
    <>
      <ContentView
        buttons={buttons}
        label={"Manage 3rd Parties"}
        centerContent={centerContent}
      >
        <div className="grid-rows-2">
          <div className="grid lg:grid-cols-4 grid-cols-3 bg-taskinatorWhite text-xs font-semibold py-2 text-taskinatorDarkGrey">
            <div className="pl-2 border-r-taskinatorLightGrey border-r-2 overflow-clip">
              Full Name
            </div>
            <div className="pl-2 border-r-taskinatorLightGrey border-r-2 overflow-clip">
              Email
            </div>
            <div className="pl-2 border-r-taskinatorLightGrey border-r-2 overflow-clip">
              Company
            </div>

            <div className="pl-2 hidden lg:flex overflow-clip">Status</div>
          </div>
          {initState[0].started && (
            <div className="my-4">
              <IconSpinner size="m" />
            </div>
          )}
          {initState[0].error && (
            <div className="text-center w-full text-taskinatorRed my-4 capitalize">
              {initState[0].error}
            </div>
          )}
          {initState[0].finished &&
            responseData &&
            responseData
              .filter((user) => {
                return (
                  LowerCaseTrim(user.firstName).includes(
                    LowerCaseTrim(filter)
                  ) ||
                  LowerCaseTrim(user.lastName).includes(
                    LowerCaseTrim(filter)
                  ) ||
                  LowerCaseTrim(user.email).includes(LowerCaseTrim(filter)) ||
                  LowerCaseTrim(user.company).includes(LowerCaseTrim(filter))
                );
              })
              .map((user) => {
                return (
                  // <div className="flex w-full">
                  <div
                    key={user._id}
                    className={`h-10 items-center grid lg:grid-cols-4 grid-cols-3 bg-taskinatorWhite my-0.5 py-1 text-sm hover:bg-taskinatorHoverBlue ${
                      user.locked ? "text-taskinatorMedGrey" : ""
                    } cursor-pointer`}
                    onClick={() => setModal({ user: user })}
                  >
                    <div className="pl-2 border-r-taskinatorLightGrey border-r-2 flex">
                      <div className="capitalize mr-1 overflow-clip">
                        {user.firstName}
                      </div>
                      <div className="capitalize mr-1 overflow-clip">
                        {user.lastName}
                      </div>
                    </div>
                    <div className="pl-2 border-r-taskinatorLightGrey border-r-2 overflow-clip">
                      {user.email}
                    </div>
                    <div className="pl-2 border-r-taskinatorLightGrey border-r-2 overflow-clip">
                      {user.company}
                    </div>

                    <div
                      className={`pl-2 capitalize hidden lg:flex ${
                        !user.locked
                          ? "text-taskinatorGreen"
                          : "text-taskinatorMedGrey"
                      }`}
                    >
                      {user.locked ? "locked" : "active"}
                    </div>
                  </div>
                );
              })}
        </div>
      </ContentView>

      {modal && (
        <Modal
          title={
            modal.user ? (
              <div className="capitalize">
                Editing: {modal.user.firstName} {modal.user.lastName[0]}
              </div>
            ) : (
              "Add 3rd Party"
            )
          }
          local={() => {
            setModal();
          }}
        >
          <AddThirdParty
            user={modal.user}
            onResponse={() => {
              sendRequest();
              setModal();
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default ThirdParty;
