import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";
import IconSpinner from "./components/UI_elements/iconSpinner";
import { useInit } from "./components/hooks/useInit";
import { AppContext } from "./contexts/appContext";
import AppPageContainer from "./pageTemplates/appPageContainer";
import Login from "./pages/authentication/login";
import ResetPasswordRequest from "./pages/authentication/resetPasswordRequest";
import SignUp from "./pages/authentication/signUp";
import ThirdPartyAccess from "./pages/thirdparty/thirdPartyAccess";
import { useWorkspaceDataV4 } from "./stateManagement/useWorkspaceDataV4";
import { useDashboardState } from "./utils/useDashboardState";
import { useSocket } from "./utils/useSocket";

const App = () => {
  const { workspaceDataV4, setWorkspaceDataV4 } = useWorkspaceDataV4();
  const { initState, initStarted, initCompleted, initErrors } = useInit(2);
  const [topLevelModal, setTopLevelModal] = useState([]);
  const [initialLoad, setInitialLoad] = useState();
  const [refresh, setRefresh] = useState();

  const [isModal, setIsModal] = useState(false);
  const [viewAction, setViewAction] = useState();

  const [viewProject, setViewProject] = useState();
  const [viewTask, setViewTask] = useState();
  const [isModalOpened, setIsModalOpened] = useState();
  const [toolTipModal, setToolTipModal] = useState();

  const { socket, socketConnected, socketError } = useSocket(workspaceDataV4);
  const [
    jobs,
    tasks,
    questions,
    notifications,
    dashboardState,
    jobListFilterValues,
    jobListFilterOptions,
  ] = useDashboardState(socket, socketConnected, workspaceDataV4);
  // const { isAdobeDCReady, DCError, setDCError } = useAdobeDCLoader();
  //// these states are for the global tag filter
  const [tagFilter, setTagFilter] = useState({ ["all tags"]: "all tags" });

  const [viewJob, setViewJob] = useState();


  const setJobViewHandler = (data) => {
    setViewJob(data);
  };

  const setActionViewHandler = (data) => {
    if (data && data?.tracerId !== viewAction?.tracerId) {
      if (viewAction) {
        socket.emit(
          "connectToAction",
          {
            action: "disconnect",
            tracerId: viewAction.tracerId,
          },
          (callback) => {
            if (callback.success) {
              initCompleted(5);
              setViewAction(data);
            } else {
              initErrors(callback.error, 5);
              // setViewAction(viewAction);
            }
          }
        );
      } else {
        setViewAction(data);
      }
    } else {
      setViewAction();
    }
  };
  // const [tagList, setTagList] = useState([]);

  const [vh, setVh] = useState(window.innerHeight * 0.01);

  const setViewHeight = () => {
    const newVh = window.innerHeight * 0.01;
    setVh(newVh);
  };

  useEffect(() => {
    setViewHeight();
    window.addEventListener("resize", setViewHeight);

    return () => {
      window.removeEventListener("resize", setViewHeight);
    };
  }, []);

  const vhStyle = { "--vh": `${vh}px` };

  const modelSetController = (data) => {
    if (topLevelModal.length === 0) {
      setTopLevelModal([data]);
    } else {
      [data].concat(topLevelModal);
    }
  };

  const modelCloseController = () => {
    setTopLevelModal(topLevelModal.slice(1));
  };

  /// top level error handling
  const ERROR = (errMessage) => {
    if (errMessage === "Unauthorized" && workspaceDataV4) {
      setWorkspaceDataV4({ ...workspaceDataV4, authenticated: false });
      modelSetController(<Login login={true} />);
    }
  };

  let routes;

  const getWorkspaceData = () => {
    initStarted(0);
    initStarted(1);
  
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/init/workspace`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        initCompleted(0);
        initCompleted(1);
        setWorkspaceDataV4(response.data.workspaceData);
      })
      .catch((err) => {
        console.log(err)
        setWorkspaceDataV4();
        // window.location.href = `${process.env.REACT_APP_WEBSITE}/login`;
        initCompleted(1);
        initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };
  /// re-Logs the user back in on refresh if token is present

  useEffect(() => {
    getWorkspaceData();
    setRefresh(!refresh);
  }, []);

  const RefreshTasks = () => {
    // getActionData();
  };

  if (workspaceDataV4 && workspaceDataV4.authenticated && socketConnected) {
    if (!workspaceDataV4.orgName && !initState[1].started) {
      if (!isModal) setIsModal(true);
    }
    if (workspaceDataV4.role === process.env.REACT_APP_ACCESS_D) {
      routes = (
        <Routes>
          <Route path="/" element={<AppPageContainer />}>
            <Routes>
              <Route path="*" element={<Navigate to="track/jobs" replace />} />
            </Routes>
          </Route>
        </Routes>
      );
    } else if (workspaceDataV4.role === process.env.REACT_APP_ACCESS_SU) {
    } else {
      routes = (
        <Routes>
          <Route path="*" element={<AppPageContainer />} />
        </Routes>
      );
    }
  } else {
    routes = (
      <Routes>
        <Route path="login" element={<Login login={true} />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="tasks/view/:tracerId" element={<Login login={true} />} />
        <Route path="accept/:iid" element={<Login />} />
        <Route path="forgotpassword" element={<ResetPasswordRequest />} />
        <Route
          path="tp/:accessCode/:accessCode2/:id/:accessCode3"
          element={<ThirdPartyAccess />}
        />
        <Route path="*" element={<Navigate to="login" replace />} />
      </Routes>
    );
  }

  return (
    <AppContext.Provider
      value={{
        topLevelModal,
        setTopLevelModal,
        modelSetController,
        modelCloseController,
        workspaceDataV4,
        setWorkspaceDataV4,
        getWorkspaceData,
        initialLoad,
        itemName: "Drawing",
        refresh: refresh,
        viewAction,
        setViewAction,
        setActionViewHandler,
        viewProject,
        setViewProject,
        viewTask,
        setViewTask,
        isModalOpened,
        setIsModalOpened,
        socket,
        tagFilter,
        setTagFilter,
        tasks,
        questions,
        notifications,
        jobs,
        dashboardState,
        jobListFilterValues,
        jobListFilterOptions,
        // isAdobeDCReady,
        // DCError,
        // setDCError,
        socketError,
        isModal,
        setIsModal,
        toolTipModal,
        setToolTipModal,
        viewJob,
        setJobViewHandler,
      }}
    >
      {!initState[1].initialised && (
        <main>
          <div className="items-center justify-center bg-taskinatorLightGrey flex flex-col w-screen h-screen  text-xs">
            <IconSpinner size="lg" />{" "}
            <div className="pl-1">Loading Data... </div>
          </div>{" "}
        </main>
      )}

      {initState[1].initialised && (
        <Router>
          <main>{routes}</main>
        </Router>
      )}
    </AppContext.Provider>
  );
};

export default App;
