import React, { useContext, useState, useRef } from "react";
import { AppContext } from "../../contexts/appContext";
import { MdClose,  } from "react-icons/md";
import ReactDOM from "react-dom";

const Modal = ({ local, pending, bgColour, size, title, children, topSection, isWithoutClose ,expand }) => {
  const appData = useContext(AppContext);
  const [startElement, setStartElement] = useState();
  let sizeCSS;

  if (size === "max") {
    sizeCSS = "2xl:w-2xl   md:w-[97%] md:max-h-[97%] w-full h-full";
  } else if (size === "full") {
    sizeCSS = "h-full w-full ";
  } else if (size === "large") {
    sizeCSS = "md:max-w-[1024px] md:max-h-[95%] md:mx-auto w-full h-full rounded-lg";
  } else if (size === "medium") {
    sizeCSS = "md:max-w-4xl md:max-h-[95%] md:h-auto w-full h-full rounded-lg";
  } else {
    sizeCSS = "md:min-w-lg md:max-h-[95%] w-full  md:h-auto h-full rounded-lg";
  }

  const backgroundRef = useRef();

  return (
    <>
      <div className=" absolute flex items-center z-50 h-full w-full inset-0 opacity-100">
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
          className={`${bgColour ? bgColour : "bg-taskinatorWhite"} mx-auto p-4   ${size ? sizeCSS : "md:w-fit w-full h-auto md:max-h-[95%] rounded "} flex flex-col sm:overflow-auto `}
        >
          {!isWithoutClose ? (
            <div className={`flex w-full ${topSection ? `p-4 rounded-lg ${topSection} text-taskinatorWhite` : "h-fit text-taskinatorDarkGrey"} `}>
              <div className="grow items-center justify-center flex text-lg font-semibold ">{title ? title : ""}</div>
            
              {!pending && (
                <div
                  className=" hover:opacity-50 justify-end  cursor-pointer "
                  onClick={() => {
                    appData.setIsModalOpened(false);
                    local ? (!pending ? (() => local())() : (() => {})()) : (() => appData.modelCloseController())();
                  }}
                >
                  <MdClose size={35} />
                </div>
              )}
            </div>
          ) : (
            <></>
          )}

          {children}
        </div>
      </div>
      <div className={`fixed top-0 left-0 h-full w-full z-40 bg-taskinatorDarkGrey opacity-90  `}></div>
    </>
  );
};

export default Modal;
