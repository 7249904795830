import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/UI_elements/button";
import JobFileSelector from "../../components/displayElements/jobs/jobFileSelector";
import JobWorkflowDisplay from "../../components/displayElements/jobs/jobWorkflowDisplay";
import JobDetailsForm from "../../components/forms/jobDetailsForm";
import { useInit } from "../../components/hooks/useInit";
import { AppContext } from "../../contexts/appContext";
import { createNewJob } from "../../services/job.service";
import { useInputV4 } from "../../stateManagement/useInputV4";
import axios from "axios";

const NewJob = ({ closeModal, project }) => {
  const navigate = useNavigate();
  // const [uploadTrigger, setUploadTrigger] = useState();
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(1);
  const { inputState, inputHandler, removeSidHandler, attachmentHandler } = useInputV4();

  console.log(inputState);
  const workflowAdded = inputState.details && inputState.details.workflow && inputState.details.workflow.value.length > 0;

  useEffect(() => {
    initReset();
  }, [inputState]);

  const issueTaskHandler = async () => {
    initStarted(0);

    axios
      .post(
        process.env.REACT_APP_BACKEND_URL + `/api/job/create/new`,
        {
          jobDetails: inputState.details,
          attachments: Object.values(inputState?.attachments ?? {}).map((attachment) => {
            return {
              key: attachment.value.key,
              fileName: attachment.value.fileName,
            };
          }),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        initCompleted(0);
        navigate("/");
        closeModal();
      })
      .catch((err) => {
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  return (
    <div className="h-full flex flex-col grow overflow-auto">
      <div className="flex flex-col xl:flex-row overflow-auto h-full scrollbar xl:pr-0 pr-1">
        <div className="xl:w-1/4 w-full shrink-0 xl:mr-1 mr-0 xl:h-full h-fit  xl:overflow-auto flex flex-col">
          <div className="py-2 px-4 text-taskinatorDarkGrey bg-taskinatorDarkGrey rounded-lg shadow mb-1  font-semibold uppercase flex items-center">
            <div className="flex items-center text-taskinatorWhite text-md">Job Details</div>
          </div>
          <JobDetailsForm globalInputHandler={inputHandler} globalSid={"details"} initState={initState} disabled={initState[0].started} project={project} />
        </div>
        <div className="flex grow flex-col xl:h-full h-fit  xl:overflow-auto">
          <div className="">
            <JobFileSelector globalSid={"details"} globalInputHandler={inputHandler} workflowId workflowAdded={workflowAdded} number={`3`} noNotes />
          </div>

          <div className="xl:mt-0 mt-1 border-l-4 border-l-taskinatorGreen py-2 px-4 bg-taskinatorGreen shadow mb-1 font-semibold uppercase flex items-center rounded-lg">
            <div className="flex items-center text-taskinatorWhite">Starting Sequence</div>
            {/* <div className="pl-1 text-taskinatorWhite capitalize font-normal text-base">(Required)</div> */}
          </div>

          <JobWorkflowDisplay
            globalSid={"details"}
            globalInputHandler={inputHandler}
            globalAttachmentHandler={attachmentHandler}
            closeModal={closeModal}
            fileSetsSelected={inputState?.details?.fileSets?.value}
          />
        </div>
      </div>
      <div className="w-full flex justify-end mt-2">
        <Button
          label="Create New Job"
          customClasses={`${inputState.isValid ? "bg-taskinatorBlue border border-taskinatorBlue" : "bg-taskinatorMedGrey border border-taskinatorMedGrey"} text-taskinatorWhite h-10 text-base py-2`}
          onClick={() => {
            issueTaskHandler();
          }}
          isLoading={initState[0].started}
          disabled={!inputState.isValid}
          error={initState[0].error}
        />
      </div>
    </div>
    // </ContentView>
  );
};

export default NewJob;
