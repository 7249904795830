import React, { useEffect, useState } from "react";
import { MdCheckCircle, MdRadioButtonUnchecked } from "react-icons/md";
import { useInputV4 } from "../../stateManagement/useInputV4";
import MemberSelector from "../dataSelectors/memberSelector";

const TaskActionDisplayForm = ({
  actionData,
  stateId,
  isSelected,
  assignees,
  currentlySetActionData,
  dispatch = () => {},
  stageIndex,
  stageUUID,
  tracerId,
  tracerStatus,
  completed,
  thirdPartyAccessCode,
  dontAutoSelect,
}) => {
  let defaultValues = {
    scope: "",
    assignee: assignees ?? [],
    isActive: dontAutoSelect ? isSelected : isSelected ? isSelected : actionData.autoSelect,
  };

  if (currentlySetActionData !== null && currentlySetActionData !== undefined && currentlySetActionData[actionData._id]) {
    defaultValues = currentlySetActionData[actionData._id];
  }

  const { inputState, inputHandler } = useInputV4({
    inputs: {
      scope: { value: defaultValues?.scope, isValid: true },
      assignee: { value: defaultValues?.assignee, isValid: true },
    },
    isValid: true,
  });

  const sid = "inputs";
  const [isActive, setIsActive] = useState(defaultValues?.isActive);
  const [isNew, setIsNew] = useState(!defaultValues?.isActive);

  const [collapse, setCollapes] = useState();

  useEffect(() => {
    dispatch({
      type: "ACTION_CHANGE",
      stateId: stateId,
      _id: actionData?._id,
      actionData: {
        scope: inputState?.inputs?.scope?.value,
        assignee: inputState?.inputs?.assignee?.value,
      },
      isActive: isActive,
      tracerId: tracerId,
      tracerStatus: tracerStatus,
      stageIndex: stageIndex,
      stageUUID: stageUUID,
      canApproveItems: actionData?.canApproveItems,
      canMarkupItems: actionData?.canMarkupItems,
      viewReleased: actionData?.viewReleased,
      viewDraft: actionData?.viewDraft,
      addMoreItems: actionData?.addMoreItems,
      canCreateDraftItems: actionData?.canCreateDraftItems,
      name: actionData?.name,
      completed: completed,
      isNew: isNew,
      thirdParty: actionData?.thirdParty,
      fileTags: actionData?.fileTags,
      completeButton: actionData?.completeButton,
      rejectButton: actionData?.rejectButton,
      milestoneTask: actionData?.milestoneTask,
      team: (actionData?.team ?? []).map((team) => {
        return {
          _id: team._id,
          firstName: team.firstName,
          lastName: team.lastName,
          locked: team.locked,
        };
      }),
    });
  }, [inputState, isActive]);

  const activationCSS = !isActive
    ? {
        container: "border-taskinatorMedGrey",
        standardText: "text-taskinatorMedGrey",
        highlightedText: "text-taskinatorMedGrey",
      }
    : {
        container: "border-taskinatorGreen shadow-md",
        standardText: "text-taskinatorDarkGrey",
        highlightedText: "text-taskinatorGreen",
      };

  return (
    <div
      onClick={tracerStatus && tracerStatus === "live" ? () => {} : () => setIsActive(!isActive)}
      key={actionData?._id}
      className={`${activationCSS.container} ${
        tracerStatus && tracerStatus === "live" ? "" : "cursor-pointer"
      } border-2 rounded-lg w-60 h-fit flex items-center justify-center m-2 shadow bg-taskinatorWhite`}
    >
      <div className="flex flex-col items-center justify-center w-full p-4">
        <div className={`font-semibold ${activationCSS.highlightedText} text-center text-sm`}>{actionData?.name}</div>

        {!collapse && (
          <div
            onClick={(event) => {
              event.stopPropagation();
            }}
            className="w-full pb-1"
          >
            <MemberSelector
              label={inputState?.inputs?.assignee?.value ? "Assigned To:" : `Available to:`}
              selectionDisplayText={actionData?.thirdParty ? "Select 3rd Party" : `Select Team Members`}
              inputHandler={inputHandler}
              disabled={!isActive}
              customClasses={activationCSS}
              sid={sid}
              roleTypes={[]}
              id="assignee"
              defaultValue={(defaultValues?.assignee ?? []).map((assignee) => {
                return { value: assignee._id };
              })}
              teamIds={
                actionData?.thirdParty
                  ? null
                  : actionData?.team.map((team) => {
                      return team._id;
                    })
              }
              thirdParty={actionData?.thirdParty}
              multi
            />

            {/* <InputV4
              label="Action Specific Scope"
              id="scope"
              customClasses={`rounded-md md:text-sm mb-2 w-full ${activationCSS.standardText}`}
              placeholder="Details any special requirements for this action"
              textareaRows={2}
              sid={sid}
              onInput={inputHandler}
              validators={[]}
              disabled={!isActive}
              defaultValue={inputState.inputs.scope.value}
            /> */}
          </div>
        )}
        {isActive ? (
          <div className="text-taskinatorGreen">{tracerStatus && tracerStatus === "live" ? <div>Currently Active</div> : <MdCheckCircle size={30} />}</div>
        ) : (
          <MdRadioButtonUnchecked size={30} color={"#c3c3c3"} />
        )}
      </div>
    </div>
  );
};

export default TaskActionDisplayForm;
