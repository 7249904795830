import React, { useEffect, useState } from "react";
import axios from "axios";
import { useInit } from "../../components/hooks/useInit";
import IconSpinner from "../../components/UI_elements/iconSpinner";
import PlanElement from "./planElement";
import Button from "../../components/UI_elements/button";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentElement, AddressElement } from "@stripe/react-stripe-js";
import DateDisplay from "../../components/UI_elements/dateDisplay";
import CardDetails from "./cardDetails";

const SelectPlan = ({ firstPayment, DaysReminingOnUnlimited, currentPlan, getData }) => {
  const { initState, initStarted, initCompleted, initErrors } = useInit(2);
  const [availablePlans, setAvailablePlans] = useState();
  const [selectedPlan, setSelectedPlan] = useState(currentPlan);
  const [showBilling, setShowBilling] = useState();
  const [updatePaymentDetails, setUpdatePaymentDetails] = useState();
  const stripe = useStripe();
  const elements = useElements();

  const currency = "AUD";

  useEffect(() => {
    initStarted(1);
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/billing/availableplans/${currency}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        setAvailablePlans(response.data);
        initCompleted(1);
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
        initErrors(errorMessage, 1);
      });
  }, []);

  const submitHandler = async (type) => {
    initStarted(0);

    try {
      if (selectedPlan.freePlan) {
        console.log("Downgrading Plan");
        await axios.post(
          process.env.REACT_APP_BACKEND_URL + `/api/billing/downgrade/${currency}/${selectedPlan.key}`,
          { selectedPlan: { key: selectedPlan.key, totalExtras: selectedPlan.totalExtras, totalTeam: selectedPlan.totalTeam, totalPrice: selectedPlan.selectedTotalPrice } },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
      } else if (!updatePaymentDetails && currentPlan.paymentDetails.last4) {
        console.log("updating Plan");
        await axios.post(
          process.env.REACT_APP_BACKEND_URL + `/api/billing/update/${currency}/${selectedPlan.key}`,
          {
            selectedPlan: { key: selectedPlan.key, totalExtras: selectedPlan.totalExtras, totalTeam: selectedPlan.totalTeam, totalPrice: selectedPlan.selectedTotalPrice },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
      } else {
        console.log("Signing Up To Plan");
        if (!stripe || !elements) {
          throw new Error("Stripe & Element Error");
        }

        const { error: submitError } = await elements.submit();

        if (submitError) {
          console.log(submitError);
          throw new Error("Payment Detail Submit Error");
        }

        const response = await axios.post(
          process.env.REACT_APP_BACKEND_URL + `/api/billing/create-setup-intent`,
         
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        const clientSecret = response.data.clientSecret;

        const confirm = await stripe.confirmSetup({
          elements,
          clientSecret,
          confirmParams: {
            return_url: process.env.REACT_APP_FRONTEND_URL,
          },
          redirect: "if_required",
        });

        await axios.post(
          process.env.REACT_APP_BACKEND_URL + `/api/billing/subscribe/${currency}/${selectedPlan.key}`,
          {
            paymentMethod: confirm.setupIntent.payment_method,
            customerId: response.data.customerId,
            selectedPlan: { key: selectedPlan.key, totalExtras: selectedPlan.totalExtras, totalTeam: selectedPlan.totalTeam, totalPrice: selectedPlan.selectedTotalPrice },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
      }

      getData();
      initCompleted(0);
    } catch (err) {
      console.log(err);
      const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
      return initErrors(errorMessage, 0);
    }
  };

  return (
    <div className="p-2 min-w-96">
      {initState[1].error && <div className="text-taskinatorRed">{initState[1].error}</div>}
      {!availablePlans && initState[1].started && (
        <div>
          <IconSpinner />
        </div>
      )}
      {/* {!showBilling && availablePlans && ( */}
      <div className="">
        <div className={`${!showBilling ? "flex" : "hidden"} justify-center pt-2 `}>
          <div className="pr-1">Available Plans Based Off:</div>
          <div className="px-1">
            <b>{availablePlans?.team}</b> Team
          </div>
          <div>|</div>
          <div className="pl-1">
            <b>{availablePlans?.guests}</b> Guests
          </div>
          <div>|</div>
          <div className="pl-1">
            <b>{availablePlans?.workflows}</b> Active Workflows
          </div>
        </div>

        <div className={` ${!showBilling ? "flex  md:flex-row flex-col" : "hidden"}`}>
          {availablePlans?.plans.map((plan) => {
            return (
              <div className="m-1">
                <PlanElement plan={plan} selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} minimumTeam={availablePlans?.team} />
              </div>
            );
          })}
        </div>
      </div>
      {/* )} */}

      {showBilling && selectedPlan && (
        <div className="">
          <div>
            <PlanElement plan={selectedPlan} selectedPlan={selectedPlan} final />
          </div>
          {!currentPlan?.paymentDetails?.last4 && (
            <div className=" mt-4">
              {" "}
              <PaymentElement />
            </div>
          )}

          {currentPlan?.paymentDetails?.last4 && <CardDetails currentPlan={currentPlan} />}

        </div>
      )}

      <div className="w-full justify-end flex flex-col grow">
        {(currentPlan.freePlan !== true || (currentPlan.freePlan === true && selectedPlan.freePlan !== true)) && (
          <Button
            disabled={!selectedPlan}
            label={!showBilling ? "Continue To Billing" : "Change Plan"}
            onClick={() => setShowBilling(!showBilling)}
            customClasses={`${
              selectedPlan ? (!showBilling ? "bg-taskinatorBlue text-taskinatorWhite" : "border-taskinatorBlue border text-taskinatorBlue") : "bg-taskinatorMedGrey text-taskinatorWhite"
            }  h-8  mt-2 grow`}
          />
        )}

        {showBilling && (
          <Button
            // disabled={!selectedPlan}'
            error={initState[0].error}
            isLoading={initState[0].started}
            label={currentPlan.paymentDetails ? `Confirm Plan Change to $${selectedPlan.selectedTotalPrice}${selectedPlan.currency}/month` : "Accept & Sign Up"}
            onClick={() => submitHandler()}
            customClasses={`
              bg-taskinatorBlue text-taskinatorWhite
              h-12  mt-2  grow`}
          />
        )}
      </div>
      {showBilling && !currentPlan.subscriptionDetails && (
        <div className="text-sm text-center w-full pt-2">
          A first payment Of ${selectedPlan.selectedTotalPrice} {selectedPlan.currency} (inc applicable tax) will be deducted{" "}
          {DaysReminingOnUnlimited > 0 ? `on the ${DateDisplay(firstPayment)}` : "today"}
        </div>
      )}
      {showBilling && currentPlan.subscriptionDetails && <div className="text-sm text-center w-full pt-2">Updated Plan Charged Prorated to Your Account</div>}
    </div>
  );
};

export default SelectPlan;
