import React, { useState, useContext } from "react";
import axios from "axios";
import Input_V4 from "../../inputs/input_V4";
import { useInit } from "../../hooks/useInit";
import { TaskContext } from "../../../contexts/taskContext";
import { useInputV4 } from "../../../stateManagement/useInputV4";
import FileUploaderV4 from "../../inputs/fileUploader_V4";
import Button from "../../UI_elements/button";
import { VALIDATOR_MINLENGTH } from "../../forms/validators/validators";

import IconSpinner from "../../UI_elements/iconSpinner";


const ActionCommentsNoteAdder = ({ tracerWorkflowGroupId, tracerId, refresh }) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();
  const taskContext = useContext(TaskContext);
  const { inputState, inputHandler, attachmentHandler } = useInputV4();
  const [critical, setCritical] = useState(false);

  const addNoteHandler = (isCritical) => {
    initStarted(0);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/tracer/add/comments/${tracerWorkflowGroupId}/${tracerId}`,
        {
          comments: inputState.inputs.comments.value,
          critical: isCritical,
          attachments: Object.values(inputState.attachments).map((attachment) => {
            return { key: attachment.value.key, fileName: attachment.value.fileName };
          }),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((responseData) => {
        refresh();
        setCritical(false);

        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  return (
    <>
      {initState[0].started && (
        <div className="h-full w-full items-center justify-center flex">
          <IconSpinner /> Submitting Note...
        </div>
      )}
      {!initState[0].started && (
        <div className="flex flex-col items-end xl:max-w-xl mx-auto pt-1 ">
          <Input_V4
            onInput={inputHandler}
            inputVer="1"
            id="comments"
            sid="inputs"
            textareaRows={3}
            customClasses={"w-full resize-none"}
            validators={[VALIDATOR_MINLENGTH(3)]}
            placeholder={"Enter diary note here..."}
          />
          <div className="pt-0.5 w-full ">
            <FileUploaderV4 attachmentHandler={attachmentHandler} id="attachment" />
          </div>
          <div className="mt-2 flex items-center  justify-between w-full">
            <div className="h-full items-center flex">
              <Button
                label={
                  <div className="w-full  flex">
                    Submit Note as Critical  
                    {/* <ToolTip content={"Click this button will require anyone assigned to this task to acknowledge the note."} /> */}
                  </div>
                }
                disabled={!inputState.isValid}
                isLoading={initState[0].started}
                customClasses={`${
                  inputState.isValid ? "border-taskinatorRed border text-taskinatorRed" : "border-taskiantorMedGrey text-taskinatorMedGrey border"
                } py-1 h-8 mr-1 rounded-lg font-medium text-sm  uppercase`}
                onClick={() => {
                  addNoteHandler(true);
                  setCritical(true);
                }}
                error={initState[0].error}
              />

              {/* <div
                onClick={() => setCritical(!critical)}
                className={` ${
                  critical ? "text-taskinatorWhite bg-taskinatorRed hover:opacity-75" : "text-taskinatorRed hover:text-taskinatorWhite hover:bg-taskinatorRed "
                } cursor-pointer border border-taskinatorRed text-sm font-medium uppercase px-2 h-8 items-center justify-center flex rounded ml-1 mr-4`}
              >
                <div className="pr-1">
                  <MdNotificationsActive />
                </div>
                Submit as Critical Note <ToolTip content={"Click this button will require anyone assigned to this task to acknowledge the note."} />
              </div> */}
            </div>

            <Button
              label="SUBMIT DIARY NOTE"
              disabled={!inputState.isValid}
              isLoading={initState[0].started}
              customClasses={`${
                inputState.isValid ? "border-taskinatorBlue border text-taskinatorBlue" : "border-taskiantorMedGrey border text-taskinatorMedGrey"
              } py-1 h-8 mr-1 rounded-lg font-medium text-sm `}
              onClick={() => addNoteHandler(false)}
              error={initState[0].error}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ActionCommentsNoteAdder;
