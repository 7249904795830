import React, { useCallback, useState } from "react";
import Button from "../../UI_elements/button";
import Modal from "../../UI_elements/modal";
import FileUploaderV4 from "../../inputs/fileUploader_V4";
import Input_V4 from "../../inputs/input_V4";
import TaskWorkflowSequenceDisplay from "./taskWorkflowSequenceDisplay";
import { MdLock } from "react-icons/md";

const TaskWorkflowElementDisplayV4 = ({
  stateId,
  actions,
  workflowData,
  globalDispatch,
  globalInputHandler,
  globalAttachmentHandler,
  globalSid,
  itemsAlreadySelected,
  workflowScope,
}) => {
  const [workflowModal, setWorkflowModal] = useState();
  const [scopeModal, setScopeModal] = useState();

  const workflowScopeValue =
    workflowScope && workflowScope[stateId]
      ? workflowScope[stateId]
      : "No Scope Defined";

  const scopeChangeHandler = useCallback(
    (id, value, isValid, sid) => {
      globalDispatch({
        type: "WORKFLOW_SCOPE",
        workflowStateId: stateId,
        workflowScope: value,
      });
    },
    [stateId]
  );

  return (
    <>
      <div
        key={stateId}
        className="mb-2 flex flex-col bg-taskinatorWhite h-full border border-taskinatorMedGrey rounded-lg  "
      >
        {/* <div className="">
          <TaskItemDisplayV4 globalSid={globalSid} globalInputHandler={globalInputHandler} workflowId />
        </div> */}
        <div className="w-full flex-col flex h-full">
          <div className="flex-col flex px-4 pt-4 pb-4">
            <div className="flex items-center justify-center">
              {/* <div className="text-md border rounded-full h-6 w-6 items-center justify-center flex font-bold border-2 mr-2 text-taskinatorDarkGrey border-taskinatorDarkGrey">2</div> */}
              <div className="w-fit pr-2 text-md flex text-taskinatorGreen uppercase font-semibold">
                {workflowData.name}
              </div>
              <div className="text-md text-taskinatorGreen uppercase font-semibold pr-2 items-center">
                |
              </div>
              <div className="text-sm pr-2 text-taskinatorDarkGrey opacity-90">
                Managers:
              </div>
              <div className="grow pr-2 flex">
                {workflowData.managers.map((manager) => {
                  return (
                    <div
                      key={manager._id}
                      className={`ml-2 w-fit capitalize bg-taskinatorHighlightBlue text-taskinatorDarkGrey font-medium py-0.5 px-2 flex items-center justify-center rounded text-xs whitespace-nowrap ${
                        manager.locked ? "opacity-50" : ""
                      }`}
                    >
                      {manager.locked ? (
                        <div className="pr-1">
                          <MdLock />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="pr-1">{manager.firstName}</div>
                      <div className="uppercase"> {manager.lastName[0]} </div>
                    </div>
                  );
                })}
              </div>
              <div
                className="cursor-pointer text-taskinatorRed hover:opacity-100 justify-end text-xs mx-1 px-4 py-2 border border-taskinatorRed rounded opacity-50"
                onClick={() =>
                  globalDispatch({ type: "REMOVE_WORKFLOW", stateId: stateId })
                }
              >
               Change Sequence
              </div>
            </div>

            {/* <div className="my-2">
              <Input_V4
                sid={globalSid}
                id="workflowscope"
                label="First Workflow Diary Entry"
                placeholder={"Add a first diary entry for this workflow"}
                textareaRows={2}
                onInput={globalInputHandler}
                validators={[]}
                validityMessage="Min 6 Charactors"
              />

              <div className="pt-1">
                <FileUploaderV4
                  id="attachment"
                  attachmentHandler={globalAttachmentHandler}
                />
              </div>
            </div> */}
          </div>
        </div>

        <div className="border-t-4 border-t-taskinatorLightGrey">
          <TaskWorkflowSequenceDisplay
            incomingWorkflowData={workflowData}
            stateId={stateId}
            dispatch={globalDispatch}
            setModal={setWorkflowModal}
            currentlySetActionData={actions[stateId]}
          />
        </div>

        {/* <div className="mt-1 border-l border-l-4 border-l-taskinatorGreen py-2 px-4 text-taskinatorDarkGrey bg-taskinatorWhite shadow mb-1 text-md font-semibold uppercase flex items-center ">
          <div>WorkfFlow</div>
        </div>

        <div className="bg-taskinatorWhite shadow  border-l border-l-4 border-l-taskinatorGreen ">
          <TaskWorkflowSequenceDisplay incomingWorkflowData={workflowData} stateId={stateId} dispatch={globalDispatch} setModal={setWorkflowModal} currentlySetActionData={actions[stateId]} />
        </div> */}
      </div>

      {/* {workflowModal && (
        <TaskWorkflowActionSetModal incomingWorkflowData={workflowData} stateId={stateId} dispatch={globalDispatch} setModal={setWorkflowModal} currentlySetActionData={actions[stateId]} />
      )} */}
      {scopeModal && (
        <Modal local={() => setScopeModal()}>
          <div className="w-96">
            <div className="w-full text-lg text-taskinatorDarkGrey font-semibold text-center pb-2">
              Set/Edit Workflow Scope
            </div>
            <Input_V4
              id={"scope"}
              sid={"workflow"}
              textareaRows={5}
              defaultValue={workflowScopeValue}
              onInput={scopeChangeHandler}
              validators={[]}
            />
            <Button
              onClick={() => setScopeModal()}
              label={"Save Scope"}
              customClasses={`bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-4`}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default TaskWorkflowElementDisplayV4;
