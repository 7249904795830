import React, { useState } from "react";
import StringShortenDisplay from "../../UI_elements/stringShortenDisplay";
import Modal from "../../UI_elements/modal";
import ItemRevisionDetailsContainer from "../items/itemRevisionDetailsContainer";
import { MdUpdate } from "react-icons/md";
import Tag from "../../UI_elements/tag";
import FileListView from "./fileListView";

const FileSimpleDetailsElement = ({ fileSet, clickable, removeItem, aidDetails, border, stacked, current }) => {
  const [modal, setModal] = useState();

  return (
    <>
      <div
        onClick={
          clickable
            ? (event) => {
                event.stopPropagation();
                setModal(true);
              }
            : () => {}
        }
        className={`h-12 border items-center  border-taskinatorBlue rounded-lg justify-center px-4  ${
          clickable ? "cursor-pointer hover:bg-taskinatorHoverBlue" : ""
        }  w-full bg-taskinatorWhite shadow  py-3 flex ${stacked ? "lg:flex-col md:flex-row flex-col" : ""} ${border ? "border border-taskinatorBlue rounded shadow border-2" : ""} `}
      >
        <div className="text-taskiantorDarkGrey text-sm text-taskinatorBlue items-center uppercase font-semibold">
          {fileSet.number} - {fileSet.name}
        </div>
        <div>
          <div className={`pl-2 flex`}>
            {fileSet?.settings?.fileTags?.map((tag, index) => {
              return <Tag key={tag._id} tagData={tag} />;
            })}
          </div>
        </div>
        <div className="flex grow"></div>
        {removeItem && (
          <div
            className={`text-xs cursor-pointer text-taskinatorRed hover:opacity-100 opacity-50 justify-end w-fit px-4 flex items-center `}
            onClick={(event) => {
              event.stopPropagation();
              removeItem(fileSet._id);
            }}
          >
            Remove
          </div>
        )}
      </div>

      {modal && (
        <Modal
          size="max"
          title="Files In Set"
          bgColour="bg-taskinatorLightGrey"
          local={() => {
            setModal();
          }}
        >
          <div>
            <FileListView fileSetId={fileSet._id} fileSelection={{}} localOnly />{" "}
          </div>
        </Modal>
      )}
    </>
  );
};

export default FileSimpleDetailsElement;
