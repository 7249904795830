import React, { useContext, useEffect, useState } from "react";
import { MdClose, MdCheck } from "react-icons/md";
import axios from "axios";
import IconSpinner from "../UI_elements/iconSpinner";
import { useInit } from "../hooks/useInit";
import { JobModalContext } from "./jobs/jobModalContext";
import ProgressBar from "../../utils/progressBar";
import StringShortenDisplay from "../UI_elements/stringShortenDisplay";
const AttachmentDisplayV4 = ({ attachmentData, onDelete, disabled, noUploadProgress, totalFilesToUpload }) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  const isUploaded = attachmentData.progress === 100;
  const jobData = useContext(JobModalContext);
  const regex = /\.([^.]+)$/;

  const extension = attachmentData?.name?.match(regex);

  const imageExtensions = ["jpg", "jpeg", "png", "gif"]; // Add more extensions if needed
  const isImage = imageExtensions.includes(extension?.[1] ?? "error");

  const [imageURL, setImageURL] = useState();

  // useEffect(() => {
  //   if (isImage && isUploaded) {
  //     initStarted(1);

  //     axios
  //       .get(process.env.REACT_APP_BACKEND_URL + `/api/attachment/download/${attachmentData.key}/${attachmentData.name}/${null}`, {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         withCredentials: true,
  //       })
  //       .then((responseData) => {
  //         setImageURL(responseData.data.signedURL);
  //         initCompleted(1);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
  //       });
  //   }
  // }, [isImage, isUploaded]);

  return (
    <div className={`text-xs border rounded mr-2 my-1 p-1 border-taskinatorBlue flex flex-col justify-start  items-start w-40`}>
      <div className="flex justify-center items-start ">
        {/* {imageURL && (
          <img
            src={imageURL} // Assuming 'url' contains the image source
            alt={attachmentData.name}
            className="max-w-xs max-h-20" // Set a max width and height
          />
        )} */}
        {!imageURL && <div>{StringShortenDisplay(15, attachmentData.name)}{attachmentData.fileExtension}</div>}
        {!attachmentData.isDeleting && (
          <div className={`text-taskinatorRed ml-3 ${!disabled ? "cursor-pointer" : "cursor-not-allowed"} hover:opacity-75 h-4 w-4 text-base`}>
            <MdClose
              onClick={() => {
                if (!disabled) {
                  onDelete(attachmentData.key);
                }
              }}
            />
          </div>
        )}
      </div>
      {!imageURL && !attachmentData.isDeleting && !noUploadProgress && attachmentData.progress >= 1 && !attachmentData.uploadError && (
        <div>
          {attachmentData.progress === 100 ? (
            <div className="text-xxs font-bold text-taskinatorGreen flex items-center justify-start w-full">
              Done
              {/* Added To Job{" "} */}
            </div>
          ) : (
            <ProgressBar percentage={attachmentData.progress} />
          )}
        </div>
      )}
      {!attachmentData.uploading && !attachmentData.uploadError && !attachmentData.preview && attachmentData.progress !== 100 && <div className="text-taskinatorGreen text-xxs">Queued for upload</div>}
      {!attachmentData.uploading && !attachmentData.uploadError && attachmentData.preview && (
        <div className="text-taskinatorGreen text-xxs flex items-start -pl-2">
          <IconSpinner size={"s"} /> Generating Preview ...{" "}
        </div>
      )}
      {attachmentData.uploadError && <div className="text-xxs text-taskinatorRed">Error Uploading</div>}
      {attachmentData.isDeleting && (
        <div className="flex items-center">
          <div>
            <IconSpinner color={"text-taskinatorBlue"} />
          </div>
          <div className="ml-1">deleting...</div>
        </div>
      )}
    </div>
  );
};

export default AttachmentDisplayV4;
