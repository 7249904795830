import React, { useContext, useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Button from "../components/UI_elements/button";
import Modal from "../components/UI_elements/modal";
import ActionDetailDisplayV2 from "../components/displayElements/actionDisplay/actionDetailDisplayV2";
import JobModalDetailDisplay from "../components/displayElements/jobs/jobModalDetailDesiplay";
import ProjectView from "../components/displayElements/project/projectView";
import NewProjectForm from "../components/forms/newProjectForm";
import UserDetailsForm from "../components/forms/userDetailsForm";
import { AppContext } from "../contexts/appContext";
import GuideDisplay from "../guides/guideDisplay";
import Activity from "../pages/activity/activity";
import AllJobs from "../pages/allTasks/allJobs";
import AllQuestions from "../pages/allTasks/allQuestions";
import Dashboard from "../pages/dashboard/dashboard";
import DraftFiles from "../pages/files/draftFiles";
import Files from "../pages/files/files";
import Focus from "../pages/files/focus";
import Items from "../pages/items/items";
import NewTask from "../pages/newTask/newTask";
import Options from "../pages/options/options";
import BillingInit from "../pages/settings/billingInit";
import Team from "../pages/team/team";
import ThirdParty from "../pages/team/thirdParty";
import Timesheet from "../pages/timesheet/timesheet";
import Workflow from "../pages/workflow/workflow";
import SideNav from "./sideNav";

const AppPageContainer = () => {
  const appData = useContext(AppContext);
  const location = useLocation();
  const [pathState, setPathState] = useState("/");
  const [storageLimitReached, setStorageLimitReached] = useState();
  const navigate = useNavigate();
  const modalActivated =
    appData.topLevelModal && appData.topLevelModal.length > 0;

  useEffect(() => {
    if (
      appData?.workspaceDataV4?.isOverStorageLimit &&
      location.pathname !== "/settings/account"
    ) {
      setStorageLimitReached(true);
    }
  }, [appData.workspaceDataV4, location]);

  return (
    // <div className="flex flex-col h-dvh grow">
    <div className="flex flex-col w-screen h-screen grow">
      {process.env.REACT_APP_DEVMODE === "true" && (
        <div className="w-full bg-taskinatorRed text-taskinatorWhite text-center">
          Demo Version
        </div>
      )}
      <div className="flex md:flex-row h-full flex-col bg-taskinatorLightGrey md:overflow-hidden overflow-auto">
        <div className="h-12 md:h-full md:w-32 bg-taskinatorDarkGrey shadow-lg transition-all shrink-0">
          <SideNav />
        </div>
        <div className="flex flex-col grow md:h-svh h-fit md:overflow-auto">
          <Routes>
            <Route path="tasks" element={<Dashboard />} />
            <Route path="timesheet" element={<Timesheet />} />
            <Route path="draft/files" element={<DraftFiles />} />
            <Route path="track/jobs" element={<AllJobs />} />
            <Route path="track/questions" element={<AllQuestions />} />
            <Route path="track/items" element={<Items />} />
            <Route path="track/files" element={<Focus />} />
            <Route
              path="view/files/:fileSetId/:fileSetName"
              element={<Files />}
            />
            <Route path="tasks/create" element={<NewTask />} />
            <Route path="analyse/activity" element={<Activity />} />
            <Route path="manage/team" element={<Team />} />
            <Route path="manage/thirdparty" element={<ThirdParty />} />
            <Route path="manage/workflows" element={<Workflow />} />
            <Route path="manage/options" element={<Options />} />
            <Route path="settings/account" element={<BillingInit />} />
            <Route path="tasks/view/:tracerId" element={<Dashboard />} />
            <Route
              path="*"
              element={
                <Navigate
                  to={
                    appData.workspaceDataV4.role ===
                    process.env.REACT_APP_ACCESS_G
                      ? "track/items"
                      : "tasks"
                  }
                  replace
                />
              }
            />
          </Routes>
        </div>
      </div>

      {modalActivated && <Modal>{appData.topLevelModal[0]}</Modal>}

      {appData.isModal && (
        <Modal bgColour="bg-taskinatorWhite " pending={true}>
          <UserDetailsForm
            done={() => {
              appData.getWorkspaceData();
              appData.setIsModal();
              navigate("/manage/workflows");
              appData.setToolTipModal(
                <GuideDisplay
                  url={`https://www.loom.com/embed/7f4cc468f31449f0aa163ec85ee7da6f?sid=ed95a7c7-1408-42cd-a95b-7d438241d264`}
                />
              );
            }}
          />
        </Modal>
      )}

      {appData.viewProject && (
        <Modal
          size={appData.viewProject.pid ? "max" : "medium"}
          bgColour="bg-taskinatorWhite "
          title={appData.viewProject.pid ? "" : "Create New Project"}
          local={() => {
            appData.setViewProject();
          }}
        >
          {appData.viewProject.pid && (
            <ProjectView
              pid={appData.viewProject.pid}
              getData={() => appData.viewProject.getData()}
            />
          )}
          {!appData.viewProject.pid && (
            <NewProjectForm
              onSuccess={(pid) => {
                appData.setViewProject({ pid: pid });
              }}
            />
          )}
        </Modal>
      )}

      {appData.viewAction && (
        <Modal
          size={"max"}
          bgColour="bg-taskinatorWhite "
          local={() => {
            appData.setActionViewHandler();
          }}
        >
          <ActionDetailDisplayV2
            tracerId={appData.viewAction.tracerId}
            onSubmitSuccess={() => {
              appData.setViewAction();
            }}
            tid={appData.viewAction.tid}
            getData={() => {}}
          />
        </Modal>
      )}

      {appData.viewJob && (
        <Modal
          size={"max"}
          bgColour="bg-taskinatorWhite "
          local={() => {
            appData.setJobViewHandler();
          }}
        >
          <JobModalDetailDisplay />
        </Modal>
      )}

      {appData.toolTipModal && (
        <Modal
          size="large"
          title="Quick Guide Video"
          local={() => {
            appData.setToolTipModal();
          }}
        >
          {appData.toolTipModal}
        </Modal>
      )}

      {appData.socketError && (
        <Modal>
          {" "}
          <div className="text-taskinatorRed text-sm p-4 font-medium">
            Error Connecting To Server - Please Refresh Page
          </div>
        </Modal>
      )}

      {storageLimitReached && (
        <Modal isWithoutClose title={"Storage Limit Reached"}>
          <div className="flex flex-col justify-center items-center p-4">
            <div>
              Unfortunately You Have Reached Your Storage Limit. Please Upgrade
              To Continue Using Next-Task
            </div>
            {![
              process.env.REACT_APP_ACCESS_T,
              process.env.REACT_APP_ACCESS_G,
              process.env.REACT_APP_ACCESS_D,
            ].includes(appData.workspaceDataV4.role) && (
              <Button
                onClick={() => {
                  setStorageLimitReached();
                  navigate("/settings/account");
                }}
                label="Go To Billing"
                customClasses={
                  "bg-taskinatorBlue h-8 rounded text-taskinatorWhite mt-4"
                }
              />
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AppPageContainer;
