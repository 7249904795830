import React, { useState, useEffect } from "react";
import { MdPlayArrow } from "react-icons/md";

const JobModalDropDownContainer = ({ headerContent, children, backgroundColour, openAtStart, thin, noDropDownBorder, onOpen, onClose }) => {
  const [dropdown, setDropDown] = useState(openAtStart ? true : false);

  useEffect(() => {
    setDropDown(openAtStart);
  }, [openAtStart]);

  return (
    <>
      <div
        onClick={() => {
          setDropDown((current) => {
          
            return !current;
          });
        }}
        className={`cursor-pointer text-sm pr-4 pl-2 text-taskinatorWhite flex w-full z-0  ${backgroundColour}   ${
          dropdown ? "rounded-t-lg border-b-0" : `${noDropDownBorder ? "rounded-t-lg" : "rounded-lg"}`
        }  ${thin ? "h-fit" : "min-h-12 h-fit"}  md:py-0 py-2 flex-col justify-center items-start
`}
      >
        <div className="flex w-full items-center text-base uppercase whitespace-nowrap">
          <div className={`text-sm flex justify-center items-center text-taskinatorWhite h-6 w-6 ${dropdown ? "rotate-90" : ""}`}>
            <MdPlayArrow size={"1.25rem"} />
          </div>
          {headerContent}
        </div>
      </div>
      {dropdown && <div className={`px-1 h-fit bg-taskinatorLightGrey ${noDropDownBorder ? "" : "border border-taskinatorMedGrey"} p-1 rounded-b-lg border-t-0`}>{children}</div>}
    </>
  );
};

export default JobModalDropDownContainer;
