const DateDisplay = (dateData) => {

  if(dateData){
  const year = new Date(dateData).getFullYear();
  const day = new Date(dateData).getDate();
  const month = new Date(dateData).getMonth() + 1;
  

  const formatedMonth = month < 10 ? `0${month}` : `${month}`;
  const formatedDate = true
    ? `${day}/${formatedMonth}/${year}`
    : `${formatedMonth}/${day}/${year}`;

  return formatedDate;
  } else return "..."
};

export default DateDisplay;
  