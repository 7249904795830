import React, { useState } from "react";
import CountUpTimer from "../../utils/countUpTimer";
import { useTimeDisplay } from "../../utils/useTimeDisplay";

const ElementCard_V2 = ({
  topContent,
  bottomContent,
  isNew,
  onClick,
  primaryBorder,
  createdDate,
  seenBy,
  done,
  doneText,
  hoverChild,
  row,
  opacity,
  thin,
  hoverCSS,
}) => {
  const [hover, setHover] = useState();
  const { getRunTime } = useTimeDisplay();

  return (
    <div className={` ${opacity ? opacity : ""} `} onClick={() => onClick()}>
      {isNew && (
        <div className="flex grow justify-end h-0 pt-3 z-40">
          <div className="relative -top-2 mr-2 items-center flex h-4 w-fit text-taskinatorWhite text-[0.75rem] bg-taskinatorRed px-2 rounded-full font-semibold">
            New
          </div>
        </div>
      )}
      {/* {!isNew && row && (
        <div className="md:flex grow  justify-end h-0 pt-3 z-40 hidden">
          <div className="relative -top-2 mr-2 items-center flex h-4 w-fit text-taskinatorWhite text-[0.75rem]   px-2 rounded-full font-semibold"></div>
        </div>
      )} */}
      <div
        className={` ${hoverCSS} z-10 flex flex-col grow bg-taskinatorWhite shadow ${
          hoverChild ? "" : ""
        } rounded  ${primaryBorder} mt-1 `}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover()}
      >
        <div
          className={`flex border-b border-b-taskinatorMedGrey px-2 ${
            thin ? "h-fit" : "h-[68px]"
          } items-center`}
        >
          {topContent}
          <div
            className={`flex grow justify-end text-xs ${
              createdDate && !done ? "block" : "hidden"
            }`}
          >
            {<CountUpTimer startDate={createdDate} />}
          </div>
          <div
            className={`flex grow justify-end text-xs ${
              !done ? "hidden" : "block"
            }`}
          >
            Closed:<div className="pl-1"> {getRunTime(done)}</div>
          </div>
        </div>

        <div className="flex items-center px-1 text-sm h-fit min-h-12 mt-1">
          {bottomContent}
        </div>
      </div>
      <div className={`${hover ? "mb-4" : "mb-4"}`}>
        {hover && seenBy && (
          <div className="flex text-xxs w-full items-center pt-1">
            <div className="w-fit whitespace-nowrap pr-1 font-medium">
              Seen By:
            </div>
            <div className="flex w-full">
              {seenBy.length > 0 &&
                seenBy.map((user, index) => {
                  return (
                    <div key={user._id ?? index} className="flex capitalize">
                      {/* {user.firstName} */}
                      <div className="flex pr-1">
                        <div className="pr-1">{user.firstName}</div>
                        <div>{user.lastName[0]}</div>
                        {seenBy.length > 1 && <div>,</div>}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ElementCard_V2;
