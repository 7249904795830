import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../contexts/appContext";
import AddFileButton from "../files/addFileButton";
import FileSimpleDetailsElement from "../files/fileSimpleDetailsElement";

const JobFileSelector = ({
  globalInputHandler,
  globalSid,
  workflowAdded,
  type,
  wfid,
  number,
  tid,
  noNotes,
}) => {
  const [fileSetSelected, setFileSetSelected] = useState({});
  const appData = useContext(AppContext);
  const [notes, setNotes] = useState();

  // const [itemSelectorState, dispatch] = useReducer(itemSelectReducer, {});
  const isItemSelected = Object.values(fileSetSelected).length > 0;

  useEffect(() => {
    globalInputHandler(
      "fileSets",
      Object.values(fileSetSelected).map((fileSet) => {
        return { ...fileSet };
      }),
      Object.values(fileSetSelected).length > 0 ? true : false,
      globalSid
    );
  }, [fileSetSelected]);

  // useEffect(() => {
  //   globalInputHandler("notes", notes ? notes : null, true, globalSid);
  // }, [notes]);

  const removeItemHandler = (itemId) => {
    const currentState = fileSetSelected;
    delete currentState[itemId];
    setFileSetSelected({ ...currentState });
  };

  // useEffect(() => {
  //   if (!workflowAdded) {
  //     setFileSetSelected({});
  //   }
  // }, [workflowAdded]);

  return (
    <>
      <div className="w-full justify-center flex flex-col h-fit items-center overflow-auto scrollbar">
        <div className="w-full">
          <div className="py-2 px-4 text-taskinatorWhite bg-taskinatorBlue shadow mb-1 text-base font-semibold uppercase flex items-center rounded-lg">
            {/* <div className=" border rounded-full h-6 w-6 items-center justify-center flex font-bold border-2 mr-2 text-taskinatorDarkGrey border-taskinatorDarkGrey">3</div> */}
            <div className="flex items-center text-base">
              {/* <div className="border rounded-full justify-center flex h-6 w-6 items-center mr-2 border-2 text-sm border-taskinatorBlue text-taskinatorBlue">{number}</div> */}
              File Set
            </div>{" "}
         
          </div>
          {isItemSelected && (
            <div className="w-full">
              {Object.values(fileSetSelected).map((fileSet, index) => {
                return (
                  <div
                    className="pb-1 text-sm flex flex-col overflow-auto scrollbar"
                    key={index}
                  >
                    <FileSimpleDetailsElement
                      fileSet={fileSet}
                      removeItem={removeItemHandler}
                      clickable
                    />
                  </div>
                );
              })}
            </div>
          )}

          <div className={`w-full justify-center items-center flex`}>
            <AddFileButton
              searchAndAdd={true}
              globalSetItems={(itemData) => setFileSetSelected(itemData)}
              type={type}
              wfid={wfid}
              tid={tid}
              selectableItemsOnTask={fileSetSelected}
            />{" "}
              
             {Object.values(fileSetSelected).length === 0  &&  <div className="pl-1 text-taskinatorRed capitalize font-normal text-sm">
              (Required)
            </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default JobFileSelector;
