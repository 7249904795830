import React from "react";
import IconSpinner from "../../UI_elements/iconSpinner";
import UserDisplayBubble from "../../UI_elements/userDisplayBubble";
import { useInit } from "../../hooks/useInit";
import AddStepDisplayButton from "../addStepDisplayButton";

const JobDetailsWorkflowDisplay = ({
  canSubmitBack,
  noAssignee,
  workflowGroupId,
  thirdParty,
  actionPlan = [],
}) => {
  const { initState } = useInit(2);

  const actionPlanWithStages = actionPlan.filter(
    (item) => (item?.stages ?? []).length
  );

  return (
    <div className="flex flex-row">
      {!actionPlanWithStages && initState[0].started && (
        <div className="w-full flex items-center justify-center h-full grow">
          <IconSpinner size={"m"} />
        </div>
      )}

      {!actionPlanWithStages && initState[0].error && (
        <div className="text-xs text-taskinatorRed text-center">
          {/* {initState[0].error} */}
        </div>  
      )}

      <div className="flex flex-row overflow-auto grow px-2 py-1 items-center xl:h-full h-fit scrollbar bg-clip-border">
        {actionPlanWithStages &&
          !initState[0].started &&
          actionPlanWithStages?.map((stage, stageIndex) => {
            {
              return (
                <div key={stageIndex} className="flex items-center flex-row">
                  <div className="flex flex-col justify-center">
                    {stage.stages.map((action, actionIndex) => {
                      return (
                        <div
                          key={`action-${action._id}`}
                          className={
                            "flex flex-col justify-center lg:mr-0 items-center my-2 cursor-pointer hover:scale-110"
                          }
                        >
                          <div
                            onClick={() => {}}
                            className={`border-taskinatorMedGrey text-taskinatorMedGrey border rounded-lg shadow by px-2 py-1 text-xs flex flex-col w-fit ${
                              action.isNext || (action.isBack && canSubmitBack)
                                ? ""
                                : "opacity-75"
                            }`}
                          >
                            <div className="flex justify-start">
                              <div className="flex items-center w-full">
                                <div className="justify-center flex">
                                  <div className="flex w-full items-center">
                                    <div className="font-semibold text-sm uppercase text-center pr-2 whitespace-nowrap">
                                      {action.name}
                                    </div>
                                  </div>
                                  {!noAssignee && (
                                    <div className="flex justify-center">
                                      <div className="flex items-center text-sm w-full justify-start">
                                        {action.assignee &&
                                        action.assignee.length > 0 ? (
                                          action.assignee.map(
                                            (assignee, assigneeIndex) => {
                                              return (
                                                <UserDisplayBubble
                                                  key={`assignee-${assigneeIndex}`}
                                                  firstName={assignee.firstName}
                                                  lastName={assignee.lastName}
                                                  user={assignee}
                                                  textSize={"text-xxs"}
                                                  colourOverride={
                                                    action.isCurrent
                                                      ? "bg-taskinatorWhite text-taskinatorGreen"
                                                      : "bg-taskinatorMedGrey text-taskinatorDarkGrey opacity-50"
                                                  }
                                                />
                                              );
                                            }
                                          )
                                        ) : (
                                          <div className="text-taskinatorMedGrey whitespace-nowrap text-xxs border rounded py-0.5 px-1">
                                            No Assignee
                                          </div>
                                        )}

                                        {/* {action.isCurrent && <div className={`pl-1 xs text-taskinatorWhite  `}>(Current)</div>} */}
                                        {/* <div className=" pr-2 text-taskinatorDarkGrey">Assignees:</div> */}
                                      </div>

                                      {(action.approvedStatus ||
                                        action.rejectedStatus) && (
                                        <div
                                          className={`h-full text-xs items-center flex opacity-50 ${
                                            action.approvedStatus
                                              ? "text-taskinatorGreen"
                                              : "text-taskinatorRed"
                                          }`}
                                        >
                                          {action.approvedStatus
                                            ? "Completed"
                                            : "Rejected"}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {stage.stages.length > 0 &&
                    stageIndex < actionPlanWithStages.length - 1 && (
                      <div className="block">
                        <AddStepDisplayButton notClickable />
                      </div>
                    )}
                  {stage.stages.length > 0 &&
                    stageIndex < actionPlanWithStages.length - 1 && (
                      <div className="hidden">
                        <AddStepDisplayButton notClickable rotate />
                      </div>
                    )}
                </div>
              );
            }
          })}
        {/* {actionPlan && tracer.tracerType === "workflow" && tracer.completed && (
          <div className="flex items-center justify-end grow text-taskinatorGreen ">
            <MdCheckCircle size={"2rem"} />
            <div className="px-2 justify-center flex flex-col text-sm font-medium">
              <div>Completed </div> <div>{DateDisplay(tracer.completedDate)}</div>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default JobDetailsWorkflowDisplay;
