import axios from "axios";
import React, { useContext } from "react";
import { AppContext } from "../../contexts/appContext";
import { useInputV4 } from "../../stateManagement/useInputV4";
import Button from "../UI_elements/button";
import { useInit } from "../hooks/useInit";
import InputV4 from "../inputs/input_V4";
import { VALIDATOR_MINLENGTH, VALIDATOR_NUMBER } from "./validators/validators";

const UserDetailsForm = ({ done }) => {
  const { initState, initStarted, initCompleted, initErrors } = useInit();
  const { inputState, inputHandler } = useInputV4({
    inputs: { confirmAgree: { value: false, isValid: true } },
    isValid: false,
  });
  const appData = useContext(AppContext);

  const submitHandler = (event) => {
    event.preventDefault();
    initStarted(0);
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/api/team/org-details`,
        {
          // name: inputState.inputs.firstname.value.toLowerCase(),
          // lastName: inputState.inputs.lastname.value.toLowerCase(),
          organisationName:
            inputState.inputs.organisationName.value.toLowerCase(),
          contactNumber: inputState.inputs.contactNumber.value,
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then(() => {
        done();
      })
      .catch((err) => {
        console.log(err);
        const errorMessage =
          err.response && err.response.data
            ? err.response.data.message
            : err.message;
        return initErrors(errorMessage, 0);
      });
  };
  return (
    <div className="w-[500px]">
      <form className="mx-auto" onSubmit={submitHandler}>
        <div className="-rounded-md">
          <div className="flex w-full grow">
            <div className="items-center justify-center mx-auto text-center pb-5">
              <div className="text-center mx-auto flex items-center justify-center font-medium text-xl">
                Welcome To Next-Task
              </div>
            </div>
          </div>
          <div className="pt-2">
            <InputV4
              inputVer="1"
              label="Company Name"
              sid="inputs"
              id="organisationName"
              placeholder={`Company Name...`}
              customClasses={"rounded-md "}
              defaultValue={appData?.workspaceDataV4?.organisationName}
              onInput={inputHandler}
              validators={[VALIDATOR_MINLENGTH(2)]}
              error={"Minimum 6 characters"}
            />
          </div>
          <div className="pt-2">
            <InputV4
              inputVer="1"
              label="Contact number"
              sid="inputs"
              id="contactNumber"
              placeholder={`Contact Number...`}
              customClasses={"rounded-b-md "}
              defaultValue={appData?.workspaceDataV4?.organisationName}
              onInput={inputHandler}
              validators={[VALIDATOR_NUMBER(), VALIDATOR_MINLENGTH(6)]}
              error={"Minimum 3 characters"}
            />
          </div>
        </div>

        <div>
          <Button
            type="submit"
            customClasses={`${
              inputState.isValid ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"
            } text-taskinatorWhite h-10 w-full my-2`}
            label={"Submit & Start"}
            isLoading={initState[0].started}
            disabled={!inputState.isValid}
            error={initState[0].error}
          />
        </div>
      </form>
    </div>
  );
};

export default UserDetailsForm;
