import React, { useContext, useState } from "react";
import JobAndWorkflow from "../../components/UI_elements/jobAndWorkflow";
import { useInit } from "../../components/hooks/useInit";
import DropDownSelector_V4 from "../../components/inputs/dropDownSelector_V4";
import InputV4 from "../../components/inputs/input_V4";
import { AppContext } from "../../contexts/appContext";
import ContentView from "../../pageTemplates/contentView";

const AllJobs = () => {
  const [tasks, setTasks] = useState();
  const { initState, initStarted, initCompleted, initErrors } = useInit();
  const buttons = [];
  const appData = useContext(AppContext);

  const [filter, setFilter] = useState("");
  const [activityRange, setActivityRange] = useState("active");

  const centerContent = (
    <div className="flex items-center sm:flex-row flex-col w-full sm:w-fit sm:justify-center  sm:mt-0 mt-1">
      <DropDownSelector_V4
        customClasses={"w-40 items-center pr-2"}
        id="range"
        defaultValue="active"
        notSearchable
        options={[
          {
            data: { name: "active", value: "active", type: "" },
            name: "active",
            value: "active",
            type: "",
          },
          {
            data: { name: "7 days", value: "7", type: "" },
            name: "7 days",
            value: "7",
            type: "",
          },
          {
            data: { name: "30 days", value: "30", type: "" },
            name: "30 days",
            value: "30",
            type: "",
          },
          {
            data: { name: "60 days", value: "60", type: "" },
            name: "60 days",
            value: "60",
            type: "",
          },
          {
            data: { name: "all", value: "all", type: "" },
            name: "all",
            value: "all",
            type: "",
          },
        ]}
        onSelection={(id, value, isValid, sid) => {
          if (isValid) {
            setActivityRange(value?.value);
          }
        }}
      />
      <InputV4
        inputVer={"1"}
        customClasses={"sm:w-96 w-[100%] items-center"}
        placeholder="Search Jobs"
        onInput={(id, value, isValid, sid) => {
          setFilter(value);
        }}
        validators={[]}
      />
    </div>
  );

  const isAll = Object.keys(appData.tagFilter)[0] === "all tags";
  const filterTags = !isAll
    ? Object.values(appData.tagFilter)
        .map((key) => appData.jobListFilterValues[key])
        .flat(1)
    : [];

  return (
    <ContentView buttons={buttons} label={"All Jobs"} centerContent={centerContent}>
      <JobAndWorkflow filter={filter} jobView activityRange={activityRange} filterTags={filterTags} isAll={isAll} />
    </ContentView>
  );
};

export default AllJobs;
