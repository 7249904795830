import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import { AppContext } from "../../contexts/appContext";
import { useInit } from "../hooks/useInit";
import { useInputV4 } from "../../stateManagement/useInputV4";

import InputV4 from "../inputs/input_V4";
import Button from "../UI_elements/button";

import { VALIDATOR_EMAIL, VALIDATOR_MINLENGTH } from "./validators/validators";

import { useMsal } from "@azure/msal-react";
import { useGoogleLogin } from "@react-oauth/google";
import IconSpinner from "../UI_elements/iconSpinner";

const loginRequest = {
  scopes: ["User.Read"],
};

const LoginForm = ({ login }) => {
  const { iid, tracerId } = useParams();
  const { instance } = useMsal();
  const [oAuthLogin, setOAuthLogin] = useState();
  const appData = useContext(AppContext);

  const navigate = useNavigate();
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();
  const { inputState, inputHandler, attachmentHandler } = useInputV4({});
  const formType = login ? { buttonLabel: "Login", url: "login" } : { buttonLabel: "Sign Up", url: `accept/${iid}` };

  const submitHandler = () => {
    initStarted(0);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/${formType.url}`,
        {
          email: inputState.inputs.email.value,
          password: inputState.inputs.password.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        appData.setWorkspaceDataV4(response.data.workspaceData);
        if (tracerId) {
          appData.setViewTask(tracerId);
        }
        // console.log(response);
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
        return initErrors(errorMessage, 0);
      });
  };

  const handleMicrosoftLogin = (event) => {
    event.preventDefault();
    setOAuthLogin(true);
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        initStarted(0);
        console.log("logging in", response);
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/api/auth/aad/${formType.url}`,
            {
              accessToken: response.accessToken,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
            }
          )
          .then((response) => {
            appData.setWorkspaceDataV4(response.data.workspaceData);
            if (tracerId) {
              appData.setViewTask(tracerId);
            }

            // console.log(response);
          })
          .catch((err) => {
            console.log(err);
            const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
            return initErrors(errorMessage, 0);
          });
        // Handle login success, e.g., navigate or set user data
      })
      .catch((e) => {
        // Handle errors
        console.error(e);
      });
  };

  const googleSignIn = useGoogleLogin({
    onSuccess: (codeResponse) => {
      initStarted(0);

      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/auth/google/${formType.url}`,
          {
            accessToken: codeResponse.access_token,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          appData.setWorkspaceDataV4(response.data.workspaceData);
          if (tracerId) {
            appData.setViewTask(tracerId);
          }
          // console.log(response);
        })
        .catch((err) => {
          console.log(err);
          const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
          return initErrors(errorMessage, 0);
        });
    },
  });

  return !oAuthLogin ? (
    <div className="w-full flex flex-col gap-2">
      <div className="font-medium uppercase">Log In With:</div>
      <button
        onClick={() => {
          googleSignIn();
          setOAuthLogin(true);
        }}
        className="w-full flex justify-center items-center box-border p-2 gap-2 border border-solid border-taskiantorMedDarkGrey rounded-md"
      >
        <img src="https://aid-frontend.prod.atl-paas.net/atlassian-id/front-end/5.0.541/google-logo.5867462c.svg" width={20} height={20} />
        <span> {!initState[0].started ? "Continue with Google" : <IconSpinner />}</span>
      </button>
      <button onClick={handleMicrosoftLogin} className="w-full flex justify-center items-center box-border p-2 gap-2 border border-solid border-taskiantorMedDarkGrey rounded-md">
        <img src="https://aid-frontend.prod.atl-paas.net/atlassian-id/front-end/5.0.541/microsoft-logo.c73d8dca.svg" width={20} height={20} />
        <span> {!initState[0].started ? "Continue with Microsoft" : <IconSpinner />}</span>
      </button>
      <div className="w-full flex justify-center items-center">or</div>

      <form
        className="w-full"
        onSubmit={(event) => {
          event.preventDefault();
          submitHandler();
        }}
      >
        <div className="-space-y-px rounded-md shadow-sm text-taskinatorDarkGrey">
          {/* <div className='pb-2 text-2xl'>
            {!login ? 'Accept Invite & Login' : 'Log Into Your Account'}
          </div> */}
          <div>
            <InputV4
              inputVer="1"
              sid="inputs"
              type="email"
              id="email"
              placeholder={`${!login ? "Email as per invite" : "Email"}`}
              customClasses={" rounded-md"}
              textSize={"text-base"}
              onInput={inputHandler}
              validators={[VALIDATOR_EMAIL()]}
              // validityMessage={"Must be an email"}
            />
          </div>
          <div>
            <InputV4
              inputVer="1"
              type="password"
              sid="inputs"
              id="password"
              textSize={"text-base"}
              placeholder={`${!login ? "Set your password" : "Password"}`}
              customClasses={"rounded-md my-1"}
              onInput={inputHandler}
              validators={[VALIDATOR_MINLENGTH(6)]}
              // validityMessage={"Must be at least 6 charactors"}
            />
          </div>
        </div>

        <Button
          type="Submit"
          label={login ? "Login" : "Accept Invite"}
          customClasses={`${inputState.isValid ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"} bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-2 `}
          disabled={!inputState.isValid}
          isLoading={initState[0].started}
          error={initState[0].error}
        />
      </form>

      <div className="w-full flex justify-center items-center gap-2">
        <div className="w-fit flex items-center justify-between text-taskinatorDarkGrey">
          {login && (
            <div onClick={() => navigate("/forgotpassword")} className="text-sm cursor-pointer underline hover:text-taskinatorBlue">
              Forgot your password?
            </div>
          )}
        </div>
        {/* <span className="text-taskiantorMedDarkGrey">•</span> */}
        {/* {login && (
          <div onClick={() => navigate("/signup")} className="text-taskinatorDarkGrey text-sm font-medium w-fit text-center cursor-pointer hover:text-taskinatorBlue">
            or Signup?
          </div>
        )} */}
        {!login && (
          <div className="text-xs text-center text-taskinatorDarkGrey">
            {"By using Next-Task you're agreeing to the terms and conditions of use."}
            <a onClick={() => (window.location.href = `${process.env.REACT_APP_WEBSITE}/terms`)} className="pl-2 text-taskinatorBlue cursor-pointer">
              Terms
            </a>
            <a onClick={() => (window.location.href = `${process.env.REACT_APP_WEBSITE}/privacy`)} className="pl-2 text-taskinatorBlue cursor-pointer">
              Privacy Policy
            </a>
          </div>
        )}
      </div>
    </div>
  ) : (
    <IconSpinner />
  );
};

export default LoginForm;
