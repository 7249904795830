import React from "react";
import TopMenu from "./topMenu";

const ContentView = ({ buttons, label, centerContent, children, notButton, toolTip, hideTopMenu }) => {
  return (
    <div className="flex flex-col h-full  ">
      {!hideTopMenu && (
        <div className="sticky top-0 z-20">
          <TopMenu buttons={buttons} label={label} centerContent={centerContent} onClick={(index) => buttons[index].onClick} notButton={notButton} toolTip={toolTip} />
        </div>
      )}
      <div className="grow h-auto overflow-auto p-1">{children}</div>
    </div>
  );
};
export default ContentView;
