import React from "react";
import IconSpinner from "../UI_elements/iconSpinner";
import { SortWithKeyword } from "../displayElements/requests/requestModal";
import ColumnItemElement_V2 from "./columnItemElement_V2";
import JobDashboardElement from "./jobDashboardElement";

const ColumnElement_V2 = ({ primaryColour, faintColour, primaryBorder, label, jobData, jobs, type, getData, textColour, initState, project, row, opacity }) => {
  let totalElements = 0;

  [...jobData, ...jobs].forEach((obj) => {
    if (obj.tracers && typeof obj.tracers === "object") {
      totalElements += Object.keys(obj.tracers).length;
    }

    if (obj.jobDetails && typeof obj.jobDetails === "object") {
      totalElements += 1;
    }
  });

  return (
    <div className="flex flex-col h-full">
      <div className={`${primaryColour} shadow mt-0.5 px-4 py-3 rounded-lg flex items-center`}>
        <div className="text-taskinatorWhite font-semibold uppercase text-base">{label}</div>
        <div className="text-base text-taskinatorWhite font-bold pl-2">({totalElements})</div>
        <div></div>
      </div>
      <div className={`${faintColour} shadow mb-0.5 mt-1 rounded-lg px-1 py-1 grow border-taskinatorMedGrey border overflow-auto scrollbar flex ${row ? "md:flex-row flex-col" : "flex-col"}`}>
        {initState[0].started && !initState[0].initialised && (
          <div className="w-full items-center justify-center flex text-xs text-taskiantorMedGrey my-2">
            <IconSpinner />
          </div>
        )}
        {initState[0].error && <div className="w-full items-center justify-center flex text-xs text-taskinatorRed my-2">Error getting data</div>}
        {initState[0].initialised && !initState[0].error && jobData.length === 0 && jobs.length === 0  && (
          <div className="w-full items-center justify-center flex text-xs text-taskiantorMedGrey my-2">Nothing Here For You...</div>
        )}
        {initState[0].initialised &&
          jobData.length > 0 &&
          SortWithKeyword(jobData, "_id").map((data) => {
            return (
              <div key={`${data.tid}-${data.actionName}-${data.workflowName}`} className="">
                <ColumnItemElement_V2 key={data._id} primaryColour={primaryColour} primaryBorder={primaryBorder} data={data} getData={getData} textColour={textColour} />
              </div>
            );
          })}
        {initState[0].initialised &&
          jobs.length > 0 &&
          jobs.map((job, index) => {
            {
              return (
                <div key={job._id ?? index}>
                  <JobDashboardElement primaryColour={primaryColour} primaryBorder={primaryBorder} job={job} textColour={textColour} />
                </div>
              );
            }
          })}
      </div>
    </div>
  );
};

export default ColumnElement_V2;
