import React from "react";
import DateDisplay from "../../UI_elements/dateDisplay";
import ItemStatus from "../../UI_elements/itemStatus";
import FileDisplay from "./fileDisplay";
import FilePreview from "../../../utils/filePreview";
import Tag from "../../UI_elements/tag";

const FileDetails = ({ file, fileDetailsView, notClickable, wide, noDate, localOnly, noTags, showAuto }) => {
  return file._id !== "placeHolder" ? (
    <div className="flex  items-center">
      <div className="px-2  sm:flex hidden text-taskinatorMedGrey">|</div>
      <div className="pr-2 w-32"> {<ItemStatus status={file.archived === true ? "Archived" : file.status} />}</div>
      {!noDate && <div className="px-2  sm:flex hidden text-taskinatorMedGrey">|</div>}
      {!noDate && <div className=" w-20 text-center justify-center sm:flex hidden">{DateDisplay(file.createdDate)}</div>}
      <div className="px-2  sm:flex hidden text-taskinatorMedGrey">|</div>
      <div className="flex w-12 justify-center">
        <div className="font-medium pr-1">Rev: </div>
        <div className=" overflow-hidden">{file.revision ? file.revision : "N/A"}</div>
      </div>
      <div className="px-2 text-taskinatorMedGrey">|</div>
      {/* <div className="font-medium px-2  sm:flex hidden">File: </div> */}
      <div
        className="w-fit"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <FilePreview file={file} markup viewMarkupsOnly notClickable={notClickable} wide={wide} isPreview={localOnly} />
      </div>
      {!noTags && <div className="px-2 text-taskinatorMedGrey ">|</div>}
      {/* <div className="font-medium px-2  sm:flex hidden">Access Tag: </div> */}
      {!noTags && (
        <div className="flex w-60">
          {file?.settings?.fileTags.map((tag) => {
            return <Tag tagData={tag} />;
          })}
        </div>
      )}
      {showAuto && <div className="px-2 text-taskinatorMedGrey">|</div>}
      {showAuto && (
        <div className="flex w-60">
          {file?.autoSequence?.length > 0 ? (
            file.autoSequence.map((tag) => {
              return <Tag tagData={{ ...tag, type: "workflows" }} />;
            })
          ) : (
            <div className="text-taskinatorRed font-medium">No Auto Sequence Selected</div>
          )}
        </div>
      )}
    </div>
  ) : (
    <div>No File</div>
  );
};

export default FileDetails;
