import React, { useContext, useState, useEffect } from "react";
import CheckboxV4 from "../../inputs/checkbox_V4";
import BulkFileUpload from "../files/bulkFileUpload";
import FileViewAndActions from "../files/fileViewAndActions";
import { JobModalContext } from "./jobModalContext";
import JobSubmission from "./jobSubmission";
import UserDisplayBubble from "../../UI_elements/userDisplayBubble";
import axios from "axios";
import { useInit } from "../../hooks/useInit";
import IconSpinner from "../../UI_elements/iconSpinner";
import JobModalDropDownContainer from "./jobModalDropDownContainer";
import Tag from "../../UI_elements/tag";
import Button from "../../UI_elements/button";
import DateDisplay from "../../UI_elements/dateDisplay";
import CountUpTimer from "../../../utils/countUpTimer";
import Modal from "../../UI_elements/modal";
import BulkFileEditor from "../files/bulkFileEditor";

const FileSetAction = ({ fileSetByTask, workflowData, setWorkflow }) => {
  const [selectAll, setSelectAll] = useState(false);
  const [referenceFiles, setReferenceFiles] = useState([]);
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  const [fileSelected, setFileSelected] = useState({});
  const [bulkEdit, setBulkEdit] = useState();
  const [showFiles, setShowFiles] = useState(fileSetByTask?.isAssigned);

  const [showReferenceFiles, setShowReferenceFiles] = useState();
  const jobData = useContext(JobModalContext);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    setCurrentPage(0);
  }, [fileSetByTask?.files.length]);

  // Calculate the current items to display
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = fileSetByTask?.files.slice(indexOfFirstItem, indexOfLastItem);

  const movementOptions = workflowData?.workflowMap;
  const forwardMovementOptions = movementOptions[fileSetByTask._id]?.forward;
  const backwardMovementOptions = movementOptions[fileSetByTask._id]?.back;
  const nextWorkflows = movementOptions[fileSetByTask._id]?.nextworkflow;

  // Change page handler
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const fileSelectionHandler = (selectedId, isChecked, file) => {
    if (isChecked) {
      setFileSelected((prevSelected) => ({
        ...prevSelected,
        [selectedId]: file, // Add the selected ID
      }));
    } else {
      setFileSelected((prevSelected) => {
        const newSelection = { ...prevSelected }; // Create a new object
        delete newSelection[selectedId]; // Remove the ID
        return newSelection; // Return the new state
      });
    }
  };

  const CompleteHandler = () => {
    initStarted(1);

    axios
      .put(
        process.env.REACT_APP_BACKEND_URL +
          `/api/job/marktaskascompleted/${jobData?.jobId}/${workflowData?.workflowGroupId}/${fileSetByTask._id}/${fileSetByTask?.taskCompleted ? "undo" : "completed"}`,
        { data: true },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response.data);
        initCompleted(1);
      })
      .catch((err) => {
        console.log(err);
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
      });
  };

  // /referenceFiles/:jobId/:taskId/:fileSetId
  useEffect(() => {
    const fetchHandler = async () => {
      try {
        initStarted(0, 1);

        const responseData = await axios.get(
          process.env.REACT_APP_BACKEND_URL + `/api/job/referenceFiles/${jobData.jobId?.toString()}/${fileSetByTask._id}/${fileSetByTask?.fileSetId?.toString()}`,

          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        initCompleted(0, 1);

        setReferenceFiles(responseData.data.data);
      } catch (err) {
        console.log(err);
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0, 1);
      }
    };

    fetchHandler();
  }, [fileSetByTask._id, fileSetByTask.files]);

  return (
    <>
      <div className="flex flex-col bg-taskinatorOffWhite border-taskinatorMedGrey border-4 rounded-lg m-0.5 p-2 border">
        <div className=" text-xs  border-b border-b-2  border-b-taskinatorMedGrey  text-taskinatorDarkGrey  uppercase items-center flex pb-1 grow">
          <b className="ml-1 px-4 py-1 items-center text-lg border border-2 rounded-xl  border-taskinatorGreen ">{fileSetByTask.name} </b>
          <div className="text-xs pl-2 h-full flex items-center capitalize border-l border-l-taskinatorMedGrey ml-2">
            Assigned To:
            {fileSetByTask?.assignees?.map((user, index) => {
              if (index < 2)
                return (
                  <div key={user._id} className="">
                    <UserDisplayBubble firstName={user.firstName} lastName={user.lastName} user={user} />
                  </div>
                );
            })}
            {fileSetByTask?.assignees?.length > 2 && <div className="text-xs ">...</div>}
          </div>

          <div className="text-xs px-2 flex items-center capitalize border-x border-x-taskinatorMedGrey mx-2 ">
            <div className="pr-2 ">Active Files:</div>
            {fileSetByTask?.taskDetails?.fileTags?.map((tag, index) => {
              // if (index < 2)
              return (
                <div key={tag._id} className="opacity-50">
                  <Tag tagData={tag} highlight={true} hideText />
                </div>
              );
            })}
            {/* {fileSetByTask?.taskDetails?.fileTags?.length > 2 && <div className="text-xs ">...</div>} */}
          </div>

          {!jobData.jobCompleted && fileSetByTask.isManager && !fileSetByTask.isAssigned && (
            <div className=" bg-taskinatorOrange  px-2 text-xs text-taskinatorWhite py-0.5 font-medium rounded uppercase">You're Managing This Task</div>
          )}
          <div className="flex justify-end items-center grow">
            {!jobData.jobCompleted &&
              !fileSetByTask.taskCompleted &&
              workflowData?.workflowMap &&
              fileSetByTask?.isAssigned &&
              !setWorkflow &&
              (backwardMovementOptions?.length > 0 || forwardMovementOptions?.length > 0 || nextWorkflows?.length > 0 ? (
                <JobSubmission fileSelected={fileSelected} fileSetByTask={fileSetByTask} setFileSelected={setFileSelected} setSelectAll={setSelectAll} workflowData={workflowData} />
              ) : (
                <div className="text-taskinatorRed">End Of Workflow</div>
              ))}
            {!jobData.jobCompleted && fileSetByTask?.taskCompleted && (
              <div className="text-xs  normal-case flex items-center">
                Marked as <div className="font-bold uppercase text-taskinatorGreen border border-taskinatorGreen rounded py-1 px-0.5 ml-1">completed</div>{" "}
                <div className="px-1">{<CountUpTimer startDate={fileSetByTask.completedDate} />}</div> ago by:{" "}
                <div className="font-semibold flex capitalize pl-1">
                  {fileSetByTask.completedBy.firstName}
                  <div className="pl-1">{fileSetByTask.completedBy.lastName}</div>
                </div>
              </div>
            )}
            {!jobData.jobCompleted && (forwardMovementOptions.length === 0 || fileSetByTask?.taskCompleted) && (
              <Button
                label={fileSetByTask?.taskCompleted ? "Re-Open Task" : "Mark Task As Completed"}
                customClasses={`${
                  fileSetByTask?.taskCompleted ? "bg-taskinatorOrange text-taskinatorBlack opacity-50" : "border-taskinatorGreen border text-taskinatorGreen"
                } hover:opacity-75   py-2 text-xs uppercase ml-2`}
                onClick={() => CompleteHandler()}
                isLoading={initState[1].started}
                disabled={initState[1].started}
              />
            )}{" "}
          </div>
        </div>

        <div className="flex grow cursor-pointer underline text-taskinatorBlue" onClick={() => setShowFiles(!showFiles)}>
          {!showFiles ? "View Files In Task" : "Hide Files In Task"}
        </div>
        {showFiles && (
          <>
            {!fileSetByTask?.taskCompleted && !jobData.jobCompleted ? (
              <div className="flex grow h-fit  items-center">
                <div className="pl-2 ">
                  <CheckboxV4
                    label="Select All Files"
                    onInput={(id, isChecked, isValid, sid) => {
                      setSelectAll(isChecked);
                    }}
                    customCheckbox={"h-5 w-5"}
                  />
                </div>
                <div className="flex grow justify-end items-center">
                  {Object.values(fileSelected).length > 1 && (
                    <div onClick={() => setBulkEdit(true)} className="underline text-taskinatorBlue py-2 cursor-pointer">
                      Bulk Edit
                    </div>
                  )}
                  <div>
                    <BulkFileUpload
                      onFilesUploaded={() => {}}
                      fileSetId={fileSetByTask.fileSetId}
                      fileSetName={fileSetByTask.fileSetName}
                      buttonandModal
                      customButtonCSS=" border-taskinatorBlue  underline text-taskinatorBlue py-2  w-full  "
                      jobData={{ _id: jobData.jobId, taskDetails: fileSetByTask.taskDetails, taskId: fileSetByTask._id, workflowData: workflowData }}
                    />
                  </div>
                  <div>
                    {/* <Button
              label="Bulk Edit"
              customClasses={`${!filesSelected ? " border-taskinatorMedGrey text-taskinatorMedGrey" : "border-taskinatorBlue text-taskinatorBlue hover:opacity-75 "} underline py-2 text-sm `}
              disabled={!filesSelected}
            /> */}
                  </div>
                </div>
              </div>
            ) : (
              <div className="h-5"></div>
            )}

            {fileSetByTask?.files.map((file, index) => {
              return (
                <div key={file.revisionTrackingId}>
                  <FileViewAndActions
                    globalOnInput={(selectedId, isChecked) => {
                      fileSelectionHandler(selectedId, isChecked, file);
                    }}
                    key={index}
                    file={file}
                    index={index}
                    fileSetByTask={fileSetByTask}
                    workflowData={workflowData}
                    setWorkflow={setWorkflow}
                    selectAll={selectAll}
                  />
                </div>
              );
            })}

            {!jobData.jobCompleted && referenceFiles && (
              <div className="mt-2">
                <JobModalDropDownContainer
                  thin
                  headerContent={
                    <>
                      <div className=" order-taskinatorWhite pl-2 pr-2 text-taskinatorDarkGrey text-sm">Reference Files </div>
                      <div className="text-taskinatorDarkGrey text-sm font-medium">({referenceFiles?.length})</div>
                    </>
                  }
                  backgroundColour={"bg-taskinatorMedGrey"}
                >
                  {referenceFiles.length === 0 && initState[0].initialised && <div className="text-xs text-taskinatorMedGrey">No Reference Files</div>}
                  <div className="">
                    {referenceFiles.length > 0 &&
                      referenceFiles.map((file, index) => {
                        return (
                          <div key={file._id}>
                            <FileViewAndActions
                              file={file}
                              index={index}
                              workflowData={workflowData}
                              taskData={fileSetByTask.taskDetails}
                              fileSetId={fileSetByTask.fileSetId}
                              completed={fileSetByTask?.taskCompleted || jobData.jobCompleted}
                              isRef
                            />
                          </div>
                        );
                      })}
                    {initState[0].started && <IconSpinner />}
                  </div>
                </JobModalDropDownContainer>
              </div>
            )}
          </>
        )}
      </div>

      {bulkEdit && (
        <Modal local={() => setBulkEdit()} title="Bulk Edit" size={"medium"}>
          <BulkFileEditor
            titleLess
            bulkFileList={fileSelected}
            currentFileSetId={fileSetByTask.fileSetId}
            taskId={fileSetByTask._id}
            workflowGroupId={workflowData?.workflowGroupId}
            onResponse={() => setBulkEdit()}
            taskTags={fileSetByTask?.taskDetails?.fileTags}
          />
        </Modal>
      )}
    </>
  );
};

export default FileSetAction;
