import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { MdFolderZip, MdImage, MdPictureAsPdf } from "react-icons/md";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../contexts/appContext";
import AdobePDFViewerContainer from "../../../utils/adobePDFViewerContainer";
import IconSpinner from "../../UI_elements/iconSpinner";
import Modal from "../../UI_elements/modal";
import StringShortenDisplay from "../../UI_elements/stringShortenDisplay";
import { useInit } from "../../hooks/useInit";

const FileDisplay = ({
  file,
  colorChange,
  maxWidth,
  previewView,
  noName,
  notClickable,
  itemId,
  thirdParty,
  markup,
  item,
  viewMarkupsOnly,
  viewInMarkup,
  setMarkupDocument,
  textLimit,
  fileDetailsView,
  openLocal,
  automatic,
  wide,
}) => {
  const appData = useContext(AppContext);
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(3);
  const { accessCode, accessCode2, id, accessCode3 } = useParams();
  const [expand, setExpand] = useState();
  const [modal, setModal] = useState();
  const [attachmentMarkup, setAttachmentMarkup] = useState();
  // const [downloadPercentage, setDownloadPercentage] = useState(0);
  const [savedMarkupURL, setSavedMarkupURL] = useState();

  const extension = file?.fileExtension;

  const modalDisplay = () => {
    return (
      <div className="flex flex-row h-full text-taskiantorDarkGrey">
        <AdobePDFViewerContainer
          expand={expand}
          setExpand={setExpand}
          attachmentKey={file.key ? file.key : file.attachmentKey}
          pdfFileName={file.fileName}
          pdfURL={modal}
          item={item}
          savedMarkupURL={savedMarkupURL}
          viewMarkupsOnly={viewMarkupsOnly}
          uniqueID={file.key ? file.key : file.attachmentKey}
          close={() => {
            setModal();
            if (openLocal) {
              openLocal();
            }
            appData.socket.emit(
              "pdfAnnotation",
              {
                action: "disconnect",
                attachmentKey: file.key ? file.key : file.attachmentKey,
              },
              (callback) => {}
            );
          }}
          fileDetailsView={fileDetailsView}
          file={file}
          openLocal={openLocal}
        />
      </div>
    );
  };


  const attachmentClickHandler = async (action) => {
    initStarted(1);

    const url = thirdParty
      ? `/api/tp/attachment/download/${accessCode}/${accessCode2}/${id}/${accessCode3}/${file.key ? file.key : file.attachmentKey}/${file.fileName}${file.fileExtension}`
      : `/api/attachment/download/${file.key ? file.key : file.attachmentKey}/${file.fileName}${file.fileExtension}/${itemId}`;
    try {
      const responseData = await axios.get(process.env.REACT_APP_BACKEND_URL + url, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      if (file.fileExtension === ".pdf") {
        if (markup || viewMarkupsOnly) {
          setModal(responseData.data.signedURL);
        } else if (viewInMarkup) {
          setMarkupDocument({
            pdfURL: responseData.data.signedURL,
            attachmentKey: file.key ? file.key : file.attachmentKey,
            pdfFileName: file.fileName,
          });
        } else {
          window.open(`${responseData.data.signedURL}`, `_blank`, `noopener, noreferrer`);
        }
      } else {
        window.open(`${responseData.data.signedURL}`, `_blank`, `noopener, noreferrer`);
      }
      initCompleted(1);
    } catch (err) {
      console.log(err);
      return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
    }
  };

  // useEffect(() => {
  //   if (automatic) {
  //     attachmentClickHandler();
  //   }
  // }, [automatic]);

  return (
    <>
      {openLocal && modal ? (
        modalDisplay()
      ) : (
        <div
          className={`flex flex-col `}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div
            className={`flex ${wide ? "w-60" : "w-fit"} rounded border bg-taskinatorItemBlue px-2 py-1  ${!colorChange ? "border-taskinatorBlue text-taskinatorDarkGrey" : colorChange} ${
              notClickable ? "" : "cursor-pointer  hover:bg-taskinatorBlue hover:border-1 hover:text-taskinatorWhite"
            }`}
            onClick={notClickable ? () => {} : () => attachmentClickHandler("download")}
          >
            <div className="flex justify-start items-center grow">
              {initState[1].started && (
                <div className="flex ">
                  <div>{<IconSpinner color={"text-taskinatorBlue"} />}</div>
                </div>
              )}
              {/* </div>{downloadPercentage !== 100 && <div  className="text-xs pl-1"> {downloadPercentage}%</div> */}
              {!initState[1].started && (
                <div className="flex items-center justify-center grow ">
                  <div>{extension === ".pdf" ? <MdPictureAsPdf size={"1.25rem"} /> : extension === ".zip" ? <MdFolderZip size={"1.25rem"} /> : <MdImage size={"1.25rem"} />}</div>
                  {!noName && (
                    <div className="font-medium pl-1 overflow-clip text-xs  ">
                      {StringShortenDisplay(textLimit ? textLimit : 20, decodeURIComponent(file.fileName))}
                      {extension}{" "}
                    </div>
                  )}
                  <div className="flex grow justify-end ">
                    {(attachmentMarkup || item?.markups?.length > 0 || file?.liveMarkups || file?.markups?.length > 0) && (
                      <div className="text-[0.7rem] bg-taskinatorRed text-taskinatorWhite w-fit px-2 rounded ml-2">MARKUPS</div>
                    )}
                  </div>{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {!openLocal && modal && (
        <Modal
          size={expand ? "max" : "large"}
          local={() => {
            setModal();
            appData.socket.emit(
              "pdfAnnotation",
              {
                action: "disconnect",
                attachmentKey: file.key ? file.key : file.attachmentKey,
              },
              (callback) => {}
            );
          }}
          isWithoutClose={true}
        >
          {modalDisplay()}
          {/* <div className="flex flex-row h-full">
            <AdobePDFViewerContainer
              attachmentKey={file.key ? file.key : file.attachmentKey}
              pdfFileName={file.fileName}
              pdfURL={modal}
              item={item}
              savedMarkupURL={savedMarkupURL}
              viewMarkupsOnly={viewMarkupsOnly}
              uniqueID={file.key ? file.key : file.attachmentKey}
              close={() => {
                setModal();
                appData.socket.emit(
                  "pdfAnnotation",
                  {
                    action: "disconnect",
                    attachmentKey: file.key ? file.key : file.attachmentKey,
                  },
                  (callback) => {}
                );
              }}
              fileDetailsView={fileDetailsView}
              file={file}
            />
          </div> */}
        </Modal>
      )}
    </>
  );
};

export default FileDisplay;
