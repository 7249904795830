import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../contexts/appContext";
import { useInputV4 } from "../../stateManagement/useInputV4";
import { useInit } from "../hooks/useInit";
import { MdClose, MdLock } from "react-icons/md";
import DirectionControlSelector from "../../components/dataSelectors/directionControlSelector";
import ToolTip from "../../utils/toolTip";
import Button from "../UI_elements/button";
import IconSpinner from "../UI_elements/iconSpinner";
import Modal from "../UI_elements/modal";
import MemberSelector from "../dataSelectors/memberSelector";
import TagSelector from "../dataSelectors/tagSelector";
import CheckboxV4 from "../inputs/checkbox_V4";
import InputV4 from "../inputs/input_V4";
import { VALIDATOR_REQUIRE } from "./validators/validators";

const Options = [
  { type: "", name: "Activates In Both Directions", value: "standard" },
  // { type: "", name: "Milestone", value: "milestone" },
  {
    type: "",
    name: "Activates When Moving Forward",
    value: "stop here going forward",
  },
  {
    type: "",
    name: "Activates When Moving Backward",
    value: "stop here going backward",
  },
];

const OptionObject = {
  ["standard"]: "Activates In Both Directions",
  ["milestone"]: "Milestone",
  ["stop here going forward"]: "Activates When Moving Forward",
  ["stop here going backward"]: "Activates When Moving Backward",
};

const NewActionForm = ({ action, teamData, children, dispatch, workflowState, stageIndex, removeButtonStyle, actionIndex }) => {
  const appData = useContext(AppContext);
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(3);
  const [edit, setEdit] = useState(!action._id ? true : false);

  const [selectType, setSelectType] = useState(action._id ? (action.thirdParty ? 1 : 2) : null);

  const [checkingRemove, setCheckingRemoving] = useState();
  const [actionsRunning, setActionsRunning] = useState([]);

  let startingState = {};
  let startingValidation = false;

  if (action._id) {
    startingState = {
      inputs: {
        name: { value: action.name, isValid: true },
        directionControl: { value: action.directionControl, isValid: true },
        team: { value: action.team, isValid: true },
        // submitText: { value: action.submitText, isValid: true },
        // rejectText: { value: action.rejectText, isValid: true },
        // canEditItem: { value: action.canEditItem, isValid: true },
        canApproveItems: { value: action.canApproveItems, isValid: true },
        canCreateDraftItems: {
          value: action.canCreateDraftItems,
          isValid: true,
        },
        canMarkupItems: {
          value: action?.canMarkupItems,
          isValid: true,
        },
        autoSelect: {
          value: action.autoSelect ? action.autoSelect : false,
          isValid: true,
        },
        milestoneTask: {
          value: action.milestoneTask ? action.milestoneTask : false,
          isValid: true,
        },
        thirdParty: {
          value: action.thirdParty ? action.thirdParty : false,
          isValid: true,
        },
        fileTags: {
          value: action.fileTags ? action.fileTags : [],
          isValid: true,
        },
        completeButton: { value: action?.completeButton, isValid: true },
        rejectButton: { value: action?.rejectButton, isValid: true },
        // viewDraft: { value: action.viewDraft, isValid: true },
        // viewReleased: { value: action.viewReleased, isValid: true },
        // addMoreItems: { value: action.addMoreItems, isValid: false },

        // leadTime: {value: action.leadTime, isValid: true},
        // isLeadTimeAdjustable: {value: action.isLeadTimeAdjustable, isValid: true}
      },
    };

    startingValidation = action.isValid;
  } else {
    startingState = {
      inputs: {
        name: { value: "", isValid: false },
        directionControl: { value: "", isValid: false },
        team: { value: [], isValid: true },
        canApproveItems: { value: false, isValid: true },
        canCreateDraftItems: { value: false, isValid: true },
        canMarkupItems: { value: false, isValid: true },
        autoSelect: { value: false, isValid: true },
        fileTags: { value: [], isValid: true },
        completeButton: { value: "", isValid: true },
        rejectButton: { value: "", isValid: true },
        milestoneTask: { value: false, isValid: true },
        // addMoreItems: { value: "", isValid: false },
      },
    };
  }

  const { inputState, inputHandler } = useInputV4(startingState, startingValidation);

  const saveClickHandler = () => {
    let actionData = {
      name: inputState.inputs.name.value,
      directionControl: inputState.inputs.directionControl.value,
      team: selectType === 1 ? [] : inputState.inputs.team.value,
      canApproveItems: selectType === 1 ? false : inputState.inputs.canApproveItems.value,
      canCreateDraftItems: selectType === 1 ? false : inputState.inputs.canCreateDraftItems.value,
      canMarkupItems: selectType === 1 ? false : inputState.inputs.canMarkupItems.value,
      thirdParty: selectType === 1 ? true : false,
      isValid: inputState.isValid,
      autoSelect: inputState.inputs.autoSelect.value,
      fileTags: inputState.inputs.fileTags.value,
      completeButton: inputState.inputs.completeButton.value,
      rejectButton: inputState.inputs.rejectButton.value,
      milestoneTask: inputState.inputs.milestoneTask.value,
      stageIndex: stageIndex,
      // addMoreItems: inputState.inputs.addMoreItems.value,
      wfid: { _id: workflowState._id, name: workflowState.name },
    };

    if (action._id) {
      actionData = { ...actionData, _id: action._id };
      onSaveHandler(actionData);
    } else {
      onCreateAndSave(actionData);
    }
  };

  const onCreateAndSave = (actionData) => {
    initStarted(0);

    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/workflow/action/createandsave`,
        { actionData: actionData, workflowState, stageIndex, actionIndex },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((responseData) => {
        dispatch({
          type: "SAVE_ACTION",
          actionData: { ...actionData, _id: responseData.data.actionDataId },
          stageIndex,
          actionIndex,
        });
        setEdit();
        initCompleted(0);
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
        // appData.ERROR(errorMessage);
        return initErrors(errorMessage, 0);
      });
  };

  const onSaveHandler = (actionData) => {
    // dispatch({ type: "SAVE_ACTION", actionData: actionData, actionId: actionId });
    initStarted(0);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/workflow/action/save/${workflowState._id}`,
        { actionData: { ...actionData, _id: action._id } },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((responseData) => {
        dispatch({
          type: "SAVE_ACTION",
          actionData,
          stageIndex,
          actionIndex,
        });
        setEdit();
        initCompleted(0);
      })
      .catch((err) => {
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  const removeActionHandler = () => {
    initStarted(2);

    console.log("removing task");
    axios
      .delete(
        process.env.REACT_APP_BACKEND_URL + `/api/workflow/action/delete/${action._id}/${stageIndex}/${workflowState._id}`,

        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((responseData) => {
        console.log(responseData.data);

        dispatch({
          type: "REMOVE_ACTION",
          stageIndex: stageIndex,
          actionIndex: actionIndex,
        });
        initCompleted(2);
      })
      .catch((err) => {
        setActionsRunning(err.response.data.runningTasks);
        return initErrors(err?.response?.data?.message ?? err?.message ?? "Error", 2);
      });
  };

  useEffect(() => {
    if (stageIndex === 0) inputHandler("directionControl", "standard", true, "inputs");
  }, [stageIndex]);

  return (
    <>
      <div
        className={`flex flex-col border items-center justify-top bg-taskinatorWhite ${
          (edit && inputState.isValid) || (!edit && action._id) ? "border-taskinatorHighlightBlue" : "border-taskinatorHighlightRed"
        } border-3 rounded-lg `}
      >
        {edit && (
          <div className=" w-96 p-2 ">
            <div
              className="flex justify-end text-taskinatorDarkGrey cursor-pointer hover:text-taskinatorBlue"
              onClick={() => {
                setEdit();
                if (!action._id) {
                  dispatch({
                    type: "REMOVE_ACTION",
                    stageIndex,
                    actionIndex,
                  });
                }
              }}
            >
              <MdClose size={"1.25rem"} />
            </div>

            {!selectType && (
              <div className="flex flex-col w-full items-center justify-center">
                <div className="text-center">Select Task Type</div>
                <div className="flex my-2 text-sm">
                  {/* <div className="text-center">
                    <Button
                      label={
                        <div className="flex justify-center pl-1">
                          {" "}
                          3rd Party Task <ToolTip content={"Tasks For Third Party"} />{" "}
                        </div>
                      }
                      onClick={() => setSelectType(1)}
                      customClasses={"mr-2 bg-taskinatorDarkGrey py-1 px-2 text-taskinatorWhite"}
                    />
                  </div> */}
                  <div className="text-center">
                    <Button
                      label={
                        <div className="flex justify-center pl-1">
                          {" "}
                          Team Tasks <ToolTip content={"Tasks for team members"} />{" "}
                        </div>
                      }
                      onClick={() => setSelectType(2)}
                      customClasses={"bg-taskinatorBlue py-1 px-2 text-taskinatorWhite"}
                    />
                  </div>
                </div>
              </div>
            )}
            {selectType && (
              <>
                <div className="flex items-center text-sm mb-2">
                  Task Type:{" "}
                  {selectType === 1 ? (
                    <div className="ml-1 border px-2 py-1 border-taskinatorDarkGrey rounded font-medium text-taskinatorDarkGrey whitespace-nowrap">3rd Party Task</div>
                  ) : (
                    <div className="ml-1 border px-2 py-1 border-taskinatorGreen rounded text-taskinatorGreen font-medium whitespace-nowrap">Team Task</div>
                  )}
                  {/* <div className="text-taskinatorBlue pl-2 underline cursor-pointer hover:font-medium" onClick={()=>setSelectType()}>Change</div> */}
                </div>
                <InputV4
                  inputVer="1"
                  label="Task Name"
                  sid="inputs"
                  id="name"
                  placeholder={`Enter an task name`}
                  customClasses={"rounded-md "}
                  defaultValue={inputState.inputs.name.value}
                  onInput={inputHandler}
                  validators={[VALIDATOR_REQUIRE(3)]}
                  validityMessage={"Must be at least three charactors"}
                />
                <DirectionControlSelector
                  inputVer="1"
                  inputHandler={inputHandler}
                  actionId={action?._id ?? ""}
                  sid={"inputs"}
                  defaultValue={startingState.inputs.directionControl.value}
                  Options={Options}
                />
                {selectType === 2 && (
                  <MemberSelector
                    inputVer="1"
                    inputHandler={inputHandler}
                    id="team"
                    sid="inputs"
                    multi
                    selectionDisplayText="Select Team Members"
                    label="Select Team Member"
                    roleTypes={[]}
                    defaultValue={inputState.inputs.team.value.map((team) => {
                      return { value: team._id };
                    })}
                  />
                )}
                {/* <TagSelector
            notRequired={false}
            id="team"
            inputHandler={inputHandler}
            defaultValue={inputState.inputs.team.value.map((teamId) => {
              return { value: teamId };
            })}
            sid="inputs"
          /> */}
                {/* 
          <InputV4
            inputVer="1"
            label="Target Leadtime (Days)"
            sid="inputs"
            id="leadTime"
            placeholder={`Enter In Days`}
            customClasses={"rounded-md "}
            defaultValue={inputState.inputs.leadTime.value}
            onInput={inputHandler}
            validators={[VALIDATOR_NUMBER()]}
            validityMessage={"Must be a number"}
          />

          <CheckboxV4 customeLabel={"text-sm"} onInput={inputHandler} sid={"inputs"} id="isLeadTimeAdjustable" label="Not adjustable at task creation"  defaultValue={inputState.inputs.isLeadTimeAdjustable.value}/> */}
                {/* <div className="pt-1">
            <InputV4
              inputVer="1"
              label="Forward Action"
              sid="inputs"
              id="submitText"
              placeholder={`Enter label for submit button`}
              customClasses={"rounded-md "}
              onInput={inputHandler}
              defaultValue={inputState.inputs.submitText.value}
              validators={[VALIDATOR_REQUIRE(3)]}
            />
            </div>
            <div className="pt-1">
           {inputState.inputs.directionControl && inputState.inputs.directionControl.value !== "milestone" &&  <InputV4
              inputVer="1"
              label="Backward Action"
              sid="inputs"
              id="rejectText"
              placeholder={`Enter label for reject button`}
              customClasses={"rounded-md "}
              defaultValue={inputState.inputs.rejectText.value}
              onInput={inputHandler}
              validators={[]}
            />}
      </div> */}
                {/* {selectType === 2 && (
                  <div className="pt-2">
                    <CheckboxV4
                      sid="inputs"
                      id="canCreateDraftItems"
                      onInput={inputHandler}
                      label="Can Create Draft Drawings"
                      defaultValue={inputState.inputs.canCreateDraftItems.value}
                      customeLabel={"text-sm"}
                      customCheckbox={"h-4 w-4"}
                      toolTip={"This enables draft drawings to be added on this task"}
                    />{" "}
                  </div>
                )}
                {selectType === 2 && (
                  <div className="pt-2">
                    <CheckboxV4
                      sid="inputs"
                      id="canMarkupItems"
                      onInput={inputHandler}
                      label="Can Mark Up Drawings"
                      defaultValue={inputState.inputs.canMarkupItems.value}
                      customeLabel={"text-sm"}
                      customCheckbox={"h-4 w-4"}
                      toolTip={"This enables markups to be added on this task"}
                    />{" "}
                  </div>
                )}
                {selectType === 2 && (
                  <div className="pt-2">
                    <CheckboxV4
                      sid="inputs"
                      id="canApproveItems"
                      onInput={inputHandler}
                      label="Can Release Drawings"
                      defaultValue={inputState.inputs.canApproveItems.value}
                      customeLabel={"text-sm"}
                      customCheckbox={"h-4 w-4"}
                      toolTip={"This enables released drawings to be added on this task"}
                    />{" "}
                  </div>
                )} */}
                {selectType === 2 && (
                  <div className="pt-2">
                    <CheckboxV4
                      sid="inputs"
                      id="autoSelect"
                      onInput={inputHandler}
                      label="Auto Select"
                      defaultValue={inputState.inputs.autoSelect.value}
                      customeLabel={"text-sm"}
                      customCheckbox={"h-4 w-4"}
                      toolTip={"This automatically sets this task in a workflow when creating the workflow"}
                    />{" "}
                  </div>
                )}
                {selectType === 2 && (
                  <div className="pt-2">
                    <CheckboxV4
                      sid="inputs"
                      id="milestoneTask"
                      onInput={inputHandler}
                      label="Is A Milestone"
                      defaultValue={inputState.inputs.milestoneTask.value}
                      customeLabel={"text-sm"}
                      customCheckbox={"h-4 w-4"}
                      toolTip={"This logs a milestone completed on files that are actioned during this task."}
                    />{" "}
                  </div>
                )}
                <div className="pt-2">
                  <TagSelector
                    id={"fileTags"}
                    inputHandler={inputHandler}
                    defaultValue={inputState?.inputs?.fileTags?.value?.map((tag) => {
                      return { value: tag._id };
                    })}
                    sid="inputs"
                    type="file"
                    label="Accessible File Tags"
                    selectionDisplayText={"Select/Create File Tag"}
                    toolTip={"This sets what files, within a file set, can be viewed on this task"}
                  />
                </div>
                <div className="pt-2">
                  <InputV4
                    inputVer="1"
                    label="Task Positive Action"
                    sid="inputs"
                    id="completeButton"
                    placeholder={`Enter a name`}
                    customClasses={"rounded-md "}
                    defaultValue={inputState.inputs.completeButton.value}
                    onInput={inputHandler}
                    validators={[]}
                    validityMessage={"Must be at least three characters"}
                    toolTip={"This allows you to track a positive action on a file within the task"}
                  />
                </div>
                <div className="pt-2">
                  <InputV4
                    inputVer="1"
                    label="Task Negative Action"
                    sid="inputs"
                    id="rejectButton"
                    placeholder={`Enter a name`}
                    customClasses={"rounded-md "}
                    defaultValue={inputState.inputs.rejectButton.value}
                    onInput={inputHandler}
                    validators={[]}
                    validityMessage={"Must be at least three characters"}
                    toolTip={"This allows you to track a negative action on a file within the task"}
                  />{" "}
                </div>
                {/* <div className="pt-2">
              <CheckboxV4
                sid="inputs"
                id="viewDraft"
                onInput={inputHandler}
                label="View Draft Items"
                defaultValue={inputState.inputs.viewDraft.value}
                customeLabel={"text-sm"}
                customCheckbox={"h-4 w-4"}
              />{" "}
            </div>

          
            <div className="pt-2">
              <CheckboxV4
                sid="inputs"
                id="viewReleased"
                onInput={inputHandler}
                label="View Released Items"
                defaultValue={inputState.inputs.viewReleased.value}
                customeLabel={"text-sm"}
                customCheckbox={"h-4 w-4"}
              />{" "}
            </div> */}
                {/* <div className="pt-2">
              <CheckboxV4
                sid="inputs"
                id="addMoreItems"
                onInput={inputHandler}
                label="Can Add more Items"
                defaultValue={inputState.inputs.addMoreItems.value}
                customeLabel={"text-sm"}
                customCheckbox={"h-4 w-4"}
              />{" "}
            </div>

            <div className="pt-2">
              <CheckboxV4
                sid="inputs"
                id="eta"
                onInput={inputHandler}
                label="Requires ETA Date?"
                // defaultValue={inputState.inputs.canApproveItems.value}
                customeLabel={"text-sm"}
                customCheckbox={"h-4 w-4"}
              />{" "}
            </div> */}
                <Button
                  type="submit"
                  label={"Save"}
                  customClasses={`${inputState.isValid ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"} bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-4 `}
                  disabled={!inputState.isValid}
                  isLoading={initState[0].started}
                  error={initState[0].error}
                  onClick={() => saveClickHandler()}
                />{" "}
              </>
            )}
          </div>
        )}
        {!edit && action._id && (
          <div className="min-h-[5rem] h-full p-2 w-60">
            <div className="font-medium text-taskinatorDarkGrey uppercase flex items-start w-full">
              <div className="text-base text-taskinatorBlue">{inputState.inputs.name.value}</div>{" "}
              <div className="mr-1 pt-1 cursor-pointer hover:text-taskinatorBlue underline text-xs pr-1 capitalize flex grow justify-end" onClick={() => setEdit(true)}>
                Edit
                {/* <MdOutlineMoreHoriz size={"1rem"} /> */}
              </div>
            </div>
            <div className="flex items-center my-0.5">
              {action.thirdParty ? (
                <div className="pr-1">
                  <div className="w-fit text-xxs border px-2 py-0.5 border-taskinatorOrange rounded font-medium text-taskinatorOrange whitespace-nowrap">3rd Party</div>
                </div>
              ) : (
                <></>
              )}
              <div className="w-fit text-xxs border px-2 py-0.5 border-taskinatorDarkGrey rounded font-medium text-taskinatorDarkGrey whitespace-nowrap capitalize">
                {OptionObject[inputState.inputs.directionControl.value]}
              </div>
            </div>
            <div className=" w-fit flex flex-wrap">
              {inputState.inputs.team.value.map((teamData, index) => {
                return (
                  <div
                    key={teamData.value ?? index}
                    className={`border-taskinatorBlue text-taskinatorBlue border rounded py-1 px-2 capitalize mr-1 text-xs mb-1 flex items-center ${teamData.locked ? "opacity-50" : ""}`}
                  >
                    {teamData.locked ? (
                      <div className="pr-1">
                        <MdLock />
                      </div>
                    ) : (
                      ""
                    )}{" "}
                    {teamData.firstName} {teamData.lastName[0]}
                  </div>
                );
              })}
            </div>

            <div className={`${removeButtonStyle} flex flex-col`} onClick={() => removeActionHandler()}>
              {initState[2].started ? "Deleting Task" : "Delete Task"}
            </div>
            <div className="text-center text-taskinatorRed">{initState[2].error && initState[2].error}</div>
          </div>
        )}
        {!edit && !action._id && (
          <div className="min-h-[5rem] w-44 flex items-center justify-center">
            <div
              onClick={() => setEdit(true)}
              className="text-center text-sm border rounded m-2 py-1 px-2 border-taskinatorBlue text-taskinatorBlue hover:bg-taskinatorBlue hover:text-taskinatorWhite cursor-pointer"
            >
              Set Details
            </div>
          </div>
        )}

        {!edit && children}
      </div>
      {checkingRemove && (
        <Modal local={() => setCheckingRemoving()} pending={initState[2].started}>
          <div className="text-center flex items-center w-40 my-4 justify-center">
            {initState[2].started && (
              <div className="flex text-base items-center">
                <div>
                  <IconSpinner size="m" />
                </div>
                <div className="pl-2">Checking for running actions...</div>
              </div>
            )}
            {initState[2].error && <div className="flex text-sm text-taskinatorRed">{initState[2].error}</div>}

            <div></div>
          </div>
        </Modal>
      )}

      {actionsRunning?.length > 0 && (
        <Modal
          local={() => {
            setActionsRunning([]);
          }}
        >
          <div className="text-center flex items-center w-96 justify-center flex-col p-4">
            <div className="text-taskinatorRed font-medium py-2">Cant Delete While Tasks Active!</div>
            {actionsRunning?.map((tasks) => {
              return (
                <div
                  onClick={
                    tasks.type === "tracer"
                      ? () => {
                          appData.setViewAction({
                            ...appData.viewAction,
                            tracerId: tasks._id,
                          });
                        }
                      : appData?.jobs?.[tasks._id]
                      ? () => appData.setJobViewHandler({ jobId: tasks._id })
                      : () => {}
                  }
                  className="w-full border border-taskinatorBlue font-medium text-taskinatorBlue rounded py-1 mb-1 hover:bg-taskinatorBlue hover:text-taskinatorWhite hover:border-taskinatorBlue cursor-pointer"
                >
                  {tasks.type === "tracer" ? tasks.taskDetails.ref : tasks.jobDetails.ref}
                </div>
              );
            })}
          </div>
        </Modal>
      )}
    </>
  );
};

export default NewActionForm;
