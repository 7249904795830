import React, { useEffect, useState } from "react";
import { useInit } from "../../hooks/useInit";
import axios from "axios";
import FilesileListViewDisplayer from "../files/fileListViewDisplayer";

const JobAutoSequenceDisplay = ({ fileSets, setUsePreset, globalInputHandler, globalSid }) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  const [files, setFiles] = useState();

  const GetFiles = () => {
    initStarted(1);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/file/autosequence`,
        {
          fileSetsIds: fileSets?.map((fileSet) => fileSet._id),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        setFiles(response.data.files);
        initCompleted(1);
      })
      .catch((err) => {
        console.log(err);
        initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
      });
  };

  useEffect(() => {
    GetFiles();
  }, [fileSets]);

  const allFileSetsHaveFiles =
    fileSets.length > 0 &&
    fileSets?.map((fs) => !!files?.find((f) => f.fileSets.map((ffs) => ffs._id).includes(fs._id.toString())) && !!files?.find((f) => f.autoSequence.length > 0)).filter((data) => data === false)
      .length === 0;

  useEffect(() => {
    globalInputHandler("autoSequence", true, allFileSetsHaveFiles, globalSid);
    globalInputHandler("workflow", [null], true, globalSid);
  }, [allFileSetsHaveFiles]);

  const removePreSetHandler = () => {
    globalInputHandler("autoSequence", false, true, globalSid);
    setUsePreset();
  };

  return (
    <>
      <div className="mb-1 flex flex-col bg-taskinatorWhite h-full border border-taskinatorMedGrey rounded-lg  ">
        {/* <div className="">
      <TaskItemDisplayV4 globalSid={globalSid} globalInputHandler={globalInputHandler} workflowId />
    </div> */}
        <div className="w-full flex-col flex h-full">
          <div className="flex-col flex px-4 pt-4 pb-4">
            <div className="flex items-center justify-center">
              {/* <div className="text-md border rounded-full h-6 w-6 items-center justify-center flex font-bold border-2 mr-2 text-taskinatorDarkGrey border-taskinatorDarkGrey">2</div> */}
              <div className="w-fit pr-2 text-md flex text-taskinatorGreen uppercase font-semibold">Auto Sequence</div>

              <div className="grow pr-2 flex"></div>
              <div
                className="cursor-pointer text-taskinatorRed hover:opacity-100 justify-end text-xs mx-1 px-4 py-2 border border-taskinatorRed rounded opacity-50"
                onClick={() => removePreSetHandler()}
              >
                Change Sequence
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex bg-taskinatorWhite mb-0.5 border border-taskinatorMedGrey  p-2 grow text-xxs font-medium text-taskinatorDarkGrey">
        <div className="w-8"> </div>
        <div className="px-2  sm:flex hidden text-taskinatorMedGrey">|</div>
        <div className="w-32 pr-2 text-center">Status</div>
        <div className="px-2  sm:flex hidden text-taskinatorMedGrey">|</div>
        <div className=" w-20 text-center justify-center sm:flex hidden">Date</div>
        <div className="px-2  sm:flex hidden text-taskinatorMedGrey">|</div>
        <div className="w-12 overflow-hidden">Revision</div>
        <div className="px-2 text-taskinatorMedGrey">|</div>
        <div className="w-60 px-2">File Name</div>
        <div className="px-2 text-taskinatorMedGrey">|</div>
        <div className="w-60">Task Access Tag</div>
        <div className="px-2 text-taskinatorMedGrey">|</div>
        <div className="grow">Auto Starting Workflow</div>
      </div>

      {fileSets.length > 0 &&
        files?.length > 0 &&
        files.map((file, index) => {
          return (
            <div className="mb-0.5 text-sm flex flex-col bg-taskinatorWhite h-full border border-taskinatorMedGrey rounded-lg  overflow-hidden ">
              <FilesileListViewDisplayer file={file} fileId={file._id} isVisible showAuto index={index} onSelectHandler={() => {}} hideCheckbox initState={initState} />
            </div>
          );
        })}
      {fileSets.length === 0 && allFileSetsHaveFiles && (
        <div className="mb-1 text-sm flex flex-col bg-taskinatorWhite h-full border border-taskinatorMedGrey rounded-lg  ">
          <div className="flex text-sm justify-center items-center  text-taskinatorRed py-4 font-bold">Add file set to continue...</div>
        </div>
      )}
      {(fileSets.length > 0 && files?.length === 0) ||
        (!allFileSetsHaveFiles && (
          <div className="mb-1 text-sm flex flex-col bg-taskinatorWhite h-full border border-taskinatorMedGrey rounded-lg  ">
            <div className="flex text-sm justify-center items-center  text-taskinatorRed py-4 font-medium">A selected file set has no files or file does not have auto sequencing set.</div>
          </div>
        ))}
    </>
  );
};

export default JobAutoSequenceDisplay;
