import React, { useContext, useEffect, useRef, useState } from "react";
import IconSpinner from "../components/UI_elements/iconSpinner";
import Modal from "../components/UI_elements/modal";
import { AppContext } from "../contexts/appContext";
import { JobModalContext } from "../components/displayElements/jobs/jobModalContext";
const dataFilter = (data) => {
  return data
    ?.map((data) => {
      return data.annotationData;
    })
    .filter((data) => data);
};

const PDFViewer = ({
  pdfURL,
  pdfFileName,
  iid,
  setSavedMarkup,
  setSavedMarkupURL,
  savedMarkupURL,
  annotationAdded,
  setAnnotationAdded,
  setLoadedDocument,
  nomarkups,
  attachmentKey,
  userName,
  userMadeChanges,
  item,
  close,
  viewMarkupsOnly,
  annotationData,
  setAnnotationData,
  uniqueID,
  thirdPartySendAnnotations,
  thirdPartyAnnotations,
  isMarkup,
}) => {
  const appData = useContext(AppContext);
  const jobData = useContext(JobModalContext);
  const [isAdobeDCReady, setAdobeDCReady] = useState(false);
  const [DCError, setDCError] = useState(null);
  const [errorModal, setErrorModal] = useState();
  // const [isAdobeDCReady, setAdobeDCReady] = useState(false);
  // const { isAdobeDCReady, error, setError } = useAdobeDCLoader();
  // const [annotationData, setAnnotationData] = useState([]);
  // const { isAdobeDCReady, DCError, setDCError } = useAdobeDCLoader();
  // const [annotationsToSave, setAnnotationsToSave] = useState();
  // const [error, setError] = useState(null);
  const adobeDCView = useRef(null);

  const pdfUserName = userName ? userName : appData.workspaceDataV4.firstName;

  const profile = {
    userProfile: {
      name: userName ? userName.firstName : appData.workspaceDataV4.firstName,
      firstName: userName ? userName.firstName : appData.workspaceDataV4.firstName,
      lastName: userName ? userName.lastName : appData.workspaceDataV4.lastName,
      email: userName ? `tp_${userName.email}` : `tm_${appData.workspaceDataV4.email}`,
    },
  };

  const [loadingAnnotations, setLoadingAnnotations] = useState(thirdPartyAnnotations?.length > 0 ? true : false);

  let isInitialLoad = true;
  let annotations = [];
  let cannotEditThisOne = false;
  let errorMessage = "Cannot Modify Annotations Here";

  useEffect(() => {
    const checkAdobeDCReady = () => {
      if (window.AdobeDC) {
        setAdobeDCReady(true);
      } else {
        const listener = () => {
          setAdobeDCReady(true);
          document.removeEventListener("adobe_dc_view_sdk.ready", listener);
        };
        document.addEventListener("adobe_dc_view_sdk.ready", listener);

        // Set a timeout for AdobeDC View readiness
        setTimeout(() => {
          if (!isAdobeDCReady) {
            setDCError("AdobeDC View did not become ready in time.");
          }
          console.log("ready");
        }, 10000); // 10 seconds timeout
      }
    };
    checkAdobeDCReady();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /// this creates the preview file

  const previewFile = (divId) => {
    try {
      const config = {
        clientId: process.env.REACT_APP_ADOBE_CLIENT_ID,
        divId: divId,
        metaData: {
          userName: pdfUserName,
        },
      };

      adobeDCView.current = new window.AdobeDC.View(config);

      const viewerConfig = {
        showDownloadPDF: isMarkup ? true : false,
        showPrintPDF: false,
        enableAnnotationAPIs: true,
        includePDFAnnotations: true,
        showAnnotationTools: !nomarkups ? true : false,
      };

      const previewFilePromise = adobeDCView.current.previewFile(
        {
          content: {
            location: {
              url: pdfURL,
            },
          },
          metaData: {
            fileName: pdfFileName,
            id: attachmentKey,
            userName: pdfUserName,
          },
        },
        viewerConfig
      );

      previewFilePromise.catch((previewError) => {
        console.log("Error previewing file:", previewError);
        setDCError(`Error in file preview: ${previewError.message}`);
      });

      const saveOptions = {
        autoSaveFrequency: 2,
        enableFocusPolling: false,
        // showSaveButton: true,
      };

      adobeDCView.current.registerCallback(window.AdobeDC.View.Enum.CallbackType.GET_USER_PROFILE_API, function () {
        return new Promise((resolve, reject) => {
          resolve({
            code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
            data: profile,
          });

          reject("error");
        });
      });

      adobeDCView.current.registerCallback(
        window.AdobeDC.View.Enum.CallbackType.SAVE_API,

        function (metaData, content, options) {
          // Custom logic for handling save

          const newFileName = `${metaData.fileName}`;

          const blob = new Blob([content], { type: "application/pdf" });
          // setSavedMarkupURL(URL.createObjectURL(blob));

          const pdfFile = new File([blob], `[MARKUP] - ${newFileName}`, {
            type: "application/pdf",
          });
          setSavedMarkup(pdfFile);
          setAnnotationAdded();

          const fileMetaData = {
            fileName: newFileName, // Use the file name from the metaData provided by the API
            id: metaData.id, // The file ID, if available
            // Any other custom file metadata you want to include
          };

          return new Promise((resolve, reject) => {
            resolve({
              code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
              data: {
                metaData: fileMetaData,
              },
            });
          });
        },
        saveOptions
      );

      previewFilePromise.then((adobeViewer) => {
        adobeViewer.getAnnotationManager().then((annotationManager) => {
          /// set config files
          annotationManager.setConfig({
            showToolbar: viewMarkupsOnly ? false : true,
            downloadWithAnnotations: true,
            printWithAnnotations: true,
          });

          const eventOptions = {
            listenOn: ["ANNOTATION_ADDED", "DOCUMENT_LOAD", "ANNOTATION_UPDATED", "ANNOTATION_DELETED"],
          };

          if (!nomarkups && !userName && isAdobeDCReady) {
            setLoadingAnnotations(true);
            appData.socket.emit(
              "pdfAnnotation",
              {
                action: "connect",
                attachmentKey: attachmentKey,
              },
              (callback) => {
                if (callback.success) {
                  annotations = callback.data;

                  setAnnotationData(
                    Array.from(
                      new Set(
                        annotations.map((data) => {
                          return `${data.firstName} ${data.lastName}`;
                        })
                      )
                    )
                  );

                  if (annotations.length > 0) {
                    adobeViewer.getAnnotationManager().then((annotationManager) => {
                      annotationManager.addAnnotations(dataFilter(annotations)).then(() => {
                        setLoadingAnnotations();
                        isInitialLoad = false;
                      });
                    });
                  } else {
                    isInitialLoad = false;
                    setLoadingAnnotations();
                  }
                } else {
                  console.log(callback);
                }
              }
            );
          } else {
            if (thirdPartyAnnotations?.length > 0) {
              annotations = thirdPartyAnnotations;
              adobeViewer.getAnnotationManager().then((annotationManager) => {
                annotationManager.addAnnotations(dataFilter(thirdPartyAnnotations)).then(() => {
                  setLoadingAnnotations();
                  isInitialLoad = false;
                });
              });
            } else {
              isInitialLoad = false;
              setLoadingAnnotations();
            }
          }

          // annotationManager.registerEventListener((event) => {
          //   if (["ANNOTATION_DELETED"].includes(event.type)) {

          //   }
          // });

          // annotationManager.registerEventListener((event) => {
          //   if (["ANNOTATION_SELECTED"].includes(event.type)) {
          //     annotationManager
          //       .getAnnotations()
          //       .then((result) => {
          //         console.log(result);

          //         cannotEditThisOne = true;
          //       })
          //       .catch((error) => console.log(error));
          //   }
          // });

          annotationManager.registerEventListener((event) => {
            if (["ANNOTATION_ADDED", "ANNOTATION_UPDATED", "ANNOTATION_DELETED"].includes(event.type)) {
              if (!isInitialLoad) {
                setAnnotationAdded(true);

                annotationManager
                  .getAnnotations()
                  .then((result) => {
                    let annotationsToSend;

                    if (event.type === "ANNOTATION_DELETED") {
                      const currentData = dataFilter(annotations);
                      const currentState = result.filter((a1Item) => !currentData.some((a2Item) => a2Item.id === a1Item.id));
                      const updatedState = currentData.filter((a2Item) => !result.some((a1Item) => a1Item.id === a2Item.id));
                      const ItemBeingDeleted = [...currentState, ...updatedState];

                      annotationsToSend = ItemBeingDeleted;
                    } else {
                      annotationsToSend = result;
                    }

                    if (!userName) {
                      if (!viewMarkupsOnly) {
                        const latestItemSelected = annotationsToSend.sort((a, b) => {
                          return new Date(b.modified).getTime() - new Date(a.modified).getTime();
                        })[0];

                        if (latestItemSelected?.creator?.id?.startsWith("tp_")) {
                          cannotEditThisOne = true;
                          errorMessage = "Cannot Modify Third Party Annotations";
                        }
                      }

                      if (!viewMarkupsOnly && !cannotEditThisOne) {
                        if (annotationsToSend.length > 0) {
                          ///sends updates to server

                          appData.socket.emit(
                            `pdfAnnotation`,
                            {
                              action: event.type === "ANNOTATION_DELETED" ? "deleteAnnotation" : "addAnnotation",
                              attachmentKey: attachmentKey,
                              annotation: annotationsToSend,
                              jobId: jobData.jobId,
                            },
                            (callback) => {
                              // userMadeChanges();

                              if (callback.success) {
                                annotations = {
                                  annotationData: annotationsToSend,
                                };
                                setAnnotationData(Array.from(new Set([...annotationData, `${profile.userProfile.firstName} ${profile.userProfile.lastName}`])));
                              } else {
                                setErrorModal("Error Updating Annotation");
                                isInitialLoad = true;
                                adobeViewer.getAnnotationManager().then(async (annotationManager) => {
                                  await annotationManager.deleteAnnotations(annotationsToSend);
                                  if (annotations.length > 0) {
                                    await annotationManager.addAnnotations(dataFilter(annotations));
                                  }

                                  isInitialLoad = false;
                                });
                              }
                            }
                          );
                        }
                      } else {
                        setErrorModal(errorMessage ? errorMessage : "Unable To Modify Annotations Here");
                        isInitialLoad = true;
                        adobeViewer.getAnnotationManager().then(async (annotationManager) => {
                          await annotationManager.deleteAnnotations(annotationsToSend);
                          if (annotations.length > 0) {
                            await annotationManager.addAnnotations(dataFilter(annotations));
                          }

                          isInitialLoad = false;
                          cannotEditThisOne = false;
                        });
                      }
                    } else {
                      thirdPartySendAnnotations(annotationsToSend, event.type);
                    }
                  })
                  .catch((error) => console.log(error));
              }
            }
          }, eventOptions);

          return () => {
            appData.socket.off(`${attachmentKey}`);
          };
        });
      });

      return previewFilePromise;
    } catch (error) {
      console.error("Error in AdobeDC View:", DCError);
      setDCError(`Error in AdobeDC View: ${DCError?.message}`);
    }
  };

  useEffect(() => {
    if (isAdobeDCReady && !DCError) {
      previewFile(`${uniqueID}`, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdobeDCReady, pdfURL, pdfFileName]);

  if (DCError) {
    return <div>Error: {DCError}</div>;
  }

  return (
    <div className="w-full flex flex-col h-full">
      {isAdobeDCReady ? <div className="h-full" id={`${uniqueID}`}></div> : <IconSpinner size="l" />}
      {errorModal && (
        <Modal
          title="Error"
          local={() => {
            setErrorModal();
          }}
        >
          <div className="text-base text-taskinatorRed p-4">{errorModal}</div>
        </Modal>
      )}
      {loadingAnnotations && (
        <Modal isWithoutClose={true}>
          Loading Annotations
          <IconSpinner />
        </Modal>
      )}
    </div>
  );
};

export default PDFViewer;
