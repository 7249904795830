import React from "react";
import CountUpTimer from "../../../utils/countUpTimer";
import FilterOutUniqueIds from "../../../utils/filterUniqueIds";
import LinkifyText from "../../../utils/linkifyText";
import DateDisplay from "../../UI_elements/dateDisplay";
import DocumentDisplay from "../../UI_elements/documentDisplay";
import IconSpinner from "../../UI_elements/iconSpinner";
import UserDisplayBubble from "../../UI_elements/userDisplayBubble";
import FilePreview from "../../../utils/filePreview";

const JobDetailsDisplay = ({ jobDetails }) => {
  if (jobDetails) {
    return (
      <>
        <div className="flex grow w-full lg:flex-row flex-col ">
          <div className="flex md:mr-1 rounded-lg border p-2 lg:w-1/4 border-taskinatorMedGrey bg-taskinatorWhite">
            <div className="flex flex-col w-full flex-wrap grow">
              <div className="flex items-start py-1 mb-1 justify-start grow border-b-2 border-b-taskinatorLightGrey">
                <div className="text-taskinatorDarkGrey pr-1 md:text-sm">Manager/s:</div>
                <div className="font-semibold">
                  <div className="grow flex flex-wrap">
                    {jobDetails?.managers?.length > 0 ? (
                      jobDetails?.managers?.map((manager, index) => {
                        return <UserDisplayBubble firstName={manager.firstName} lastName={manager.lastName} user={manager} textSize={" md:text-xs"} key={index} />;
                      })
                    ) : (
                      <div className="text-taskinatorMedGrey md:text-sm whitespace-nowrap">None Set</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex items-start py-1 mb-1 justify-start border-b-2 border-b-taskinatorLightGrey">
                <div className="text-taskinatorDarkGrey pr-1 md:text-sm">Due Date:</div>
                <div className="md:text-sm">{DateDisplay(jobDetails.dueDate)}</div>
              </div>

              <div className="flex items-start py-1 mb-1 justify-start grow border-b-2 border-b-taskinatorLightGrey">
                <div className="text-taskinatorDarkGrey pr-1 md:text-sm">Priority:</div>
                <div className="md:text-sm">{jobDetails.priority}</div>
              </div>

              <div className="flex items-start py-1 mb-1 justify-start grow border-b-2 border-b-taskinatorLightGrey">
                <div className="text-taskinatorDarkGrey pr-1 md:text-sm">Created By:</div>
                <div className=" md:text-sm capitalize">
                  {jobDetails.createdBy.split(" ")[0]} {jobDetails.createdBy.split(" ")[1][0]}
                </div>
              </div>

              <div className="flex items-start py-1 mb-1 justify-start grow border-b-2 border-b-taskinatorLightGrey">
                <div className="text-taskinatorDarkGrey pr-1 md:text-sm">Active For:</div>
                <div className="text-base md:text-sm">{<CountUpTimer startDate={jobDetails.createdDate} />}</div>
              </div>


            </div>
          </div>

          <div className="grow lg:mt-0 mt-1 rounded-lg border p-2 border-taskinatorMedGrey bg-taskinatorWhite flex flex-col">
            <div className="flex flex-col grow">
              <div className="flex flex-col grow h-auto">
                <div className="text-taskinatorDarkGrey font-semibold whitespace-nowrap text-sm pb-2">Job Instructions:</div>
                <div className="text-base md:text-sm whitespace-pre-wrap h-fit grow">
                  {jobDetails?.scope?.length > 0 ? <LinkifyText text={jobDetails.scope} /> : <div className="text-taskinatorMedGrey whitespace-nowrap md:text-sm">No Scope Details</div>}
                </div>
              </div>
              <div className="flex flex-col pt-1 border-t-2 border-t-taskinatorLightGrey py-2 mt-2">
                <div className="text-taskinatorDarkGrey pr-1 text-sm font-semibold whitespace-nowrap">Job Reference Documents:</div>
                <div className="text-base md:text-sm flex flex-wrap">
                  {jobDetails?.attachments.length > 0 ? (
                    jobDetails?.attachments.map((attachment, index) => {
                      return (
                        <div className="w-fit md:text-sm pr-1" key={index}>
                          <FilePreview viewMarkupsOnly file={attachment} />
                          {/* <DocumentDisplay attachment={attachment} /> */}
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-taskinatorMedGrey whitespace-nowrap md:text-sm">No Attachments</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div>
        <IconSpinner />
      </div>
    );
  }
};

export default JobDetailsDisplay;
