import aws from "aws-sdk";

export const GetFileFromAws = async ({ oid, key }) => {
  const region = process.env.REACT_APP_AWS_REGION;
  const bucketName = process.env.REACT_APP_S3_BUCKET;
  const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
  const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

  const s3 = new aws.S3({
    region,
    accessKeyId,
    secretAccessKey,
    signatureVersion: "v4",
  });
  const params = {
    Bucket: bucketName,
    Key: `${oid}/${key}`,
  };

  return new Promise((resolve, reject) => {
    s3.getObject(params, function (err, data) {
      if (err) {
        return resolve(false);
      } else {
        return resolve(data);
      }
    });
  });
};
