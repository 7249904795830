import React, { useContext } from "react";
import { MdLock } from "react-icons/md";
import { AppContext } from "../../../contexts/appContext";
import DateDisplay from "../../UI_elements/dateDisplay";
import Tag from "../../UI_elements/tag";
const FileSetRowDisplay = ({ fileSet, index, itemState, sectionCSS, widths, setViewItemDetails, itemsSelected, onSelectHandler, searchAndAdd, isRegister, tid }) => {
  const thisItemHasBeenSelected = itemsSelected && itemsSelected[fileSet._id] ? true : false;

  const appData = useContext(AppContext);

  const itemIsLocked = process.env.REACT_APP_OVERIDELOCK === "true" ? false : fileSet?.inJob && !isRegister;

  // file.inAction || (file.inTask.length > 0 && file.inTask.filter((task) => task._id === tid).length === 0 && !isRegister);

  const isGuest = appData.workspaceDataV4.role === process.env.REACT_APP_ACCESS_G;

  return (
    <div
      className={`${
        thisItemHasBeenSelected
          ? `bg-taskinatorBlue text-taskinatorWhite   ${isRegister ? "mt-2 rounded-t-lg" : ""} font-medium`
          : fileSet.archived
          ? "bg-taskinatorWhite text-taskinatorDarkGrey opacity-50"
          : index !== 0
          ? "bg-taskinatorOffWhite text-taskinatorDarkGrey hover:bg-taskinatorHoverBlue"
          : "bg-taskinatorWhite text-taskinatorDarkGrey hover:bg-taskinatorHoverBlue"
      } ${itemIsLocked ? "cursor-not-allowed opacity-50" : isGuest ? "" : "cursor-pointer"} py-2 text-sm  w-full `}
      onClick={
        searchAndAdd
          ? !itemIsLocked
            ? () => {
                onSelectHandler(fileSet, itemState);
              }
            : () => {}
          : //   : () =>
          //       setConfirmModal({
          //         fileSet: fileSet,
          //         itemState: itemState,
          //         message: fileSet.inAction ? "Can't add this item as its already in the selected workflow." : "Warning! Item is already in an task.",
          //         button: fileSet.inAction ? "Confirm" : "Proceed",
          //         inAction: fileSet.inAction,
          //       })
          isGuest
          ? () => {}
          : () => setViewItemDetails({ itemData: fileSet, itemState: itemState })
      }
    >
      <div className="flex  ">
        <div className={`${sectionCSS} ${widths[0]}`}>
          {itemIsLocked ? (
            <div className="text-taskinatorRed">
              <MdLock />
            </div>
          ) : (
            <div>&nbsp;</div>
          )}
        </div>
        <div className={`${sectionCSS} ${widths[2]}  `}>{fileSet.number}</div>
        <div className={`${sectionCSS} ${widths[1]}`}>{fileSet.name}</div>
        {/* <div className={`${sectionCSS} ${widths[3]}`}>{fileSet.revision}</div> */}
        <div className={`${sectionCSS} ${widths[5]}`}>
          {fileSet?.settings?.fileTags?.length === 0 && <div>&nbsp;</div>}
          {fileSet?.settings?.fileTags?.map((tag, index) => {
            return <Tag key={tag._id} tagData={tag} />;
          })}
        </div>

        {/* {isRegister && (
          <div className={`${sectionCSS} ${widths[6]}`}>
            {fileSet?.settings?.accessTags?.length === 0 && <div>&nbsp;</div>}
            {fileSet?.settings?.accessTags?.map((tag, index) => {
              return <Tag key={tag._id} tagData={tag} />;
            })}

            <div>
              {fileSet.sharedWith &&
                fileSet.sharedWith.map((tag, index) => {
                  return <Tag key={tag._id} tagData={{ type: "external", ...tag }} />;
                })}
            </div>
          </div>
        )} */}
        <div className={`${sectionCSS} ${widths[2]}`}>{fileSet.numberOfFiles}</div>
        <div className={`${sectionCSS} ${widths[10]}`}>
          {fileSet?.inJob?._id && (
            <div
              className={`${thisItemHasBeenSelected ? "bg-taskinatorWhite text-taskinatorBlue " : "bg-taskinatorGreen text-taskinatorWhite "} text-xs font-medium uppercase rounded-full py-1 px-4 `}
              key={fileSet.inJob._id}
            >
              {tid && fileSet.inJob._id === tid ? (fileSet.inAction ? "Active In Selected Workflow" : "Active In Job") : fileSet.inJob.ref}
            </div>
          )}
          {fileSet.archived && <div className={`${"bg-taskinatorOrange border text-taskinatorWhite"} text-xs font-medium uppercase rounded-full py-1 px-4 `}>File Set Archived</div>}{" "}
        </div>
        {isRegister && <div className={`${sectionCSS} ${widths[7]} capitalize`}>{fileSet?.createdBy?.firstName}</div>}
        {isRegister && <div className={`${sectionCSS} ${widths[8]} border-r-0`}>{DateDisplay(fileSet?.createdBy?.createdDate)}</div>}
      </div>
    </div>
  );
};

export default FileSetRowDisplay;
