import { MdLock } from "react-icons/md";
import ColourGenerator from "../../utils/colourGenerator";
import StringShortenDisplay from "./stringShortenDisplay";

const Tag = ({ tagData, children, size, highlight, hideText, tagsActiveOnOtherWorkflows, alertTag }) => {
  let tagStyleCSS;

  if (!highlight && !tagsActiveOnOtherWorkflows) {
    if (tagData?.type === "access") {
      tagStyleCSS = " bg-taskinatorOrange text-taskinatorWhite opacity-75";
    } else if (tagData?.type === "item") {
      tagStyleCSS = " bg-taskinatorHoverGreen text-taskinatorWhite opacity-75";
    } else if (["task", "team"].includes(tagData?.type)) {
      tagStyleCSS = " bg-taskinatorHighlightBlue text-taskinatorDarkGrey ";
    } else if (tagData?.type === "workflows") {
      tagStyleCSS = `${ColourGenerator(null, null, tagData?.name)} text-taskinatorWhite`;
    } else if (tagData?.type === "external") {
      tagStyleCSS = " text-taskinatorWhite bg-taskinatorBlue border border-taskinatorBlue";
    } else if (tagData?.type === "project") {
      tagStyleCSS = " bg-taskinatorPurple text-taskinatorWhite opacity-75";
    } else {
      tagStyleCSS = " text-taskinatorDarkGrey bg-taskinatorLightGrey border border-taskinatorMedGrey";
    }
  } else {
    if (tagsActiveOnOtherWorkflows && !highlight) {
      tagStyleCSS = " text-taskinatorWhite bg-taskinatorRed border border-taskinatorRed opacity-50";
    } else if (tagsActiveOnOtherWorkflows && highlight) {
      tagStyleCSS = " text-taskinatorWhite bg-taskinatorGreen border border-taskinatorGreen";
    } else {
      tagStyleCSS = " text-taskinatorWhite bg-taskinatorGreen border border-taskinatorGreen";
    }
  }

  if (alertTag) {
    tagStyleCSS = " text-taskinatorWhite bg-taskinatorRed border border-taskinatorRed opacity-50";
  }

  return (
    <div
      key={tagData?._id}
      className={`${tagStyleCSS} mr-1 shadow-md  text-xs  rounded  w-fit py-0.5 uppercase px-1 flex items-center justify-center whitespace-nowrap ${
        tagData?.data && tagData?.data?.locked ? "opacity-50" : ""
      }`}
    >
      {/* <div className="h-1.5 w-1.5 bg-taskinatorWhite rounded-full"></div> */}
      <div className="flex items-center font-medium">
        {tagData?.data && tagData?.data?.locked ? (
          <div className="pr-1">
            <MdLock />
          </div>
        ) : (
          ""
        )}
        {tagData?.type === "external" ? "Shared With: " : ""}

        {tagData?.name}
        
        {tagData?.data && tagData?.data?.roleTags?.length > 0 && <div className="pl-1 font-light text-xxs">{StringShortenDisplay(20, `- ${tagData?.data?.roleTags[0]?.name}`)}</div>}
        {highlight && !hideText && <div className="pl-1 font-light">(This Task)</div>}
        {tagsActiveOnOtherWorkflows && !hideText && <div className="pl-1 font-light">(Other Task)</div>}
      </div>
      {children}
    </div>
  );
};

export default Tag;
