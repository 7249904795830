import React, { useState, useEffect } from "react";
import ToolTip from "../../utils/toolTip";
import DateDisplay from "../../components/UI_elements/dateDisplay";
const PlanElement = ({ plan, selectedPlan, setSelectedPlan, final, minimumTeam, currentView }) => {
  const [extra, setExtra] = useState(plan.extraRequiredUsers ? plan.extraRequiredUsers : 0);

  const planSelected =  selectedPlan?.key === plan?.key;
  const cantSelect = !plan.available && plan.extraRequiredUsers === 0 || currentView ;

  const totalExtraUsers = final ? plan.totalExtras : extra;
  const totalTeam = plan.teamLimit + extra;
  const totalPrice = final ? plan.selectedTotalPrice : plan.freePlan ? 0 : plan.extraUsers * totalExtraUsers + plan.price;

  useEffect(() => {
    if (setSelectedPlan && planSelected) {
      setSelectionHandler();
    }
  }, [totalExtraUsers, totalTeam, totalPrice]);

  const setSelectionHandler = () => {
    setSelectedPlan({ ...plan, selectedTotalPrice: totalPrice, totalTeam: totalTeam, totalExtras: extra });
  };

  return (
    <div
      onClick={
        cantSelect || final
          ? () => {}
          : () => {
              setSelectionHandler();
            }
      }
      className={`${setSelectedPlan ? `${cantSelect ? "text-taskinatorMedGrey cursor-not-allowed" : "hover:bg-taskinatorHoverBlue text-taskinatorDarkGrey cursor-pointer"}` : ""}  p-2 mt-2 rounded ${
        final ||  currentView ? "grow" : "w-64"
      } ${planSelected ? "border-4  border border-taskinatorBlue" : "border-taskinatorMedGrey border-2 shadow"}`}
    >
      <div className="font-medium text-lg"> {plan.name}</div>
      <div className={` font-bold ${selectedPlan && cantSelect ? "text-taskinatorMedGrey" : "text-taskinatorBlue"} pb-2`}>
        ${plan.price} {plan.currency}/Month
      </div>
      <div className="text-xs"> Includes:</div>

      <div className="flex">
        <b className="pr-1">{plan.teamLimit}</b> Team Members <ToolTip content={"Number of users who can do and manage tasks"} />
      </div>
      <div className="flex">
        <b className="pr-1">{plan.guestLimit} </b> Guest Members{" "}
        <ToolTip content={"Guests can only view drawing log items that have been shared with them via access tags - one guest is included per user"} />
      </div>
      <div className="flex">
        {" "}
        <b className="pr-1">{plan.storageLimit / 1000}GB </b> Storage Limit <ToolTip content={"Total file storage on Next-Task"} />
      </div>
      {/* {plan.freePlan && (
        <div className="border-t border-t-taskinatorMedGrey pt-1 mt-1">
          <div className={`${plan.available ? "text-taskinatorRed" : `${setSelectedPlan ? "" : "text-taskinatorRed"}`}`}>
            <b className="pr-1">{plan.freeLimit}</b>Active Workflow Limit{" "}
          </div>
        </div>
      )} */}
      {!currentView && plan.extraUsers && (
        <div className="border-t border-t-taskinatorMedGrey pt-1 mt-1">
          {" "}
          <b> ${plan.extraUsers}</b> {plan.currency}/Month Per Extra Team
        </div>
      )}
      {currentView && <div className="border-t border-t-taskinatorMedGrey pt-1 mt-1">Next Payment Date: {DateDisplay(new Date(plan.subscriptionDetails.EndOfBillingCycle) * 1000)}</div>}

      {(setSelectedPlan || final) && (
        <div className="pt-4">
          {planSelected && !final && !selectedPlan.freePlan && (
            <div className="flex items-center">
              Add More Users: <b className="pl-2 pr-2">{extra}</b>{" "}
              <div
                onClick={extra > 0 && totalTeam > minimumTeam ? () => setExtra(extra - 1) : () => {}}
                className={`${totalTeam > minimumTeam ? "" : "cursor-not-allowed"} flex bg-taskinatorMedGrey h-6 w-6 ml-1 text-taskinatorWhite rounded items-center justify-center  font-bold`}
              >
                -
              </div>
              <div onClick={() => setExtra(extra + 1)} className="bg-taskinatorMedGrey h-6 w-6 text-taskinatorWhite rounded items-center justify-center flex font-bold ml-1">
                +
              </div>
            </div>
          )}
          <div className="font-medium flex items-center">
            Plan Total:{" "}
            {totalExtraUsers > 0 ? (
              <div className="text-xs text-taskinatorRed pl-2">
                ({plan.teamLimit} + {totalExtraUsers} Extra Users)
              </div>
            ) : (
              <div> </div>
            )}
          </div>
          <div className="flex items-end">
            <div className="text-taskinatorBlue font-medium text-lg"> ${totalPrice}</div>

            <div className="text-sm pl-1">{plan.currency}/Month</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlanElement;
