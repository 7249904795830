import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { MdOutlineWarning } from "react-icons/md";
import { AppContext } from "../../../contexts/appContext";
import { useInputV4 } from "../../../stateManagement/useInputV4";
import FilePreview from "../../../utils/filePreview";
import Button from "../../UI_elements/button";
import IconSpinner from "../../UI_elements/iconSpinner";
import ItemStatus from "../../UI_elements/itemStatus";
import Modal from "../../UI_elements/modal";
import ItemStateSelector from "../../dataSelectors/itemStateSelector";
import TagSelector from "../../dataSelectors/tagSelector";
import ApproveRejectForm from "../../forms/approveRejectForm";
import { VALIDATOR_MINLENGTH } from "../../forms/validators/validators";
import { useInit } from "../../hooks/useInit";
import InputV4 from "../../inputs/input_V4";
import { JobModalContext } from "../jobs/jobModalContext";
import { FileContext } from "./FileContext";
import FileModifierButtons from "./fileModifierButtons";
import FileRowDisplay from "./fileRowDisplay";
import SequenceSetter from "./sequenceSetter";
const CreateFile = ({ file, currentFileSetId, titleless, onResponse, taskData, inOtherWorkflow, workflowGroupId, fileSetId, taskTags, bulkEdit, noButtonsOrStatus, taskCompleted }) => {
  const fileContext = useContext(FileContext);

  const jobModalContext = useContext(JobModalContext);

  const appData = useContext(AppContext);

  const jobData = appData?.jobs[jobModalContext.jobId];
  const allFileData = jobData?.files?.find((file) => file._id === file._id);
  let otherActiveTags = {};

  allFileData?.currentJobs
    .filter((cj) => cj.taskId !== taskData._id)
    .forEach((cj) => {
      jobData.workflows
        .find((wf) => wf.workflowGroupId === cj.workflowGroupId)
        .tasks.find((task) => task._id === cj.taskId)
        .taskDetails.fileTags.forEach((tag) => (otherActiveTags = { ...otherActiveTags, [tag._id]: tag._id }));
    });

  const [confirmDelete, setConfirmDelete] = useState();

  const { inputState, inputHandler, attachmentHandler, resetState } = useInputV4({ inputs: {} }, false);
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(3);
  const sid = "inputs";
  const fileId = file._id.toString();
  const [checkData, setCheckData] = useState();
  const [saved, setSaved] = useState();
  const [viewRevs, setViewRevs] = useState();

  const inJob = jobModalContext?.jobId ? inOtherWorkflow : fileContext?.itemsSelected?.[currentFileSetId]?.inJob || checkData?.inJob;

  const getFileData = () => {
    initStarted(1);

    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/file/checkdata/${file._id.toString()}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        initCompleted(1);
        setCheckData(response.data);
      })
      .catch((err) => {
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
      });
  };

  const removeFileHandler = () => {
    initStarted(0);

    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/file/remove/${fileId}/${jobModalContext?.jobId}/${workflowGroupId}/${taskData?._id}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        let fileObject = { ...(fileContext?.itemList ?? {}) };

        if (fileObject[currentFileSetId] && fileObject[currentFileSetId][fileId]) {
          // Delete the file from the object
          delete fileObject[currentFileSetId][fileId];

          // Optionally, if after deleting the file, you want to check if the focusId group is empty and remove it too
          if (Object.keys(fileObject?.[currentFileSetId]).length === 0) {
            delete fileObject[currentFileSetId];

            fileContext?.setItemList?.(fileObject);
          }
        } else {
          console.log("File not found.");
        }

        // Finally, update your context or state with the modified fileObject

        fileContext?.setFileSelection?.();

        initCompleted(0);
      })
      .catch((err) => {
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  const submitHandler = (isUnarchive) => {
    initStarted(2);

    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/file/finalise/${fileId}/${fileSetId}`,
        {
          fileName: inputState.inputs.fileName.value,
          revision: inputState.inputs.revision.value,
          status: inputState.inputs.itemState.value,
          fileTags: inputState.inputs.fileTags.value,
          accessTags: inputState.inputs.accessTags.value,
          autoSequence: inputState.inputs.autoSequence.value,
          jobId: jobModalContext?.jobId,
          taskId: taskData?._id,
          workflowGroupId: workflowGroupId,
          isUnarchive: isUnarchive,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        const updatedFiles = response.data.file;

        const fileObject = { [updatedFiles._id.toString()]: updatedFiles };

        if (jobModalContext.jobId) {
          onResponse();
        } else {
          fileContext?.setItemList?.((currentFiles) => {
            return {
              ...currentFiles,
              [currentFileSetId]: {
                ...currentFiles[currentFileSetId],
                ...fileObject,
              },
            };
          });
        }

        fileContext?.setFileSelection?.({
          file: { ...updatedFiles },
          fileSetId: currentFileSetId,
        });

        setSaved(true);
        initCompleted(2);
      })
      .catch((err) => {
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 2);
      });
  };

  useEffect(() => {
    getFileData();
  }, [file._id]);

  useEffect(() => {
    setSaved();
  }, [inputState]);

  const duplicates = checkData?.duplicateFileNames?.length > 0;
  const revisions = checkData?.revisions?.length > 0;

  const sectionCSS = "  border-r border-r-taskinatorLightGrey   overflow-clip whitespace-nowrap items-center  ";
  const widths = [
    "w-10 shrink-0 border-r-2 justify-center ",
    "2xl:w-2/12 xl:w-4/12 md:w-4/12 w-8/12   shrink-0 px-2 border-r-2",
    "xl:w-1/12 w-2/12 shrink-0 px-2 justify-center border-r-2",
    "xl:w-1/12 w-2/12 shrink-0 px-2 justify-center border-r-2",
    "w-32 px-2 justify-center items-center border-r-2",
    "w-2/12 shrink-0 px-2 border-r-2 hidden xl:flex",
    "w-2/12 shrink-0 px-2 border-r-2 hidden xl:flex",
    "w-2/12 shrink-0 px-2 justify-center border-r-2 hidden 2xl:flex",
    "w-1/12 shrink-0 px-2 justify-center   hidden 2xl:flex",
    "lg:w-1/12 md:w-2/12 md:flex hidden shrink-0 px-2 justify-center  border-r-2",
    "shrink-0 px-2 border-r-2 md:flex md:grow hidden",
  ];

  if (!initState[1].started) {
    return (
      <div className="flex flex-col overflow-auto h-fit">
        {/* <div className="absolute opacity-50 h-full h-fit border bg-taskinatorBlack z-50"></div> */}
        <div className="p-4">
          {!titleless && <div className="font-medium text-center border-b border-b-taskinatorMedGrey">File Details</div>}

          {inJob && (
            <div className="text-xs mt-4 text-center text-taskinatorRed flex  rounded-lg items-center  justify-center py-1">
              <MdOutlineWarning size={"1rem"} />
              {file.archived === false ? (
                <>
                  <div className="px-1"> Editing disabled as this file is currently in a job: </div> <div className="font-bold  uppercase  text-taskinatorRed   ">{inJob.ref}</div>
                </>
              ) : (
                <div className="px-1"> Editing disabled as this file is Archived. </div>
              )}
              <div>
                {/* <Button
                  label="View Job Details"
                  customClasses={"text-taskinatorWhite bg-taskinatorBlue mt-2 w-full h-8"}
                  onClick={() => {
                    appData.setJobViewHandler({ jobId: inJob._id });
                  }}
                /> */}
              </div>
            </div>
          )}

          {file.status !== "pending" && !inJob && !file.archived === true && !noButtonsOrStatus && (
            <div className="text-sm border-b pb-2  border-b-taskinatorMedGrey">
              {" "}
              <FileModifierButtons file={file} noEdit fullColour taskData={taskData} />
            </div>
          )}

          {!noButtonsOrStatus && (
            <div className="mt-2 w-fit">
              <div className="flex capitalize text-sm text-taskinatorDarkGrey">Current Status</div>
              <ItemStatus status={file?.archived === true ? "Archived" : file.status} />
            </div>
          )}

          <div className="mt-2 w-full">
            <div className="flex capitalize text-sm text-taskinatorDarkGrey">File</div>
            <div className="flex items-center">
              <FilePreview file={file} viewMarkupsOnly={!!inJob} textLimit={50} notClickable={titleless} isPreview small />
            </div>
          </div>

          <div className="mt-2">
            <InputV4
              disabled={inJob || initState[0].started || initState[2].started || file.archived === true || taskCompleted}
              label="File Name"
              sid={sid}
              id="fileName"
              placeholder={`file Name`}
              customClasses={"rounded-md md:text-sm"}
              onInput={inputHandler}
              validators={[VALIDATOR_MINLENGTH(1)]}
              //   disabled={disabled}
              validityMessage="Min 3 characters"
              defaultValue={file?.fileName ? decodeURI(file?.fileName) : ""}
            />
          </div>

          {duplicates && !taskCompleted && (
            <div className="text-taskinatorRed text-xs flex flex-col ">
              <span>
                <b>Warning!</b> Duplicate pending file names found:
              </span>
              {checkData?.duplicateFileNames
                ?.filter((file) => file.fileName === inputState.inputs.fileName.value)
                .map((file) => {
                  return (
                    <div className="" key={file._id}>
                      {file?.fileSets?.map((set) => {
                        return (
                          <span className="w-fit mb-1 ">
                            {file.fileName}
                            {file.fileExtension} - Rev: {file?.revision ?? " N/A"} - [{file.status}] in {currentFileSetId === set._id ? "This Set" : set.name}
                          </span>
                        );
                      })}
                    </div>
                  );
                })}
            </div>
          )}

          <div className="mt-2">
            <InputV4
              disabled={inJob || initState[0].started || initState[2].started || file.archived === true || taskCompleted}
              label="File Revision"
              sid={sid}
              id="revision"
              placeholder={`File Revision`}
              customClasses={"rounded-md md:text-sm"}
              onInput={inputHandler}
              validators={[VALIDATOR_MINLENGTH(1)]}
              //   disabled={disabled}
              validityMessage="Min 1 characters"
              defaultValue={file?.revision ? file?.revision : ""}
            />
          </div>

          {revisions ? (
            <div
              onClick={() => {
                setViewRevs(true);
              }}
              className="border mt-1 border-taskinatorBlue text-taskinatorWhite bg-taskinatorBlue text-xs rounded py-1 px-1 hover:cursor-pointer hover:opacity-75 "
            >
              {" "}
              There are {checkData?.revisions?.length} previous revisions
            </div>
          ) : (
            <span className="text-xs text-taskiantorDarkGrey">
              <b>Note:</b> No Revisions Found For This File
            </span>
          )}

          <ItemStateSelector
            sid={sid}
            label={`Set File Status`}
            inputHandler={inputHandler}
            defaultValue={file?.status ? file?.status : "1"}
            disabled={inJob || initState[0].started || initState[2].started || file.archived === true || taskCompleted}
          />

          <div className="pt-2">
            <TagSelector
              id={"fileTags"}
              inputHandler={inputHandler}
              defaultValue={
                file?.settings?.fileTags?.length > 0
                  ? file?.settings?.fileTags?.map((tag) => {
                      return { value: tag._id };
                    })
                  : []
              }
              tagsToHighlight={taskTags ? taskTags.map((tag) => tag._id) : []}
              sid="inputs"
              type="file"
              label="Task Access Tag"
              selectionDisplayText={"Select/Create File Tag"}
              toolTip={"Which task this file should appear in"}
              disabled={inJob || initState[0].started || initState[2].started || file.archived === true || taskCompleted}
              tagsActiveOnOtherWorkflows={Object.values(otherActiveTags)}
            />
          </div>

          <div className="pt-2">
            <TagSelector
              notRequired
              id={"accessTags"}
              inputHandler={inputHandler}
              defaultValue={
                file?.settings?.accessTag?.length > 0
                  ? file?.settings?.accessTag?.map((tag) => {
                      return { value: tag._id };
                    })
                  : []
              }
              sid="inputs"
              type="access"
              label="Internal Access Tags"
              selectionDisplayText={"Select/Create Access Tag"}
              disabled={inJob || initState[0].started || initState[2].started || file.archived === true || taskCompleted}
            />
          </div>

          <div className="pt-2">
            <SequenceSetter
              defaultValue={
                file?.autoSequence?.map((data) => {
                  return { value: data.value };
                }) ?? []
              }
              notRequired
              id={"autoSequence"}
              sid="inputs"
              inputHandler={inputHandler}
              disabled={inJob || initState[0].started || initState[2].started || file.archived === true || taskCompleted}
            />
          </div>

          <div className="mt-2">
            <div className="flex capitalize text-sm text-taskinatorDarkGrey">Sets This File Appears In</div>
            <div>
              {file?.fileSets?.map((set, index) => {
                return (
                  <div className="border bg-taskinatorBlue text-taskinatorWhite p-1 rounded w-fit text-xs" key={index}>
                    {set.name}
                  </div>
                );
              })}
            </div>
          </div>
          {!taskCompleted && (
            <div>
              {!saved ? (
                <Button
                  onClick={() => submitHandler()}
                  label={`${file.status !== "pending" ? "Save" : "Finalise File"}`}
                  customClasses={`${
                    !inputState.isValid || !!inJob || file.archived === true ? "bg-taskinatorMedGrey" : "bg-taskinatorblue"
                  } bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-2 text-sm`}
                  disabled={!inputState.isValid || inJob || file.archived === true || taskCompleted}
                  isLoading={initState[2].started}
                  error={initState[2].error}
                />
              ) : (
                <div className="text-center h-10 pt-2 text-xs text-taskinatorGreen">File Saved</div>
              )}
            </div>
          )}

          {!taskCompleted && (
            <Button
              onClick={() => setConfirmDelete(true)}
              label={file.status === "pending" ? `Delete File` : file.archived === true ? "Undo Archiving" : "Archive File"}
              customClasses={`border-taskiantorRed opacity-75 hover:opacity-100 text-taskinatorRed border h-10 w-full mt-2 text-sm`}
              isLoading={initState[0].started}
              disabled={(inJob && !file.archived === true) || initState[0].started || taskCompleted}
            />
          )}
        </div>

        {confirmDelete && (
          <Modal local={() => setConfirmDelete()}>
            <ApproveRejectForm
              message={`Are you sure you want to ${file.status === "pending" ? `Delete` : file.archived === true ? "Undo Archiving of" : "Archive"} this file?`}
              // requiredTextMatch={workflowData.name}
              confirmFunction={() => (file.archived === true ? submitHandler(true) : removeFileHandler())}
              rejectFunction={() => setConfirmDelete()}
              confirmLoadingState={file.archived === true ? initState[2].started : initState[0].started}
              error={file.archived === true ? initState[2].error : initState[0].error}
              confirmButtonLabel="CONFIRM"
              confirmButtonCSS={"bg-taskinatorRed "}
            />
          </Modal>
        )}

        {viewRevs && (
          <Modal
            local={() => {
              setViewRevs();
            }}
            size="max"
            title="File Revision History"
            bgColour={"bg-taskinatorLightGrey "}
          >
            <div>
              <div className="flex bg-taskinatorWhite w-full mb-1 py-2 font-medium">
                <div className={`${sectionCSS} ${widths[0]}`}></div>
                <div className={`${sectionCSS} ${widths[4]}`}>Status</div>
                <div className={`${sectionCSS} ${widths[1]}`}>File</div>
                <div className={`${sectionCSS} ${widths[3]}`}>Revision</div>
                <div className={`${sectionCSS} ${widths[10]} items-center`}>File Set</div>
              </div>
              <div>
                {checkData?.revisions.map((file) => {
                  {
                    return (
                      <div>
                        <FileRowDisplay file={file} index={0} sectionCSS={sectionCSS} widths={widths} isPreview notClickable viewMarkupsOnly showName />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  } else {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <IconSpinner size="m" />
      </div>
    );
  }
};

export default CreateFile;
