import React, { useContext, useState } from "react";
import { AppContext } from "../../contexts/appContext";
import { FormatDateWithoutTime } from "../../utils/formattedDate";
import StringShortenDisplay from "../UI_elements/stringShortenDisplay";
import Tag from "../UI_elements/tag";
import { useInit } from "../hooks/useInit";
import ElementCard_V2 from "./elementCard_V2";
import { MdOutlineWarning } from "react-icons/md";
import CountUpTimer from "../../utils/countUpTimer";
import DateDisplay from "../UI_elements/dateDisplay";

const JobDashboardElement = ({ primaryBorder, job, setAllJobsViewHandler }) => {
  const appData = useContext(AppContext);
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();

  const [modal, setModal] = useState();
  const [hoverChild, setHoverChild] = useState();

  const [startId, setStartId] = useState();
  const isManagingThisJob = job.jobDetails.managers.find((manager) => manager._id.toString() === appData.workspaceDataV4.user_id);

  return (
    <>
      <ElementCard_V2
        key={job._id}
        hoverCSS={"hover:ring hover:ring-2 hover:ring-taskinatorGreen cursor-pointer"}
        onClick={!appData?.jobs?.[job._id] ? () => setAllJobsViewHandler(job._id) : () => appData.setJobViewHandler({ jobId: job._id, jobData: job })}
        thin
        topContent={
          <div className="flex w-full flex-col capitalize text-xs items-start py-1">
            <div className="flex w-full justify-between items-center my-1">
              <div className="flex items-center">
                Job:
                <div className="mx-1 w-fit text-xs border-taskinatorDarkGrey border px-2 text-taskinatorDarkGrey rounded font-medium uppercase py-0.5 box-border">
                  {StringShortenDisplay(20, job?.jobDetails?.ref)}
                </div>
                {isManagingThisJob ? (
                  <div className=" bg-taskinatorOrange  px-2 text-xs text-taskinatorWhite py-0.5 font-medium rounded uppercase">You're Managing This Job</div>
                ) : (
                  <div className=" bg-taskinatorGreen  px-2 text-xs text-taskinatorWhite py-0.5 font-medium rounded uppercase">Tasks For You</div>
                )}
              </div>

              <div>
                {job?.jobDetails?.dueDate ? (
                  <div className="flex justify-start items-center text-sm">
                    <span className="text-xs text-taskinatorDarkGrey">Due :&nbsp;</span>
                    <span className="font-bold text-xs text-taskinatorDarkGrey">{FormatDateWithoutTime(job?.jobDetails?.dueDate)}</span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="w-full flex">
              {job?.jobDetails?.taskTags?.map((tag) => {
                return (
                  <div className="text-xs" key={tag.name}>
                    <Tag tagData={tag} />
                  </div>
                );
              })}
              <div className="flex grow justify-end">
                {job?.completed && (
                  <div className="text-xs  normal-case flex items-center">
                    <div className="font-bold uppercase text-taskinatorGreen border border-taskinatorGreen rounded py-1 px-0.5 ml-1">completed</div>{" "}
                    <div className="px-1">{DateDisplay(job.completedDate)}</div>
                  </div>
                )}
                {job.jobDetails?.onHold ? (
                  <div className="w-fit text-xs border-taskinatorOrange border px-2 text-taskinatorWhite bg-taskinatorOrange rounded font-medium items-center justify-center flex uppercase box-border">
                    ON HOLD
                  </div>
                ) : (
                  !job?.completed && (
                    <div className="flex">
                      {" "}
                      <CountUpTimer startDate={job.jobDetails?.createdDate} /> <div className="pl-1 text-taskinatorRed"> old</div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        }
        bottomContent={
          <div className="flex grow  flex-col" key={job._id}>
            {Object.keys(job.fileMapObject)?.map((fileSetKey) => {
              const fileSet = job.fileMapObject[fileSetKey];

              return (
                <div className="flex flex-col bg-taskinatorLightGrey" key={fileSetKey}>
                  <div className={` text-xs flex items-center text-taskinatorDarkGrey  p-2 border border-taskinatorMedGrey rounded-t border-b-none bg-taskinatorItemBlue gap-y-2`}>
                    <div className=" font-semibold uppercase mr-4">
                      {fileSet?.number} - {fileSet.name}
                    </div>
                    <div>
                      {fileSet?.fileSetDetails?.settings?.fileTags?.map((tag) => {
                        return <Tag tagData={tag} />;
                      })}
                    </div>
                  </div>

                  <div className="border border-t-none rounded-b border-taskinatorMedGrey pt-1 px-1 mb-1">
                    {fileSet.workflows.map((workflow, index) => {
                      return (
                        <div key={workflow._id} className={`pt-1`}>
                          {workflow.newFileCount > 0 && !job?.completed && (
                            <div className="flex grow  justify-end h-0  z-40">
                              <div className="relative -top-2 mr-2 items-center flex h-4 w-fit text-taskinatorWhite text-[0.75rem] bg-taskinatorRed  px-2 rounded-full font-semibold">
                                {workflow.newFileCount}
                              </div>
                            </div>
                          )}

                          <div className="bg-taskinatorHoverGreen px-2 py-1 mb-1 rounded text-taskinatorWhite font-medium text-xs uppercase flex items-center">
                            {" "}
                            <div className="  uppercase mr-2"> {workflow.name}</div>
                            {workflow.setWorkflow && (
                              <div className="bg-taskinatorWhite text-taskinatorRed animate-pulse px-1 py-0.5 rounded flex">
                                <div className="pr-1">
                                  {" "}
                                  <MdOutlineWarning size={"1rem"} />
                                </div>
                                <b>Set Workflow </b>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        }
        primaryBorder={primaryBorder}
      />
    </>
  );
};

export default JobDashboardElement;
