import React, { useContext, useState } from "react";
import { MdInfoOutline } from "react-icons/md";
import { AppContext } from "../contexts/appContext";

const ToolTip = ({ content, full, video, customCSS }) => {
  const [show, setShow] = useState(false);
  const appData = useContext(AppContext);

  return (
    <div className="relative flex items-center px-1">
      <div
        onClick={video ? () => appData.setToolTipModal(content) : () => {}}
        onMouseEnter={video ? () => {} : () => setShow(true)}
        onMouseLeave={video ? () => {} : () => setShow(false)}
        className="cursor-pointer "
      >
        {full ? (
          <div
            className={`text-xs font-medium border-taskiantorMedGrey text-taskinatorDarkGrey bg-taskinatorMedGrey rounded  w-fit  hover:opacity-75 px-2 py-1  ${customCSS} `}
          >
            <div className="flex items-center justify-center">
              <div className="pr-1">
                <MdInfoOutline />
              </div>{" "}
              Watch Quick Guide
            </div>
          </div>
        ) : (
          <MdInfoOutline />
        )}
      </div>
      {show && (
        <div className="z-40 absolute bottom-full mb-2 px-4 py-2 text-sm text-taskinatorDarkGrey bg-taskinatorWhite min-w-40  rounded-md shadow-lg border border-taskinatorMedGrey">
          {content}
        </div>
      )}
    </div>
  );
};

export default ToolTip;
