import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { AppContext } from "../../contexts/appContext";
import { useInit } from "../hooks/useInit";
import { useInputV4 } from "../../stateManagement/useInputV4";

import Input_V4 from "../inputs/input_V4";
import RoleSelector from "../dataSelectors/roleSelector";
import TagSelector from "../dataSelectors/tagSelector";
import Button from "../UI_elements/button";
import SignUpSelector from "../dataSelectors/signUpSelector";
import { VALIDATOR_MINLENGTH, VALIDATOR_EMAIL, VALIDATOR_NUMBER } from "./validators/validators";
import IconSpinner from "../UI_elements/iconSpinner";
import CheckboxV4 from "../inputs/checkbox_V4";
import { MdInfoOutline } from "react-icons/md";

const InviteEditMember = ({ user, onResponse }) => {
  const appData = useContext(AppContext);
  const { inputState, inputHandler } = useInputV4({
    inputs: {},
    isValid: false,
  });
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(4);
  const [resendInvite, setResendInvite] = useState();
  const [isChecked, setIsChecked] = useState(user?.notifyWeekDaysOnly ?? false);

  const submitHandler = (event) => {
    event.preventDefault();

    const dataToSubmit = user
      ? user && user.role !== process.env.REACT_APP_ACCESS_O
        ? {
            user_id: user._id,
            role: inputState.inputs.role.value,
            tags: inputState.inputs.tags.value,
            roleTags: inputState.inputs.roleTags.value,
            notifyWeekDaysOnly: inputState.inputs.notifyWeekDaysOnly.value,
            availableHours: inputState.inputs.availableHours.value,
            signInStrategies: inputState.inputs.signInStrategies.value,
          }
        : {
            user_id: user._id,
            role: inputState.inputs.role.value,
            tags: inputState.inputs.tags.value,
            roleTags: inputState.inputs.roleTags.value,
            notifyWeekDaysOnly: inputState.inputs.notifyWeekDaysOnly.value,
            availableHours: inputState.inputs.availableHours.value,
            signInStrategies: inputState.inputs.signInStrategies.value,
          }
      : {
          firstName: inputState.inputs.firstName.value,
          lastName: inputState.inputs.lastName.value,
          email: inputState.inputs.email.value,
          assignedRole: inputState.inputs.role.value,
          tags: inputState.inputs.tags.value,
          roleTags: inputState.inputs.roleTags.value,
          notifyWeekDaysOnly: inputState.inputs.notifyWeekDaysOnly.value,
          availableHours: inputState.inputs.availableHours.value,
          signInStrategies: inputState.inputs.signInStrategies.value,
        };

    initStarted(0);
    axios
      .put(process.env.REACT_APP_BACKEND_URL + `/api/team/${user ? "edit" : "create"}`, dataToSubmit, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token ",
        },
        withCredentials: true,
      })
      .then((response) => {
        initCompleted(0);
        onResponse();
      })
      .catch((err) => {
        console.log(err);
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";

        return initErrors(errorMessage, 0);
      });
  };

  const deactivateHandler = () => {
    initStarted(1);

    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/team/deactivate`, // this should change to workspaceDataId
        {
          user_id: user._id,
          lock: !user.locked,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        onResponse();
        initCompleted(1);
      })
      .catch((err) => {
        console.log(err);
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";

        return initErrors(errorMessage, 1);
      });
  };

  const resendInviteHandler = () => {
    initStarted(2);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/team/resendInvite`, // this should change to workspaceDataId
        {
          user_id: user._id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        setResendInvite(true);
        initCompleted(2);
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
        appData.ERROR(errorMessage);
        return initErrors(errorMessage, 2);
      });
  };

  const cancelInviteHandler = () => {
    initStarted(3);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/team/cancelinvite`, // this should change to workspaceDataId
        {
          user_id: user._id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        onResponse();
        initCompleted(3);
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
        appData.ERROR(errorMessage);
        return initErrors(errorMessage, 3);
      });
  };

  useEffect(() => {
    inputHandler("notifyWeekDaysOnly", isChecked, true, "inputs");
  }, [isChecked]);


  console.log(inputState)
  return (
    <form className="md:w-96 w-full " onSubmit={submitHandler}>
      {/* <div className=" font-semibold w-full text-center text-lg text-taskinatorDarkGrey">{user ? "Edit Member" : "Invite People"}</div> */}

      {!user && <Input_V4 label="First Name" sid="inputs" id="firstName" customClasses={"capitalize"} placeholder={`First Name...`} onInput={inputHandler} validators={[VALIDATOR_MINLENGTH(1)]} />}

      {!user && <Input_V4 label="Last Name" sid="inputs" id="lastName" customClasses={"capitalize"} placeholder={`Last Name...`} onInput={inputHandler} validators={[VALIDATOR_MINLENGTH(3)]} />}

      {!user && <Input_V4 label="Email" sid="inputs" id="email" placeholder={`Email`} onInput={inputHandler} validators={[VALIDATOR_EMAIL]} />}

      <div className={`${!user || (user && user.role !== process.env.REACT_APP_ACCESS_O) ? "flex grow" : "hidden"}`}>
        {" "}
        <RoleSelector inputVer="1" inputHandler={inputHandler} defaultValue={user ? user.role : null} sid="inputs" />
      </div>

      {(!user || (user && appData.workspaceDataV4.role === process.env.REACT_APP_ACCESS_O)) && (
        <SignUpSelector inputVer="1" inputHandler={inputHandler} sid="inputs" defaultValue={user ? user.signInStrategies : appData.workspaceDataV4.ownerSignUpStrategy} />
      )}

      <Input_V4
        label="Available Hours Per Week"
        sid="inputs"
        id="availableHours"
        
        defaultValue={user?.availableHours ?? ""}
        placeholder={`Time in hours`}
        validityMessage={"Must be a number"}
        onInput={inputHandler}
        validators={[VALIDATOR_NUMBER()]}
      />

      <div className="flex justify-start items-center gap-1 my-1">
        <CheckboxV4
          onInput={inputHandler}
          sid="inputs"
          id="notifyWeekDaysOnly"
          label="Notify Weekdays Only"
          customeLabel={"text-sm h-5"}
          customCheckbox={"h-4 w-4"}
          defaultValue={user?.notifyWeekDaysOnly ?? false}
          
        />
        <MdInfoOutline />
      </div>

      <TagSelector
        inputVer="1"
        notRequired={true}
        id={"roleTags"}
        inputHandler={inputHandler}
        defaultValue={
          user && user.roleTags
            ? user.roleTags.map((tag) => {
                console.log(tag);
                return { type: tag.type, value: tag._id, name: tag.name };
              })
            : []
        }
        sid="inputs"
        type="role"
        label="Role Tag"
        selectionDisplayText={"Select Role Tag"}
      />

      <TagSelector
        inputVer="1"
        notRequired={true}
        id={"tags"}
        inputHandler={inputHandler}
        defaultValue={
          user && user.accessTags
            ? user.accessTags.map((tag) => {
                console.log(tag);
                return { type: tag.type, value: tag._id, name: tag.name };
              })
            : []
        }
        sid="inputs"
        type="access"
        label="Access Tag"
        selectionDisplayText={"Select Access Tag"}
      />

      {user && (
        <div className={`flex text-sm ${!initState[2].started || !initState[3].started ? "items-baseline" : "items-center"}`}>
          <div className=" mr-2">Member Status:</div>
          <div className={`capitalize ${!user.locked && user.activated ? "text-taskinatorGreen" : "text-taskinatorMedGrey"}`}> {user.activated ? "activated" : user.invited ? "invited" : ""}</div>
          {user.invited && !user.activated && !initState[2].started && (
            <div className={`ml-2 text-xs ${!resendInvite ? "text-taskinatorBlue cursor-pointer hover:underline" : ""}`} onClick={!resendInvite ? () => resendInviteHandler() : () => {}}>
              {!resendInvite ? "Resend Invite" : "Invite Resent"}
            </div>
          )}
          {user.invited && !user.activated && !initState[3].started && (
            <div className={`ml-2 text-xs text-taskinatorRed cursor-pointer hover:underline`} onClick={() => cancelInviteHandler()}>
              Cancel Invite
            </div>
          )}
          {initState[2].started ||
            (initState[3].started && (
              <div className="ml-2">
                <IconSpinner />
              </div>
            ))}
        </div>
      )}
      {user && user.activated && user.role !== process.env.REACT_APP_ACCESS_O && (
        <Button
          label={!user.locked ? "Lock User" : "Unlock User"}
          customClasses={`${!user.locked ? "border-taskinatorRed text-taskinatorRed" : "border-taskinatorDarkGrey text-taskinatorDarkGrey"} border  rounded  h-8 w-full mt-2 mb-2 `}
          isLoading={initState[1].started}
          onClick={() => deactivateHandler()}
          error={initState[1].error}
        />
      )}

      <Button
        type="submit"
        label={user ? "Save" : "Invite"}
        customClasses={`${inputState.isValid ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"} bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-2 `}
        disabled={!inputState.isValid}
        isLoading={initState[0].started}
        error={initState[0].error}
      />
    </form>
  );
};

export default InviteEditMember;
