import React, { useContext, useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useInit } from "../../hooks/useInit";
import { useInputV4 } from "../../../stateManagement/useInputV4";
import InputV4 from "../../inputs/input_V4";
import { VALIDATOR_MINLENGTH } from "../../forms/validators/validators";
import TagSelector from "../../dataSelectors/tagSelector";
import ItemStateSelector from "../../dataSelectors/itemStateSelector";
import FileDisplay from "./fileDisplay";
import IconSpinner from "../../UI_elements/iconSpinner";
import ItemStatus from "../../UI_elements/itemStatus";
import Button from "../../UI_elements/button";
import { MdWarning } from "react-icons/md";
import { FileContext } from "./FileContext";
import { JobModalContext } from "../jobs/jobModalContext";
import ApproveRejectForm from "../../forms/approveRejectForm";
import Modal from "../../UI_elements/modal";
import FileModifierButtons from "./fileModifierButtons";
import { AppContext } from "../../../contexts/appContext";
import { MdOutlineWarning } from "react-icons/md";
import FilePreview from "../../../utils/filePreview";
import FileRowDisplay from "./fileRowDisplay";
import { deepEqual } from "../../../utils/deepEqual";
import WorkflowNextWorkflow from "../workflowNextWorkflow";
import SequenceSetter from "./sequenceSetter";

const BulkFileEditor = ({ bulkFileList, inOtherWorkflow, currentFileSetId, onResponse, taskId, workflowGroupId, titleLess, taskTags }) => {
  const fileContext = useContext(FileContext);
  const jobModalContext = useContext(JobModalContext);
  const appData = useContext(AppContext);
  const inJob = jobModalContext?.jobId ? inOtherWorkflow : fileContext?.itemsSelected?.[currentFileSetId]?.inJob;
  const jobData = appData?.jobs[jobModalContext.jobId];
  let otherActiveTags = {};
  const allFileData = jobData?.files?.find((file) => file._id === file._id);
  allFileData?.currentJobs
    .filter((cj) => cj.taskId !== taskId)
    .forEach((cj) => {
      jobData.workflows
        .find((wf) => wf.workflowGroupId === cj.workflowGroupId)
        .tasks.find((task) => task._id === cj.taskId)
        .taskDetails.fileTags.forEach((tag) => (otherActiveTags = { ...otherActiveTags, [tag._id]: tag._id }));
    });

  const sid = "inputs";

  const [defaultValues, setDefaultValues] = useState(() => analyzeData(Object.values(bulkFileList)));

  useEffect(() => {
    // Update defaultValues only when bulkFileList changes
    const newData = analyzeData(Object.values(bulkFileList));
    if (!deepEqual(newData, defaultValues)) {
      // Using Lodash isEqual for deep comparison
      setDefaultValues({ ...newData });
    }
  }, [bulkFileList, defaultValues]);

  const { inputState, inputHandler, attachmentHandler, resetState } = useInputV4({ inputs: {} }, false);

  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(3);

  const [saved, setSaved] = useState();

  useEffect(() => {
    setSaved();
  }, [inputState]);


  const submitHandler = (isUnarchive) => {
    initStarted(2);

    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/file/bulkUpdate`,
        {
          fileIds: Object.values(bulkFileList).map((d) => d._id),
          revision: inputState.inputs.revision.value,
          status: inputState.inputs.itemState.value,
          fileTags: inputState.inputs.fileTags.value,
          accessTags: inputState.inputs.accessTags.value,
          autoSequence: inputState.inputs.autoSequence.value,
          jobId: jobModalContext?.jobId,
          taskId: taskId,
          workflowGroupId: workflowGroupId,
          // isUnarchive: isUnarchive,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        const updatedFiles = response.data.updatedFiles;

        if (jobModalContext.jobId) {
          onResponse();
        } else {
          updatedFiles.map((file) => {
            fileContext?.setItemList?.((currentFiles) => {
              return {
                ...currentFiles,
                [currentFileSetId]: {
                  ...currentFiles[currentFileSetId],
                  [file._id.toString()]: file,
                },
              };
            });
          });
        }

        // fileContext?.setFileSelection?.({
        //   file: { ...updatedFiles },
        //   fileSetId: currentFileSetId,
        // });

        setSaved(true);
        initCompleted(2);
      })
      .catch((err) => {
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 2);
      });
  };

  return (
    <div className="flex flex-col overflow-auto h-fit">
      {/* <div className="absolute opacity-50 h-full h-fit border bg-taskinatorBlack z-50"></div> */}
      <div className="p-4">
        {!titleLess && <div className="font-medium text-center border-b border-b-taskinatorMedGrey">Bulk Edit</div>}
        {inJob && (
          <div className="text-xs mt-4 text-center text-taskinatorRed flex  rounded-lg items-center  justify-center py-1">
            <MdOutlineWarning size={"1rem"} />
            <div className="px-1"> Editing disabled as this file is currently in a job: </div> <div className="font-bold  uppercase  text-taskinatorRed   ">{inJob.ref}</div>
            <div>
              {/* <Button
                  label="View Job Details"
                  customClasses={"text-taskinatorWhite bg-taskinatorBlue mt-2 w-full h-8"}
                  onClick={() => {x
                    appData.setJobViewHandler({ jobId: inJob._id });
                  }}
                /> */}
            </div>
          </div>
        )}
        <div className="mt-2">
          <InputV4
            // disabled={inJob || initState[0].started || initState[2].started || file.archived === true}
            label="File Revision"
            sid={sid}
            id="revision"
            placeholder={defaultValues?.revision === "Varies" ? `Data Varies...` : "Data is the same"}
            customClasses={"rounded-md md:text-sm"}
            onInput={inputHandler}
            validators={[VALIDATOR_MINLENGTH(1)]}
            disabled={inJob || initState[0].started || initState[2].started}
            validityMessage="Min 1 characters"
            defaultValue={defaultValues?.revision}
          />
        </div>

        <ItemStateSelector
          sid={sid}
          label={`Set File Status`}
          inputHandler={inputHandler}
          defaultValue={defaultValues?.status}
          variesOverride={defaultValues?.status}
          selectionDisplayText={defaultValues?.status === "Varies" ? "Status Varies..." : null}
          disabled={inJob || initState[0].started || initState[2].started}
        />

        <div className="pt-2">
          <TagSelector
            id={"fileTags"}
            inputHandler={inputHandler}
            defaultValue={defaultValues?.fileTags.map((tag) => {
              return { value: tag };
            })}
            tagsToHighlight={taskTags ? taskTags.map((tag) => tag._id) : []}
            sid="inputs"
            type="file"
            label="Task Access Tag"
            selectionDisplayText={"Select/Create File Tag"}
            toolTip={"Which task this file should appear in"}
            pendingTextOverride={defaultValues?.fileTags === "Varies" ? "Data Varies..." : null}
            disabled={inJob || initState[0].started || initState[2].started}
            tagsActiveOnOtherWorkflows={Object.values(otherActiveTags)}
          />
        </div>
        <div></div>

        <div className="pt-2">
          <TagSelector
            notRequired
            id={"accessTags"}
            inputHandler={inputHandler}
            defaultValue={defaultValues?.accessTags?.map((tag) => {
              return { value: tag };
            })}
            sid="inputs"
            type="access"
            label="Internal Access Tags"
            selectionDisplayText={"Select/Create Access Tag"}
            disabled={inJob || initState[0].started || initState[2].started}
          />
        </div>

        <div className="pt-2">
          <SequenceSetter
            notRequired
            id={"autoSequence"}
            inputHandler={inputHandler}
            defaultValue={
              defaultValues?.autoSequence?.map((tag) => {
                return { value: tag };
              }) ?? []
            }
            sid="inputs"
            disabled={inJob || initState[0].started || initState[2].started}
          />
        </div>

        {!saved ? (
          <Button
            onClick={() => submitHandler()}
            label={`Save All`}
            customClasses={`${!inputState.isValid && !inJob ? "bg-taskinatorMedGrey" : "bg-taskinatorblue"} bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-2 text-sm`}
            disabled={!inputState.isValid || inJob}
            isLoading={initState[2].started}
            error={initState[2].error}
          />
        ) : (
          <div className="text-center h-10 pt-2 text-xs text-taskinatorGreen">File Saved</div>
        )}
      </div>
    </div>
  );
};

export default BulkFileEditor;

function checkUniformity(array) {
  // This function checks if all items in the array are the same.
  return array.every((val, i, arr) => JSON.stringify(val) === JSON.stringify(arr[0]));
}

function analyzeData(dataArray) {
  const revisions = dataArray.map((item) => item.revision);
  const statuses = dataArray.map((item) => item.status);
  const fileTags = dataArray.length > 1 ? dataArray.map((item) => item?.settings?.fileTags.map((tag) => tag._id)) : [dataArray[0]?.settings?.fileTags?.map((tag) => tag._id)];
  const accessTags = dataArray.length > 1 ? dataArray.map((item) => item?.settings?.accessTags.map((tag) => tag._id)) : [dataArray[0]?.settings?.accessTags?.map((tag) => tag._id)];
  const autoSequence = dataArray.length > 1 ? dataArray.map((item) => item?.autoSequence?.map((tag) => tag._id)) : [dataArray[0]?.autoSequence?.map((tag) => tag._id)];

  const results = {
    revision: checkUniformity(revisions) ? revisions[0] : "Varies",
    status: checkUniformity(statuses) ? statuses[0] : "Varies",
    fileTags: checkUniformity(fileTags) ? fileTags[0] : ["Varies"],
    accessTags: checkUniformity(accessTags) ? accessTags[0] : ["Varies"],
    autoSequence: checkUniformity(autoSequence) ? autoSequence[0] : ["Varies"],
  };

  return results;
}
