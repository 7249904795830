import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FileContext } from "./FileContext";
import BulkFileUpload from "./bulkFileUpload";
import CreateFileSet from "./createFileSet";
import FileListView from "./fileListView";
import FileSetRowDisplay from "./fileSetRowDisplay";
import FileModifierButtons from "./fileModifierButtons";
import CheckboxV4 from "../../inputs/checkbox_V4";
import Button from "../../UI_elements/button";
const FileSetRegisterListElement = ({
  itemData,
  searchAndAdd,
  itemsSelected,
  onSelectHandler,
  isRegister,
  tid,
  setFileSelection,
  fileSelection,
  onFilesUploaded,
  hideFiles,
  searchValue,
  onBulkSelection,
  bulkFileSelection,
  bulkEditEnabled,
  setBulkEditEnabled,
  hideArchived,
}) => {
  const [viewItemDetails, setViewItemDetails] = useState();
  // const {tid} = useParams();
  const navigate = useNavigate();
  const fileContext = useContext(FileContext);
  const [selectAll, setSelectAll] = useState(false);

  const sectionCSS = "flex border-r border-r-taskinatorLightGrey h-full  whitespace-nowrap items-center ";
  const widths = [
    "w-10 shrink-0 border-r-2 justify-center",
    "2xl:w-2/12 xl:w-4/12 md:w-4/12 w-8/12   shrink-0 px-2 border-r-2",
    "xl:w-1/12 w-2/12 shrink-0 px-2 justify-center border-r-2",
    "xl:w-1/12 w-2/12 shrink-0 px-2 justify-center border-r-2",
    "w-32 px-2 justify-center items-center border-r-2",
    "w-2/12 shrink-0 px-2 border-r-2 hidden xl:flex items-center justify-start",
    "w-2/12 shrink-0 px-2 border-r-2 hidden xl:flex",
    "w-2/12 shrink-0 px-2 justify-center border-r-2 hidden 2xl:flex",
    "w-1/12 shrink-0 px-2 justify-center   hidden 2xl:flex",
    "lg:w-1/12 md:w-2/12 md:flex hidden shrink-0 px-2 justify-center  border-r-2",
    " shrink-0 px-2 border-r-2 justify-center md:flex md:grow hidden",
  ];

  const availableItems = itemData && itemData.length > 0 && itemData;

  return (
    <div className={` w-full flex flex-col h-full overflow-auto scrollbar`}>
      <div className="w-full flex-col h-full overflow-auto justify-center items-center grow  flex">
        <div className="flex bg-taskinatorWhite text-[0.6rem] font-semibold text-taskinatorDarkGrey py-2 uppercase w-full h-10 mb-0.5">
          {/* <div className={`${sectionCSS} ${widths[0]}`}></div> */}
          {/* <div className={`${sectionCSS} ${widths[4]}`}>Status</div> */}

          <div className="flex w-full items-center">
            <div className={`${sectionCSS} ${widths[0]}`}>&nbsp;</div>
            <div className={`${sectionCSS} ${widths[2]}`}>Number</div>
            <div className={`${sectionCSS} ${widths[1]}`}>Name</div>
            {/* <div className={`${sectionCSS} ${widths[3]}`}>Number of Files</div> */}
            {/* <div className={`${sectionCSS} ${widths[9]}`}>Attachment</div> */}
            <div className={`${sectionCSS} ${widths[5]}`}>File Set Tag</div>
            {/* {isRegister && <div className={`${sectionCSS} ${widths[6]}`}>Access Tag/s</div>} */}
            <div className={`${sectionCSS} ${widths[2]}`}># Of Files</div>
            <div className={`${sectionCSS} ${widths[10]}`}>In-progress Activity</div>
            {isRegister && <div className={`${sectionCSS} ${widths[7]}`}>Created By</div>}
            {isRegister && <div className={`${sectionCSS} ${widths[8]} border-r-0`}>Created Date</div>}
          </div>
          {/* <div className={`${sectionCSS} ${widths[0]}`}></div> */}
        </div>
        {!availableItems && availableItems.length === 0 && (
          <div className="py-4 bg-taskinatorWhite w-full grow border border-taskinatorRed items-center justify-center flex text-taskinatorDarkGrey flex-col">
            <div className="py-2 text-sm">No File Sets Available</div>
          </div>
        )}
        <div className="w-full overflow-auto flex  flex-col grow  justify-start scrollbar">
          {availableItems &&
            availableItems.length > 0 &&
            availableItems
              .filter((fileSet) => {
                console.log(hideArchived, fileSet);
                if (hideArchived && fileSet.archived) {
                  return false;
                } else {
                  return true;
                }
              })
              .map((fileSet, index) => {
                const thisFileSetHasBeenSelected = itemsSelected && itemsSelected[fileSet._id] ? true : false;
                const inJob = fileContext?.itemsSelected?.[fileSet._id]?.inJob;
                // if ((thisFileSetHasBeenSelected && Object.keys(itemsSelected).length > 0) || Object.keys(itemsSelected).length === 0 || !isRegister) {
                return (
                  <div key={itemData._id ?? index} className={`flex flex-col h-fit mb-1 `}>
                    <div className={`${(thisFileSetHasBeenSelected && Object.keys(itemsSelected).length > 0) || Object.keys(itemsSelected).length === 0 || !isRegister ? "" : "opacity-50"}`}>
                      <FileSetRowDisplay
                        fileSet={fileSet}
                        index={0}
                        sectionCSS={sectionCSS}
                        widths={widths}
                        setViewItemDetails={() => {
                          navigate(`/view/files/${fileSet._id.toString()}/${fileSet.name}`);
                        }}
                        itemsSelected={itemsSelected}
                        onSelectHandler={onSelectHandler}
                        searchAndAdd={searchAndAdd}
                        isRegister={isRegister}
                        tid={tid}
                      />
                    </div>
                    {thisFileSetHasBeenSelected && !hideFiles && (
                      <div className=" flex flex-col h-full overflow-auto text-sm border-t-0 border-taskinatorBlue rounded-b-lg border py-1  shadow-lg ">
                        <div className="flex">
                          <div className="pl-3 grow w-fit flex text-nowrap ">
                            <CheckboxV4
                              label="Select All Files"
                              onInput={(id, isChecked, isValid, sid) => {
                                setSelectAll(isChecked);
                                if (!isChecked) {
                                  fileContext.setBulkFileSelection();
                                }
                              }}
                              defaultValue={selectAll}
                              customCheckbox={"h-5 w-5"}
                            />
                          </div>
                          <div className="pb-1 flex justify-end w-full pr-2">
                            {/* <Button customClasses="underline text-taskinatorDarkGrey py-2 w-full" label={`Download As One`} onClick={() => setBulkEditEnabled(true)} /> */}
                            <CreateFileSet fileSet={fileSet} customButtonCSS=" border-taskinatorBlue underline text-taskinatorDarkGrey py-2  w-full  " onResponse={() => fileContext.sendRequest()} />

                            <BulkFileUpload
                              onFilesUploaded={onFilesUploaded}
                              fileSetId={fileSet._id.toString()}
                              fileSetName={fileSet.name}
                              buttonandModal
                              customButtonCSS=" border-taskinatorBlue  underline text-taskinatorBlue py-2  w-full  "
                            />
                          </div>
                        </div>
                        <div className="flex bg-taskinatorWhite  p-2 grow text-xxs font-medium text-taskinatorDarkGrey">
                          <div className="w-8"> </div>
                          <div className="px-2  sm:flex hidden text-taskinatorMedGrey">|</div>
                          <div className="w-32 pr-2 text-center">Status</div>
                          <div className="px-2  sm:flex hidden text-taskinatorMedGrey">|</div>
                          <div className=" w-20 text-center justify-center sm:flex hidden">Date</div>
                          <div className="px-2  sm:flex hidden text-taskinatorMedGrey">|</div>
                          <div className="w-12 overflow-hidden">Revision</div>
                          <div className="px-2 text-taskinatorMedGrey">|</div>
                          <div className="w-60 px-2">File Name</div>
                          <div className="px-2 text-taskinatorMedGrey">|</div>
                          <div>Task Access Tag</div>
                        </div>
                        <div className="flex flex-col h-full overflow-auto grow scrollbar">
                          <FileListView
                            onBulkSelection={onBulkSelection}
                            fileSetId={fileSet._id.toString()}
                            setFileSelection={setFileSelection}
                            fileSelection={fileSelection?.file}
                            searchValue={searchValue}
                            setSelectAll={setSelectAll}
                            bulkFileSelection={bulkFileSelection}
                            selectAll={selectAll}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
                // }
              })}
        </div>
      </div>
    </div>
  );
};

export default FileSetRegisterListElement;
