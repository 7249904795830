import React from "react";
import {
  MdOutlineKeyboardDoubleArrowRight,
  MdOutlineStop,
} from "react-icons/md";
import CheckboxV4 from "../inputs/checkbox_V4";
import AddStepDisplayButton from "./addStepDisplayButton";
import WorkflowActionDisplay from "./workflowActionDisplay";
import WorkfloworkflowNextWorkflow from "./workflowNextWorkflow";

const entryExitDisplay = (state, type) => {
  const displayIcon = !state ? (
    <div className="flex items-center justify-center p-2 w-36">
      <div className="text-sm text-right">
        {type === "noEntry" ? "Inbound" : "Outbound"}
      </div>
      <MdOutlineKeyboardDoubleArrowRight size={40} />
    </div>
  ) : type === "noEntry" ? (
    ""
  ) : (
    <div className="flex flex-col items-center justify-center p-2 w-36">
      <MdOutlineStop size={40} />
      <div className="text-sm ">End of task</div>
    </div>
  );
  return <div className="text-taskinatorMedGrey">{displayIcon}</div>;
};

const WorkflowStageDisplay = ({
  workflowOptions,
  workflowState,
  dispatch,
  teamData,
  removeButtonStyle,
  workflowData
}) => {
  const isworkflow = workflowState.stages && workflowState.stages.length > 0;

  return (
    <div className="flex items-center py-6">
      {/* {entryExitDisplay(workflowState.noEntry, "noEntry")} */}
      {workflowState.stages && workflowState.stages.length === 0 && (
        <div
          onClick={() => dispatch({ type: "ADD_STAGE", stageIndex: 0 })}
          className="border border-taskinatorBlue rounded text-center py-1 px-2 text-taskinatorBlue hover:opacity-80 cursor-pointer text-sm"
        >
          Add First Stage
        </div>
      )}
      {isworkflow &&
        workflowState.stages.map((stage, stageIndex) => {
          return (
            <div
              key={stage._id ?? stageIndex}
              className="flex flex-col justify-center items-center"
            >
              <div className="flex justify-center items-center">
                {stageIndex === 0 && (
                  <AddStepDisplayButton
                    customClasses={"h-36 w-6"}
                    action
                    onClick={() =>
                      dispatch({
                        type: "ADD_STAGE",
                        stageIndex: stageIndex - 1,
                      })
                    }
                  />
                )}

                <div className=" ">
                  <div className="flex flex-col items-center justify-center border border-taskinatorMedGrey shadow border-1 rounded-lg p-2 min-h-[9rem] bg-taskinatorLightGrey">
                    <div className="flex grow items-center">
                      <WorkflowActionDisplay
                        workflowState={workflowState}
                        dispatch={dispatch}
                        teamData={teamData}
                        stageIndex={stageIndex}
                        stage={stage}
                        removeButtonStyle={removeButtonStyle}
                      />
                    </div>

                    {stage.length === 0 && (
                      <div
                        className={` ${removeButtonStyle} `}
                        onClick={() => {
                          dispatch({ type: "REMOVE_STAGE", stageIndex });
                        }}
                      >
                        Remove Task Group
                      </div>
                    )}
                  </div>
                </div>
                <AddStepDisplayButton
                  customClasses={"h-36 w-6"}
                  onClick={() => dispatch({ type: "ADD_STAGE", stageIndex })}
                />
              </div>
            </div>
          );
        })}
      {isworkflow && (
        <div className="text-center shrink-0">
          <div className="text-sm font-medium text-taskinatorDarkGrey">
           Next Sequence To Activate{" "}
          </div>
          <div className="flex items-center justify-center bg-taskinatorHoverBlue p-4 border border-taskinatorMedGrey rounded">
            <WorkfloworkflowNextWorkflow
              workflowOptions={workflowOptions}
              currentWorkFlow={workflowData}
              dispatch={dispatch}
              
            />
            {/* <div className="px-2 text-xs">and/or</div>
            <AccessTagToNotify currentWorkFlow={workflowState} dispatch={dispatch} /> */}
            <div className="px-2 text-xs hidden">and/or</div>
            <div className="flex flex-col hidden">
              <div className="w-30 shrink-0 p-2 rounded bg-taskinatorWhite border-taskinatorMedGrey border-2 mb-1 ">
                <CheckboxV4
                  onInput={() => {}}
                  globalDispatch={dispatch}
                  globalDispatchType="ITEM_TAG_NOTIFICATION"
                  label="Notify By Drawing Access Tag/s"
                  customeLabel={"text-xs"}
                  customCheckbox={"h-4 w-4"}
                  defaultValue={workflowState.notifyByItemTags}
                />
              </div>
              <div className="w-30 shrink-0 p-2 rounded bg-taskinatorWhite border-taskinatorMedGrey border-2 mb-1">
                <CheckboxV4
                  onInput={() => {}}
                  globalDispatch={dispatch}
                  globalDispatchType="WORKFLOW_MANAGER_NOTIFICATION"
                  label="Notify Workflow Manager/s"
                  customeLabel={"text-xs"}
                  customCheckbox={"h-4 w-4"}
                  defaultValue={workflowState.notifyWorkflowManagers}
                />
              </div>
              {/* <div className="w-30 shrink-0 p-2 rounded bg-taskinatorWhite border-taskinatorMedGrey border-2 mb-1"><CheckboxV4 onInput={()=>{}} label="" customeLabel={"text-xs"} customCheckbox={"h-4 w-4"}/></div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkflowStageDisplay;
