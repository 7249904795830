import LowerCaseTrim from "./lowerCaseAndTrim";

const NotificationFormatter = (notificationData, filter, appData) => {
  let formattedNotifications = {};

  Object.values(notificationData)
    .filter((n) => {
      if (!appData.tagFilter || appData.tagFilter["all tags"]) {
        return true;
      } else {
        return (
          appData.tagFilter &&
          Object.values(appData.tagFilter)
            .flat(1)
            .includes(n.tid._id.toString())
        );
      }
    })
    .filter((n) => {
      if (!filter) {
        return true;
      } else {
        if (filter.trim().length === 0) {
          return true;
        } else {
          return LowerCaseTrim(n.taskRef).includes(LowerCaseTrim(filter));
        }
      }
    })
    .map((filteredNotifications) => {
      const { tid, taskRef, ...requestDetails } = filteredNotifications;

      const updatedRequestDetails = formattedNotifications?.[tid?._id]
        ? {
            ...formattedNotifications?.[tid?._id]?.elementData,
            [filteredNotifications?._id]: { ...requestDetails },
          }
        : { [filteredNotifications?._id]: { ...requestDetails, ref: taskRef } };

      formattedNotifications = {
        ...formattedNotifications,
        [tid?._id]: {
          ref: taskRef,
          tid: tid?._id,
          elementData: updatedRequestDetails,
        },
      };

      return null;
    });

  return formattedNotifications;
};

export default NotificationFormatter;
