import LowerCaseTrim from "./lowerCaseAndTrim";
import { v4 as uuidv4 } from "uuid";
const JobFormatter = (jobs, filter, appData) => {
  let formattedJobs = {};

  // if (Object.values(jobs).length) {
  //   debugger;
  // }

  Object.values(jobs)
    .filter((job) => {
      if (appData.tagFilter["all tags"]) {
        return true;
      } else if (appData.tagFilter["no tags"]) {
        return job.jobDetails.taskTags.length === 0;
      } else {
        return job.jobDetails.taskTags.filter((tag) => Object.keys(appData.tagFilter).includes(tag.name)).length > 0;
      }
    })
    .filter((job) => {
      if (!filter) {
        return true;
      } else {
        if (filter.trim().length === 0) {
          return true;
        } else {
          return LowerCaseTrim(job.jobDetails.ref).includes(LowerCaseTrim(filter));
        }
      }
    })
    .sort((a, b) => {
      // Convert dates to Date objects for accurate comparison
      const dateA = new Date(a.jobDetails.createdDate);
      const dateB = new Date(b.jobDetails.createdDate);

      return dateB - dateA; // Ascending order
      // return dateB - dateA; // For descending order
    })
    .map((job) => {

      let fileMapObject = {};
      let workflowMap = {};
      let count = 0;

      job.files.forEach((file, index) => {
        let fileSetId;
        let workflowData;


      
        file.currentJobs.forEach((wf) => {
          workflowData = job.workflows.find((workflow) => workflow.workflowGroupId === wf.workflowGroupId);
          // Check if the workflow map for this ID has already been initialized

          fileSetId = workflowData.fileSetDetails._id;

          if (!workflowMap?.[fileSetId]?.[wf.workflowGroupId]) {
            // Initialize the workflow in the map if it doesn't exist

            if (!workflowMap[fileSetId]) {
              workflowMap[fileSetId] = {};
            }

            workflowMap[fileSetId][wf.workflowGroupId] = {
              _id: wf.workflowId,
              files: [],
              name: job.workflows.find((wft) => wft.workflowGroupId === wf.workflowGroupId).name,
              newFileCount: 0,
              workflowData: workflowData,
              setWorkflow: !workflowData.workflowMap || workflowData.tasks.find((task) => task.assignee.length === 0) ? true : false,
            };
          }

          // Add file to files array if not already added (avoid duplication)
          if (!workflowMap?.[fileSetId]?.[wf.workflowGroupId]?.files[file._id]) {
            workflowMap[fileSetId][wf.workflowGroupId].files = { ...workflowMap[fileSetId][wf.workflowGroupId].files, [file._id]: file };
          }

          // Increment the file count for this workflow if file hasn't been seen

          file.currentJobs.forEach((cfp) => {
            if (workflowMap[fileSetId][wf.workflowGroupId] && !cfp.seen.find((user) => user._id.toString() === appData.workspaceDataV4.user_id) && !cfp.referenceOnly) {
              workflowMap[fileSetId][wf.workflowGroupId].newFileCount++;
            }
          });
        });

        fileMapObject = {
          ...fileMapObject,
          [fileSetId]: {
            name: workflowData.fileSetDetails.name,
            number: workflowData.fileSetDetails.number,
            fileSetId: fileSetId,
            uuid: uuidv4(),
            fileSetName: workflowData.fileSetDetails.fileSetName,
            setting: workflowData.fileSetDetails.settings,
            workflows: Object.values(workflowMap[fileSetId]),
          },
        };
      });



      // only return the job file if the users has files assigned to them.
      if (job.files.length > 0) {
        formattedJobs = {
          ...formattedJobs,
          [job._id]: {
            ...job,

            fileMapObject: fileMapObject,
          },
        };
      }
    });

  return formattedJobs;
};

export default JobFormatter;
