import React, { useEffect, useRef, useState } from "react";
import { MdClose, MdOutlineAdd, MdPlayArrow, MdSearch } from "react-icons/md";
import ToolTip from "../../utils/toolTip";
import IconSpinner from "../UI_elements/iconSpinner";
import StringShortenDisplay from "../UI_elements/stringShortenDisplay";
import Tag from "../UI_elements/tag";
import { deepEqual } from "../../utils/deepEqual";

const MultiSelectDropdown = ({
  inputVer,
  defaultValue,
  label,
  disabled,
  options,
  notSearchable,
  onSelection,
  id,
  sid,
  notRequired,
  create,
  selectionDisplayText,
  onCreate,
  creating,
  modal,
  inline,
  AddButtonText,
  dontAutoSelect,
  toolTip,
  tagsToHighlight,
  tagsActiveOnOtherWorkflows,
}) => {
  const [selection, setSelection] = useState([]);
  const [autoSelection, setAutoSelection] = useState();
  const [dataVaries, setDataVaries] = useState(defaultValue?.[0]?.value === "Varies");
  const [previousSelection, setPreviousSelection] = useState();
  const [ogDefaults, setOgDefaults] = useState(defaultValue ?? []);
  const targetRef = useRef(null);

  const optionsToUse = [...options];

  const areEqual = deepEqual(ogDefaults, defaultValue ?? []);

  // const dataVaries = defaultValue?.[0]?.value === "Varies";

  useEffect(() => {
    if (!defaultValue || defaultValue.length === 0) {
      if (defaultValue && options.length === 1 && !dontAutoSelect) {
        setSelection([0]);
        setAutoSelection(true);
      }
      return;
    }

    setOgDefaults(defaultValue);
    const defaultValueSet = defaultValue.map((data) => data.value);

    let newSelection = [];

    if (defaultValue?.[0]?.value === "Varies") {
      setSelection([]);
      setDataVaries(true);
    } else {
      setDataVaries(false);
      optionsToUse.forEach((option, index) => {
        if (defaultValueSet.includes(option.value)) {
          newSelection = [...newSelection, index];
        }
      });

      setSelection(() => newSelection);
    }
  }, [areEqual]);

  const [dropDown, setDropDown] = useState();
  const [filterValue, setFilterValue] = useState("");

  const noItem = create ? options?.filter((data) => data.name.toLowerCase() === filterValue.toLowerCase()).length === 0 && filterValue !== "" : false;

  const onClickHandler = () => {
    if (!disabled) {
      if (dropDown) {
        setDropDown();
      } else {
        setDropDown(true);
      }
    }
  };

  const onFilterInputChangeHandler = (event) => {
    setFilterValue(event.target.value);
  };

  const selectionHandler = (index) => {
    setDataVaries(false);

    if (selection.includes(index)) {
      setSelection(
        selection.filter((data) => data !== index)
        // .sort((a, b) => {
        //   return a - b;
        // })
      );
    } else {
      const newSelection = [...selection, index];
      setSelection(
        newSelection
        // .sort((a, b) => {
        //   return a - b;
        // })
      );
    }
  };

  useEffect(() => {
    // if (JSON.stringify(previousSelection) !== JSON.stringify(selection)) {

    if (dataVaries) {
      onSelection(id, [{ _id: "Varies" }], true, sid);
    } else {
      if (optionsToUse?.[selection[0]]) {
        onSelection(
          id,
          selection.map((data) => optionsToUse[data].data),
          notRequired ? true : selection.length > 0 ? true : false,
          sid
        );
        // setPreviousSelection(selection);
      } else {
        onSelection(id, [], notRequired ? true : false, sid);
      }
    }
    // }
  }, [selection, id, onSelection, notRequired, sid, dataVaries]);

  return (
    <div ref={targetRef} className="relative">
      {label && (
        <div className={`text-sm h-5 flex`}>
          <div>{label}</div>
          <div className={`${!notRequired ? "text-taskinatorRed" : "text-taskinatorMedGrey"} pl-1`}>{!notRequired ? " (Required)" : " (Optional)"}</div>
          {toolTip && <ToolTip content={toolTip} />}
        </div>
      )}

      <div className={""}>
        <div className={`${inline ? "flex" : ""}`}>
          <div className={`flex flex-wrap w-full  ${inline ? "rounded-l border-r-0 border-taskinatorMedGrey" : "rounded-t border-b-0 border-taskinatorDarkGrey"} border p-2`}>
            {/* {dataVaries && (
              <div className="text-taskinatorMedGrey text-xs p-0.5">
                <Tag tagData={{ name: "Data Varies" }} alertTag></Tag>
              </div>
            )} */}
            {selection.length === 0 && (
              <div className={`${dataVaries ? "text-taskinatorDarkGrey" : "text-taskiantorMedGrey"} text-xs items-center justify-center flex`}>{dataVaries ? "Data Varies..." : "None Selected"}</div>
            )}
            {selection.length > 0 &&
              selection.map((selectionIndex, index) => {
                const disable = disabled || tagsActiveOnOtherWorkflows?.includes(optionsToUse?.[selectionIndex]?.data?._id);

                return (
                  <div className="p-0.5" key={optionsToUse[selectionIndex]?.value ? optionsToUse[selectionIndex]?.value : index}>
                    <Tag
                      tagData={optionsToUse[selectionIndex]}
                      highlight={tagsToHighlight?.includes(optionsToUse?.[selectionIndex]?.data?._id) ?? false}
                      tagsActiveOnOtherWorkflows={tagsActiveOnOtherWorkflows?.includes(optionsToUse?.[selectionIndex]?.data?._id) ?? false}
                    >
                      <div className={`ml-1 hover:text-taskinatorRed ${disable ? "cursor-not-allowed" : "cursor-pointer "}`} onClick={disable ? () => {} : () => selectionHandler(selectionIndex)}>
                        <MdClose />
                      </div>
                    </Tag>
                  </div>
                );
              })}
          </div>
          {inline && (
            <div
              className="border border-taskinatorMedGrey border-l rounded-r bg-taskinatorLightGrey hover:bg-taskinatorDarkGrey hover:text-taskinatorWhite cursor-pointer items-center justify-center flex px-4 text-xs whitespace-nowrap font-medium"
              onClick={onClickHandler}
            >
              {AddButtonText ? AddButtonText : "Add Member"}
            </div>
          )}
        </div>
        <div className={`w-full`}>
          {!inline && (
            <div
              className={`${inputVer === "1" ? "" : "bg-taskinatorLightGrey"} flex ${
                inline ? "" : ""
              } border rounded-b border-taskinatorDarkGrey w-full h-8 items-center text-taskinatorDarkGrey capitalize ${!disabled ? "cursor-pointer" : "cursor-not-allowed"} ${
                !disabled ? `hover:bg-taskinatorBlue hover:border-taskinatorBlue hover:text-taskinatorWhite border-taskinatorMedGrey}` : ""
              }  `}
              onClick={onClickHandler}
            >
              <div className={`text-sm flex justify-center items-center text-taskinatorMedGrey h-6 w-6 ${dropDown ? "rotate-90" : ""}`}>
                <MdPlayArrow />
              </div>
              <div className={`text-xs flex grow justify-center text-center font-semibold`}>{selectionDisplayText}</div>
            </div>
          )}

          {/* //////////drop down starts here */}
          <div className={`${dropDown ? "block" : "hidden"} scrollbar ${modal ? "" : "sticky top-0"} z-50 bg-taskinatorWhite rounded border border-taskinatorMedGrey shadow-lg`}>
            {!notSearchable && (
              <div className="flex items-center mx-2 my-1 bg-taskinatorLightGrey rounded">
                <div className="mx-2 bg-taskinatorLightGrey py-1">
                  <MdSearch />
                </div>
                <div className="w-full">
                  <input
                    onChange={onFilterInputChangeHandler}
                    className="w-full bg-taskinatorLightGrey py-1 focus:outline-none md:text-xs text-base"
                    placeholder={create ? "Add/Find" : "Search"}
                  ></input>
                </div>
              </div>
            )}
            <div className="max-h-[10rem] overflow-y-auto scrollbar">
              {!options || (options && options.length === 0) ? (
                <div className="text-center text-taskinatorMedGrey text-xs py-1">No options available </div>
              ) : (
                options.map((data, index) => {
                  const highlightThis = tagsToHighlight && tagsToHighlight.includes(data.data._id);

                  return (
                    <div
                      key={data?.value ? data?.value : data?.name}
                      className={`text-base md:text-xs flex p-1 ${
                        selection.includes(index)
                          ? "bg-taskinatorBlue text-taskinatorWhite cursor-pointer"
                          : `${data?.data?.locked ? "cursor-not-allowed" : `${highlightThis ? "" : "hover:bg-taskinatorHoverBlue cursor-pointer"}`} `
                      }
                      ${filterValue ? (data?.name?.toLowerCase().includes(filterValue.toLowerCase()) ? "block" : "hidden") : "block"}
                      capitalize justify-start text-center`}
                      onClick={data?.data?.locked ? () => {} : () => selectionHandler(index)}
                    >
                      <div className={`ml-1 flex items-center font-medium capitalize ${data?.data?.locked ? "text-taskinatorMedGrey" : ""} `}>
                        {data?.name} {data?.data?.locked ? "(locked)" : ""} {data?.data?.company && <div className="pl-1 font-light">{StringShortenDisplay(20, `- ${data.data.company}`)}</div>}{" "}
                        {data?.data?.roleTags?.length > 0 ? <div className="pl-1 font-light">{StringShortenDisplay(20, `- ${data?.data?.roleTags[0]?.name}`)}</div> : ""}
                        {highlightThis && <div className={`pl-1 uppercase ${selection.includes(index) ? "text-taskinatorWhite" : "text-taskinatorRed"} `}>(This Task)</div>}
                      </div>
                    </div>
                  );
                })
              )}
            </div>

            {create && (
              <div
                onClick={
                  noItem
                    ? () => {
                        onCreate(filterValue);
                        setSelection(
                          selection.map((index) => {
                            return index + 1;
                          })
                        );
                        setFilterValue("");
                      }
                    : () => {}
                }
                className={`${
                  noItem ? "text-taskinatorBlue cursor-pointer" : "text-taskinatorMedGrey"
                } text-center border-t border-t-taskinatorMedGrey pt-1 mt-1 flex items-center justify-center py-2`}
              >
                <div className="pr-1">
                  <MdOutlineAdd />
                </div>
                <div className="text-sm">{!creating ? `Create a ${create === "item tag" ? "asset tag" : create} "${filterValue}"` : <IconSpinner />}</div>
              </div>
            )}
          </div>
        </div>
      </div>

      {dropDown && <div onClick={onClickHandler} className={`fixed inset-0 z-30  `}></div>}
    </div>
  );
};

export default MultiSelectDropdown;
