import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Button from "../../UI_elements/button";
import { useInit } from "../../hooks/useInit";
import Modal from "../../UI_elements/modal";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../contexts/appContext";

import InputV4 from "../../inputs/input_V4";
import { useInputV4 } from "../../../stateManagement/useInputV4";
import { VALIDATOR_MINLENGTH } from "../../forms/validators/validators";

const AddWorkflowHandler = ({ globalDispatch, buttonLabel, localActive, closeModal }) => {
  const appData = useContext(AppContext);
  const navigate = useNavigate();
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  const [localState, setLocalState] = useState();
  const [localSelected, setLocalSelected] = useState({});
  const { inputState, inputHandler, attachmentHandler } = useInputV4({ inputs: {} }, false);
  const [modal, setModal] = useState();
  const { tid } = useParams();
  const workflowListToUse = globalDispatch ? globalDispatch.workflowList : localState;

  useEffect(() => {
    initStarted(0);
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/workflow/workflows/valid`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        let workflowObject = {};
        response.data.workflows.map((workflow) => {
          workflowObject = { ...workflowObject, [workflow._id]: workflow };
        });

        globalDispatch ? globalDispatch.dispatch({ type: "SET_WORKFLOW_TEMPLATES", workflows: workflowObject }) : setLocalState(workflowObject);

        initCompleted(0);
      })
      .catch((err) => {
        initErrors(err?.response?.data?.message ?? err?.message ?? '', 0);
      });
  }, []);

  const addWorkflowRequest = () => {
    // initStarted(1);
    // axios
    //   .put(
    //     process.env.REACT_APP_BACKEND_URL + `/api/task/addtotask/${tid}`,
    //     {
    //       selectedWorkflows: Object.values(localSelected).map((workflow) => {
    //         return { _id: workflow._id, scope: "", name: workflow.name, managers: workflow.managers };
    //       }),
    //       comments: inputState.inputs.comments.value,
    //     },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       withCredentials: true,
    //     }
    //   )
    //   .then((response) => {
    //     taskContext.getActionData();
    //     // response.data.newTracers.map((tracer) => {
    //     //   appData.ADD_TASK(tracer);
    //     // });
    //     setLocalSelected({});
    //     setModal();
    //     initCompleted(1);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     initErrors(err?.response?.data?.message ?? err?.message ?? '', 1);
    //   });
  };

  const OneSelected = Object.keys(localSelected).length > 0;

  const localSelectionHandler = (workflow) => {
    if (localSelected[workflow._id]) {
      const currentLocal = localSelected;
      delete currentLocal[workflow._id];
      setLocalSelected({ ...currentLocal });
    } else {
      setLocalSelected({ ...localSelected, [workflow._id]: workflow });
    }
  };

  return (
    <>
      <Button
        label={buttonLabel ? buttonLabel : "Select Sequence"}
        customClasses={"border-taskinatorGreen border text-taskinatorGreen  hover:text-taskinatorWhite hover:bg-taskinatorGreen  text-xs  mx-1 py-2 "}
        onClick={() => setModal(true)}
        isLoading={initState[0].started}
      />
      {modal && (
        <Modal local={() => setModal()} title="Select Sequence">
          <div className="w-full md:w-96 flex flex-col overflow-auto max-h-96 mt-2">
            {Object.values(workflowListToUse).length === 0 ? (
              <div className="text-center text-taskinatorRed text-sm">
                No Available Sequences{" "}
                <div
                  onClick={() => {
                    setModal();
                    navigate("/manage/workflows");
                    closeModal();
                  }}
                  className="bg-taskinatorBlue py-1 px-2 rounded hover:opacity-75 cursor-pointer text-taskinatorWhite "
                >
                  Create First Sequence
                </div>{" "}
              </div>
            ) : (
              <div className="border border-taskinatorMedGrey rounded-lg bg-taskinatorLightGrey ">
                <div className="flex flex-col max-h-[12rem] overflow-auto rounded-lg scrollbar">
                  {Object.values(workflowListToUse).map((workflow, index) => {
                    return (
                      <div
                        key={workflow._id}
                        className={`${globalDispatch && globalDispatch.selectedWorkflowIds.includes(workflow._id) ? "hidden" : "flex"} ${
                          localActive && localActive.includes(workflow._id) ? "hidden" : "flex"
                        }  bg-clip-border  ${
                          localSelected[workflow._id] ? "bg-taskinatorBlue text-taskinatorWhite" : "hover:bg-taskinatorHoverBlue bg-taskinatorWhite"
                        } cursor-pointer mb-0.5  px-2 h-10 items-center  text-sm capitalize`}
                        onClick={
                          globalDispatch
                            ? () => globalDispatch.addWorkflowHandler({ _id: workflow._id, name: workflow.name, managers: workflow.managers })
                            : () => {
                                localSelectionHandler(workflow);
                              }
                        }
                      >
                        - {workflow.name}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {OneSelected && (
              <div className="my-2">
                <InputV4
                  onInput={inputHandler}
                  id="comments"
                  sid="inputs"
                  validators={[VALIDATOR_MINLENGTH(6)]}
                  textareaRows={4}
                  label="Comments For Sequence"
                  placeholder={"Detail creation notes for this sequence"}
                />
                <Button
                  onClick={() => {
                    addWorkflowRequest();
                  }}
                  label="Add Sequence/s"
                  customClasses={` ${inputState.isValid ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"} text-taskinatorWhite h-10 w-full mt-2 `}
                  disabled={!OneSelected && !inputState.isValid}
                  isLoading={initState[1].started}
                  error={initState[1].error}
                />
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default AddWorkflowHandler;
