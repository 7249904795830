import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { AppContext } from "../../contexts/appContext";
import { useInit } from "../hooks/useInit";
import { useInputV4 } from "../../stateManagement/useInputV4";

import Input_V4 from "../inputs/input_V4";
import RoleSelector from "../dataSelectors/roleSelector";
import TagSelector from "../dataSelectors/tagSelector";
import Button from "../UI_elements/button";
import LiveDataCheckOnInput from "../inputs/liveDataCheckOnInput";
import { VALIDATOR_MINLENGTH, VALIDATOR_EMAIL } from "./validators/validators";
import IconSpinner from "../UI_elements/iconSpinner";

const AddThirdParty = ({ user, onResponse }) => {
  const appData = useContext(AppContext);
  const { inputState, inputHandler } = useInputV4({ inputs: {}, isValid: false });
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(4);

  const submitHandler = (event) => {
    event.preventDefault();

    let data = {
      firstName: inputState.inputs.firstName.value,
      lastName: inputState.inputs.lastName.value,
      email: inputState.inputs.email.value,
      company: inputState.inputs.company.value,
    };

    if (user) {
      data = { ...data, userId: user._id };
    }

    initStarted(0);
    axios
      .put(process.env.REACT_APP_BACKEND_URL + `/api/thirdparty/${user ? "edit" : "add"}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token ",
        },
        withCredentials: true,
      })
      .then((response) => {
        initCompleted(0);
        onResponse();
      })
      .catch((err) => {
        console.log(err);
        const errorMessage = err?.response?.data?.message ?? err?.message ?? '';

        return initErrors(errorMessage, 0);
      });
  };


  const deactivateHandler = () => {
    initStarted(1);

    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/thirdparty/deactivate`, // this should change to workspaceDataId
        {
        userId: user._id,
          lock: !user.locked,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        onResponse();
        initCompleted(1);
      })
      .catch((err) => {
        console.log(err)
        const errorMessage = err?.response?.data?.message ?? err?.message ?? '';
    
        return initErrors(errorMessage, 1);
      });
  };

  return (
    <form className="md:w-96 w-full " onSubmit={submitHandler}>
      {/* <div className=" font-semibold w-full text-center text-lg text-taskinatorDarkGrey">{user ? "Edit Member" : "Invite People"}</div> */}

      <Input_V4
        label="First Name"
        sid="inputs"
        id="firstName"
        customClasses={"capitalize"}
        placeholder={`First Name...`}
        defaultValue={user ? user.firstName : null}
        onInput={inputHandler}
        validators={[VALIDATOR_MINLENGTH(3)]}
      />

      <Input_V4
        label="Last Name"
        sid="inputs"
        id="lastName"
        customClasses={"capitalize"}
        placeholder={`Last Name...`}
        defaultValue={user ? user.lastName : null}
        onInput={inputHandler}
        validators={[VALIDATOR_MINLENGTH(3)]}
      />

      <Input_V4 label="Email" sid="inputs" id="email" placeholder={`Email`} onInput={inputHandler} validators={[VALIDATOR_EMAIL]} defaultValue={user ? user.email : null} />

      <Input_V4 label="Company" sid="inputs" id="company" placeholder={`Company`} onInput={inputHandler} validators={[VALIDATOR_MINLENGTH(3)]} defaultValue={user ? user.company : null} />

      {user && (
        <Button
          label={!user.locked ? "Lock User" : "Unlock User"}
          customClasses={`${!user.locked ? "border-taskinatorRed text-taskinatorRed" : "border-taskinatorDarkGrey text-taskinatorDarkGrey"} border  rounded  h-8 w-full mt-2 mb-2 `}
          isLoading={initState[1].started}
          onClick={() => deactivateHandler()}
          error={initState[1].error}
        />
      )}
      <Button
        type="submit"
        label={user ? "Save" : "Add"}
        customClasses={`${inputState.isValid ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"} bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-2 `}
        disabled={!inputState.isValid}
        isLoading={initState[0].started}
        error={initState[0].error}
      />
    </form>
  );
};

export default AddThirdParty;
