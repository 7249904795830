import axios from "axios";
import { Client } from "@microsoft/microsoft-graph-client";



export const UploadFiles = async ({ fileName, fileKey, type, SetUploadPercentage }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.put(
        process.env.REACT_APP_BACKEND_URL + `/api/attachment/attach/upload`,
        {
          fileName,
          fileKey,
          type,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      console.log(response);
      resolve(response.data);
    } catch (err) {
      reject();
    }
  });
};

export const GetAWSSignedObj = async ({ fileName }) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/attachment/attach/sign`,
        {
          fileName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(false);
      });
  });
};

export const UploadFileIntoAWS = async ({ uploadUrl, attachment }) => {
  const options = {
    headers: {
      "Content-Type": attachment.type,
      "Content-Disposition": "inline",
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .put(uploadUrl, attachment, options)
      .then((responseData) => {
        resolve(true);
      })
      .catch((err) => {
        resolve(false);
      });
  });
};

export const GetGoogleAccessToken = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/attachment/attach/google`, {
        headers: {
          "Content-Type": "application/json",
        },

        withCredentials: true,
      })
      .then((responseData) => {
        resolve(responseData.data.accessToken);
      })
      .catch((err) => {
        resolve(false);
      });
  });
};

export const CreateFolderIntoGoogleDrive = async ({ folderName, folderId = "", accessToken }) => {
  const folderMetadata = {
    name: folderName,
    mimeType: "application/vnd.google-apps.folder",
    parents: [folderId],
  };
  return new Promise((resolve, reject) => {
    axios
      .post("https://www.googleapis.com/drive/v3/files", folderMetadata, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        resolve({ folderId: response.data.id, folderName });
      })
      .catch((error) => {
        resolve(false);
      });
  });
};

export const UploadFileIntoGoogleDrive = async ({ attachment, folderId, accessToken }) => {
  const metadata = {
    name: attachment.name, // Filename at Google Drive
    mimeType: attachment.type, // mimeType at Google Drive
    parents: [folderId], // Folder ID at Google Drive
  };

  const formData = new FormData();
  formData.append("metadata", new Blob([JSON.stringify(metadata)], { type: "application/json" }));
  formData.append("attachment", attachment);

  return new Promise((resolve, reject) => {
    axios
      .post("https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id", formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/related",
        },
      })
      .then((res) => {
        resolve(res.data.id);
      })
      .catch((err) => {
        resolve(false);
      });
  });
};

export const GetMicrosoftAccessToken = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/attachment/attach/microsoft`, {
        headers: {
          "Content-Type": "application/json",
        },

        withCredentials: true,
      })
      .then((responseData) => {
        resolve(responseData.data.accessToken);
      })
      .catch((err) => {
        resolve(false);
      });
  });
};

export const UploadFileIntoOneDrive = async ({ file, folderName, accessToken }) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", file);
    axios
      .put(`https://graph.microsoft.com/v1.0/me/drive/root:/${folderName}/${file.name}:/content`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        resolve(res.data.id);
      })
      .catch((err) => {
        resolve(false);
      });
  });
};

export const DeleteFileFromOneDrive = async ({ accessToken, fileId }) => {
  return new Promise((resolve, reject) => {
    const client = Client.init({
      authProvider: (done) => {
        done(null, accessToken);
      },
    });

    client
      .api(`/me/drive/items/${fileId}`)
      .delete()
      .then((res) => {
        resolve(res.data.id);
      })
      .catch((err) => {
        resolve(false);
      });
  });
};
