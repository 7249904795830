import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export async function ConvertPdfToImage(file) {
  if (!file) throw new Error("No file provided");

  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = async (event) => {
      try {
        const typedArray = new Uint8Array(event.target.result);
        const pdfDoc = await pdfjs.getDocument({ data: typedArray }).promise;
        const page = await pdfDoc.getPage(1);
        const viewport = page.getViewport({ scale: 2 });
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        await page.render({ canvasContext: context, viewport: viewport }).promise;
        const dataUrl = canvas.toDataURL("image/png");

        // Trigger download
        const link = document.createElement("a");
        link.href = dataUrl;
        // link.download = "converted-image.png";
        // link.click();

        resolve(dataUrl);
      } catch (error) {
        console.error("Error converting PDF to image:", error);
        reject(error);
      }
    };

    fileReader.onerror = () => {
      reject(new Error("Error reading PDF file"));
    };

    fileReader.readAsArrayBuffer(file);
  });
}
