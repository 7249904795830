import React from "react";
import IconSpinner from "../UI_elements/iconSpinner";
import ColumnItemElement from "./columnItemElement";
import ColumnNotificationElement from "./columnNotificationElement";
import ColumnRequestElement from "./columnRequestElement";

const ColumnElement = ({
  primaryColour,
  faintColour,
  primaryBorder,
  label,
  jobData,
  type,
  getData,
  textColour,
  initState,
  project,
  row,
  opacity,
}) => {
  let totalElements = 0;

  jobData.forEach((obj) => {
    if (obj.elementData && typeof obj.elementData === "object") {
      totalElements += Object.keys(obj.elementData).length;
    }
  });

  return (
    <div className="flex flex-col h-full">
      <div
        className={`${primaryColour} shadow mt-0.5 px-4 py-3 rounded-lg flex items-center`}
      >
        <div className="text-taskinatorWhite font-semibold uppercase text-base">
          {label}
        </div>
        <div className="text-base text-taskinatorWhite font-bold pl-2">
          ({totalElements})
        </div>
      </div>
      <div
        className={`${faintColour} shadow mb-0.5 mt-1 rounded-lg px-1 py-1 grow border-taskinatorMedGrey border overflow-auto scrollbar flex ${
          row ? "md:flex-row flex-col" : "flex-col"
        }`}
      >
        <div className=""></div>
        {initState[0].started && !initState[0].initialised && (
          <div className="w-full items-center justify-center flex text-xs text-taskiantorMedGrey my-2">
            <IconSpinner />
          </div>
        )}
        {initState[0].error && (
          <div className="w-full items-center justify-center flex text-xs text-taskinatorRed my-2">
            Error getting data
          </div>
        )}
        {initState[0].initialised &&
          !initState[0].error &&
          jobData.length === 0 && (
            <div className="w-full items-center justify-center flex text-xs text-taskiantorMedGrey my-2">
              Nothing Here For You...
            </div>
          )}
        {initState[0].initialised &&
          !project &&
          jobData.length > 0 &&
          jobData.map((job, index) => {
            return (
              <div key={job._id ?? index} className="">
                {/* mb-2 border p-1 border-dashed border-taskinatorMedGrey rounded  */}
                <div className="flex items-end text-taskinatorDarkGrey">
                  {/* <div className="text-sm pr-1">Job:</div>
                  <div className="font-semibold text-sm">{StringShortenDisplay(20, job.ref)}</div> */}
                  <div className="flex grow justify-end ">
                    {/* <div
                      onClick={() => navigate(`/view/job/${job.tid}`, { state: { from: "all" } })}
                      className="border-taskinatorDarkGrey  text-taskinatorDarkGrey rounded py-1 px-2 font-semibold underline hover:text-taskinatorBlue cursor-pointer text-xs"
                    >
                      View Job
                    </div>{" "} */}
                  </div>
                </div>
                {job.elementData &&
                  Object.values(job.elementData).map((element) => {
                    if (type === "item") {
                      const isManaged =
                        element.isManaged ||
                        element.isWatched ||
                        element.taskManged;
                      return (
                        <div key={element._id}>
                          <ColumnItemElement
                            primaryColour={
                              isManaged
                                ? "bg-taskinatorManagedGrey"
                                : primaryColour
                            }
                            primaryBorder={
                              isManaged
                                ? "border-l-taskinatorManagedGrey"
                                : primaryBorder
                            }
                            data={element}
                            jobData={job}
                            getData={getData}
                            textColour={
                              isManaged
                                ? "border-taskinatorManagedGrey"
                                : textColour
                            }
                            opacity={isManaged ? "opacity-75" : null}
                          />
                        </div>
                      );
                    } else if (type === "request") {
                      return (
                        <div key={element._id}>
                          {" "}
                          <ColumnRequestElement
                            key={element._id}
                            primaryBorder={primaryBorder}
                            data={element}
                            getData={getData}
                          />{" "}
                        </div>
                      );
                    } else if (type === "notification") {
                      return (
                        <div key={element._id}>
                          <ColumnNotificationElement
                            key={element._id}
                            primaryBorder={primaryBorder}
                            data={element}
                            jobData={job}
                            getData={getData}
                          />{" "}
                        </div>
                      );
                    }
                  })}
              </div>
            );
          })}
        {/* 
        {initState[0].initialised &&
          project &&
          jobData.length > 0 &&
          jobData.map((project) => {
            return (
              // <div className="pr-2 md:w-96 md:min-w-96 shrink-0 w-full">
              <ColumnProjectElement key={project.key} primaryBorder={primaryBorder} data={project} getData={getData} row />
              // </div>
            );
          })} */}
      </div>
    </div>
  );
};

export default ColumnElement;
