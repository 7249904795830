import React, { useContext } from "react";
import { AppContext } from "../../contexts/appContext";
import DropDownSelector_V4 from "../inputs/dropDownSelector_V4";

const RolesSelector = ({inputVer, inputHandler, defaultValue, sid, disabled }) => {
  const appData = useContext(AppContext);
  let rolesOptions = [
  
    { type: "", name: "Admin", value: process.env.REACT_APP_ACCESS_A },
    { type: "", name: "Manager", value: process.env.REACT_APP_ACCESS_M },
    { type: "", name: "Team", value: process.env.REACT_APP_ACCESS_T },
    { type: "", name: "Guest", value: process.env.REACT_APP_ACCESS_G },
    // { type: "", name: "Dashboard", value: process.env.REACT_APP_ACCESS_D },
  ];

  if (appData.workspaceDataV4.role === process.env.REACT_APP_ACCESS_O) {
    rolesOptions = [{ type: "", name: "Owner", value: process.env.REACT_APP_ACCESS_O }, ...rolesOptions];
  }

  return (
    <DropDownSelector_V4
      color={"border-taskinatorMedGrey text-taskinatorDarkGrey font-semibold"}
      selectionColor={"text-taskinatorBlue uppercase"}
      label="Role"
      id={"role"}
      notSearchable
      type="SEARCH"
      sid={sid}
      defaultValue={defaultValue}
      options={rolesOptions}
      onSelection={inputHandler}
      disabled={disabled}
      selectionDisplayText={"Select Role"}
      inputVer={inputVer}
      
    />
  );
};

export default RolesSelector;
