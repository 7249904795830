import axios from 'axios'
import React, {
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react'
import { MdCheck } from 'react-icons/md'
import { AppContext } from '../../contexts/appContext'
import { TaskContext } from '../../contexts/taskContext'
import { useInputV4 } from '../../stateManagement/useInputV4'
import Button from '../UI_elements/button'
import IconSpinner from '../UI_elements/iconSpinner'
import UserDisplayBubble from '../UI_elements/userDisplayBubble'
import NextWorkflowDisplayAndSelect from '../displayElements/workflow/nextWorkflowDisplayAndSelect'
import { useInit } from '../hooks/useInit'
import FileUploaderV4 from '../inputs/fileUploader_V4'
import InputV4 from '../inputs/input_V4'
import { VALIDATOR_MINLENGTH } from './validators/validators'

const submissionStateReducer = (state, action) => {
  let currentSetWorkflowState = state

  switch (action.type) {
    case 'ADD_WORKFLOW':
      delete currentSetWorkflowState['close']
      return {
        ...currentSetWorkflowState,
        [action.workflow._id]: {
          wfid: action.workflow._id,
          managers: [],
          autoSelect: action.autoSelect,
        },
      }

    case 'DELETE_WORKFLOW':
      delete currentSetWorkflowState[action.workflow._id]
      delete currentSetWorkflowState['close']
      return { ...currentSetWorkflowState }

    case 'CLOSE_WORKFLOW':
      if (currentSetWorkflowState.close) {
        return {}
      } else {
        return { ['close']: 'close' }
      }

    case 'MANAGER_CHANGE':
      return {
        ...currentSetWorkflowState,
        [action.wfid]: {
          ...currentSetWorkflowState[action.wfid],
          managers: action.managers,
        },
      }

    default:
      return state
  }
}

const SubmitFormV4 = ({
  tracer,
  next,
  back,
  workflowNext,
  approvedStatus,
  rejectedStatus,
  onSubmitSuccess,
  item,
  taskDetails,
}) => {
  const appData = useContext(AppContext)
  const taskContext = useContext(TaskContext)
  const { inputState, inputHandler, attachmentHandler } = useInputV4(
    { inputs: {}, attachments: {} },
    false
  )
  const { initState, initStarted, initCompleted, initErrors, initReset } =
    useInit(2)

  const [otherActive, setOtherActive] = useState()

  const [nextWorkflows, setNextWorkflows] = useReducer(
    submissionStateReducer,
    {}
  )

  const atleastOneWorkflowSelected = workflowNext
    ? Object.values(nextWorkflows).length > 0
      ? true
      : false
    : true

  useEffect(() => {
    initStarted(1)
    axios
      .get(
        process.env.REACT_APP_BACKEND_URL +
          `/api/tracer/otheractiveworkflows/${tracer.tid}/${tracer.tracerWorkflowGroupId}/${tracer._id}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        setOtherActive(response.data)
        initCompleted(1)
      })
      .catch((err) => {
        console.log(err)
        initErrors(err?.response?.data?.message ?? err?.message ?? '', 1)
      })
  }, [tracer])

  const { elementData, ...remaindingDetails } = taskDetails

  const SubmitHandler = () => {
    initStarted(0)

    let url

    if (workflowNext && approvedStatus) {
      url = `/api/tracer/close/${tracer._id}/${tracer.tracerWorkflowGroupId}/${taskDetails.tid}`
    } else {
      url = `/api/tracer/submit/${tracer._id}/${tracer.tracerStageId}/${taskDetails.tid}/${tracer.tracerWorkflowGroupId}/${tracer.wfid._id}`
    }

    const closeTask =
      nextWorkflows[`close`] && (otherActive?.isOther ?? '') === false
        ? true
        : false

    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + url,
        {
          nextSteps: workflowNext
            ? nextWorkflows[`close`]
              ? []
              : Object.values(nextWorkflows)
            : next,
          backSteps: back,
          approvedStatus: approvedStatus,
          rejectedStatus: rejectedStatus,
          comments: inputState.inputs.comments.value,
          attachments: Object.values(inputState.attachments).map(
            (attachment) => {
              return {
                key: attachment.value.key,
                fileName: attachment.value.fileName,
              }
            }
          ),
          closeTask: closeTask,
          aid: tracer.aid,
          wfid: tracer.wfid,
          itemsOnTracer: item,
          note: tracer.note,
          taskDetails: remaindingDetails,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        // taskContext.getActionData();
        // appData.getWorkspaceData();

        if (closeTask && appData.workspaceDataV4.isTrial) {
          // appData.setViewAction.getActionData();
        }

        initCompleted(0)
        onSubmitSuccess()
      })
      .catch((err) => {
        console.log(err)
        return initErrors(err?.response?.data?.message ?? err?.message ?? '', 0)
      })
  }

  const validInput = rejectedStatus
    ? inputState.isValid
    : approvedStatus && workflowNext
    ? inputState.isValid && atleastOneWorkflowSelected && !initState[1].started
    : inputState.isValid
  let checkForError = false

  if (rejectedStatus && back.length === 0) {
    checkForError = true
  } else if (approvedStatus && !workflowNext && next.length === 0) {
    checkForError = true
  }

  if (!checkForError) {
    return (
      <div className='flex flex-col md:w-96  justify-center md:mt-0  mt-10'>
        {approvedStatus && !workflowNext && (
          <div className=' text-sm flex flex-col pb-4 items-center font-medium '>
            <div className='uppercase'>Next Task Activated:</div>
            <div className='flex flex-wrap'>
              {next.map((next) => {
                return (
                  <div className='mr-1 mb-1 border py-1 px-2 border-taskinatorGreen   rounded flex items-center justify-center'>
                    <div className='text-taskinatorGreen font-semibold pr-1 border-r border-r-taskinatorGreen mr-1'>
                      {next.name}{' '}
                    </div>
                    <div className='font-normal pr-1 '> Assignee/s:</div>
                    <div>
                      {next.assignee.map((user) => {
                        return <UserDisplayBubble user={user} />
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
        {rejectedStatus && (
          <div className=' text-sm flex flex-col pb-4 items-center font-medium '>
            <div className='uppercase'> Reject Back To: </div>
            <div className='flex flex-wrap'>
              {back.map((back) => {
                return (
                  <div className='mr-1 mb-1 border py-1 px-2 border-taskinatorDarkGrey  rounded flex items-center justify-center'>
                    <div className='text-taskinatorDarkGrey  font-semibold pr-1 border-r border-r-taskinatorDarkGrey mr-1'>
                      {back.name}{' '}
                    </div>
                    <div className='font-normal pr-1 '> Assignee/s:</div>
                    <div>
                      {back.assignee.map((user) => {
                        return <UserDisplayBubble user={user} />
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
        {approvedStatus && workflowNext && (
          <div className='py-2'>
            {Object.keys(next).length > 0 && (
              <div className='text-sm py-2 rounded-t border-b-0 font-semibold text-center flex w-full border-2 justify-center text-taskinatorGreen border-taskiantorGreen'>
                Close Workflow And Activate/Notify:
              </div>
            )}
            {next.map((workflow) => {
              if (workflow !== true)
                return (
                  <NextWorkflowDisplayAndSelect
                    workflow={workflow}
                    onSelect={setNextWorkflows}
                    isSelected={nextWorkflows[workflow._id]}
                  />
                )
            })}
            {Object.keys(next).length > 0 && (
              <div className='text-center '>or</div>
            )}
            {(otherActive?.isOther ?? '') === undefined && (
              <div className='flex justify-center'>
                <IconSpinner /> Checking task details...
              </div>
            )}
            {(otherActive?.isOther ?? '') !== undefined && (
              <div
                onClick={() => setNextWorkflows({ type: 'CLOSE_WORKFLOW' })}
                className={` ${
                  nextWorkflows.close
                    ? ' text-taskinatorWhite bg-taskinatorRed hover:opacity-75'
                    : 'text-taskinatorRed hover:bg-taskinatorRed hover:text-taskinatorWhite'
                } font-semibold my-1 p-2 border border-taskinatorRed  rounded p-1  cursor-pointer text-sm text-center flex items-center justify-center`}
              >
                {`Close Workflow ${
                  otherActive?.isOther ?? '' ? '' : ' and End Job'
                }`}
                <div className='pl-1'>
                  <MdCheck size={'1rem'} />{' '}
                </div>
              </div>
            )}
          </div>
        )}

        <InputV4
          sid={'inputs'}
          id='comments'
          validityMessage={'Minimum 6 chars required'}
          validators={
            rejectedStatus || workflowNext || tracer.aid.thirdParty
              ? [VALIDATOR_MINLENGTH(3)]
              : []
          }
          onInput={inputHandler}
          textareaRows={5}
          placeholder={
            'Enter any details that might be helpful or members should be aware of in the next task...'
          }
          label='Submission Comments'
        />

        <div className='mt-2'>
          <FileUploaderV4
            label='Submission Attachments'
            attachmentHandler={attachmentHandler}
          />
        </div>

        <Button
          label={`Submit`}
          customClasses={`${
            validInput ? 'bg-taskinatorBlue' : 'bg-taskinatorMedGrey'
          } bg-taskinatorBlue text-sm text-taskinatorWhite h-10 grow w-full mt-4 `}
          disabled={!validInput && !checkForError}
          isLoading={initState[0].started}
          error={initState[0].error}
          onClick={() => SubmitHandler()}
        />
      </div>
    )
  } else {
    return (
      <div className='text-taskinatorRed text-sm text-center animate-pulse py-4'>
        Error Loading... Please refresh window
      </div>
    )
  }
}

export default SubmitFormV4
