import React, { useState, useContext } from "react";
import Button from "../../UI_elements/button";
import { useInit } from "../../hooks/useInit";
import Modal from "../../UI_elements/modal";
import CreateFile from "./createFile";
import { JobModalContext } from "../jobs/jobModalContext";
import { AppContext } from "../../../contexts/appContext";
import BulkFileUpload from "./bulkFileUpload";

const FileModifierButtons = ({ file, horizontal, noEdit, fullColour, inOtherWorkflow, workflowGroupId, workflowId, fileSetId, taskData, workflowName, taskTags }) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(3);
  const [editFile, setEditFile] = useState();
  const [add, setAdd] = useState();
  const [newRevDetails, setNewRevDetails] = useState();

  const jobModalContext = useContext(JobModalContext);
  const jobId = jobModalContext?.jobId;



  const canEdit = !inOtherWorkflow;
  const mustEdit = file.status === "pending";

  const addNewMarkup = "Add Mark Up";
  const addNewRevision = "Add New Revision";
  return (
    <>
      <div className={`flex ${horizontal ? "items-center justify-end" : "flex-col"} w-full `}>
        {/* {canEdit && !noEdit && (
          <Button
            onClick={() => {
              setEditFile(true);
            }}
            label={"Edit File"}
            customClasses={"border-taskinatorMedGrey border text-taskinatorMedGrey h-8 px-2 hover:bg-taskinatorMedGrey hover:text-taskinatorWhite"}
          />
        )} */}

        {file.status !== "pending" && (
          <Button
            onClick={() => {
              setAdd(addNewRevision);
            }}
            label={addNewRevision}
            customClasses={`border-taskinatorGreen hover:bg-taskinatorGreen hover:text-taskinatorWhite  text-taskinatorGreen border h-8 w-full ${horizontal ? "ml-1" : "mt-2"} `}
            isLoading={initState[0].started}
          />
        )}

        {file.status !== "pending" && file.fileExtension === ".pdf" && (
          <Button
            onClick={() => {
              setAdd(addNewMarkup);
            }}
            label={addNewMarkup}
            customClasses={`border-taskinatorPurple  hover:bg-taskinatorPurple hover:text-taskinatorWhite text-taskinatorPurple border h-8 w-full ${horizontal ? "ml-1" : "mt-2"} `}
            isLoading={initState[0].started}
          />
        )}
      </div>

      {editFile && (
        <Modal
          size="medium"
          local={() => {
            setEditFile();
          }}
          title="Edit File Details"
        >
          <div className=" overflow-auto h-fit scrollbar">
            <CreateFile
              file={file}
              currentFileSetId={file}
              titleless
              onResponse={() => {
                setEditFile();
              }}
              inOtherWorkflow={inOtherWorkflow}
              taskData={taskData}
              workflowGroupId={workflowGroupId}
              fileSetId={fileSetId}
              taskTags={taskTags}
            />
          </div>
        </Modal>
      )}

      {add && (
        <Modal
          size="medium"
          local={() => {
            setAdd();
          }}
          title={<div className="text-base">{`${add} to ${decodeURIComponent(file.fileName)}${file.fileExtension}`}</div>}
        >
          {!newRevDetails && (
            <BulkFileUpload
              onFilesUploaded={() => {}}
              SaveLocally
              limit={1}
              autoTrigger={
                add === addNewRevision
                  ? (file) => setNewRevDetails(file)
                  : () => {
                      setAdd();
                    }
              }
              markup={add === addNewMarkup ? file._id : false}
              revision={add === addNewRevision ? { fileId: file._id, revisionTrackingId: file.revisionTrackingId } : false}
              jobData={{ _id: jobId, taskDetails: taskData, taskId: taskData._id, workflowData: { _id: workflowId, workflowGroupId: workflowGroupId, name: workflowName } }}
              fileSetId={fileSetId}
            />
          )}

          {newRevDetails && (
            <CreateFile
              file={newRevDetails[0]}
              currentFileSetId={newRevDetails[0]}
              titleless
              onResponse={() => {
                setAdd();
                setNewRevDetails();
              }}
              inOtherWorkflow={inOtherWorkflow}
              taskData={taskData}
              workflowGroupId={workflowGroupId}
              fileSetId={fileSetId}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default FileModifierButtons;
