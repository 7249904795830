import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../components/UI_elements/button";
import { AppContext } from "../../contexts/appContext";

const DRIVE_OPTIONS = {
  aws: "AWS",
  google: "Google Drive",
  microsoft: "Micrsoft Onedrive",
};

let previousOption = "";

const DriveSettings = ({ settings, currentDriveOption }) => {
  const appData = useContext(AppContext);
  const [selectedOption, setSelectedOption] = useState(currentDriveOption);
  const location = useLocation();
  const [driveStatus, setDriveStatus] = useState(undefined);

  const handleOptionChange = (event) => {
    previousOption = selectedOption;
    setSelectedOption(event.target.value);
  };

  const userGoogleLogin = () => {
    const left = window.innerWidth / 2 - 200;
    const top = window.innerHeight / 2 - 300;
    window.open(
      "http://localhost:5000/api/auth/google",
      "_blank",
      `toolbar=no, scrollbars=yes, resizable=no, width=400, height=600, left=${left}, top=${top}`
    );
  };

  const userMicrosoftLogin = () => {
    const left = window.innerWidth / 2 - 200;
    const top = window.innerHeight / 2 - 300;
    window.open(
      "http://localhost:5000/api/auth/microsoft",
      "_blank",
      `toolbar=no, scrollbars=yes, resizable=no, width=400, height=600, left=${left}, top=${top}`
    );
  };

  useEffect(() => {
    if (
      selectedOption === "google" &&
      appData.workspaceDataV4.driveOption !== "google"
    )
      userGoogleLogin();
    if (
      selectedOption === "microsoft" &&
      appData.workspaceDataV4.driveOption !== "microsoft"
    )
      userMicrosoftLogin();
  }, [selectedOption, appData.workspaceDataV4]);

  useEffect(() => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const status = queryParams.get("status");
      if (status) {
        window.opener.postMessage(status, window.location.origin);
        window.close();
      }
    }
  }, [location]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin === window.location.origin) {
        setDriveStatus(event.data);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (driveStatus === "success") {
      appData.getWorkspaceData();
      toast.success(
        `Successfully setuped with ${DRIVE_OPTIONS[selectedOption]}`,
        {
          position: "top-center",
          hideProgressBar: true,
        }
      );
    }
    if (driveStatus === "failed") {
      toast.error(`Failed setup with ${DRIVE_OPTIONS[selectedOption]}`, {
        position: "top-center",
        hideProgressBar: true,
      });
      setSelectedOption(previousOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driveStatus]);

  return (
    <div>
      <div className="font-semibold text-sm">Select storage option</div>
      <div className="w-full flex flex-col items-start pt-2 text-sm gap-2 border-t border-taskinatorMedGrey mt-2">
        <label className="text-taskinatorDarkGrey text-xs font-medium flex items-center gap-1 hover:cursor-pointer">
          <input
            type="radio"
            value="aws"
            checked={selectedOption === "aws"}
            onChange={handleOptionChange}
          />
          AWS
        </label>
        <label className="text-taskinatorDarkGrey text-xs font-medium flex items-center gap-1 hover:cursor-pointer">
          <input
            type="radio"
            value="google"
            checked={selectedOption === "google"}
            onChange={handleOptionChange}
          />
          Google Drive
        </label>
        <label className="text-taskinatorDarkGrey text-xs font-medium flex items-center gap-1 hover:cursor-pointer">
          <input
            type="radio"
            value="microsoft"
            checked={selectedOption === "microsoft"}
            onChange={handleOptionChange}
          />
          Microsoft Onedrive
        </label>
      </div>
      <div className="pt-4">
        <Button
          label={"Save Changes"}
          customClasses={
            "bg-taskinatorBlue h-8 py-2 text-taskinatorWhite text-xs"
          }
        />
      </div>
      <div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default DriveSettings;
