import axios from "axios";
import React, { useContext, useState } from "react";
import { MdFolderZip, MdImage, MdPictureAsPdf } from "react-icons/md";
import { useParams } from "react-router-dom";
import { AppContext } from "../../contexts/appContext";
import PDFViewer from "../../utils/adobePdfViewer";
import { useInit } from "../hooks/useInit";
import IconSpinner from "./iconSpinner";
import Modal from "./modal";
import StringShortenDisplay from "./stringShortenDisplay";

const DocumentDisplay = ({
  attachment,
  colorChange,
  maxWidth,
  noName,
  notClickable,
  itemId,
  thirdParty,
  markup,
  item,
}) => {
  const appData = useContext(AppContext);
  const { initState, initStarted, initCompleted, initErrors, initReset } =
    useInit(3);
  const { accessCode, accessCode2, id, accessCode3 } = useParams();
  const [modal, setModal] = useState();
  const [savedMarkupURL, setSavedMarkupURL] = useState();

  const regex = /\.([^.]+)$/;
  const extension = (attachment.fileName ?? "").match(regex);

  const getFileFromGoogle = async (fileId, accessToken, responseType) => {
    const response = await axios({
      method: "get",
      url: `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType,
    });

    return response.data;
  };

  const getFileFromMicrosoft = async (fileId, accessToken, responseType) => {
    console.log({ fileId, accessToken, responseType });
    const response = await axios({
      method: "get",
      url: `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}/content`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType,
    });

    return response.data;
  };

  const attachmentClickHandler = async (action) => {
    initStarted(1);
    switch (appData.workspaceDataV4.driveOption) {
      case "aws":
        const url = thirdParty
          ? `/api/tp/attachment/download/${accessCode}/${accessCode2}/${id}/${accessCode3}/${attachment.key}/${attachment.fileName}`
          : `/api/attachment/download/${attachment.key}/${attachment.fileName}/${itemId}`;
        try {
          const responseData = await axios.get(
            process.env.REACT_APP_BACKEND_URL + url,
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
            }
          );

          if (markup) {
            setModal(responseData.data.signedURL);
          } else {
            window.open(
              `${responseData.data.signedURL}`,
              `_blank`,
              `noopener, noreferrer`
            );
          }
          initCompleted(1);
        } catch (err) {
          console.log(err);
          return initErrors(
            err?.response?.data?.message ?? err?.message ?? "",
            1
          );
        }

        break;

      case "google":
        try {
          const response = await axios.get(
            process.env.REACT_APP_BACKEND_URL + `/api/attachment/attach/google`,
            {
              headers: {
                "Content-Type": "application/json",
              },

              withCredentials: true,
            }
          );

          if (markup) {
            const data = await getFileFromGoogle(
              attachment.key,
              response.data.accessToken,
              "arraybuffer"
            );
            setModal(data);
          } else {
            const data = await getFileFromGoogle(
              attachment.key,
              response.data.accessToken,
              "blob"
            );

            const url = URL.createObjectURL(data);
            window.open(`${url}`, `_blank`, `noopener, noreferrer`);
          }
          initCompleted(1);
        } catch (error) {
          console.log(error);
          return initErrors(
            error?.response?.data?.message ?? error?.message ?? "",
            1
          );
        }
        break;

      case "microsoft":
        try {
          const response = await axios.get(
            process.env.REACT_APP_BACKEND_URL +
              `/api/attachment/attach/microsoft`,
            {
              headers: {
                "Content-Type": "application/json",
              },

              withCredentials: true,
            }
          );

          console.log({
            key: attachment.key,
            accessToken: response.data.accessToken,
          });

          if (markup) {
            const data = await getFileFromMicrosoft(
              attachment.key,
              response.data.accessToken,
              "arraybuffer"
            );
            setModal(data);
          } else {
            const data = await getFileFromMicrosoft(
              attachment.key,
              response.data.accessToken,
              "blob"
            );

            const url = URL.createObjectURL(data);
            window.open(`${url}`, `_blank`, `noopener, noreferrer`);
          }
          initCompleted(1);
        } catch (error) {
          console.log(error);
          return initErrors(
            error?.response?.data?.message ?? error?.message ?? "",
            1
          );
        }
        break;

      default:
        initCompleted(1);
        break;
    }
  };

  return (
    <>
      <div
        className={`flex w-fit rounded border px-2 py-1 ${
          !colorChange
            ? "border-taskinatorBlue text-taskinatorDarkGrey"
            : colorChange
        } ${
          notClickable
            ? ""
            : "cursor-pointer hover:bg-taskinatorBlue hover:border-2 hover:text-taskinatorWhite"
        }`}
        onClick={
          notClickable ? () => {} : () => attachmentClickHandler("download")
        }
      >
        <div className="flex justify-center items-center">
          {initState[1].started && (
            <div className="flex">
              <div>{<IconSpinner color={"text-taskinatorBlue"} />}</div>
            </div>
          )}
          {/* </div>{downloadPercentage !== 100 && <div  className="text-xs pl-1"> {downloadPercentage}%</div> */}
          {!initState[1].started && (
            <div>
              {extension[1] === "pdf" ? (
                <MdPictureAsPdf size={"1.25rem"} />
              ) : extension[1] === "zip" ? (
                <MdFolderZip size={"1.25rem"} />
              ) : (
                <MdImage size={"1.25rem"} />
              )}
            </div>
          )}
          {!initState[1].started && !noName && (
            <>
              <div className="font-normal pl-1 overflow-clip text-xs">
                {StringShortenDisplay(
                  20,
                  decodeURIComponent(decodeURIComponent(attachment.fileName))
                )}
              </div>
            </>
          )}
        </div>
      </div>

      {modal && (
        <Modal
          size={"full"}
          local={() => {
            setModal();
            appData.socket.emit(
              "pdfAnnotation",
              {
                action: "disconnect",
                attachmentKey: attachment.key,
              },
              (callback) => {
                console.log(callback.message);
              }
            );
          }}
          isWithoutClose={true}
        >
          <div className="flex flex-row h-full">
            <PDFViewer
              attachmentKey={attachment.key}
              pdfFileName={attachment.fileName}
              pdfURL={modal}
              item={item}
              close={() => {
                setModal("");
                appData.socket.emit(
                  "pdfAnnotation",
                  {
                    action: "disconnect",
                    attachmentKey: attachment.key,
                  },
                  (callback) => {
                    console.log(callback.message);
                  }
                );
              }}
              setAnnotationAdded={() => {}}
              savedMarkupURL={savedMarkupURL}
              setSavedMarkupURL={() => setSavedMarkupURL()}
              setSavedMarkup={() => {}}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default DocumentDisplay;
