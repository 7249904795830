import React, { useContext, useState } from "react";
import { AppContext } from "../../../contexts/appContext";
import { useParams } from "react-router-dom";
import UserDisplayBubble from "../../UI_elements/userDisplayBubble";
import { MdCheckCircle, MdAnnouncement } from "react-icons/md";
import FormatDate from "../../../utils/formattedDate";
import StringShortenDisplay from "../../UI_elements/stringShortenDisplay";
import Button from "../../UI_elements/button";
import { useNavigate } from "react-router-dom";
import { useTimeDisplay } from "../../../utils/useTimeDisplay";
import CountUpTimer from "../../../utils/countUpTimer";

const RequestDisplay = ({ request, onClick, group }) => {
  const appData = useContext(AppContext);
  const isNew = group !== "all" && request.seen.filter((seen) => seen._id === appData.workspaceDataV4.user_id).length === 0;
  const { tid } = useParams();
  const navigate = useNavigate();
  const haveResponsed = request.responded && request.responded === appData.workspaceDataV4.user_id;
  const youreCreator = request.createdBy._id.toString() === appData.workspaceDataV4.user_id;

  const [childHover, setChildHover] = useState();
  const { getRunTime } = useTimeDisplay();

  const youreAssigned =
    request.assignees &&
    request.assignees
      .map((user) => {
        return user._id.toString();
      })
      .includes(appData.workspaceDataV4.user_id.toString());

  return (
    <div key={request._id} className={`w-full mb-1 ${isNew ? "" : ""}`}>
      {isNew && (
        <div className="flex w-full justify-end h-0 pt-3">
          <div className="relative -top-2 mr-2 items-center flex h-4 w-fit text-taskinatorWhite text-[0.75rem] bg-taskinatorRed  px-2 rounded-full font-semibold">New</div>
        </div>
      )}

      <div
        onClick={onClick ? (childHover ? () => {} : () => onClick()) : () => {}}
        className={`border-l-4 ${
          !request.completed ? (youreAssigned ? "border-l-taskinatorOrange" : "border-l-taskinatorOrange") : "border-l-taskinatorMedGrey"
        } p-2  flex grow  text-xs items-center ${
          onClick ? `${childHover ? "" : `${youreAssigned ? "hover:bg-taskinatorGreenHover" : "hover:bg-taskinatorHoverOrange"} cursor-pointer`}` : ""
        } bg-taskinatorWhite shadow`}
      >
        <div className="flex flex-wrap grow ">
          <div className="mr-2 my-1  ">
            {request.completed ? (
              <div className="text-taskinatorMedGrey font-semibold uppercase">
                {" "}
                <div className="flex">
                  <div className="pr-1"> Completed</div> {getRunTime(request.completedDate)}
                </div>
              </div>
            ) : (
              <div className="text-taskinatorOrange font-semibold">
                {!tid ? (
                  haveResponsed ? (
                    <div className={`text-taskinatorGreen font-medium `}>You've Responded</div>
                  ) : !youreCreator ? (
                    "Requires A Response"
                  ) : (
                    <div className="text-taskinatorDarkGrey">You Created This</div>
                  )
                ) : haveResponsed ? (
                  <div className="text-taskinatorGreen font-medium   ">You've Responded</div>
                ) : (
                  <div className="text-lg">
                    <MdAnnouncement />
                  </div>
                )}
              </div>
            )}
          </div>

          <div className=" mr-2 font-semibold my-1 text-taskiantorRed uppercase">
            <div
              className={`py-1 px-2 rounded text-taskinatorWhite ${
                !request.completed
                  ? request.urgancy && request.urgancy.toLowerCase() === "critical"
                    ? `${youreAssigned ? "bg-taskinatorRed" : "bg-taskinatorMedGrey"} animate-pulse`
                    : request.urgancy && request.urgancy.toLowerCase() === "urgent"
                    ? `${youreAssigned ? "bg-taskinatorOrange" : "bg-taskinatorMedGrey"}`
                    : `${youreAssigned ? "bg-taskinatorGreen" : "bg-taskinatorMedGrey"}`
                  : "bg-taskinatorMedGrey"
              }`}
            >
              {request.urgancy === "critical" ? "Can't Continue Task" : request.urgancy === "urgent" ? "Will Be Held Up Soon" : "Need ASAP"}
            </div>
          </div>

          <div className="flex items-center  my-1  mr-2">
            <div className="flex w-full items-center">
              <div className="pr-1 text-taskinatorDarkGrey">Assignee/s: </div>
              {request.assignees && request.assignees.length === 0 && <div className="text-taskiantorMedGrey">no one...</div>}
              {request.assignees && request.assignees.length > 0 && (
                <div className="flex">
                  {request.assignees.map((user) => {
                    return (
                      <div className="flex">
                        <UserDisplayBubble firstName={user.firstName} lastName={user.lastName} user={user} />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          {/* <div className="capitalize pr-2 border-r border-r-taskinatorMedGrey mr-2 my-1  ">
          <div className="border-taskiantorDarkGrey border rounded text-taskinatorDarkGrey font-medium py-1 px-2">{request.type}</div>
        </div> */}
          {!tid && (
            <div className=" mr-2 flex my-1">
              <div className="pr-1 text-taskinatorDarkGrey">Job Ref: </div>
              <div className="font-medium">{request?.taskDetails?.ref ?? "N/A"}</div>
            </div>
          )}

          {/* <div className="pr-2 border-r border-r-taskinatorMedGrey mr-2 flex my-1">
          <div className="pr-1 text-taskinatorDarkGrey">Details: </div>
          <div className="font-medium">{StringShortenDisplay(20, request.requestDetails)}</div>
        </div> */}

          <div className="flex items-center   mr-2 my-1">
            <div className="pr-1 text-taskinatorDarkGrey">Creator: </div>
            <div>
              <UserDisplayBubble firstName={request.createdBy.firstName} lastName={request.createdBy.lastName} />
            </div>
          </div>
          {!request.completed && (
            <div className="flex items-center pr-2 my-1">
              <div className="pr-1 text-taskinatorDarkGrey ">Open For: </div>
              {/* <div className="font-medium ">{FormatDate(request.createdBy.date)}</div> */}
              <div>{CountUpTimer(request.createdBy.date)}</div>
            </div>
          )}
        </div>
        <div>
          {request.creatorTracer && (appData.viewAction && appData.viewAction.tracerId) !== request.creatorTracer && (
            <div className="grow flex justify-end ">
              <Button
                onMouseEnter={() => setChildHover(true)}
                onMouseLeave={() => setChildHover()}
                label="View Task"
                onClick={request.creatorTracer ? () => appData.setActionViewHandler({ tracerId: request.creatorTracer }) : () => {}}
                customClasses={"bg-taskinatorMedGrey text-taskinatorWhite rounded hover:bg-taskinatorBlue h-8"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestDisplay;
