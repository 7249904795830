import React, { useContext, useState } from "react";
import axios from "axios";
import { AppContext } from "../../../contexts/appContext";
import { MdCheckCircle, MdUpdate, MdAddCircle, MdOutlineSubdirectoryArrowRight, MdLock } from "react-icons/md";
import ItemStatus from "../../UI_elements/itemStatus";

import Tag from "../../UI_elements/tag";
import DateDisplay from "../../UI_elements/dateDisplay";

import DocumentDisplay from "../../UI_elements/documentDisplay";
import ApproveRejectForm from "../../forms/approveRejectForm";
import Modal from "../../UI_elements/modal";

const ItemRowDisplay = ({ itemRowData, index, itemState, sectionCSS, widths, setViewItemDetails, itemsSelected, onSelectHandler, searchAndAdd, isRegister, tid }) => {
  const thisItemHasBeenSelected = itemsSelected && itemsSelected[itemRowData._id] ? true : false;
  const appData = useContext(AppContext);
  const itemIsLocked = itemRowData.inAction || (itemRowData.inTask.length > 0 && itemRowData.inTask.filter((task) => task._id === tid).length === 0 && !isRegister);

  const isGuest = appData.workspaceDataV4.role === process.env.REACT_APP_ACCESS_G;

  return (
    // <>
    <div
      className={` ${
        thisItemHasBeenSelected
          ? "bg-taskinatorBlue text-taskinatorWhite"
          : itemRowData.inAction
          ? "bg-taskinatorWhite text-taskinatorDarkGrey opacity-50"
          : `${index !== 0 ? "bg-taskinatorOffWhite text-taskinatorDarkGrey hover:bg-taskinatorHoverBlue" : "bg-taskinatorWhite text-taskinatorDarkGrey hover:bg-taskinatorHoverBlue"}`
      } ${itemIsLocked ? "cursor-not-allowed opacity-50" : `${isGuest ? "" : "cursor-pointer"}`}  py-2  text-sm h-14   w-full flex `}
      onClick={
        searchAndAdd
          ? !itemIsLocked
            ? () => {
                onSelectHandler(itemRowData, itemState);
              }
            : () => {}
          : //   : () =>
          //       setConfirmModal({
          //         itemRowData: itemRowData,
          //         itemState: itemState,
          //         message: itemRowData.inAction ? "Can't add this item as its already in the selected workflow." : "Warning! Item is already in an task.",
          //         button: itemRowData.inAction ? "Confirm" : "Proceed",
          //         inAction: itemRowData.inAction,
          //       })
          isGuest
          ? () => {}
          : () => setViewItemDetails({ itemData: itemRowData, itemState: itemState })
      }
    >
      <div className={`${sectionCSS} ${widths[0]}`}>
        {itemState === "draft" && index !== 0 && <MdOutlineSubdirectoryArrowRight />}

        {!itemIsLocked && itemRowData.draft && itemState === "released" && (
          <div className={`${thisItemHasBeenSelected ? "text-taskiantorWhite" : "text-taskinatorOrange"}`}>
            <MdUpdate size={"1rem"} />
          </div>
        )}

        {!itemIsLocked && !itemRowData.draft && itemState === "released" && (
          <div className={`${thisItemHasBeenSelected ? "text-taskiantorWhite" : "text-taskinatorGreen"}`}>
            <MdCheckCircle size={"1rem"} />
          </div>
        )}
        {itemIsLocked && (
          <div className="text-taskinatorRed">
            <MdLock />
          </div>
        )}
      </div>
      <div className={`${sectionCSS} ${widths[4]} opacity-80`}>
        <ItemStatus status={itemRowData[itemState].status} whiteOverride={thisItemHasBeenSelected} />
      </div>
      <div className="flex w-full">
        <div className={`${sectionCSS} ${widths[2]} `}>{index === 0 && itemRowData.currentItemDetails.itemNumber}</div>
        <div className={`${sectionCSS} ${widths[1]} `}>{index === 0 && itemRowData.currentItemDetails.itemName}</div>

        <div className={`${sectionCSS} ${widths[3]} `}>{itemRowData[itemState].revision}</div>
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
          className={`${sectionCSS} ${widths[9]} `}
        >
          {itemRowData[itemState].attachment ? <DocumentDisplay attachment={itemRowData[itemState].attachment} noName itemId={itemRowData[itemState]._id} /> : "None"}
        </div>
        <div className={`${sectionCSS} ${widths[5]}`}>
          {itemRowData.currentItemTags &&
            itemRowData.currentItemTags.map((tag, index) => {
              return <Tag key={tag._id} tagData={tag} />;
            })}
        </div>
        {!isRegister && (
          <div className={`${sectionCSS} ${widths[10]}`}>
            {itemRowData.inTask &&
              itemRowData.inTask.map((task) => {
                return (
                  <div className="bg-taskinatorRed text-taskinatorWhite font-medium rounded-full py-1 px-4 opacity-50" key={task._id}>
                    {tid && task._id && task._id === tid ? (itemRowData.inAction ? "Active In Selected Workflow" : "Active In Job") : task.ref}
                  </div>
                );
              })}
          </div>
        )}
        {isRegister && (
          <div className={`${sectionCSS} ${widths[6]}`}>
            <div>
              {itemRowData.currentAccessTags &&
                itemRowData.currentAccessTags.map((tag, index) => {
                  return <Tag key={tag._id} tagData={tag} />;
                })}
            </div>
            <div>
              {itemRowData.sharedWith &&
                itemRowData.sharedWith.map((tag, index) => {
                  console.log(tag);
                  return <Tag key={tag._id} tagData={{ type: "external", ...tag }} />;
                })}
            </div>
          </div>
        )}
        {isRegister && <div className={`${sectionCSS} ${widths[7]} capitalize`}>{itemRowData[itemState].createdBy}</div>}
        {isRegister && <div className={`${sectionCSS} ${widths[8]} border-r-0`}>{DateDisplay(itemRowData[itemState].createdDate)}</div>}
      </div>
    </div>
  );
};

export default ItemRowDisplay;

{
  /* {confirmModal && (
        <Modal
        bgColour="bg-taskinatorLightGrey"
          local={() => {
            setConfirmModal();
          }}
        title="Warning"
        >
          <ApproveRejectForm
            message={confirmModal.message}
            confirmFunction={
              confirmModal.inAction
                ? () => setConfirmModal()
                : () => {
                    onSelectHandler(confirmModal.itemRowData, confirmModal.itemState);
                    setConfirmModal();
                  }
            }
            rejectFunction={() => setConfirmModal()}
            confirmButtonLabel={confirmModal.button}
            noConfirmButton ={confirmModal.inAction}
            rejectButtonLabel={"Cancel"}
            confirmButtonCSS={"bg-taskinatorGreen "}
            rejectButtonCSS={confirmModal.inAction ? "bg-taskiantorRed" : null}
          />
        </Modal>
      )}
    </> */
}
