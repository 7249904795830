import React from "react";
import FileSetDropdown from "./fileSetDropdown";

const WorkflowGroup = ({ fileSet }) => {
  return fileSet.workflows
    .sort((a, b) => {
      if (b.name > a.name) {
        return -1;
      } else if (b.name < a.name) {
        return 1;
      }
      return 0;
    })
    .map((selectedWorkflowData) => {
      return (
        <div className="mb-2" key={selectedWorkflowData._id}>
          <FileSetDropdown selectedWorkflowData={selectedWorkflowData} fileSetId={fileSet.fileSetId} fileSetName={fileSet.name} />
        </div>
      );
    });
};

export default WorkflowGroup;
