import axios from "axios";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useInputV4 } from "../../../stateManagement/useInputV4";
import Button from "../../UI_elements/button";
import Modal from "../../UI_elements/modal";
import UserDisplayBubble from "../../UI_elements/userDisplayBubble";
import { VALIDATOR_MINLENGTH } from "../../forms/validators/validators";
import { useInit } from "../../hooks/useInit";
import Input_V4 from "../../inputs/input_V4";
import NextWorkflowDisplayAndSelect from "../workflow/nextWorkflowDisplayAndSelect";
import { JobModalContext } from "./jobModalContext";
import { AppContext } from "../../../contexts/appContext";

const submissionStateReducer = (state, action) => {
  let currentSetWorkflowState = state;

  switch (action.type) {
    case "ADD_WORKFLOW":
      delete currentSetWorkflowState["close"];
      return {
        ...currentSetWorkflowState,
        [action.workflow._id]: {
          wfid: action.workflow._id,
          managers: [],
          autoSelect: action.autoSelect,
        },
      };

    case "DELETE_WORKFLOW":
      delete currentSetWorkflowState[action.workflow._id];
      delete currentSetWorkflowState["close"];
      return { ...currentSetWorkflowState };

    case "CLOSE_WORKFLOW":
      if (currentSetWorkflowState.close) {
        return {};
      } else {
        return { ["close"]: "close" };
      }

    case "MANAGER_CHANGE":
      return {
        ...currentSetWorkflowState,
        [action.wfid]: {
          ...currentSetWorkflowState[action.wfid],
          managers: action.managers,
        },
      };

    case "RESET":
      return {};

    default:
      return state;
  }
};

const nextTaskDisplay = (task) => {
  return (
    <div className=" mb-1 border py-1 px-2 border-taskinatorMedGrey bg-taskinatorWhite  rounded flex items-center justify-center">
      <div className="text-taskinatorDarkGrey font-semibold pr-1 border-r border-r-taskinatorGreen mr-1">{task.taskDetails.name} </div>
      <div className="font-normal pr-1 "> Assignee/s:</div>
      <div>
        {task.assignee.map((user) => {
          return <UserDisplayBubble user={user} />;
        })}
      </div>
    </div>
  );
};

const JobSubmission = ({ fileSelected, fileSetByTask, setFileSelected, setSelectAll, workflowData, submissionId }) => {
  const [submit, setSubmit] = useState();
  const [sendDirection, setSendDirection] = useState();

  const [manual, setManual] = useState();

  const jobData = useContext(JobModalContext);
  const appData = useContext(AppContext);

  const allWorkflows = appData?.jobs?.[jobData.jobId]?.workflows;

  const { inputState, inputHandler, attachmentHandler } = useInputV4({ inputs: {}, attachments: {} }, false);
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  // const workflowData = jobData.selectedWorkflowData;

  const [nextWorkflows, setNextWorkflows] = useReducer(submissionStateReducer, {});

  const movementOptions = workflowData?.workflowMap;
  const backMovementOptions = movementOptions[fileSetByTask._id]?.back;
  const forwardMovementOptions = movementOptions[fileSetByTask._id]?.forward;
  const nextWorkflowOptions = movementOptions[fileSetByTask._id]?.nextworkflow;
  const filesSelected = Object.keys(fileSelected)?.length > 0;
  const workflowSelected = Object.values(nextWorkflows).length > 0;

  useEffect(() => {
    setSendDirection();
  }, [nextWorkflows]);

  useEffect(() => {
    if (workflowSelected) {
      setNextWorkflows({ type: "RESET" });
    }
  }, [sendDirection, submit]);

  const SubmitHandler = async () => {
    try {
      initStarted(0);

      await axios.put(
        process.env.REACT_APP_BACKEND_URL + `/api/job/submit/${jobData.jobId?.toString()}/${workflowData?.workflowGroupId?.toString()}/${fileSetByTask._id}/${fileSetByTask.fileSetId}`,
        {
          direction: sendDirection,
          nextWorkflow: Object.values(nextWorkflows),
          filesToSend: Object.keys(fileSelected),
          comments: inputState.inputs.comments.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      initCompleted(0);
      setSubmit();
      setFileSelected({});
      setSelectAll(false);
      setSendDirection();
      // setIsDone(positionStatus);
    } catch (err) {
      console.log(err);
      return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
    }
  };

  return (
    <>
      <Button
        label="Submit Selected Files"
        customClasses={`${!filesSelected ? "border border-taskinatorMedGrey text-taskinatorMedGrey" : "bg-taskinatorBlue text-taskinatorWhite hover:opacity-75 "} py-2 text-xs uppercase`}
        disabled={!filesSelected}
        onClick={() => setSubmit(true)}
        isLoading={initState[0].started}
      />
      {submit && (
        <Modal
          local={() => {
            setSubmit();
            setSendDirection();
          }}
          size="medium"
          title={"Submission Details"}
        >
          <div className="flex flex-col w-full  p-4 justify-center md:mt-0  mt-10">
            <div className=" text-sm flex flex-col  pb-4 items-center font-medium w-full  border-b-2 border-b-taskinatorMedGrey mb-2 ">
              <div className="flex w-full md:flex-row flex-col ">
                {backMovementOptions ? (
                  // <div className=" md:w-1/2 w-full flex flex-col mr-2">
                  <div
                    onClick={() => {
                      setSendDirection("back");
                    }}
                    className="hover:ring-1 hover:ring-taskinatorDarkGrey h-fit rounded-md cursor-pointer   border-taskinatorMedGrey md:w-1/2 w-full flex flex-col md:mr-1 mr-0"
                  >
                    <div
                      className={`w-full  border-taskinatorDarkGrey items-center justify-center flex rounded-t-md ${
                        sendDirection === "back" ? "text-taskinatorWhite bg-taskinatorDarkGrey border" : "text-taskinatorDark border"
                      }  h-10  uppercase  `}
                    >
                      Send BACK To
                    </div>

                    <div className=" border p-1 border-t-0 rounded-b border-taskinatorDarkGrey bg-taskinatorLightGrey">
                      {backMovementOptions?.map((backOptions) => {
                        const taskData = workflowData.tasks.find((task) => task._id === backOptions);

                        return nextTaskDisplay(taskData);
                      })}{" "}
                    </div>
                  </div>
                ) : (
                  <div className="border border-taskinatorMedGrey text-taskinatorMedGrey h-10 uppercase items-center justify-center md:w-1/2 w-full flex  mr-2 px-2 rounded mb-2">
                    No Options Backward
                  </div>
                )}

                {forwardMovementOptions.length > 0 ? (
                  <div
                    onClick={() => {
                      setSendDirection("forward");
                    }}
                    className="hover:ring-1  text-taskinatorBlue hover:ring-taskinatorBlue rounded-md cursor-pointer    borderborder-taskinatorGreen md:w-1/2 w-full flex flex-col md:ml-1 ml-0"
                  >
                    <div
                      className={`w-full  border-taskinatorBlue items-center justify-center flex rounded-t-md ${
                        sendDirection === "forward" ? "text-taskinatorWhite bg-taskinatorBlue border" : "text-taskinatorBlue border"
                      }  h-10  uppercase  `}
                    >
                      Send To Next Task
                    </div>
                    {/* <Button
                      grow
                      label="Send FORWARD To"
                      customClasses={`w-full border border-taskinatorGreen ${
                        sendDirection === "forward" ? "text-taskinatorWhite bg-taskinatorGreen" : "text-taskinatorGreen"
                      }  h-10 uppercase hover:bg-taskinatorGreen hover:text-taskinatorWhite rounded-b-none`}
                      onClick={() => {
                        setSendDirection("forward");
                      }}
                    /> */}
                    {/* Next Tasks In Workflow: */}
                    <div className=" border p-1 border-t-0 rounded-b-md border-taskinatorBlue bg-taskinatorLightGrey">
                      {forwardMovementOptions?.map((forwardOptions) => {
                        const taskData = workflowData.tasks.find((task) => task._id === forwardOptions);

                        return <div key={forwardOptions._id}>{nextTaskDisplay(taskData)}</div>;
                      })}
                    </div>
                  </div>
                ) : nextWorkflowOptions.length > 0 ? (
                  <div className="md:mt-0 mt-2 md:border-t-0 border-t-2 md:pt-0 pt-2  border-taskinatorMedGrey md:w-1/2 w-full flex flex-col md:ml-1 ml-0 ">
                    {/* <div
                      className={`w-full  border-taskinatorBlue items-center justify-center flex rounded-t-md ${
                        sendDirection === "forward" ? "text-taskinatorWhite bg-taskinatorBlue border" : "text-taskinatorBlue border"
                      }  h-10  uppercase  `}
                    >
                      Mark Workflow Completed
                    </div> */}

                    <div
                      className={`w-full border border-taskinatorGreen flex items-center justify-center
                       text-taskinatorWhite bg-taskinatorGreen rounded  h-10 uppercase hover:bg-taskinatorGreen hover:text-taskinatorWhite`}
                    >
                      Move To Next Workflow
                    </div>

                    {nextWorkflowOptions.map((workflow) => {
                      if (workflow !== true) return <NextWorkflowDisplayAndSelect workflow={workflow} onSelect={setNextWorkflows} isSelected={nextWorkflows[workflow._id]} />;
                    })}
                  </div>
                ) : (
                  <div className="border border-taskinatorMedGrey text-taskinatorMedGrey h-10 uppercase items-center justify-center w-1/2 flex  mr-2 px-2 rounded">No Options Forward</div>
                )}
              </div>

              {/* {allWorkflows && (
                <div>
                  <div className="flex flex-wrap capitalize underline hover:cursor-pointer hover:text-taskinatorBlue text-taskinatorDarkGrey opacity-75" onClick={() => setManual(!manual)}>
                    {" "}
                    Manually Select >
                  </div>

                  {manual && (
                    <div>
                      {allWorkflows.map((workflow) => {
                        return (
                          <div className="mb-2">
                            <div>{workflow?.name}</div>
                            <div className="flex flex-col">
                              {workflow?.tasks?.map((task) => {
                                return <div> - {task.taskDetails.name}</div>;
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )} */}
            </div>

            <Input_V4
              sid={"inputs"}
              id="comments"
              validityMessage={"Minimum 6 chars required"}
              validators={sendDirection === "back" ? [VALIDATOR_MINLENGTH(3)] : []}
              onInput={inputHandler}
              textareaRows={5}
              placeholder={"Enter any details that might be helpful or members should be aware of in the next task..."}
              label="Submit Next Instructions"
            />

            <Button
              label={`Submit`}
              customClasses={`${
                (sendDirection || workflowSelected) && filesSelected && inputState.isValid ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"
              }  text-sm text-taskinatorWhite h-10 grow w-full mt-4 `}
              disabled={(!sendDirection && !workflowSelected) || !filesSelected || (sendDirection === "back" && !inputState.isValid)}
              isLoading={initState[0].started}
              error={initState[0].error}
              onClick={() => SubmitHandler()}
            />
          </div>
        </Modal>
      )}{" "}
    </>
  );
};

export default JobSubmission;
