import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../contexts/appContext";
import CommentBubbleElement from "./commentBubbleElement";
import { useInit } from "../../hooks/useInit";
import IconSpinner from "../../UI_elements/iconSpinner";
import { animateScroll } from "react-scroll";

const ActionCommentsDisplay = ({ tracerWorkflowGroupId, refresh, setRefresh }) => {
  const [logs, setLogs] = useState({});
  const { initState, initStarted, initCompleted, initErrors } = useInit();

  const appData = useContext(AppContext);
  useEffect(() => {
    initStarted(0);

    appData.socket.emit(
      "init",
      {
        action: "logs",
        tracerWorkflowGroupId: tracerWorkflowGroupId,
      },
      (callback) => {
        if (callback.success) {
          setLogs(callback.logs);
          initCompleted(0);
        } else {
          initErrors(callback.error, 0);
        }
      }
    );
  }, [tracerWorkflowGroupId]);

  appData.socket.on("logUpdate", (data) => {
    if (data.action === "update") {
      setLogs({ ...logs, [data.log._id]: data.log });
    }
  });

  const actionComments = logs && Object.values(logs).sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));


  // const fixScrollToBottom = (id) => {
  //   const card = document.querySelector(id);
  //   const duration = (card.scrollHeight - card.clientHeight) / 5;

  //   animateScroll.scrollToBottom({
  //     containerId: "response-card",
  //     duration: duration,
  //     smooth: true,
  //   });
  // };

  // useEffect(() => {
  //   fixScrollToBottom("#response-card");
  // }, [logs]);

  return (
    <div className={`flex flex-col grow xl:overflow-auto justify-between pl-1  `}>
      {!actionComments && initState[0].started && !initState[0].initialised && (
        <div className="w-full flex items-center justify-center h-full grow">
          <IconSpinner size={"m"} />
        </div>
      )}
      {!actionComments && initState[0].error && <div className="text-xs text-taskinatorRed text-center w-full flex items-center justify-start h-full">{initState[0].error} </div>}
      {!actionComments && initState[0].initialised && <div className="text-xs text-taskinatorDarkGrey">No Notifications...</div>}

      <div  className="overflow-auto  lg:h-full h-96 flex flex-col-reverse scrollbar">
        {actionComments &&
          actionComments.length > 0 &&
          initState[0].initialised &&
          actionComments.map((actionComment, index) => {
            return <CommentBubbleElement actionComment={actionComment} tracerWorkflowGroupId={tracerWorkflowGroupId} refresh={refresh} setRefresh={setRefresh} key={actionComment._id} />;
          })}
      </div>
    </div>
  );
};

export default ActionCommentsDisplay;
