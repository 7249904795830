import React, { useEffect, useState } from "react";

const CountUpTimer = ({ startDate, hoursOnly }) => {
  const [elapsedTime, setElapsedTime] = useState(0);

  const elapseTimeHandler = () => {
    const now = new Date();
    const timeDifference = now - new Date(startDate);
    setElapsedTime(timeDifference);
  };

  useEffect(() => {
    if (startDate) {
      if (elapsedTime === 0) {
        elapseTimeHandler();
      }

      const interval = setInterval(() => {
        elapseTimeHandler();
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setElapsedTime(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  const additionalLapseTime =
    typeof hoursOnly === "number" ? hoursOnly * 1000 : 0;
  const totalElapsedTime = elapsedTime + additionalLapseTime;

  const { seconds, minutes, hours, days } = CalcTime(totalElapsedTime);

  if (typeof hoursOnly === "number") {
    return (
      <div className="text-taskinatorWhite font-medium animate">{`${
        hours < 0 ? 0 : hours
      }h ${minutes < 0 ? 0 : minutes}m  ${
        startDate ? `${seconds < 0 ? 0 : seconds}s` : ""
      }`}</div>
    );
  } else if (startDate) {
    return (
      <div className="text-taskinatorRed font-medium ">
        {`${days > 0 ? `${days}d` : ""} ${
          !days && hours > 0 ? `${hours}h` : ""
        } ${!hours && !days && minutes > 0 ? `${minutes}m` : ""} ${
          !minutes && !hours && !days && seconds > 0 ? `${seconds}s` : ""
        } `}
      </div>
    );
  } else {
    return <div className="text-taskinatorRed font-medium">No Time</div>;
  }
};

export default CountUpTimer;

export const CalcTime = (totalElapsedTime) => {
  const seconds = Math.floor(totalElapsedTime / 1000) % 60;
  const minutes = Math.floor(totalElapsedTime / (1000 * 60)) % 60;
  const hours = Math.floor(totalElapsedTime / (1000 * 60 * 60));
  const days = Math.floor(totalElapsedTime / (1000 * 60 * 60 * 24));

  return {
    seconds,
    minutes,
    hours,
    days,
  };
};
