import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { MdCheckCircle, MdOutlineWarning } from "react-icons/md";
import { AppContext } from "../../../contexts/appContext";
import Button from "../../UI_elements/button";
import Modal from "../../UI_elements/modal";
import { useInit } from "../../hooks/useInit";
import CheckboxV4 from "../../inputs/checkbox_V4";
import { JobModalContext } from "../jobs/jobModalContext";
import FileDetails from "./fileDetails";
import FileJobViewDetails from "./fileJobViewDetails";
import FileViewDetailsModal from "./fileViewDetailsModal";
import LowerCaseTrim from "../../../utils/lowerCaseAndTrim";
import StringShortenDisplay from "../../UI_elements/stringShortenDisplay";

const determineOverallStatus = (finalAction, workflowData) => {
  if (finalAction?.length === 0) {
    return null; // Return null if array is empty after filtering
  }

  let hasReject = false;
  let allComplete = true;
  let finalConfirmed;

  for (const position of finalAction) {
    if (position.positive === false) {
      hasReject = true; // At least one status matches rejectButton
      finalConfirmed = { stageId: position.stageId, isNegative: true, status: position.status };
      break; // No need to continue the loop
    } else if (position.positive === true) {
      finalConfirmed = { stageId: position.stageId, isPositive: true, status: position.status };
      allComplete = false; // Not all statuses are completeButton
      break;
    }
    return null;
  }

  return finalConfirmed;
};

const FileViewAndActions = ({ isRef, file, globalOnInput, index, selectAll, fileSetByTask, workflowData, setWorkflow, taskData, workflowGroupId, fileSetId, completed }) => {
  const jobData = useContext(JobModalContext);
  const filter = jobData.filter;
  const appData = useContext(AppContext);

  const [viewFileDetails, setViewFileDetails] = useState();
  const [showDetails, setShowDetails] = useState();
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(3);
  const currentActiveTask = fileSetByTask?.taskDetails;
  const isSubmitted = file?.currentJobs?.submissionDirection ? true : false;
  const finalAction = (file?.lastActions || [])?.filter((data) => data).length > 0 ? determineOverallStatus(file.lastActions, workflowData) : null;
  const isNew = file?.currentJobs?.seen?.filter((seen) => seen._id === appData.workspaceDataV4.user_id).length === 0;

  const [isDone, setIsDone] = useState();
 
  const isVisible =
    LowerCaseTrim(file?.fileName)?.includes(LowerCaseTrim(filter)) ||
    LowerCaseTrim(file?.revision ?? "N/A")?.includes(LowerCaseTrim(filter)) ||
    LowerCaseTrim(file?.fileExtension)?.includes(LowerCaseTrim(filter)) ||
    LowerCaseTrim(file?.status ?? "pending")?.includes(LowerCaseTrim(filter)) ||
    file?.settings?.fileTags?.map((tag) => LowerCaseTrim(tag.name))?.includes(LowerCaseTrim(filter)) ||
    file?.settings?.accessTags?.map((tag) => LowerCaseTrim(tag.name))?.includes(LowerCaseTrim(filter));

    useEffect(()=>{
      initReset(0)
      initReset(2)
    }, [file])

  useEffect(() => {
    setIsDone(file?.currentJobs?.status);
  }, [file?.currentJobs?.status]);

  const onSelectHandler = useCallback(
    (isChecked) => {
      if (globalOnInput) {
        console.log(file._id);
        globalOnInput(file._id, isChecked);
      }
    },
    [file]
  );

  useEffect(() => {
    if (!isVisible && globalOnInput) {
      globalOnInput(file._id, false);
    }
  }, [isVisible]);

  const updatePositionStatusHandler = async (positionStatus) => {
    try {
      initStarted(0, positionStatus);

      await axios.put(
        process.env.REACT_APP_BACKEND_URL + `/api/job/file/positionstatus/${file?._id?.toString()}/${file?.currentJobs?.submissionId}/${jobData.jobId}/${fileSetByTask?._id}`,
        {
          positionStatus: positionStatus,
          positive: positionStatus === currentActiveTask.completeButton ? true : positionStatus === currentActiveTask.rejectButton ? false : null,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      // initCompleted(0, positionStatus);
      // setIsDone(positionStatus);
    } catch (err) {
      console.log(err);
      return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0, positionStatus);
    }
  };

  const startWorkHandler = async () => {};

  const closeModelHandler = () => {
    setViewFileDetails();
    appData.socket.emit(
      "pdfAnnotation",
      {
        action: "disconnect",
        attachmentKey: file.key ? file.key : file.attachmentKey,
      },
      (callback) => {}
    );
  };

  const removeFileHandler = () => {
    initStarted(2);

    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/file/remove/${file._id}/${jobData?.jobId}/${workflowData?.workflowGroupId ?? workflowGroupId}/${fileSetByTask?._id}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        initCompleted(2);
      })
      .catch((err) => {
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 2);
      });
  };

  if (isVisible) {
    return (
      <div>
        {isNew && globalOnInput && !jobData.jobCompleted && (
          <div className="flex grow  justify-end h-0 pt-3 z-40">
            <div className="relative -top-2 mr-2 items-center flex h-4 w-fit text-taskinatorWhite text-[0.75rem] bg-taskinatorRed  px-2 rounded-full font-semibold">New</div>
          </div>
        )}
        <div className={`${showDetails ? "ring ring-2 ring-taskinatorItemBlue rounded-lg" : ""}`}>
          <div
            className={`${
              showDetails ? "bg-taskinatorHoverBlue rounded-t-lg" : "bg-taskinatorWhite rounded-lg"
            } border border-taskinatorMedGrey flex p-2 items-center mt-0.5 text-taskinatorDarkGrey hover:bg-taskinatorHoverBlue  `}
          >
            <div
              onClick={(event) => {
                event.stopPropagation();
              }}
              className=" border-r border-r-taskinatorMedGrey mr-2"
            >
              <CheckboxV4
                disabled={
                  isSubmitted || file?.fileName === process.env.REACT_APP_PLACEHOLDER_CODE || !fileSetByTask?.isAssigned || !globalOnInput || setWorkflow || fileSetByTask?.taskCompleted || isRef
                }
                id={"check"}
                sid="fileOnSet"
                defaultValue={selectAll}
                onInput={(id, isChecked, isValid, sid) => onSelectHandler(isChecked)}
                customCheckbox={"h-5 w-5"}
              />
            </div>
            <div
              onClick={
                file?.fileName === process.env.REACT_APP_PLACEHOLDER_CODE
                  ? () => {}
                  : () => {
                      setShowDetails(!showDetails);
                    }
              }
              className="grow justify-start flex items-center cursor-pointer"
            >
              {file?.fileName !== process.env.REACT_APP_PLACEHOLDER_CODE ? <FileDetails file={file} wide notClickable noDate noTags /> : <div>Add file to set to continue</div>}
              {(!isDone || fileSetByTask?.taskCompleted || jobData.jobCompleted) && finalAction?.status && (
                <div
                  className={` ${
                    finalAction?.isPositive
                      ? "ml-2 text-taskinatorGreen border py-1 px-2 rounded font-medium uppercase text-xs"
                      : finalAction?.isNegative
                      ? "ml-2 text-taskinatorRed border py-1 px-2 rounded font-medium uppercase text-xs"
                      : ""
                  }`}
                >
                  Last Action: {finalAction?.status}
                </div>
              )}
              {isRef && file.currentJobs.length > 0 && (
                <div className="flex pl-2 items-center grow justify-end">
                  Active In:{" "}
                  <div className="pl-1">
                    {file.currentJobs.map((job) => {
                      return (
                        <div className="flex items-center text-xxs opacity-75">
                          <div className="border p-1 mr-1 rounded border-taskinatorDarkGrey text-taskinatorDarkGrey  uppercase">
                            {job.jobId === jobData.jobId ? "This Job" : `${StringShortenDisplay(10, job.jobRef)}`}{" "}
                          </div>
                          <div className="border p-1 mr-1 rounded border-taskinatorGreen text-taskinatorGreen  uppercase">{StringShortenDisplay(10, job.workflowName)} </div>
                          <div className="border p-1 mr-1 rounded border-taskinatorDarkGrey text-taskinatorWhite bg-taskinatorDarkGrey uppercase">{StringShortenDisplay(10, job.taskName)}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {/* {isRef && file.currentJobs.length === 0 && (
                <div className="flex pl-2 items-center grow justify-end">
                  <div className=" w-fit items-center sm:flex hidden text-taskinatorGreen font-medium flex">
                    <MdCheckCircle /> <div className="pl-1">Submitted To This Task</div>
                  </div>{" "}
                </div>
              )} */}
            </div>
            {globalOnInput && !fileSetByTask.canStart && !jobData.jobCompleted && !fileSetByTask?.taskCompleted && (
              <div>
                {isSubmitted ? (
                  <div className=" w-fit items-center sm:flex hidden text-taskinatorGreen font-medium flex">
                    <MdCheckCircle /> <div className="pl-1">Submitted</div>
                  </div>
                ) : file.status !== "pending" ? (
                  file?.fileName !== process.env.REACT_APP_PLACEHOLDER_CODE && (
                    <div className=" w-fit items-center sm:flex hidden">
                      {isDone && (
                        <div className="flex items-center">
                          <div
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            className={` ${isDone === currentActiveTask.rejectButton ? "text-taskinatorRed" : "text-taskinatorGreen"} pr-1 font-normal whitespace-nowrap flex pr-2`}
                          >
                            {isDone}
                          </div>
                          {fileSetByTask.isAssigned && (
                            <Button
                              onClick={(event) => {
                                event.stopPropagation();
                                updatePositionStatusHandler(null);
                              }}
                              label={"Undo Action"}
                              customClasses={"border border-taskinatorRed  text-taskinatorRed opacity-50 py-1 text-xxs hover:bg-taskinatorRed hover:text-taskinatorWhite "}
                            />
                          )}
                        </div>
                      )}

                      {!jobData.jobCompleted && !isDone && fileSetByTask.isAssigned && !setWorkflow && !fileSetByTask?.taskCompleted && (
                        <div className={`flex  ${isNew ? "" : ""}`}>
                          {currentActiveTask?.completeButton?.length > 0 && (
                            <Button
                              onClick={(event) => {
                                event.stopPropagation();
                                updatePositionStatusHandler(currentActiveTask.completeButton);
                              }}
                              label={currentActiveTask.completeButton}
                              customClasses={"border border-taskinatorGreen text-taskinatorGreen py-1 hover:bg-taskinatorGreen hover:text-taskinatorWhite mr-1"}
                              isLoading={initState[0].started && initState[0].identifier === currentActiveTask.completeButton}
                            />
                          )}

                          {currentActiveTask?.rejectButton?.length > 0 && (
                            <Button
                              onClick={(event) => {
                                event.stopPropagation();
                                updatePositionStatusHandler(currentActiveTask.rejectButton);
                              }}
                              label={currentActiveTask.rejectButton}
                              customClasses={"border border-taskinatorRed text-taskinatorRed py-1 hover:bg-taskinatorRed hover:text-taskinatorWhite"}
                              isLoading={initState[0].started && initState[0].identifier === currentActiveTask.rejectButton}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )
                ) : (
                  <div className="text-taskinatorRed text-xs flex items-center">
                    <MdOutlineWarning size={"1rem"} />
                    <p className="pl-1">Set File Status</p>
                    <Button
                      onClick={() => {
                        removeFileHandler();
                      }}
                      label={"Delete"}
                      customClasses={"text-taskinatorRed opacity-50 hover:opacity-100 hover:border-taskinatorRed hover:border"}
                      isLoading={initState[2].started}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          {showDetails && (
            <div className=" bg-taskinatorOffWhite p-2 border border-taskinatorMedGrey border-t-0 rounded-b-lg shadow">
              <FileJobViewDetails
                file={file}
                inOtherWorkflow={file.inOtherWorkflow}
                taskData={fileSetByTask?.taskDetails || taskData}
                workflowGroupId={workflowData.workflowGroupId}
                taskCompleted={fileSetByTask?.taskCompleted ?? completed}
                fileSetId={fileSetByTask?.fileSetId || fileSetId}
                workflowId={workflowData._id}
                workflowName={workflowData.name}
                taskTags={currentActiveTask?.fileTags ?? taskData.fileTags}
              />
            </div>
          )}
        </div>

        {viewFileDetails && (
          <Modal
            size={"max"}
            isWithoutClose
            local={() => {
              closeModelHandler();
            }}
          >
            <FileViewDetailsModal
              updatePositionStatusHandler={updatePositionStatusHandler}
              file={file}
              isNew={isNew}
              openLocal={() => {
                closeModelHandler();
              }}
            />
          </Modal>
        )}
      </div>
    );
  }
};

export default FileViewAndActions;
