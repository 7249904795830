import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import FileDisplay from "./fileDisplay";
import { JobModalContext } from "../jobs/jobModalContext";

const FileViewDetailsModal = ({ updatePositionStatusHandler, file, openLocal, isNew }) => {
  const jobData = useContext(JobModalContext);

  useEffect(async () => {
    if (!isNew) {
      try {
        await axios.get(
          process.env.REACT_APP_BACKEND_URL + `/api/job/file/seen/${file._id?.toString()}/${jobData.jobId?.toString()}/${jobData.selectedWorkflowData.workflowGroupId?.toString()}`,

          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        // setIsDone(positionStatus);
      } catch (err) {
        console.log(err);
        // return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0, positionStatus);
      }
    }
  }, [file]);

  return (
    <div className="h-full">
      <FileDisplay file={file} automatic viewMarkupsOnly openLocal={openLocal} fileDetailsView />
    </div>
  );
};

export default FileViewDetailsModal;
