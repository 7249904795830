import React, { useState } from "react";
import Button from "../../components/UI_elements/button";
import Modal from "../../components/UI_elements/modal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PlanElement from "./planElement";
import SelectPlan from "./selectPlan";
import CardDetails from "./cardDetails";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const CurrentPlan = ({ plan, unlimitedUntil, getData }) => {
  const DaysReminingOnUnlimited = Math.ceil((new Date(unlimitedUntil).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));

  const [upgrade, setUpgrade] = useState();

  const getDataHandler = () => {
    setUpgrade();
    getData();
  };

  return (
    <div>
      <div className="font-semibold text-sm">Current Plan</div>

      <div className="text-sm w-full flex flex-col grow justify-between pt-2 border-t border-taskinatorMedGrey mt-2">
        {/* {plan.freePlan && DaysReminingOnUnlimited > 0 && (
          <div className=" text-taskinatorRed pt-2 font-bold">
            {" "}
            <div> {DaysReminingOnUnlimited} Days Remaining With No Workflow Limit Then Reverts Current Plan</div>
          </div>
        )} */}
        <PlanElement plan={plan} currentView/>
        <Button
          label="Change Plan"
          onClick={() => {
            setUpgrade(true);
          }}
          customClasses={"bg-taskinatorBlue bg-taskinatorBlue text-taskinatorWhite h-8  mt-2 grow"}
        />
      </div>

      {upgrade && (
        <Modal
          title="Change Plan or Billing"
          local={() => {
            setUpgrade();
          }}
        >
          <Elements
            stripe={stripePromise}
            options={{
              mode: "setup",
              currency: "aud",
              appearance: {
                theme: "stripe",
              },
            }}
          >
            <SelectPlan firstPayment={unlimitedUntil} DaysReminingOnUnlimited={DaysReminingOnUnlimited} currentPlan={plan} getData={getDataHandler} />
          </Elements>
        </Modal>
      )}
    </div>
  );
};

export default CurrentPlan;
