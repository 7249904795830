import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../contexts/appContext";
import { getJobActionPlan } from "../../../services/job.service";
import Button from "../../UI_elements/button";
import Modal from "../../UI_elements/modal";
import { useInit } from "../../hooks/useInit";
import EditWorkflow from "../workflow/editWorkflow";
import FileSetAction from "./fileSetAction";
import JobDetailsWorkflowDisplay from "./jobDetailsWorkflowDisplay";
import { JobModalContext } from "./jobModalContext";
import JobModalDropDownContainer from "./jobModalDropDownContainer";
import { MdCheckCircle, MdOutlineWarning } from "react-icons/md";
import axios from "axios";

const FileSetDropdown = ({ selectedWorkflowData, fileSetId, fileSetName }) => {
  const appData = useContext(AppContext);

  let filesByTask = {};

  const isManager = selectedWorkflowData.workflowData.managers.find((manager) => manager._id === appData.workspaceDataV4.user_id);

  const [isOnlyWorkFlow, setIsOnlyWorkFlow] = useState(true);
  const [onDelete, setDelete] = useState();
  const [editWorkflow, setEditWorkflow] = useState();
  const [actionPlan, setActionPlan] = useState([]);
  const [bulkEditEnabled, setBulkEditEnabled] = useState();

  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  const jobData = useContext(JobModalContext);

  const disableEdits = false;

  (Object.values(selectedWorkflowData.files) ?? [])
    .sort((a, b) => {
      if (b.fileName > a.fileName) {
        return -1;
      } else if (b.fileName < a.fileName) {
        return 1;
      }
      return 0;
    })
    .map((file) => {
      file.currentJobs
        .filter((wfs) => wfs.workflowGroupId === selectedWorkflowData.workflowData.workflowGroupId)
        .map((currentJob) => {
          // workflow.currentFilePosition.map((currentTask) => {

          const task = selectedWorkflowData.workflowData.tasks.find((task) => task._id === currentJob.taskId);

          const isAssigned = task.assignee.find((user) => user._id.toString() === appData.workspaceDataV4.user_id) ? true : false;
          const isAssignedToUser = !isAssigned && task.assignee.length > 0 ? true : false;
          const isManager = selectedWorkflowData.workflowData.managers.find((user) => user._id.toString() === appData.workspaceDataV4.user_id) ? true : false;
          const canStart = !isAssigned && !isAssignedToUser && task.taskDetails.team.find((user) => user._id.toString() === appData.workspaceDataV4.user_id) ? true : false;

          filesByTask[currentJob.taskId] = {
            name: task.taskDetails.name,
            _id: currentJob.taskId,
            stageId: currentJob.stageId,
            taskDetails: { ...task.taskDetails, _id: currentJob.taskId },
            taskCompleted: task.markedAsCompleted,
            completedBy: task.completedBy,
            completedDate: task.completedDate,
            isAssigned: isAssigned,
            isManager: isManager,
            canStart: canStart,
            assignees: task.assignee,
            fileSetId: fileSetId,
            fileSetName: fileSetName,

            files: [...(filesByTask[currentJob.taskId]?.files || []), { ...file, currentJobs: file.currentJobs.find((cj) => cj.taskId === currentJob.taskId) }],
          };
        });
    });

  useEffect(() => {
    initStarted(1);

    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/job/actionPlan/${jobData?.jobId}/${selectedWorkflowData?.workflowData?.workflowGroupId}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        initCompleted(1);
        setActionPlan(response.data.actionPlan);
      })
      .catch((err) => {
        console.log(err);
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
      });

    // getJobActionPlan({
    //   jobId: jobData?.jobId,
    //   workflowGroupId: selectedWorkflowData?.workflowData?.workflowGroupId,
    //   onFinish: (res, err) => {
    //     console.log(res, err);
    //     initCompleted(1);
    //     if (!res || err) {
    //       initErrors(err?.response?.data?.message ?? err?.message ?? "Error while getting actionplan data of the job", 1);
    //     }
    //     setActionPlan(res?.actionPlan ?? []);
    //   },
    // });
  }, [jobData.jobId, selectedWorkflowData?.workflowData?.tasks]);

  const mustSetWorkflow = selectedWorkflowData.setWorkflow;

  return (
    <>
      <div className="border border-taskinatorHoverGreen rounded-lg">
        <JobModalDropDownContainer
          noDropDownBorder
          openAtStart
          thin
          headerContent={
            <>
              <div className="pl-1">Sequence:</div>
              <b className=" order-taskinatorWhite pl-2 pr-2"> {selectedWorkflowData?.name} </b>

              <div className="flex grow justify-end"></div>
            </>
          }
          backgroundColour={"bg-taskinatorHoverGreen"}
        >
          <div>
            {([process.env.REACT_APP_ACCESS_A, process.env.REACT_APP_ACCESS_O].includes(appData.workspaceDataV4.role) ||
              ([process.env.REACT_APP_ACCESS_M].includes(appData.workspaceDataV4.role) && isManager)) && (
              <div className="flex flex-col">
                <div className="bg-taskinatorWhite p-2 rounded-lg border border-taskinatorMedGrey mb-1">
                  {!mustSetWorkflow ? (
                    <div className="flex w-full  items-center justify-center">
                      <JobDetailsWorkflowDisplay actionPlan={actionPlan} workflowGroupId={selectedWorkflowData?.workflowData?.workflowGroupId} />
                    </div>
                  ) : (
                    <div className=" text-taskinatorRed font-bold py-1 px-2 rounded w-fit items-center uppercase animate-pulse flex">
                      <MdOutlineWarning size={"1.25rem"} className="mr-2" /> Must Set Sequence To Active Task!{" "}
                    </div>
                  )}
                </div>
                <div className={`flex grow md:justify-end`}>
                  {/* {isManager && !disableEdits && (
                    <div className="flex">
                      <div className="items-center flex">
                        <Button
                          label={`Mark Workflow As Completed`}
                          customClasses={`${
                            initState[1].error
                              ? "border-taskiantorRed text-taskinatorRed cursor-not-allowed"
                              : "cursor-pointer text-taskinatorRed hover:bg-taskinatorRed hover:text-taskinatorWhite border-taskinatorRed"
                          } justify-end h-8 text-sm px-4 py-2 border rounded opacity-50`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setDelete(true);
                          }}
                          error={initState[1].error}
                          disabled={initState[1].error}
                        />
                      </div>
                    </div>
                  )} */}
                  {isManager && !disableEdits && !jobData.jobCompleted && (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditWorkflow(true);
                      }}
                      label={!mustSetWorkflow ? "Edit This Sequence" : "Set Sequence"}
                      customClasses={`${
                        mustSetWorkflow
                          ? "bg-taskinatorRed text-taskinatorWhite animate-pulse hover:opacity-75"
                          : "border-taskinatorDarkGrey text-taskinatorDarkGrey hover:bg-taskinatorMedGrey hover:text-black"
                      }  uppercase h-8 flex items-center justify-center text-xs px-2 border rounded ml-2`}
                    />
                  )}
                </div>
              </div>
            )}

            {Object.values(filesByTask).map((fileSetByTask) => {
              return (
                <div key={fileSetByTask._id} className="pt-1">
                  <FileSetAction fileSetByTask={fileSetByTask} workflowData={selectedWorkflowData.workflowData} setWorkflow={selectedWorkflowData.setWorkflow} />
                </div>
              );
            })}
          </div>
        </JobModalDropDownContainer>
      </div>

      {editWorkflow && (
        <Modal
          title="Edit Current Sequence"
          local={() => {
            setEditWorkflow();
          }}
        >
          <EditWorkflow
            selectedWorkflowData={selectedWorkflowData}
            splitFilesByTask={filesByTask}
            selStageIds={selectedWorkflowData?.workflowData?.tasks}
            actionPlan={actionPlan}
            handleCloseModal={() => setEditWorkflow(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default FileSetDropdown;
