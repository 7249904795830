import React, { useContext, useState, useEffect } from "react";
import { MdClose, MdDownload, MdOutlineZoomInMap, MdOutlineZoomOutMap, MdUpdate } from "react-icons/md";
import Button from "../components/UI_elements/button";
import DocumentDisplay from "../components/UI_elements/documentDisplay";
import ItemStatus from "../components/UI_elements/itemStatus";
import StringShortenDisplay from "../components/UI_elements/stringShortenDisplay";
import CreateFile from "../components/displayElements/files/createFile";
import { StampPDF } from "../components/functions/mergeAndStampPDF";
import { useInit } from "../components/hooks/useInit";
import { AppContext } from "../contexts/appContext";
import PDFViewer from "./adobePdfViewer";
import { GetFileFromAws } from "./aws";
import FilePreview from "./filePreview";
import axios from "axios";
import IconSpinner from "../components/UI_elements/iconSpinner";

const AdobePDFViewerContainer = ({
  pdfURL,
  pdfFileName,
  iid,
  setSavedMarkup,
  setSavedMarkupURL,
  savedMarkupURL,
  setAnnotationAdded,
  setLoadedDocument,
  nomarkups,
  attachmentKey,
  userName,
  userMadeChanges,
  item,
  close,
  viewMarkupsOnly,
  fileDetailsView,
  file,
  openLocal,
  expand,
  setExpand,
}) => {
  pdfFileName = decodeURIComponent(pdfFileName);
  const appData = useContext(AppContext);
  const { initState, initStarted, initCompleted, initErrors } = useInit(2);

  const [primaryPDF, setPrimaryPDF] = useState();
  const [loadedPdf, setLoadedPdf] = useState();
  const [annotationData, setAnnotationData] = useState([]);

  const getPDFData = async (pdfURL, type) => {
    const response = await axios.get(pdfURL, {
      responseType: "blob",
    });

    if (type === "primary") {
      setPrimaryPDF({ url: URL.createObjectURL(response.data), blob: response.data });
    }
  };

  useEffect(() => {
    getPDFData(pdfURL, "primary");
  }, [pdfURL]);

  const setDocumentMarkupHandler = (data) => {
    console.log(data);
    if (loadedPdf?.attachmentKey === data.attachmentKey) {
      setLoadedPdf();
    } else {
      setLoadedPdf(data);
    }
  };

  const handleDownload = async () => {
    initStarted(0);

    const stampedBlob = await StampPDF(primaryPDF.blob, file);
    initCompleted(0);
    const url = window.URL.createObjectURL(stampedBlob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${pdfFileName}.pdf`);
    link.click();
  };

  const markups = item ? item?.markups : file ? file?.markups : [];

  return (
    <div className="w-full flex flex-col h-full">
      <div className="w-full flex justify-start items-center box-border py-3 border-b border-solid border-taskinatorMedGrey gap-5 relative">
        <div className="w-fit flex gap-5 items-center border-taskinatorBlack ">
          {!fileDetailsView && !file && (
            <div className="w-fit flex items-center bg-taskinatorItemBlue px-4 py-2 rounded">
              <div className={`flex font-medium w-fit items-center pr-2`}>
                {item?.draft && (
                  <div className={`flex text-taskinatorOrange pr-1 border-r border-r-taskinatorMedGrey mr-1`}>
                    <MdUpdate size={"1rem"} />
                  </div>
                )}
                <div className="">{item?.itemDetails?.itemNumber}</div>
                <div className="px-2">-</div>
                <div className="uppercase w-fit whitespace-nowrap">{StringShortenDisplay(40, item?.itemDetails?.itemName)}</div>

                <div className={`lg:block hidden px-2 font-bold text-taskinatorMedGrey`}>|</div>
              </div>
              <div className={`flex xl:items-center`}>
                <div className={`items-center flex justify-start`}>
                  {item && (
                    <div className="flex items-center text-taskinatorDarkGrey flex-row grow">
                      <div className="flex">
                        <div className="pr-2 items-center justify-center flex">
                          <ItemStatus status={item.status} />
                        </div>

                        <div className="flex">
                          <div className="pr-2">Revision: </div>
                          <div className="font-semibold">{item.revision}</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="pl-4 flex items-center">
            Live Markups By:
            {annotationData?.length > 0 &&
              annotationData?.map((data) => {
                return <div className="capitalize border border-taskinatorGreen py-1 px-2 mx-1 rounded">{data}</div>;
              })}
            {annotationData?.length === 0 && <div className="text-taskinatorMedGrey pl-2">No Markups</div>}
          </div>

          <div className="pl-4 flex items-center pr-4">
            Attached Markups
            {markups?.length > 0 &&
              markups?.map((data, index) => {
                return (
                  <div className="mx-1" key={data.key}>
                    {item ? (
                      <DocumentDisplay viewInMarkup attachment={data.attachment} setMarkupDocument={(data) => setDocumentMarkupHandler(data)} />
                    ) : (
                      <div className={`${loadedPdf && loadedPdf.attachmentKey !== data.key ? "opacity-50" : ""}`}>
                        <FilePreview file={data} setMarkupDocument={(data) => setDocumentMarkupHandler(data)} />
                      </div>
                    )}
                  </div>
                );
              })}
            {!markups || (markups?.length === 0 && <div className="text-taskinatorMedGrey pl-2">No Markups</div>)}
          </div>
        <div className="flex items-center justify-end flex-1 text-taskiantorDarkGrey">
        
          {setExpand && (
            <div
              onClick={() => {
                setExpand(!expand);
              }}
              className="cursor-pointer text-taskiantorDarkGrey hover:text-taskinatorBlue mr-4"
            >
              {!expand ? <MdOutlineZoomOutMap size={25} /> : <MdOutlineZoomInMap size={25} />}
            </div>
          )}
          <div className="flex">
            <MdClose className="hover:opacity-50 cursor-pointer" size={35} onClick={() => close()} />
          </div>
        </div>
      </div>

      <div className="h-full w-full">
        <div className="flex flex-row h-full grow">
          <div className="flex flex-col grow">
            <div className="bg-taskinatorItemBlue h-12 text-taskinatorDarkGrey grow flex items-center justify-center font-bold uppercase">
              Current File
              <div className="pl-2">
                {primaryPDF?.url && (
                  <Button
                    label={"Download"}
                    isLoading={initState[0].started}
                    disabled={initState[0].started}
                    onClick={handleDownload}
                    icon={<MdDownload />}
                    customClasses={"bg-taskinatorBlue  border-taskinatorBlue text-taskinatorWhite h-8 text-xs md:text-sm py-2 w-full"}
                  />
                )}
              </div>
            </div>

            {primaryPDF?.url ? (
              <PDFViewer
                attachmentKey={attachmentKey}
                pdfFileName={pdfFileName}
                pdfURL={primaryPDF.url}
                item={item}
                setAnnotationAdded={() => {}}
                savedMarkupURL={savedMarkupURL}
                setSavedMarkupURL={() => {}}
                setSavedMarkup={() => {}}
                viewMarkupsOnly={viewMarkupsOnly}
                setAnnotationData={setAnnotationData}
                annotationData={annotationData}
                uniqueID={attachmentKey}
              />
            ) : (
              <div className="h-full flex justify-center items-center border">
                <IconSpinner size="l" />
              </div>
            )}
          </div>

          {loadedPdf && (
            <div className="flex flex-col w-1/2">
              <div className="bg-taskinatorHoverRed h-12 text-taskinatorDarkGrey grow flex items-center justify-center font-bold">Markups ({loadedPdf.pdfFileName})</div>
              <PDFViewer
                attachmentKey={loadedPdf.attachmentKey}
                pdfFileName={loadedPdf.pdfFileName}
                pdfURL={loadedPdf.pdfURL}
                item={item}
                setAnnotationAdded={() => {}}
                savedMarkupURL={savedMarkupURL}
                setSavedMarkupURL={() => {}}
                setSavedMarkup={() => {}}
                viewMarkupsOnly
                nomarkups
                setAnnotationData={() => {}}
                uniqueID={loadedPdf.attachmentKey}
                isMarkup
              />
            </div>
          )}
          {fileDetailsView && (
            <div className="bg-taskinatorLightGrey rounded m-1 w-96 h-full flex flex-col grow border border-taskinatorMedGrey overflow-auto scrollbar">
              <div className="bg-taskinatorWhite m-3 rounded">
                <CreateFile file={file} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdobePDFViewerContainer;
