import React, { useContext, useState } from "react";
import ColumnElement from "../../components/dashboard/columnElement";
import ColumnElementV2 from "../../components/dashboard/columnElement_V2";
import CheckboxV4 from "../../components/inputs/checkbox_V4";
import InputV4 from "../../components/inputs/input_V4";
import { AppContext } from "../../contexts/appContext";
import ContentView from "../../pageTemplates/contentView";
import JobFormatter from "../../utils/jobFormatter";
import NotificationFormatter from "../../utils/notificationFormatter";
import RequestFormatter from "../../utils/requestFormatter";
import TaskFormatter_bi from "../../utils/taskFormatter_byItemAction";

const Dashboard = () => {
  const [filter, setFilter] = useState();
  const [mine, setMine] = useState(true);

  const appData = useContext(AppContext);

  const getTasks = () => {};
  // const getProject = () => {};
  const getRequests = () => {};
  const getNotifications = () => {};

  const buttons = [];
  const centerContent = (
    <div className="flex items-center sm:flex-row flex-col w-full sm:w-fit sm:justify-start sm:mt-0 mt-1">
      <div className="pr-2">
        <CheckboxV4
          defaultValue={true}
          onInput={(id, isChecked, isValid, sid) => {
            setMine(isChecked);
          }}
          customCheckbox={"h-4 w-4"}
          label={"Only Show My Jobs"}
          customeLabel={"font-semibold text-taskinatorDarkGrey"}
        />
      </div>
      <InputV4
        inputVer={"1"}
        customClasses={"sm:w-96 w-[100%] items-center"}
        placeholder="Search Dashboard"
        onInput={(id, value, isValid, sid) => {
          setFilter(value);
        }}
        validators={[]}
      />
    </div>
  );
  const css = "md:w-1/3 m-1 h-auto md:min-w-[26rem]";
  // const canViewProjects =
  //   appData.workspaceDataV4.projects &&
  //   [
  //     process.env.REACT_APP_ACCESS_M,
  //     process.env.REACT_APP_ACCESS_A,
  //     process.env.REACT_APP_ACCESS_O,
  //   ].includes(appData.workspaceDataV4.role);

  return (
    <ContentView buttons={buttons} label={""} centerContent={centerContent}>
      <div className="flex flex-col h-full w-full">
        <div className="flex md:flex-row flex-col h-full w-full overflow-auto">
          <div className={`${css}`}>
            <ColumnElementV2
              type="item"
              primaryColour={"bg-taskinatorGreen"}
              faintColour={"bg-taskinatorGreenHover"}
              primaryBorder={"border-l-taskinatorGreen"}
              label="Jobs"
              jobData={Object.values(TaskFormatter_bi(appData.tasks, filter, mine, false, false, appData))}
              jobs={Object.values(JobFormatter(appData.jobs, filter, appData))}
              getData={getTasks}
              textColour={"text-taskinatorGreen"}
              initState={appData.dashboardState}
            />
          </div>

          <div className={`${css}`}>
            <ColumnElement
              type="request"
              primaryColour={"bg-taskinatorOrange"}
              faintColour={"bg-taskinatorHoverOrange"}
              primaryBorder={"border-l-taskinatorOrange"}
              label="Questions"
              getData={getRequests}
              jobData={Object.values(RequestFormatter(appData.questions, filter, appData))}
              initState={appData.dashboardState}
            />
          </div>

          <div className={`${css}`}>
            <ColumnElement
              type="notification"
              primaryColour={"bg-taskinatorRed"}
              faintColour={"bg-taskinatorHoverRed"}
              primaryBorder={"border-l-taskinatorRed"}
              label="Notifications"
              getData={getNotifications}
              jobData={Object.values(NotificationFormatter(appData.notifications, filter, appData))}
              initState={appData.dashboardState}
            />
          </div>
        </div>
      </div>
    </ContentView>
  );
};

export default Dashboard;
