import React, { useContext, useState } from "react";
import { AppContext } from "../../contexts/appContext";
import FilterOutUniqueIds from "../../utils/filterUniqueIds";
import Modal from "../UI_elements/modal";
import StringShortenDisplay from "../UI_elements/stringShortenDisplay";
import UserDisplayBubble from "../UI_elements/userDisplayBubble";
import RequestModal from "../displayElements/requests/requestModal";
import ElementCard from "./elementCard";
const ColumnRequestElement = ({ primaryBorder, data, getData }) => {
  const [modal, setModal] = useState();

  const appData = useContext(AppContext);
  const isNew = data.seen && data.seen.filter((seen) => seen._id === appData.workspaceDataV4.user_id).length === 0;
  const youreAssigned =
    data.assignees &&
    data.assignees
      .map((user) => {
        return user._id.toString();
      })
      .includes(appData.workspaceDataV4.user_id.toString());



  return (
    <>
      <ElementCard
        topContent={
          <div className="flex items-center text-xs">
            
            <div className="flex items-center">
                Job:
            <div className="mx-1 w-fit text-xs border-taskinatorDarkGrey border px-2 text-taskinatorDarkGrey rounded font-medium uppercase py-0.5 box-border">
                  {StringShortenDisplay(20, data?.ref)}
                </div>
                </div>

             <div className="pr-1 font-medium"> </div>
            {FilterOutUniqueIds(data.collaborators).map((user, index) => {
              if (index < 2)
                return (
                  <div className="flex items-center" key={user._id}>
                   
                    <UserDisplayBubble firstName={user.firstName} lastName={user.lastName} textSize={"text-xs"} user={user} />
                  </div>
                );
            })}
            {FilterOutUniqueIds(data.collaborators).length > 2 && <div className="text-xs ">...</div>}

            <div className=" mr-2 font-semibold  flex items-center">
              <div className="pr-1 font-medium"> We: </div>
              <div
                className={`py-1 px-2 rounded uppercase text-taskinatorWhite ${
                  !data.completed
                    ? data.urgancy && data.urgancy.toLowerCase() === "critical"
                      ? `${youreAssigned ? "bg-taskinatorRed" : "bg-taskinatorMedGrey"} animate-pulse`
                      : data.urgancy && data.urgancy.toLowerCase() === "urgent"
                      ? `${youreAssigned ? "bg-taskinatorOrange" : "bg-taskinatorMedGrey"}`
                      : `${youreAssigned ? "bg-taskinatorGreen" : "bg-taskinatorMedGrey"}`
                    : "bg-taskinatorMedGrey"
                }`}
              >
                {data.urgancy === "critical" ? "Can't Continue Task" : data.urgancy === "urgent" ? "Will Be Held Up Soon" : "Need ASAP"}
              </div>
            </div>
          </div>
        }
        bottomContent={<div className=" text-xs"><b>R:</b> {StringShortenDisplay(50, data.requestDetails)}</div>}
        isNew={isNew}
        createdDate={data.createdBy.date}
        primaryBorder={data.completed ? "border-taskinatorMedGrey" : primaryBorder}
        onClick={() => setModal(true)}
        // seenBy={data.seen}
        done={data.completedDate}
      />

      {modal && (
        <Modal title="Job Questions" size="medium" bgColour={"bg-taskinatorLightGrey"} local={() => setModal()}>
          <RequestModal request={data} group={"mine"} closeModal={() => setModal()} primaryBorder={primaryBorder} getData={getData} />
        </Modal>
      )}
    </>
  );
};

export default ColumnRequestElement;
