import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../contexts/appContext";
import Button from "../../UI_elements/button";
import Modal from "../../UI_elements/modal";
import StringShortenDisplay from "../../UI_elements/stringShortenDisplay";
import Tag from "../../UI_elements/tag";
import JobDetailsForm from "../../forms/jobDetailsForm";
import JobOnHold from "../task/JobOnHold";
import JobRequests from "../task/jobRequests";
import JobDetailsDisplay from "./jobDetailsDisplay";
import { JobModalContext } from "./jobModalContext";
import JobModalDropDownContainer from "./jobModalDropDownContainer";
import LowerCaseTrim from "../../../utils/lowerCaseAndTrim";
import JobFormatter from "../../../utils/jobFormatter";
import WorkflowGroup from "./workflowGroup";
import AddFileSetToJob from "./addFileSetToJob";
import { useInputV4 } from "../../../stateManagement/useInputV4";
import { VALIDATOR_MINLENGTH } from "../../forms/validators/validators";
import InputV4 from "../../inputs/input_V4";
import axios from "axios";
import { useInit } from "../../hooks/useInit";
import CountUpTimer from "../../../utils/countUpTimer";
import ApproveRejectForm from "../../forms/approveRejectForm";

const JobModalDetailDisplay = ({ allJobsSelectedJobData, allJobsViewHandler }) => {
  const [buttons, setButtons] = useState([]);
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  const [addFileSet, setAddFileSet] = useState();
  const [tasksDetails, setTaskDetails] = useState();
  const [workflowGroupId, setWorkflowGroupId] = useState();
  const [confirmDelete, setConfirmDelete] = useState();

  const appData = useContext(AppContext);

  const { inputState, inputHandler } = useInputV4({
    inputs: { search: { value: "", isValid: false } },
    isValid: false,
  });
  const filter = inputState.inputs.search.value;

  const jobData = appData?.viewJob?.jobId
    ? Object.values(JobFormatter(appData.jobs, "", appData)).find((job) => job._id.toString() === appData.viewJob.jobId)
    : Object.values(JobFormatter(allJobsSelectedJobData, "", appData)).find((job) => job._id.toString() === allJobsViewHandler);

  /// this code resets the mmodal view for the job when a task is removed
  useEffect(() => {
    if (!jobData) {
      appData.setJobViewHandler();
    }
  }, [jobData]);

  const isManager = [process.env.REACT_APP_ACCESS_A, process.env.REACT_APP_ACCESS_O, process.env.REACT_APP_ACCESS_M].includes(appData.workspaceDataV4.role);
  const isManagingThisJob = jobData?.jobDetails?.managers?.find((manager) => manager._id.toString() === appData.workspaceDataV4.user_id);

  const [editJobDetails, setEditJobDetails] = useState(false);

  const CompleteHandler = () => {
    initStarted(1);

    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/job/markjobascompleted/${jobData?._id}/${jobData?.completed ? "undo" : "completed"}`,
        { data: true },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response.data);
        initCompleted(1);
      })
      .catch((err) => {
        console.log(err);
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
      });
  };

  if (jobData) {
    return (
      <JobModalContext.Provider
        value={{
          jobId: appData?.viewJob?.jobId ? appData.viewJob.jobId : allJobsViewHandler,
          tasksDetails,
          setTaskDetails,
          workflowGroupId,
          setWorkflowGroupId,
          jobCompleted: jobData?.completed,
          filter: filter

          // selectedWorkflowData,
        }}
      >
        <div className={`p-2 xl:h-full h-fit overflow-auto flex xl:flex-row flex-col text-sm`}>
          <div className={`flex flex-col xl:h-full h-fit xl:grow xl:overflow-auto lg:mr-0.5 mr-0 scrollbar`}>
            <div className="w-full xl:h-full h-fit flex lg:flex-row flex-col grow lg:overflow-auto">
              <div className={`flex flex-col grow xl:overflow-auto`}>
                <div className={`flex flex-col h-fit md:mr-0.5 mr-0`}>
                  <div className="">
                    <JobModalDropDownContainer
                      openAtStart
                      headerContent={
                        <div className="flex justify-between items-center w-full">
                          <div className="flex grow justify-start items-center">
                            <div className="pl-1">Job:</div>
                            <b className="order-taskinatorWhite pl-2">{StringShortenDisplay(30, jobData?.jobDetails?.ref)}</b>
                            {/* <b className=" order-taskinatorWhite pl-2"> Job Details </b> */}
                            <div className={`flex lg:border-llg:ml-4 ml-0 lg:pr-2 lg:pl-4 pl-0 text-base font-light text-taskinatorMedGrey`}>
                              <div className={`flex items-center justify-center lg:py-0 py-2`}>
                                <div className="flex">
                                  {jobData?.jobDetails?.taskTags?.map((tag, index) => {
                                    if (index < 2) return <Tag tagData={tag} key={index} />;
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                          {isManagingThisJob && <div className=" bg-taskinatorOrange  px-2 text-xs text-taskinatorWhite py-0.5 font-medium rounded uppercase">You're Managing This Job</div>}
                          {jobData?.completed && (
                            <div className="text-xs  normal-case flex items-center">
                              Marked as <div className="font-bold uppercase text-taskinatorGreen border border-taskinatorGreen rounded py-1 px-0.5 ml-1">completed</div>{" "}
                              <div className="px-1">{<CountUpTimer startDate={jobData.completedDate} />}</div> ago by:{" "}
                              <div className="font-semibold flex capitalize pl-1">
                                {jobData.completedBy.firstName}
                                <div className="pl-1">{jobData.completedBy.lastName}</div>
                              </div>
                            </div>
                          )}
                          {jobData?.jobDetails?.onHold && (
                            <div
                              className={`border-taskinatorOrange text-taskinatorDarkGrey animate-pulse bg-taskinatorOrange font-medium uppercase h-8 flex items-center justify-center text-xs px-2 border rounded ml-2`}
                            >
                              ON HOLD
                            </div>
                          )}
                        </div>
                      }
                      backgroundColour={"bg-taskinatorDarkGrey"}
                    >
                      <JobDetailsDisplay jobDetails={jobData?.jobDetails} />
                      {isManager && (
                        <div className="flex justify-end mt-1">
                          <>
                            {!jobData?.completed && (
                              <JobOnHold
                                jobId={jobData?._id}
                                activator
                                taskDetails={jobData?.jobDetails}
                                // tracer={tracer}
                                // getData={() => getData()}
                              />
                            )}
                            {!jobData?.completed && (
                              <div className="">
                                <Button
                                  onClick={() => setConfirmDelete(true)}
                                  isLoading={initState[1].started}
                                  label={jobData?.completed ? "Re-Open Job" : "Close Job"}
                                  customClasses={`${
                                    jobData?.completed ? "bg-taskinatorOrange text-taskinatorBlack opacity-50" : "bg-taskinatorRed text-taskinatorWhite"
                                  } hover:opacity-75   py-2 text-xs uppercase ml-2`}
                                />
                              </div>
                            )}
                            {!jobData?.completed && (
                              <div className="">
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setEditJobDetails(true);
                                  }}
                                  label="Edit Details"
                                  customClasses={`border-taskinatorDarkGrey text-taskinatorDarkGrey hover:bg-taskinatorMedGrey hover:text-black uppercase h-8 flex items-center justify-center text-xs px-2 border rounded ml-2`}
                                />
                              </div>
                            )}
                          </>
                        </div>
                      )}
                    </JobModalDropDownContainer>
                  </div>
                  <div className="bg-taskinatorLightGrey rounded-lg mt-1 border border-taskinatorMedGrey p-2 ">
                    <div className="flex items-center justify-center w-full">
                      <InputV4
                        inputVer={"1"}
                        onInput={inputHandler}
                        customClasses="text-center border-taskinatorDarkGrey text-taskinatorDarkGrey  md:w-96 w-[100%]"
                        defaultValue={inputState.inputs.search.value}
                        placeholder={`Search for files by name, number, rev, extension or tag`}
                        sid="inputs"
                        id="search"
                        validators={[VALIDATOR_MINLENGTH(1)]}
                      />
                    </div>
                  </div>

                  {jobData &&
                    Object.values(jobData?.fileMapObject)
                      .sort((a, b) => {
                        if (b.name > a.name) {
                          return -1;
                        } else if (b.name < a.name) {
                          return 1;
                        }
                        return 0;
                      })
                      // .map((job) => {
                      //   if (!filter) {
                      //     return job;
                      //   } else {
                      //     if (filter.trim().length === 0) {
                      //       return job;
                      //     } else {
                      //       const filteredWorkflow = job.workflows
                      //         .map((wf) => {
                      //           const files = Object.values(wf.files).filter(
                      //             (file) =>
                      //               LowerCaseTrim(file?.fileName)?.includes(LowerCaseTrim(filter)) ||
                      //               LowerCaseTrim(file?.revision ?? "N/A")?.includes(LowerCaseTrim(filter)) ||
                      //               LowerCaseTrim(file?.fileExtension)?.includes(LowerCaseTrim(filter)) ||
                      //               LowerCaseTrim(file?.status ?? "pending")?.includes(LowerCaseTrim(filter)) ||
                      //               file?.settings?.fileTags?.map((tag) => LowerCaseTrim(tag.name))?.includes(LowerCaseTrim(filter)) ||
                      //               file?.settings?.accessTags?.map((tag) => LowerCaseTrim(tag.name))?.includes(LowerCaseTrim(filter))
                      //           );

                      //           if (files.length > 0) {
                      //             return {
                      //               ...wf,
                      //               files: files,
                      //             };
                      //           }
                      //         })
                      //         .filter((data) => data);

                      //       if (filteredWorkflow.length > 0) {
                      //         return {
                      //           ...job,
                      //           workflows: filteredWorkflow,
                      //         };
                      //       }
                      //     }
                      //   }
                      // })
                      // .filter((data) => data)
                      .map((fileSet, index) => {
                        let selectedWorkflowData = [];

                        const totalNewFiles = fileSet.workflows.flat(1).reduce((accumulator, currentFile) => {
                          return accumulator + currentFile.newFileCount;
                        }, 0);

                        return (
                          <div className="pt-1" key={fileSet.fileSetId}>
                            {totalNewFiles > 0 && !jobData?.completed && (
                              <div className="flex grow justify-end h-0 pt-2 z-40">
                                <div className="relative -top-2 mr-2 items-center flex h-4 w-fit text-taskinatorWhite text-[0.75rem] bg-taskinatorRed px-2 rounded-full font-semibold">
                                  {totalNewFiles}
                                </div>
                              </div>
                            )}

                            <JobModalDropDownContainer
                              openAtStart
                              headerContent={
                                <>
                                  <div className="pl-1">File Set:</div>
                                  <b className=" order-taskinatorWhite px-2">{fileSet.number}</b>
                                  <div>-</div>
                                  <b className=" order-taskinatorWhite pl-2">{fileSet.name}</b>
                                  {fileSet?.settings?.fileTags?.map((tag, index) => {
                                    if (index < 2) return <Tag tagData={tag} />;
                                  })}

                                  <div className="flex grow justify-end"></div>
                                </>
                              }
                              backgroundColour={"bg-taskinatorBlue"}
                            >
                              <div className="pt-1">
                                <WorkflowGroup fileSet={fileSet} />
                              </div>
                            </JobModalDropDownContainer>
                          </div>
                        );
                      })}
                  {!jobData.completed && (
                    <div className="flex items-center justify-center">
                      <Button
                        label={"Add Another File Set"}
                        onClick={() => setAddFileSet(true)}
                        customClasses={`my-2 border-taskinatorBlue text-taskinatorBlue hover:text-taskinatorWhite hover:bg-taskinatorBlue   w-fit border    text-xs  mx-1 py-2 `}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={`xl:w-1/4 w-full shrink-0 flex flex-col mt-1 xl:mt-0 overflow-auto`}>
            <div className="text-sm px-4 text-taskinatorWhite bg-taskinatorOrange shadow font-semibold uppercase flex w-full rounded-lg min-h-12">
              <div className="flex text-base uppercase items-center text-center w-full justify-center">Critical Questions</div>
              {/* <div className="flex grow justify-end items-center">{buttons.length > 0 && <Button label={buttons[0].label} customClasses={buttons[0].customClasses} onClick={buttons[0].onClick} />}</div> */}
            </div>
            <div className="bg-taskinatorHoverOrange p-1 flex-col grow flex mt-2 rounded-lg overflow-auto">
              <JobRequests group={"task"} tid={jobData?._id} canSetRequests={{ setButtons: setButtons, buttons: buttons }} />
            </div>
            {!jobData.completed && (
              <div className="flex h-fit mt-1 items-center w-full justify-end border border-taskinatorMedGrey p-2 rounded-lg">
                {buttons.length > 0 && <Button label={buttons[0].label} customClasses={buttons[0].customClasses} onClick={buttons[0].onClick} />}
              </div>
            )}
          </div>
        </div>

        {editJobDetails && (
          <Modal local={() => setEditJobDetails(false)} title="Edit Job Details">
            <div className="md:w-96 w-full">
              <JobDetailsForm
                jId={jobData?._id}
                details={jobData?.jobDetails}
                closeModal={() => setEditJobDetails(false)}
                // onSuccess={getData}
              />
            </div>
          </Modal>
        )}

        {addFileSet && (
          <Modal local={() => setAddFileSet(false)}>
            <AddFileSetToJob tid={appData.viewJob.jobId} closeModal={() => setAddFileSet(false)} />
          </Modal>
        )}

        {confirmDelete && (
          <Modal local={() => setConfirmDelete()}>
            <ApproveRejectForm
              message={<b className="text-taskinatorRed">Are you sure you want to close this Job - This is Permanent and cannot be undone!</b>}
              // requiredTextMatch={workflowData.name}
              confirmFunction={() => CompleteHandler()}
              rejectFunction={() => setConfirmDelete()}
              confirmLoadingState={initState[1].started}
              error={initState[1].error}
              confirmButtonLabel="CONFIRM CLOSING"
              confirmButtonCSS={"bg-taskinatorRed "}
              requiredTextMatch="CLOSE JOB"
            />
          </Modal>
        )}
      </JobModalContext.Provider>
    );
  }
};

export default JobModalDetailDisplay;
