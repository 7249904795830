import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useInit } from "../../hooks/useInit";

import IconSpinner from "../../UI_elements/iconSpinner";

const NextWorkflowDisplayAndSelect = ({ workflow, isSelected, onSelect, onAssignHandler }) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();
  const [workflowManagers, setWorkflowManagers] = useState();
  const [selectedManagers, setSelectedManagers] = useState({});
  const [actions, setActions] = useState([]);

  useEffect(() => {
    initStarted(1);

    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/workflow/managers/${workflow._id}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        setActions(response.data.actions);

        setWorkflowManagers(response.data.managers);
        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  }, [workflow]);

  // console.log(selectedManagers)
  const managerSelectHandler = (manager) => {
    let updatedManagers = selectedManagers;

    if (selectedManagers[manager._id]) {
      delete updatedManagers[manager._id];
    } else {
      updatedManagers = { ...updatedManagers, [manager._id]: manager };
    }
    setSelectedManagers({ ...updatedManagers });
    onSelect({ type: "MANAGER_CHANGE", wfid: workflow._id, managers: Object.values(updatedManagers) });
  };

  return (
    <div>
      <div
        className={`w-full cursor-pointer text-xs text-center ${
          isSelected
            ? "border-taskinatorGreen bg-taskinatorHoverGreen text-taskinatorWhite hover:opacity-75 rounded-t mt-2  "
            : "mt-1 border-taskinatorGreen  hover:bg-taskinatorHoverGreen hover:text-taskinatorWhite text-taskinatorGreen rounded "
        }  uppercase border  p-2 font-semibold   `}
        key={workflow._id}
        onClick={() => onSelect({ type: isSelected ? "DELETE_WORKFLOW" : "ADD_WORKFLOW", workflow: workflow, autoSelect: actions })}
      >
        {workflow.name}
      </div>
      {isSelected && (
        <div className="flex flex-col w-full border border-taskinatorGreen border-t-0 rounded-b p-2  mb-2">
          {!workflowManagers && initState[0].started && <IconSpinner />}
          {workflowManagers && initState[0].finished && (
            <div className="flex items-center  w-full ">
              <div className="text-xs pr-1">Managers To Notify:</div>
              {workflowManagers.map((manager) => {
                return (
                  <div
                    onClick={() => {
                      managerSelectHandler(manager);
                    }}
                    className={`${
                      selectedManagers[manager._id] ? "bg-taskinatorDarkGrey  text-taskinatorWhite" : ""
                    } cursor-pointer hover:bg-taskinatorDarkGrey capitalize font-medium hover:text-taskinatorWhite mr-1 border rounded px-1 py-1 text-xs text-taskinatorDarkGrey  border-taskinatorDarkGrey  rounded `}
                  >
                    {manager.firstName}
                  </div>
                );
              })}
              <div
                onClick={() => {
                  setSelectedManagers({});
                  onSelect({ type: "MANAGER_CHANGE", wfid: workflow._id, managers: Object.values({}) });
                }}
                className={`${
                  Object.values(selectedManagers).length === 0 ? "bg-taskinatorDarkGrey text-taskinatorWhite" : ""
                } cursor-pointer hover:bg-taskinatorDarkGrey  capitalize font-medium hover:text-taskinatorWhite mr-1 border rounded px-1 py-1 text-xs text-taskinatorDarkGrey  border-taskinatorDarkGrey rounded `}
              >
                All
              </div>
            </div>
          )}
          {actions && actions.length > 0 && isSelected && (
            <div className="flex items-center  w-full text-xs my-2">
              <div className=" pr-1">Workflow Task/s Auto Selected: </div>
              {actions[0]
                .filter((action) => action.autoSelect)
                .map((action) => {
                  return <div className=" border rounded border-taskiantorGreen py-1 px-2 mr-1 font-semibold uppercase text-taskinatorGreen">{action.name}</div>;
                })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NextWorkflowDisplayAndSelect;
