import React, { useCallback } from "react";
import MultiSelectDropdown from "../inputs/multiSelectDropdown";

const WorkflowNextWorkflow = ({ workflowOptions, currentWorkFlow, dispatch }) => {
  const nextWorkflowOptions = workflowOptions
    .filter((workflow) => workflow._id !== currentWorkFlow._id && workflow.isValid)
    .map((workflow) => {
      return {
        type: "workflows",
        name: workflow.name,
        value: workflow._id,
        data: workflow,
      };
    });

  const onSelectionHandler = useCallback((id, value, isValid, sid) => {
    dispatch({ type: "NEXT_WORKFLOWS", nextAvailableWorkflows: value });
  }, []);

  return (
    <div className="border-taskinatorMedGrey border-2 rounded-lg shadow p-2 w-60 items-center justify-center shrink-0 bg-taskinatorWhite">
      {/* <div className="text-center text-taskinatorDarkGrey pb-1 text-sm">Workflows</div> */}
      <MultiSelectDropdown
        dontAutoSelect
        // label={"Next Sequence"}
        notRequired
        inputVer={"1"}
        selectionDisplayText="Select Sequence "
        onSelection={onSelectionHandler}
        options={nextWorkflowOptions}
        defaultValue={currentWorkFlow.nextAvailableWorkflows.map((nextOptions) => {
          return { value: nextOptions._id };
        })}
      />
    </div>
  );
};

export default WorkflowNextWorkflow;
